import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "./StationCategory.module.css";
import { StationRouterBusList } from "./components/StationRouterBusList";
import { StationList } from "./components/StationList";
import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";
import { StationForm } from "./components/StationForm";
import { Modal, notification } from "antd";
import {
  addStation,
  editStation,
  editStationRouterBus,
  fetchStations,
  saveStation,
  setAddStationBusOpen,
  setAddStationOpen,
  setErrMsg,
  fetchBuses,
  fetchRouters,
  fetchLocations,
  addStationBus,
  saveStationBus,
} from "store/slice/StationCategorySlice";
import { RouterBusForm } from "./components/RouterBusForm";
export const StationCategory = () => {
  const selectAddStationOpen = useSelectorRoot(
    (state) => state.stationsCategory.addStationOpen
  );
  const selectStationEditing = useSelectorRoot(
    (state) => state.stationsCategory.editingStation
  );

  const addStationRouterBusOpen = useSelectorRoot(
    (state) => state.stationsCategory.addRouterBusStationOpen
  );
  const selectStationRouterBusEditing = useSelectorRoot(
    (state) => state.stationsCategory.editingStationRouterBus
  );

  const selectStationSelected = useSelectorRoot(
    (state) => state.stationsCategory.stationSelected
  );

  const selectIsRefreshing = useSelectorRoot(
    (state) => state.login.isRefreshing
  );
  const selectErrMsg = useSelectorRoot(
    (state) => state.stationsCategory.errMsg
  );
  const [stationForm] = useForm();
  const [stationBusForm] = useForm();
  const dispatch = useDispatchRoot();

  useEffect(() => {
    if (!selectIsRefreshing) {
      dispatch(fetchStations());
      dispatch(fetchLocations());
      dispatch(fetchBuses());
      dispatch(fetchRouters());
    }
  }, [dispatch, selectIsRefreshing]);

  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({
        message: selectErrMsg,
      });
      dispatch(setErrMsg(null));
    }
  }, [selectErrMsg, dispatch]);

  const closeAddStation = () => {
    dispatch(setAddStationOpen(false));
  };
  const handleCreateStation = async () => {
    const stationValue = await stationForm
      .validateFields()
      .catch((err) => console.log(err));
    if (stationValue) {
      dispatch(addStation(stationValue));
    }
  };

  const closeEditStation = () => {
    dispatch(editStation(null));
  };
  const handleSaveStation = async () => {
    const stationValue = await stationForm
      .validateFields()
      .catch((err) => console.log(err));
    if (stationValue && selectStationEditing) {
      dispatch(
        saveStation({
          ...selectStationEditing,
          ...stationValue,
        })
      );
    }
  };

  const closeAddStationRouterBus = () => {
    dispatch(setAddStationBusOpen(false));
  };
  const handleCreateStationRouterBus = async () => {
    const tzOffset = new Date().getTimezoneOffset();
    if (selectStationSelected) {
      const stationBusValue = await stationBusForm
        .validateFields()
        .catch((err) => console.log(err));

      if (stationBusValue) {
        dispatch(
          addStationBus({
            ...stationBusValue,
            stationId: selectStationSelected.id,
            date: stationBusValue.date
              .subtract(tzOffset, "minutes")
              .toISOString(),
          })
        );
      }
    }
  };

  const closeEditStationBus = () => {
    dispatch(editStationRouterBus(null));
  };
  const handleSaveStationRouterBus = async () => {
    const tzOffset = new Date().getTimezoneOffset();
    if (selectStationSelected && selectStationRouterBusEditing) {
      const stationBusValue = await stationBusForm
        .validateFields()
        .catch((err) => console.log(err));
      if (stationBusValue) {
        dispatch(
          saveStationBus({
            ...selectStationRouterBusEditing,
            ...stationBusValue,
            stationId: selectStationSelected.id,
            date: stationBusValue.date
              .subtract(tzOffset, "minutes")
              .toISOString(),
          })
        );
      }
    }
  };

  return (
    <div className={styles.agentsContainer}>
      <div className={styles.container}>
        <StationList />
        <StationRouterBusList />
      </div>

      {selectAddStationOpen && (
        <Modal
          open={selectAddStationOpen}
          title="Thêm nhà xe"
          onCancel={closeAddStation}
          afterClose={() => stationForm.resetFields()}
          destroyOnClose
          okText="Lưu"
          cancelText="Hủy"
          onOk={handleCreateStation}
        >
          <StationForm form={stationForm} />
        </Modal>
      )}
      {selectStationEditing !== null && (
        <Modal
          open={!!selectStationEditing}
          title="Thông tin nhà xe"
          okText="Lưu"
          cancelText="Hủy"
          afterClose={() => stationForm.resetFields()}
          destroyOnClose
          onCancel={closeEditStation}
          onOk={handleSaveStation}
        >
          <StationForm
            form={stationForm}
            editingStation={selectStationEditing}
          />
        </Modal>
      )}

      {addStationRouterBusOpen && (
        <Modal
          open={addStationRouterBusOpen}
          title="Thêm tuyến xe"
          onCancel={closeAddStationRouterBus}
          afterClose={() => stationBusForm.resetFields()}
          destroyOnClose
          okText="Lưu"
          cancelText="Hủy"
          onOk={handleCreateStationRouterBus}
        >
          <RouterBusForm form={stationBusForm} />
        </Modal>
      )}
      {selectStationRouterBusEditing !== null && (
        <Modal
          open={!!selectStationRouterBusEditing}
          title="Thông tin tuyến xe"
          okText="Lưu"
          cancelText="Hủy"
          afterClose={() => stationBusForm.resetFields()}
          destroyOnClose
          onCancel={closeEditStationBus}
          onOk={handleSaveStationRouterBus}
        >
          <RouterBusForm
            form={stationBusForm}
            editingRouterBus={selectStationRouterBusEditing}
          />
        </Modal>
      )}
    </div>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  RootEpic,
  IComboTour,
  IComboTourParams,
  ICommonResult,
  ICreateComboTour,
  IUpdateComboTour,
  IUpdateComboTourBuses,
} from "common/define-types";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import {
  createComboTour,
  getAllComboToursPageable,
  removeComboTour,
  updateComboTour,
  updateComboTourBuses,
} from "api/comboTour.api";
import { routerStationBusSlice } from "./RouterStationBus";

export interface ComboTourCategoryState {
  isLoading: boolean;
  isSubmitting: boolean;
  comboTours: IComboTour[] | [];
  errMsg: string | null;
  comboToursResult: ICommonResult | null;
  addModalOpen: boolean;
  deletingComboTourId: string | null;
  editingComboTour: IComboTour | null;
  editingComboTourBuses: IComboTour | null;
  filterValue: IComboTourParams | null;
}

const initialState: ComboTourCategoryState = {
  isLoading: false,
  isSubmitting: false,
  comboTours: [],
  errMsg: null,
  comboToursResult: null,
  addModalOpen: false,
  deletingComboTourId: null,
  editingComboTour: null,
  editingComboTourBuses: null,
  filterValue: {
    search: "",
  },
};

export const comboTourCategorySlice = createSlice({
  name: "comboTourCategory",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchComboTourCategorys: (
      state,
      action: PayloadAction<IComboTourParams | undefined>
    ) => {
      state.isLoading = true;
      state.isSubmitting = false;
      state.addModalOpen = false;
      state.editingComboTour = null;
      state.deletingComboTourId = null;
      state.errMsg = null;
    },
    setComboTours: (state, action: PayloadAction<IComboTour[]>) => {
      state.comboTours = action.payload;
      state.isLoading = false;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
    setComboToursResult: (
      state,
      action: PayloadAction<ICommonResult | null>
    ) => {
      state.comboToursResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    editComboTour: (state, action: PayloadAction<IComboTour | null>) => {
      state.editingComboTour = action.payload;
    },
    editComboTourBuses: (state, action: PayloadAction<IComboTour | null>) => {
      state.editingComboTourBuses = action.payload;
      state.isSubmitting = false;
    },
    addComboTour: (state, action: PayloadAction<ICreateComboTour>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    deleteComboTour: (state, action: PayloadAction<string>) => {
      state.deletingComboTourId = action.payload;
      state.errMsg = null;
    },
    setAddModalOpen: (state, action: PayloadAction<boolean>) => {
      state.addModalOpen = action.payload;
    },
    saveComboTour: (state, action: PayloadAction<IUpdateComboTour>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    saveComboTourBuses: (
      state,
      action: PayloadAction<IUpdateComboTourBuses>
    ) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    // saveDiscountTour: (state, action: PayloadAction<{ agentId: string, body: IUpdateDiscountAgent }>) => {
    // },
    // saveDiscountBus: (state, action: PayloadAction<{ agentId: string, body: IUpdateDiscountAgent }>) => {
    // },
    setComboTourCategoryFilterValue: (
      state,
      action: PayloadAction<IComboTourParams>
    ) => {
      state.filterValue = {
        ...state.filterValue,
        ...action.payload,
      };
    },
  },
});
const getComboTourCategorys$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchComboTourCategorys.match),
    switchMap((re) => {
      return getAllComboToursPageable({ ...re.payload }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.Results) {
            const {
              Results: comboTours,
              FirstRowIndex,
              LastRowIndex,
              Page,
              PageCount,
              PageSize,
              QueryCount,
            } = res;
            // if (res && !res?.response?.error && res) {
            //   const comboTours = res;
            return [
              comboTourCategorySlice.actions.setComboTours(comboTours ?? []),
              comboTourCategorySlice.actions.setComboToursResult({
                firstRowIndex: FirstRowIndex,
                lastRowIndex: LastRowIndex,
                page: Page,
                pageCount: PageCount,
                pageSize: PageSize,
                queryCount: QueryCount,
              }),
              // [28-3-2024][Phuc Thinh][Re-fetch router station bus when data comboTour is changed]
              routerStationBusSlice.actions.fetchRouterStationBusData({
                dateTime: new Date().toISOString(),
              }),
            ];
          } else {
            return [
              comboTourCategorySlice.actions.setErrMsg(res?.response.error),
              comboTourCategorySlice.actions.setComboTours([]),
              comboTourCategorySlice.actions.setComboToursResult(null),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          comboTourCategorySlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi lấy danh sách tour"
          ),

          comboTourCategorySlice.actions.setComboTours([]),
          comboTourCategorySlice.actions.setComboToursResult(null),
        ])
      );
    })
  );
const getComboToursWhenFilter$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setComboTourCategoryFilterValue.match),
    switchMap((re) => {
      return getAllComboToursPageable({
        ...state$.value.comboTourCategory.comboToursResult,
        ...re.payload,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.Results) {
            const {
              Results: comboTours,
              FirstRowIndex,
              LastRowIndex,
              Page,
              PageCount,
              PageSize,
              QueryCount,
            } = res;
            // if (res && !res?.response?.error && res) {
            //   const comboTours = res;
            return [
              comboTourCategorySlice.actions.setComboTours(comboTours ?? []),
              comboTourCategorySlice.actions.setComboToursResult({
                firstRowIndex: FirstRowIndex,
                lastRowIndex: LastRowIndex,
                page: Page,
                pageCount: PageCount,
                pageSize: PageSize,
                queryCount: QueryCount,
              }),
              routerStationBusSlice.actions.fetchRouterStationBusData({
                dateTime: new Date().toISOString(),
              }),
            ];
          } else {
            return [
              comboTourCategorySlice.actions.setErrMsg(res?.response.error),
              comboTourCategorySlice.actions.setComboTours([]),
              comboTourCategorySlice.actions.setComboToursResult(null),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          comboTourCategorySlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi lấy danh sách tour"
          ),

          comboTourCategorySlice.actions.setComboTours([]),
          comboTourCategorySlice.actions.setComboToursResult(null),
        ])
      );
    })
  );
const addComboTour$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addComboTour.match),
    switchMap((re) => {
      return createComboTour(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.comboTourCategory.comboToursResult) {
              return [
                comboTourCategorySlice.actions.fetchComboTourCategorys(
                  state$.value.comboTourCategory.comboToursResult
                ),
              ];
            }
            return [comboTourCategorySlice.actions.fetchComboTourCategorys()];
          } else {
            return [
              comboTourCategorySlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          comboTourCategorySlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi thêm tour"
          ),
        ])
      );
    })
  );
const saveComboTour$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveComboTour.match),
    switchMap((re) => {
      return updateComboTour(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.comboTourCategory.comboToursResult) {
              return [
                comboTourCategorySlice.actions.fetchComboTourCategorys(
                  state$.value.comboTourCategory.comboToursResult
                ),
              ];
            }
            return [comboTourCategorySlice.actions.fetchComboTourCategorys()];
          } else {
            return [
              comboTourCategorySlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          comboTourCategorySlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi lưu thông tin tour"
          ),
        ])
      );
    })
  );
const saveComboTourBuses$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveComboTourBuses.match),
    switchMap((re) => {
      return updateComboTourBuses(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [comboTourCategorySlice.actions.editComboTourBuses(null)];
          } else {
            return [
              comboTourCategorySlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          comboTourCategorySlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi lưu thông tin tuyến xe"
          ),
        ])
      );
    })
  );
const deleteComboTour$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteComboTour.match),
    switchMap((re) => {
      return removeComboTour(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.comboTourCategory.comboToursResult) {
              return [
                comboTourCategorySlice.actions.fetchComboTourCategorys(
                  state$.value.comboTourCategory.comboToursResult
                ),
              ];
            }
            return [comboTourCategorySlice.actions.fetchComboTourCategorys()];
          } else {
            return [
              comboTourCategorySlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          comboTourCategorySlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi xóa tour"
          ),
        ])
      );
    })
  );

export const {
  fetchComboTourCategorys,
  setComboTours,
  setErrMsg,
  addComboTour,
  setAddModalOpen,
  saveComboTour,
  saveComboTourBuses,
  editComboTour,
  editComboTourBuses,
  deleteComboTour,
  setComboTourCategoryFilterValue,
  // saveDiscountBus,
  // saveDiscountTour
} = comboTourCategorySlice.actions;

export const ComboTourCategoryEpics = [
  getComboTourCategorys$,
  addComboTour$,
  saveComboTour$,
  deleteComboTour$,
  getComboToursWhenFilter$,
  saveComboTourBuses$,
];

export const comboTourCategoryReducer = comboTourCategorySlice.reducer;

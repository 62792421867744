import {
  Typography,
  Button,
  Pagination,
  PaginationProps,
  Skeleton,
  // notification,
} from "antd";
import styles from "../StationCategory.module.css";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatchRoot, useSelectorRoot } from "store/store";
// import { ChangeEvent, useState } from "react";
import { RoleEnum } from "common/define-types";
import {
  fetchStationRouterBuses,
  setAddStationBusOpen,
} from "store/slice/StationCategorySlice";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import { RouterBusItem } from "./RouterBusItem";

export const StationRouterBusList = () => {
  // const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const selectStationBuses = useSelectorRoot(
    (state) => state.stationsCategory.stationSelectedRouterBuses
  );
  const selectStationSelected = useSelectorRoot(
    (state) => state.stationsCategory.stationSelected
  );
  const selectStationBusesResult = useSelectorRoot(
    (state) => state.stationsCategory.routerBusResults
  );
  const selectIsBusFetching = useSelectorRoot(
    (state) => state.stationsCategory.loading.isRouterBusFetching
  );
  const userRole = useSelectorRoot((state) => state.login.role);
  const dispatch = useDispatchRoot();

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectStationBusesResult) {
      dispatch(
        fetchStationRouterBuses({
          ...selectStationBusesResult,
          page,
        })
      );
    } else {
      dispatch(fetchStationRouterBuses());
    }
  };

  const openAddModal = () => {
    dispatch(setAddStationBusOpen(true));
  };

  return (
    <div className={styles.listContainer}>
      <div className={styles.listContainer_header}>
        <Typography.Text strong style={{ fontSize: 20 }}>
          Danh sách tuyến xe
        </Typography.Text>
        <div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={openAddModal}
            disabled={
              userRole !== RoleEnum.ADMIN || selectStationSelected === null
            }
          >
            Thêm tuyến xe
          </Button>
        </div>
      </div>
      <div className={styles.searchContainer}>
        {selectIsBusFetching ? (
          <Skeleton.Input block={true} />
        ) : (
          <>
            <Typography.Text>{`Số lượng tuyến xe: ${
              selectStationBuses?.length || 0
            }`}</Typography.Text>
            {/* <Input.Search
              allowClear
              style={{ flex: 1 }}
              placeholder="Tìm kiếm tài khoản"
              onChange={handleSearch}
            /> */}
          </>
        )}
      </div>
      <div className={styles.listWrapper}>
        <div className={`${styles.listWrapper_List} customScroll`}>
          {selectIsBusFetching ? (
            <>
              <Skeleton paragraph={{ rows: 3 }} />
              <Skeleton paragraph={{ rows: 3 }} />
              <Skeleton paragraph={{ rows: 3 }} />
            </>
          ) : selectStationBuses && selectStationBuses.length > 0 ? (
            [...selectStationBuses].map((bus) => (
              <RouterBusItem routerBus={bus} key={bus.id} />
            ))
          ) : (
            <EmptyHolder message="Chưa có tuyến xe" />
          )}
        </div>
      </div>
      <div className={styles.listContainer_footer}>
        <Pagination
          total={selectStationBusesResult?.queryCount || 0}
          pageSize={selectStationBusesResult?.pageSize || 10}
          current={selectStationBusesResult?.page || 1}
          onChange={handleChangePage}
          showTotal={(total, range) =>
            `Hiển thị ${
              range[1] === 0 && range[1] - range[0] === 0
                ? 0
                : range[1] - range[0] + 1
            }/${total} tuyến xe`
          }
        />
      </div>
    </div>
  );
};

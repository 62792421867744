import { Segmented, SegmentedProps, Skeleton, Typography } from 'antd';

import styles from '../Operation.module.css';
import { GroupDetailView } from './GroupDetailView';
import { Journey } from './Journey';
import { CustomerList } from './CustomerList';
import { JobDateEnum } from 'common/define-types';
import { useDispatchRoot, useSelectorRoot } from 'store/store';
import { setJobDateSelected } from 'store/slice/GroupSlice';
import { useForm } from 'antd/es/form/Form';
import Utils from 'common/Utils';
import { SegmentedValue } from 'antd/es/segmented';

export const GroupDetailContainer = () => {
  const selectJobDateSelected = useSelectorRoot(
    (state) => state.group.jobDateSelected
  );
  // const selectGroupTickets = useSelectorRoot(
  //   (state) => state.group.groupTickets
  // );
  const selectGroupSelected = useSelectorRoot(
    (state) => state.group.groupSelected
  );
  const selectIsJobLoading = useSelectorRoot(
    (state) => state.group.isJobLoading
  );
  const selectIsJobTicketsLoading = useSelectorRoot(
    (state) => state.group.isJobTicketsLoading
  );
  // const selectDrivers = useSelectorRoot((state) => state.driver.drivers);
  const jobDateOptions: SegmentedProps['options'] = [
    {
      value: JobDateEnum.ALL,
      label: `All`,
    },
  ];
  const dispatch = useDispatchRoot();
  //[10-5-2024][Phuc Thinh][Fix bugs all days are red text]
  
  const options: SegmentedProps['options'] = selectGroupSelected
    ? new Array(Utils.getGroupCountDay(selectGroupSelected) || 4)
        .fill(null)
        .map((_, index) => ({
          label: (
            <Typography.Text
              // style={{
              //   color:
              //     Utils.checkGroupEndDateIsBeforeCountDay(
              //       selectGroupSelected
              //     ) &&
              //     index + 1 === (Utils.getGroupCountDay(selectGroupSelected) || 4)
              //       ? 'red'
              //       : '#000000a6',
              // }}
            >
              {`Ngày ${index + 1}`}
            </Typography.Text>
          ),
          value: index + 1,
        }))
    : [];
  const [groupInfoForm] = useForm();
  const selectIsSubmitting = useSelectorRoot(
    (state) => state.group.isSubmitting
  );
  const selectGroupSelectedCount = useSelectorRoot(
    (state) => state.group.groupSelectedCount
  );
  // const selectMotorbikes = useSelectorRoot(
  //   (state) => state.group.groupMotorbikes
  // );
  const selectGroupIssues = useSelectorRoot((state) => state.group.groupIssue);

  return (
    <div
      className={styles.listContainer}
      style={{
        width: 'clamp(320px, 100%, 65rem)',
        marginBottom: '1rem',
        minWidth: 320,
      }}
    >
      <div className={styles.listContainer_header}>
        {selectIsJobLoading || selectIsJobTicketsLoading ? (
          <>
            <Skeleton.Input active={true} block={true} />
          </>
        ) : (
          <Segmented
            options={[...jobDateOptions, ...options]}
            value={selectJobDateSelected}
            onChange={(value: SegmentedValue) =>
              dispatch(setJobDateSelected(value))
            }
          />
        )}
      </div>
      <div className={styles.detailContainer}>
        <div className={styles.detailWrapper}>
          <div className={styles.detailWrapper_column}>
            <div
              className={styles.detailWrapper_item}
              style={{ minHeight: 365 }}
            >
              <GroupDetailView
                group={selectGroupSelected}
                form={groupInfoForm}
                isSubmitting={selectIsSubmitting || selectIsJobLoading}
                issues={selectGroupIssues}
                countDriver={selectGroupSelectedCount?.countDriver || 0}
                countMotor={selectGroupSelectedCount?.countMotor || 0}
                countTicket={selectGroupSelectedCount?.countTicket || 0}
              />
            </div>
            <div className={styles.detailWrapper_item}>
              <Journey />
            </div>
          </div>
          <div className={styles.detailWrapper_column}>
            <div className={styles.detailWrapper_item}>
              <CustomerList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

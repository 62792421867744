import {
  GroupTicketType,
  IGroupTicket,
  IJobTicket,
  IMotorbike,
  IsDriverNumberType,
  IsDriverType,
  JobTicketStatusEnum,
  JobTicketType,
  JobTypeItem,
  SelfMotor,
} from 'common/define-types';
import styles from '../Operation.module.css';
import { Checkbox, Divider, Typography } from 'antd';
import DriverIcon from 'image/icon/driver-icon-green.svg';
import MotorbikeIcon from 'image/icon/motorbike-icon-blue.svg';
import DisabledDriverIcon from 'image/icon/driver-icon-grey.svg';
import DisabledMotorbikeIcon from 'image/icon/motorbike-icon-grey.svg';
import { PhoneOutlined } from '@ant-design/icons';
import { useDispatchRoot, useSelectorRoot } from 'store/store';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { checkJobTickets, uncheckJobTickets } from 'store/slice/GroupSlice';
import { useMemo } from 'react';

interface IProps {
  ticket: IJobTicket;
  isCancelTour?: boolean;
  listHasJobTicketDriver?: boolean;
  listHasJobTicketMotor?: boolean;
  isMissingTourLead?: boolean;
}
export const Customer = ({
  ticket,
  isCancelTour = false,
  listHasJobTicketDriver = false,
  listHasJobTicketMotor = false,
  isMissingTourLead = false,
}: IProps) => {
  const selectGroupTickets = useSelectorRoot(
    (state) => state.group.groupTickets
  );
  const ticketInfor: IGroupTicket | undefined = selectGroupTickets.find(
    (gTicket) => gTicket.id === ticket.ticketId
  );
  const selectDrivers = useSelectorRoot((state) => state.group.allDrivers);
  const selectMotorbikes = useSelectorRoot(
    (state) => state.group.allMotorbikes
  );
  const selectAdditionalMotorbikes = useSelectorRoot(
    (state) => state.motorbike.additionalMotor
  );
  const selectJobTourSelected = useSelectorRoot(
    (state) => state.group.jobTourSelected
  );
  const allMotorOptions: IMotorbike[] = useMemo(() => {
    return [...selectMotorbikes, ...selectAdditionalMotorbikes];
  }, [selectMotorbikes, selectAdditionalMotorbikes]);
  const foundDriver = selectDrivers.find(
    (driver) => driver.id === ticket.driverId
  );
  const foundMotor = allMotorOptions.find(
    (motor) => motor.id === ticket.motorbikeId
  );
  const selectJobTickets = useSelectorRoot((state) => state.group.jobTickets);
  const dispatch = useDispatchRoot();

  const getIsDriver = (ticket: IGroupTicket) => {
    if (ticket.isDriver === IsDriverNumberType.DRIVER_PRICE)
      return ticket.driverPrice?.isDriver === IsDriverType.YES;
    return ticket.isDriver === IsDriverNumberType.YES;
  };

  const handleCheckJobTicket = (
    e: CheckboxChangeEvent,
    jobTicketType: JobTicketType
  ) => {
    const foundJobTicket = selectJobTickets.find(
      (jTicket) =>
        jTicket.ticketId === ticket.ticketId && jTicket.type === jobTicketType
    );
    if (foundJobTicket) {
      if (e.target.checked) {
        dispatch(checkJobTickets([foundJobTicket]));
      } else {
        dispatch(uncheckJobTickets([foundJobTicket]));
      }
    }
  };

  const renderCheckbox = (jobTicketType: JobTicketType) => {
    const foundJobTicket = selectJobTickets.find(
      (jTicket) =>
        jTicket.ticketId === ticket.ticketId && jTicket.type === jobTicketType
    );
    const foundJasmineMotor = selectMotorbikes.find(
      (motor) => motor.id === foundJobTicket?.motorbikeId
    );
    if (jobTicketType !== JobTicketType.Tickets && !foundJobTicket) {
      return (
        <div className={styles.checkboxItem}>
          <Checkbox style={{ opacity: '0', pointerEvents: 'none' }} />
        </div>
      );
    }
    if (jobTicketType === JobTicketType.Tickets) {
      if (
        ticketInfor?.type === GroupTicketType.DUMMY &&
        (selectJobTourSelected?.jobItem?.typeItem !==
          JobTypeItem.DriverTicket ||
          !foundJasmineMotor)
      ) {
        return (
          <div className={styles.checkboxItem}>
            <Checkbox style={{ opacity: '0', pointerEvents: 'none' }} />
          </div>
        );
      }
    }
    return (
      <div className={styles.checkboxItem}>
        <Checkbox
          className='strongBorder'
          checked={foundJobTicket?.isCheck}
          onChange={(e: CheckboxChangeEvent) =>
            handleCheckJobTicket(e, jobTicketType)
          }
          disabled={
            isCancelTour ||
            isMissingTourLead ||
            foundJobTicket?.status === JobTicketStatusEnum.BREAKUPTOUR
          }
        />
      </div>
    );
  };

  return (
    <div
      className={`${styles.customerContainer} ${
        isCancelTour ? styles.disabled : isMissingTourLead ? styles.warning : ''
      }`}
    >
      <div className={styles.customerWrapper}>
        <div className={styles.customerRow}>
          <Typography.Text
            strong
            className={styles.customerEvenColumn}
            style={{ textTransform: 'capitalize' }}
            title={(ticketInfor?.fullName ?? 'Trống').toLowerCase()}
          >
            {`${(ticketInfor?.fullName || 'Trống').toLowerCase()}`}
          </Typography.Text>
          <div className={styles.inforWithIcon}>
            <PhoneOutlined
              rotate={90}
              style={{
                fontSize: 20,
                color: isCancelTour ? '#BFBFBF' : '#FBBC04',
              }}
            />
            <span
              className={styles.textEllipsis}
              title={ticketInfor?.phone ?? ''}
            >
              {ticketInfor?.phone || 'Trống'}
            </span>
          </div>
        </div>
        <Divider style={{ marginBlock: 8 }} />
        <div className={styles.customerRow}>
          <div className={styles.inforWithIcon}>
            <img
              src={isCancelTour ? DisabledDriverIcon : DriverIcon}
              alt='driver'
            />
            <span className={styles.textEllipsis}>
              {!!ticketInfor && getIsDriver(ticketInfor)
                ? foundDriver
                  ? foundDriver.name
                  : 'Trống'
                : 'Tự lái'}
            </span>
          </div>
          <div className={styles.inforWithIcon}>
            <img
              src={isCancelTour ? DisabledMotorbikeIcon : MotorbikeIcon}
              alt='motorbike'
            />
            <span className={styles.textEllipsis}>
              {ticketInfor?.selfMotor === SelfMotor.NO
                ? foundMotor?.motorNumber || 'Trống'
                : 'Xe riêng của khách'}
              {/* {!ticket.motorbikeId
                ? "Xe riêng của khách"
                : allMotorOptions.filter(
                    (motorbike) => motorbike.id === ticket.motorbikeId
                  ).length > 0
                ? allMotorOptions.filter(
                    (motorbike) => motorbike.id === ticket.motorbikeId
                  )[0].motorNumber
                : "Trống"} */}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.checkboxContainer}>
        {renderCheckbox(ticket.type)}
        {listHasJobTicketDriver && renderCheckbox(JobTicketType.Driver)}
        {listHasJobTicketMotor && renderCheckbox(JobTicketType.Motor)}
      </div>
    </div>
  );
};

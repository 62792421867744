import styles from "../Booking.module.css";
import { PayeeProfile } from "./Billing/PayeeProfile";
import { PaymentMethod } from "./Billing/PaymentMethod";
import { TourDetail } from "./Billing/TourDetail";
import { Form, notification } from "antd";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { saveBooking, submitBooking } from "store/slice/BookingSlice";
import { IUpdateBooking, IUpdatePaymentMethodBooking } from "api/types/booking";
import { IBookingTicket, IPaymentMethodBooking } from "common/define-types";
import moment from "moment";

//[14-12-2023][Phuc Thinh][Feature/14901]
//Change Tour to TourCombo
export const BillingInfo = () => {
  const [payeeForm] = Form.useForm();
  const selectComboToursSelected = useSelectorRoot(
    (state) => state.booking.selectedComboTours
  );
  const selectEditingBooking = useSelectorRoot(
    (state) => state.booking.editingBooking
  );
  const selectCurrentAgent = useSelectorRoot(
    (state) => state.booking.currentAgent
  );
  const dispatch = useDispatchRoot();
  const handleCreateBooking = async (
    paymentMethodBookings: IPaymentMethodBooking[]
  ) => {
    const payeeValue = await payeeForm.validateFields().catch((err) => {
      notification.error({
        message: "Vui lòng nhập thông tin người thanh toán",
      });
    });
    if (!payeeValue) return;
    const bookingTickets: any = [];
    if (selectComboToursSelected) {
      selectComboToursSelected.forEach((selectedComboTour) => {
        if (selectedComboTour.tickets && selectedComboTour.tickets.length > 0) {
          bookingTickets.push(
            // create Booking
            ...selectedComboTour.tickets.map((ticket) => ({
              ...ticket,
              comboTourId: selectedComboTour.Id,
              driverId: ticket.driverId || "",
              bookingId: selectEditingBooking?.bookingId,
              comboTour: undefined,
            }))
          );
        }
      });
    }

    const postValue = {
      ...payeeValue,
      userPayName: `${payeeValue.lastName || ""} ${payeeValue.firstName || ""}`,
      agentId: selectCurrentAgent?.id,
      code: `Booking_${moment().format("DDMMYYYY")}`,
    };

    if (bookingTickets.length <= 0) {
      notification.error({ message: "Danh sách vé đang trống" });
      return;
    }

    // [11-12-2023][Phuc Thinh][Feature/14844]
    // add vitriDon, linkGGMap to busTicket
    dispatch(
      submitBooking({
        ...postValue,
        paymentMethodBookings: paymentMethodBookings,
        tickets: bookingTickets.map(({ id, ...rest }: IBookingTicket) => ({
          ...rest,
          busTicketC1: rest.busTicketC1
            ? {
                dateTime: rest.busTicketC1.dateTime,
                routerStationBusId: rest.busTicketC1.routerStationBusId,
                vitriDon: rest.busTicketC1.vitriDon,
                linkGGMap: rest.busTicketC1.linkGGMap,
                note: rest.busTicketC1.note,
              }
            : null,
          busTicketC2: rest.busTicketC2
            ? {
                dateTime: rest.busTicketC2.dateTime,
                routerStationBusId: rest.busTicketC2.routerStationBusId,
                // vitriDon: rest.busTicketC2.vitriDon,
                // linkGGMap: rest.busTicketC2.linkGGMap,
                note: rest.busTicketC2.note,
              }
            : null,
        })),
      })
    );
  };
  const handleUpdateBooking = async (
    paymentMethodBookingsConnect?: IUpdatePaymentMethodBooking[],
    paymentMethodBookingsDisable?: IUpdatePaymentMethodBooking[]
  ) => {
    const payeeValue = await payeeForm.validateFields().catch((err) =>
      notification.error({
        message: "Vui lòng nhập thông tin người thanh toán",
      })
    );
    if (!payeeValue) return;
    const bookingTickets: any = [];
    if (selectComboToursSelected) {
      selectComboToursSelected.forEach((selectedComboTour) => {
        if (selectedComboTour.tickets && selectedComboTour.tickets.length > 0) {
          bookingTickets.push(
            // create Booking
            ...selectedComboTour.tickets.map((ticket) => ({
              ...ticket,
              startDate: moment(ticket.startDate).add(7, "hours").toISOString(),
              comboTourId: selectedComboTour.Id,
              driverId: ticket.driverId || "",
              bookingId: selectEditingBooking?.bookingId,
              busTicketC1: ticket.busTicketC1
                ? {
                    ...ticket.busTicketC1,
                    dateTime: moment(ticket.busTicketC1.dateTime)
                      .add(7, "hours")
                      .toISOString(),
                  }
                : null,
              busTicketC2: ticket.busTicketC2
                ? {
                    ...ticket.busTicketC2,
                    dateTime: moment(ticket.busTicketC2.dateTime)
                      .add(7, "hours")
                      .toISOString(),
                  }
                : null,
              busTicketIdC1: ticket.busTicketC1 ? ticket.busTicketIdC1 : null,
              busTicketIdC2: ticket.busTicketC2 ? ticket.busTicketIdC2 : null,
              dockerId: ticket.dockerId ?? null,
              comboTour: undefined,
            }))
          );
        }
      });
    }

    const postValue = {
      ...payeeValue,
      userPayName: `${payeeValue.lastName} ${payeeValue.firstName}`,
      agentId: selectCurrentAgent?.id,
    };

    if (bookingTickets.length <= 0) {
      notification.error({ message: "Danh sách vé đang trống" });
      return;
    }

    if (selectEditingBooking !== null) {
      const { tickets, bookingId, ...restBookingValue } = selectEditingBooking;
      const editingBookingTickets = tickets.filter(
        (ticket: IBookingTicket) => ticket.isErase !== true
      );
      const updatePostValue: IUpdateBooking = {
        ...restBookingValue,
        ...postValue,
        ticketsUpdate: bookingTickets.filter((bTicket: IBookingTicket) =>
          editingBookingTickets.some((ticket) => bTicket.id === ticket.id)
        ),
        ticketsConnect: bookingTickets
          .filter((bTicket: IBookingTicket) =>
            editingBookingTickets.every((ticket) => bTicket.id !== ticket.id)
          )
          .map(({ id, ...rest }: IBookingTicket) => ({ ...rest })),
        ticketIdsDisable: editingBookingTickets
          .filter(
            (ticket) =>
              !bookingTickets.some(
                (bTicket: IBookingTicket) => bTicket.id === ticket.id
              )
          )
          .map((ticket) => ticket.id),
        paymentMethodBookingsConnect: paymentMethodBookingsConnect ?? undefined,
        paymentMethodBookingsDisable: paymentMethodBookingsDisable ?? undefined,
      };
      dispatch(saveBooking(updatePostValue));
    }
  };

  return (
    <div className={styles.billingInfoContainer}>
      <TourDetail />
      <PayeeProfile payeeForm={payeeForm} />
      <PaymentMethod
        handleSubmit={handleCreateBooking}
        handleUpdateBooking={handleUpdateBooking}
      />
    </div>
  );
};

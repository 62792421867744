import type { Identifier, XYCoord } from "dnd-core";
import { REACTDnD } from "common/constants";
import { GroupItemType, IGroupJobItem, MoveJobArgs } from "common/define-types";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import styles from "../TourCategory.module.css";
import { Divider, Tree, Typography } from "antd";
import { DragOutlined, DeleteOutlined } from "@ant-design/icons";
// import Utils from "common/Utils";

interface JobTourCardProps {
  job: IGroupJobItem;
  index: number;
  moveJob?: (args: MoveJobArgs) => void;
  deleteJob?: (jobTourId: string) => void;
  extra?: JSX.Element;
}
interface DragItem extends IGroupJobItem {
  index: number;
}
export const JobTourCard = ({
  job,
  moveJob,
  index,
  deleteJob,
  extra,
}: JobTourCardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: REACTDnD.ItemTypes.JOB_ITEM,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      //   const dragName = item.name;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        // console.log(dragIndex, hoverIndex, hoverClientY, hoverMiddleY);

        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        // console.log(dragIndex, hoverIndex, hoverClientY, hoverMiddleY);
        return;
      }
      // Time to actually perform the action
      moveJob && moveJob({ dragJob: item, hoverIndex });
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: REACTDnD.ItemTypes.JOB_ITEM,
    item: () => {
      return {
        ...job,
        index,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  preview(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className={styles.jobCardContainer}
    >
      <div className={styles.jobTourWrapper}>
        {job.type === GroupItemType.Parent && job.groupItemDTOs?.length ? (
          <Tree
            showLine
            treeData={[
              {
                title: (
                  <Typography.Text
                    strong
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >{`${index + 1}. ${job.name}`}</Typography.Text>
                ),
                key: job.id,
                children: (job.groupItemDTOs || [])?.map(
                  (groupJob, jobItemIndex: number) => ({
                    title: `${jobItemIndex + 1}. ${groupJob.name}`,
                    key: groupJob.id,
                  })
                ),
              },
            ]}
          />
        ) : (
          <Typography.Text
            strong
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              paddingLeft: 5,
            }}
          >{`${index + 1}. ${job.name}`}</Typography.Text>
        )}
        <div
          style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}
        >
          {moveJob && (
            <DragOutlined ref={drag} style={{ cursor: "-webkit-grab" }} />
          )}
          {deleteJob && (
            <>
              <Divider
                type="vertical"
                style={{ borderInlineStartColor: "#00000069" }}
              />
              {
                <DeleteOutlined
                  onClick={() => deleteJob(job.id)}
                  style={{ color: "red" }}
                />
              }
            </>
          )}
          <>{extra}</>
        </div>
      </div>
    </div>
  );
};

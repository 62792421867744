import { Typography } from 'antd';
import styles from './Tour.module.css';
import Utils from 'common/Utils';
import { IComboTour } from 'common/define-types';
import SYSTEM_CONSTANTS from 'common/constants';

interface IProps {
  tour: IComboTour;
  isBooked?: boolean;
  handleClick: (...args: any[]) => void;
}
//[14-12-2023][Phuc Thinh][Feature/14901]
//Change Tour to TourCombo
export const Tour = ({ tour, isBooked = false, handleClick }: IProps) => {
  return (
    <div
      className={`${styles.tour} ${isBooked ? styles.bookedTour : ''}`}
      onClick={() => handleClick(tour, isBooked)}
    >
      <div className={styles.imgContainer}>
        <img
          loading='lazy'
          src={
            tour && tour.Thumbnail
              ? `${SYSTEM_CONSTANTS.API_URL}${tour.Thumbnail}`
              : 'https://source.unsplash.com/gzeUpbjoTUA'
          }
          alt='tour'
          className={styles.tourImg}
        />
      </div>
      <div className={styles.inforWrapper}>
        <div style={{ width: '100%' }}>
          <div className={styles.tourInfor}>
            <Typography.Text
              style={{
                fontSize: 20,
                fontWeight: 700,
                textTransform: 'uppercase',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              title={tour.Title || ''}
            >
              {tour.Title || ''}
            </Typography.Text>
          </div>
          <div className={styles.tourInfor}>
            <Typography.Text
              strong
              style={{
                fontSize: 16,
                color: 'rgba(113, 125, 150, 1)',
                textTransform: 'uppercase',
              }}
            >
              {tour.SubTitle || ''}
            </Typography.Text>
            <Typography.Text
              strong
              style={{
                fontSize: 16,
                color: 'rgba(113, 125, 150, 1)',
                textTransform: 'uppercase',
              }}
            >
              {tour?.Description || ''}
            </Typography.Text>
          </div>
        </div>
        <div className={styles.tourPrice}>{`Từ ~${Utils.formatCurrency(
          tour.GiaHienThi
        )}`}</div>
        {/* <Button className={styles.buttonTourDetail}>Chi tiết tour</Button> */}
      </div>
    </div>
  );
};

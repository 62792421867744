import { Modal, Spin } from "antd";
import { ITour } from "common/define-types";
import { JobTourTab } from "./JobTourTab";
import { JobItemTab } from "./JobItemTab";
import styles from "../TourCategory.module.css";

interface EditJobTourModalProps {
  open: boolean;
  handleClose?: () => void;
  tour: ITour;
  loading?: boolean;
}
export const EditJobTourModal = ({
  open,
  handleClose,
  tour,
  loading,
}: EditJobTourModalProps) => {
  return (
    <Modal
      open={open}
      onCancel={handleClose}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      destroyOnClose
      centered
      width={"clamp(400px, 95%, 900px)"}
    >
      <Spin spinning={loading}>
        <div className={styles.jobTourModalContainer}>
          <JobTourTab tour={tour} />
          <JobItemTab />
        </div>
      </Spin>
    </Modal>
  );
};

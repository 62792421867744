import { Select, notification } from "antd";
import { getAllRouters } from "api/router.api";
import Utils from "common/Utils";
import { ILocation, IRouter } from "common/define-types";
import { useEffect, useState } from "react";
import { useSelectorRoot } from "store/store";

interface IProps {
  value?: string;
  onChange?: (arg: string) => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  allowClear?: boolean;
  testId: string;
  propsOptions?: IRouter[];
  locations: ILocation[];
}
export const RouterSelect = ({
  value,
  onChange = () => {},
  style,
  readOnly,
  allowClear = true,
  testId,
  propsOptions,
  locations,
}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [routers, setRouters] = useState<IRouter[]>([]);
  const selectIsRefreshing = useSelectorRoot(
    (state) => state.login.isRefreshing
  );

  useEffect(() => {
    if (!selectIsRefreshing) {
      if (propsOptions?.length) return;
      setLoading(true);
      getAllRouters().subscribe(
        (res) => {
          if (res) {
            setRouters(res);
          }
        },
        (err) => {
          setLoading(false);
          notification.error({ message: "Có lỗi khi lấy danh sách" });
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [selectIsRefreshing, propsOptions]);

  const options = propsOptions || routers;
  return (
    <Select
      data-test-id={testId}
      options={[...options]
        .sort((a, b) => a.localtionStartId.localeCompare(b.localtionStartId))
        .map((router) => {
          const { fromLocation, endLocation } = Utils.getBusLocation(
            locations,
            router.localtionStartId || "",
            router.localtionEndId || ""
          );
          return {
            label: `${fromLocation?.name || ""} -> ${endLocation?.name || ""}`,
            value: router.id,
          };
        })}
      loading={loading}
      value={value}
      style={style}
      onChange={(value) => onChange(value)}
      allowClear={allowClear}
      disabled={readOnly}
    />
  );
};

import { Button, Form, Input, InputNumber, Typography } from "antd";
import { ReactElement } from "react";
import styles from "./ExtraService.module.css";
import { IExtraService } from "common/define-types";
// import { useDispatchRoot } from "store/store";
// import { setExtraServiceSelected } from "store/slice/BookingSlice";
import { DeleteOutlined } from "@ant-design/icons";

interface IProps {
  service?: IExtraService;
  renderFormItem?: () => ReactElement | undefined;
  handleDelete?: (serviceCode: string) => void;
}
const ExtraServiceItem = ({
  service,
  renderFormItem,
  handleDelete,
}: IProps) => {
  // const dispatch = useDispatchRoot();
  const handleDeleteService = () => {
    if (service) {
      handleDelete && handleDelete(service.id);
    }
    // dispatch(
    //   setExtraServiceSelected({
    //     key: service.code,
    //     value: false,
    //   })
    // );
  };
  return (
    <div>
      {!!service && (
        <div className={styles.service_header}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {service.name}
          </Typography.Text>
          {handleDelete && (
            <Button
              onClick={handleDeleteService}
              type="text"
              icon={<DeleteOutlined style={{ color: "red" }} />}
            />
          )}
        </div>
      )}
      {renderFormItem ? (
        renderFormItem()
      ) : (
        <div className={styles.formItem_group}>
          <Form.Item label="Số tiền (đ)">
            <InputNumber controls={false} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="Ghi chú">
            <Input.TextArea rows={1} />
          </Form.Item>
        </div>
      )}
    </div>
  );
};

export default ExtraServiceItem;

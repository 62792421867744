import {
  Pagination,
  PaginationProps,
  Select,
  Skeleton,
  Typography,
  notification,
} from "antd";
import styles from "../Group.module.css";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { GroupItem } from "./GroupItem";
import { useEffect, useState } from "react";
import moment from "moment";
import { fetchGroupData, setErrMsg } from "store/slice/GroupSlice";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import { GroupStatusEnum } from "common/define-types";
import Utils from "common/Utils";

interface IProps {
  groupOnly?: boolean;
  isResponsive?: boolean;
}
export const GroupList = ({
  groupOnly = false,
  isResponsive = false,
}: IProps) => {
  const [tourDay, setTourDay] = useState(null);
  const selectGroups = useSelectorRoot((state) => state.group.groups);
  const selectGroupsResult = useSelectorRoot(
    (state) => state.group.groupsResult
  );
  const selectIsGroupLoading = useSelectorRoot(
    (state) => state.group.isLoading
  );
  const dispatch = useDispatchRoot();
  const selectErrMsg = useSelectorRoot((state) => state.group.errMsg);
  const selectSelectedDate = useSelectorRoot(
    (state) => state.group.selectedDate
  );
  const filteredGroup = selectGroups.filter((group) => {
    if (!tourDay) return true;
    const startDate = moment.utc(group.startDate);
    const businessDate = moment.utc(group.businessDate).endOf("day");
    const tempBusinessDate = businessDate.isBefore(moment.utc(group.startDate))
      ? moment.utc(group.startDate).subtract(1, "days").endOf("day")
      : businessDate;
    const inTourDay = tempBusinessDate.clone().add(1, "days");
    const dayDiff = inTourDay.clone().diff(startDate, "days");
    return dayDiff + 1 === tourDay;
  });

  const handleChangePagi: PaginationProps["onChange"] = (page) => {
    if (selectGroupsResult) {
      dispatch(
        fetchGroupData({
          page: page,
          pageSize: selectGroupsResult.pageSize,
        })
      );
    }
  };

  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({
        message: selectErrMsg,
      });
      dispatch(setErrMsg(null));
    }
  }, [selectErrMsg, dispatch]);

  return (
    <div
      className={`${styles.listContainer} ${
        isResponsive ? styles.listContainer_group : ""
      }`}
      style={{ minWidth: 400 }}
    >
      <div className={styles.listContainer_header}>
        <Typography.Text strong style={{ fontSize: 20 }}>
          Danh sách groups
        </Typography.Text>
        {!selectIsGroupLoading && groupOnly && (
          <Select
            allowClear
            options={new Array(Utils.getMaxGroupCountDay(selectGroups))
              .fill(null)
              .map((_, index) => ({
                label: `Ngày ${index + 1}`,
                value: index + 1,
              }))}
            placeholder="Ngày đi tour"
            style={{
              width: 150,
            }}
            value={tourDay}
            onChange={(value) => setTourDay(value)}
          />
        )}
      </div>
      <div className={`${styles.listWrapper}`}>
        <div
          className={`${styles.listWrapper_List} ${
            isResponsive ? styles.listWrapper_List_group : ""
          } customScroll`}
        >
          {selectIsGroupLoading ? (
            <>
              <Skeleton paragraph={{ rows: 3 }} />
              <Skeleton paragraph={{ rows: 3 }} />
              <Skeleton paragraph={{ rows: 3 }} />
            </>
          ) : (
            <>
              {!groupOnly &&
                moment(selectSelectedDate).isSameOrAfter(
                  moment().startOf("day")
                ) && <GroupItem group={null} />}
              {filteredGroup && filteredGroup.length > 0 ? (
                filteredGroup.map((group) => (
                  <GroupItem
                    group={group}
                    key={group.id}
                    // isActive={
                    //   !!selectGroupSelected &&
                    //   selectGroupSelected !== null &&
                    //   selectGroupSelected.id === group.id
                    // }
                    readOnly={group.status === GroupStatusEnum.END}
                    // readOnly={moment(group.startDate)
                    //   .add(group.dmuC_Tour.countDay - 1, "days")
                    //   .startOf("day")
                    //   .isBefore(moment())}
                  />
                ))
              ) : (
                <EmptyHolder message="No groups" />
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.listContainer_footer}>
        <Pagination
          total={filteredGroup.length}
          pageSize={selectGroupsResult?.pageSize || 10}
          current={selectGroupsResult?.page || 1}
          showTotal={(total, range) =>
            `Hiển thị ${
              range[1] === 0 && range[1] - range[0] === 0
                ? 0
                : range[1] - range[0] + 1
            }/${total} nhóm`
          }
          onChange={handleChangePagi}
        />
      </div>
    </div>
  );
};

import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import { ICreateTour, IUpdateJobTours } from "common/define-types";

const api = `${SYSTEM_CONSTANTS.API_URL}/JobTour`;

export const getJobTourByTourId = (tourId: string): Observable<any> => {
  return HttpClient.get(`${api}/${tourId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const createJobTour = (body: ICreateTour): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updateJobTours = ({
  tourId,
  ...rest
}: IUpdateJobTours): Observable<any> => {
  return HttpClient.put(
    `${api}/jobTour?tourId=${tourId}`,
    JSON.stringify(rest),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const removeJobTour = (tourId: string): Observable<any> => {
  return HttpClient.delete(`${api}/${tourId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import {
  IComboTourParams,
  ICreateComboTour,
  IUpdateComboTour,
  IUpdateComboTourBuses,
} from "common/define-types";
import Utils from "common/Utils";

//[14-12-2023][Phuc Thinh][Feature/14901]
// add API get combo tours
const api = `${SYSTEM_CONSTANTS.API_URL}/ComboTour`;

export const getAllComboTours = (params: IComboTourParams): Observable<any> => {
  return HttpClient.get(`${api}?${Utils.getParamsString(params)}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getAllComboToursPageable = (
  params: IComboTourParams
): Observable<any> => {
  return HttpClient.get(`${api}/allTour?${Utils.getParamsString(params)}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const createComboTour = (body: ICreateComboTour): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updateComboTour = ({
  comboTourId,
  ...rest
}: IUpdateComboTour): Observable<any> => {
  return HttpClient.put(`${api}/${comboTourId}`, JSON.stringify(rest), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updateComboTourBuses = ({
  comboTourId,
  busIds,
}: IUpdateComboTourBuses): Observable<any> => {
  return HttpClient.put(
    `${api}/${comboTourId}/addRouterBus`,
    JSON.stringify(busIds),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const removeComboTour = (comboTourId: string): Observable<any> => {
  return HttpClient.delete(`${api}?id=${comboTourId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, notification, Row, Select, Skeleton, Typography } from 'antd';
import {
  ComboTourItemType,
  ComboTourStatus,
  IComboTour,
  ITour,
  SelfMotor,
  TourStepEnum,
} from 'common/define-types';
import { EmptyHolder } from 'components/Empty/EmptyHolder';
import { Booking } from 'pages/Booking/Booking';
import { useEffect, useState } from 'react';
import {
  bookTour,
  selectTicket,
  // clearBooking,
  setErrMsg,
  setTourStep,
  unbookTour,
} from 'store/slice/BookingSlice';
import { fetchTourCategorys } from 'store/slice/TourCategorySlice';
import { useDispatchRoot, useSelectorRoot } from 'store/store';
import { Tour } from './Tour';
import styles from './Tour.module.css';
//[14-12-2023][Phuc Thinh][Feature/14901]
//Change Tour to TourCombo

const tourColumns = [
  {
    title: 'Có xe, không bus',
    onFilter: (comboTour: IComboTour) => {
      const comboBuses = comboTour.ListItem?.filter(
        (item) => item.ItemType === ComboTourItemType.Bus
      );
      const motorPrice = comboTour.ListItem?.find(
        (item) => item.ItemType === ComboTourItemType.MotorPrice
      );
      const hasBusCombo = comboBuses?.length;
      const hasJasmineMotor = motorPrice?.Value === SelfMotor.NO;
      return !hasBusCombo && hasJasmineMotor;
    },
  },
  {
    title: 'Có xe, có bus',
    onFilter: (comboTour: IComboTour) => {
      const comboBuses = comboTour.ListItem?.filter(
        (item) => item.ItemType === ComboTourItemType.Bus
      );
      const motorPrice = comboTour.ListItem?.find(
        (item) => item.ItemType === ComboTourItemType.MotorPrice
      );
      const hasBusCombo = comboBuses?.length;
      const hasJasmineMotor = motorPrice?.Value === SelfMotor.NO;
      return hasBusCombo && hasJasmineMotor;
    },
  },
  {
    title: 'Không xe, không bus',
    onFilter: (comboTour: IComboTour) => {
      const comboBuses = comboTour.ListItem?.filter(
        (item) => item.ItemType === ComboTourItemType.Bus
      );
      const motorPrice = comboTour.ListItem?.find(
        (item) => item.ItemType === ComboTourItemType.MotorPrice
      );
      const hasBusCombo = comboBuses?.length;
      const hasJasmineMotor = motorPrice?.Value === SelfMotor.NO;
      return !hasBusCombo && !hasJasmineMotor;
    },
  },
  {
    title: 'Không xe, có bus',
    onFilter: (comboTour: IComboTour) => {
      const comboBuses = comboTour.ListItem?.filter(
        (item) => item.ItemType === ComboTourItemType.Bus
      );
      const motorPrice = comboTour.ListItem?.find(
        (item) => item.ItemType === ComboTourItemType.MotorPrice
      );
      const hasBusCombo = comboBuses?.length;
      const hasJasmineMotor = motorPrice?.Value === SelfMotor.NO;
      return hasBusCombo && !hasJasmineMotor;
    },
  },
];

export const Tours = () => {
  const dispatch = useDispatchRoot();
  const [isHover, setIsHover] = useState(false);
  const selectActiveTourStep = useSelectorRoot(
    (state) => state.booking.tourStep
  );
  const selectHangHoas = useSelectorRoot((state) => state.hangHoa.motorbikes);
  const selectComboTours = useSelectorRoot((state) => state.booking.comboTours);
  const tours = useSelectorRoot((state) => state.tourCategory.tours);
  const [dataTour, setDataTour] = useState<ITour[]>(tours);
  const selectComboToursSelected = useSelectorRoot(
    (state) => state.booking.selectedComboTours
  );
  const selectErrMsg = useSelectorRoot((state) => state.booking.errMsg);
  const selectCurrentAgent = useSelectorRoot(
    (state) => state.booking.currentAgent
  );
  const [comboTours, setComboTours] = useState<IComboTour[]>([]);
  const [selectedTour, setSelectedTour] = useState<string>()
  useEffect(() => {
    setComboTours(selectComboTours.filter(
      (comboTour) =>
        comboTour.ListItem &&
        comboTour.ListItem?.length > 0 &&
        comboTour.Status === ComboTourStatus.AVAILABLE
        && comboTour.ListItem.findIndex(x => !selectedTour || selectedTour === x.Id) !== -1
    ));
  }, [selectComboTours, selectedTour])
  useEffect(() => {
    setDataTour(tours)
  }, [tours])
  useEffect(() => {
    dispatch(
      fetchTourCategorys()
    );
  }, [dispatch])
  const isToursLoading = useSelectorRoot((state) => state.booking.isLoading);



  const handleBookTour = (comboTour: IComboTour) => {
    if (comboTour) {
      dispatch(bookTour({ body: comboTour, hangHoas: selectHangHoas }));
    }
  };
  const handleUnbookTour = (comboTour: IComboTour) => {
    if (comboTour) {
      dispatch(unbookTour(comboTour));
    }
  };
  const toggleClickBookTour = (comboTour: IComboTour, isBooked: boolean) => {
    if (comboTour) {
      if (isBooked) {
        handleUnbookTour(comboTour);
      } else {
        handleBookTour(comboTour);
      }
    }
  };

  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({
        message: selectErrMsg,
      });
    }
    dispatch(setErrMsg(null));
  }, [selectErrMsg, dispatch]);

  useEffect(() => {
    if (selectActiveTourStep === TourStepEnum.TOURS) {
      dispatch(selectTicket(null));
    }
  }, [dispatch, selectActiveTourStep]);

  return selectActiveTourStep === TourStepEnum.TOURS ? (
    <div className={styles.toursContainer}>
      <div className={styles.toursWrapper}>
        <div style={{display: 'flex', flex: 1, flexDirection: 'row', marginBottom: 10}}>
        <Typography.Text
          style={{
            color: '#005BA5',
            fontSize: 24,
            paddingInline: 5,
            fontWeight: 500,
          }}
        >
          Chọn loại tour
        </Typography.Text>
        <div style={{flex: 1}}></div>
        <Select
            allowClear
            style={{ minWidth: 150}}
            options={dataTour.map(x => ({
              label: x.description,
              value: x.id,
            })).concat([{
              label: 'Tất cả',
              value: ''
            }])}
            placeholder="-- Chọn loại tour --"
            onSelect={(x) => {
              if (x?.length > 0) {
                setSelectedTour(x)
              } else {
                setSelectedTour(undefined)
              }
              
            }}
          />
        </div>
        
        <div
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className={`${styles.tours} ${isHover && styles.scrollAble}`}
        >
          {isToursLoading ? (
            <>
              <Skeleton
                avatar={{ size: 80, shape: 'square' }}
                paragraph={{ rows: 3 }}
                active
              />
              <Skeleton
                avatar={{ size: 80, shape: 'square' }}
                paragraph={{ rows: 3 }}
                active
              />
              <Skeleton
                avatar={{ size: 80, shape: 'square' }}
                paragraph={{ rows: 3 }}
                active
              />
            </>
          ) : comboTours.length > 0 &&
            selectCurrentAgent &&
            selectCurrentAgent.code ? (
            <Row gutter={[10, 10]}>
              {tourColumns.map((col) => (
                <Col span={Math.round(24 / tourColumns.length)} key={col.title}>
                  <div
                    style={{
                      display: 'flex',
                      gap: 10,
                      flexDirection: 'column',
                    }}
                  >
                    {comboTours.filter(col.onFilter).map((comboTour, index) => (
                      <Tour
                        key={index}
                        tour={comboTour}
                        isBooked={selectComboToursSelected.some(
                          (selectedComboTour) =>
                            comboTour.Id === selectedComboTour.Id
                        )}
                        handleClick={toggleClickBookTour}
                      />
                    ))}
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            // selectComboTours
            //   .filter(
            //     (comboTour) =>
            //       comboTour.ListItem &&
            //       comboTour.ListItem?.length > 0 &&
            //       comboTour.Status === ComboTourStatus.AVAILABLE
            //   )
            //   .map((comboTour, index) => (
            //     <Tour
            //       key={index}
            //       tour={comboTour}
            //       isBooked={selectComboToursSelected.some(
            //         (selectedComboTour) => comboTour.Id === selectedComboTour.Id
            //       )}
            //       handleClick={toggleClickBookTour}
            //     />
            //   ))
            <EmptyHolder message='Hiện chưa có tour' />
          )}
        </div>
      </div>
      {selectComboTours.length > 0 && !isToursLoading && (
        <Button
          className={styles.buttonTourDetail}
          disabled={!selectCurrentAgent || !selectCurrentAgent.code}
          block
          onClick={() => {
            if (
              !selectComboToursSelected ||
              selectComboToursSelected.length <= 0
            ) {
              notification.error({
                message: 'Click vào tour để chọn các loại tour trước',
              });
              return;
            }
            dispatch(setTourStep(TourStepEnum.DETAILS));
          }}
        >
          Đặt vé <ArrowRightOutlined />
        </Button>
      )}
    </div>
  ) : (
    <Booking />
  );
};

import { FilterBar } from "components/Bar/FilterBar";
import styles from "./Group.module.css";
import { GroupList } from "./components/GroupList";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { useEffect } from "react";
import {
  fetchGroup,
  setIsOnlyStartDate,
  setSelectedDate,
} from "store/slice/GroupSlice";
import { TicketListContainer } from "./components/TicketListContainer";
import dayjs, { Dayjs } from "dayjs";
// import moment from "moment";
// import Utils from "common/Utils";
// import { notification } from "antd";

export const Group = () => {
  const dispatch = useDispatchRoot();
  // const selectErrMsg = useSelectorRoot((state) => state.group.errMsg);
  const selectSelectedDate = useSelectorRoot(
    (state) => state.group.selectedDate
  );
  // const selectGroupSelected = useSelectorRoot(
  //   (state) => state.group.groupSelected
  // );
  // const selectGroups = useSelectorRoot((state) => state.group.groups);
  useEffect(() => {
    dispatch(setIsOnlyStartDate(true));
    dispatch(fetchGroup(null));
    if (disabledDate(dayjs(selectSelectedDate))) {
      dispatch(setSelectedDate(new Date().toISOString()));
    }
  }, [dispatch, selectSelectedDate]);

  // useEffect(() => {
  //   if (
  //     selectGroupSelected === null &&
  //     moment(selectSelectedDate).isBefore(
  //       moment().set({ ...Utils.startDateOfGroup })
  //     ) &&
  //     selectGroups.length > 0
  //   ) {
  //     dispatch(selectGroup(selectGroups[0]));
  //   }
  // }, [selectSelectedDate, selectGroupSelected, selectGroups, dispatch]);

  // useEffect(() => {
  //   if (selectErrMsg) {
  //     notification.error({
  //       message: selectErrMsg,
  //     });
  //   }
  // }, [selectErrMsg]);
  const disabledDate = (current: Dayjs) => {
    return !!dayjs().isAfter(current.add(7, "day"));
  };
  return (
    <div className={styles.container}>
      <FilterBar
        value={selectSelectedDate}
        handleFilterDate={(value) => {
          if (value) dispatch(setSelectedDate(value.toISOString()));
        }}
        disabledDate={disabledDate}
      />
      <div className={styles.wrapper}>
        <GroupList isResponsive={true} />
        <TicketListContainer isResponsive={true} />
      </div>
    </div>
  );
};

import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import { IParams } from "common/define-types";
import Utils from "common/Utils";
import { ICreateSupplier, IUpdateSupplier } from "./types/supplier";

const api = `${SYSTEM_CONSTANTS.API_URL}/Supplier`;

export const getAllSuppliers = (params: IParams): Observable<any> => {
  return HttpClient.get(`${api}?${Utils.getParamsString(params)}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getAllSuppliersPageable = (params?: IParams): Observable<any> => {
  return HttpClient.get(
    `${api}/allSupplier?${Utils.getParamsString(params || {})}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getSupplierJobItems = (params: IParams): Observable<any> => {
  return HttpClient.get(
    `${api}/jobitems?${Utils.getParamsString(params)}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const createSupplier = (body: ICreateSupplier[]): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updateSupplier = (body: IUpdateSupplier): Observable<any> => {
  return HttpClient.put(`${api}?id=${body.id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const removeSupplier = (stationId: string): Observable<any> => {
  return HttpClient.delete(`${api}/${stationId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

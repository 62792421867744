export const firebaseConfig = {
  apiKey: 'AIzaSyDgVJ5ujjHFKZqjoe37sjz09bUZ7cCX0IU',
  authDomain: "fir-redyfix.firebaseapp.com",
  databaseURL:
    "https://firebase-redyfix-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "firebase-redyfix",
  storageBucket: "firebase-redyfix.appspot.com",
  messagingSenderId: "179710895779",
  appId: '1:179710895779:web:257daf6e1abe12adbb4116',
  measurementId: "G-7TYBY8K15N",
};

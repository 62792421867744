import { Form, FormInstance, TimePicker } from "antd";
import Utils from "common/Utils";
import { useEffect } from "react";
import { IRouterStationBus } from "common/define-types";
import { MoneyInput } from "components/Input/MoneyInput";
import { BusSelect } from "components/Selects/BusSelect";
import { useSelectorRoot } from "store/store";
import { RouterSelect } from "components/Selects/RouterSelect";
import Dayjs from "dayjs";
import styles from "../StationCategory.module.css";

interface IProps {
  form?: FormInstance<any>;
  editingRouterBus?: IRouterStationBus;
}
export const RouterBusForm = ({ form, editingRouterBus }: IProps) => {
  const [defaultForm] = Form.useForm();
  const routerBusForm = form || defaultForm;
  const selectBuses = useSelectorRoot((state) => state.stationsCategory.buses);
  const selectLocations = useSelectorRoot(
    (state) => state.stationsCategory.locations
  );
  const selectRouters = useSelectorRoot(
    (state) => state.stationsCategory.routers
  );

  useEffect(() => {
    if (editingRouterBus) {
      routerBusForm.setFields(
        Utils.objectToAntFormData({
          ...editingRouterBus,
          date: Dayjs(editingRouterBus.date),
          routerId: editingRouterBus.dmuC_Router_Station?.dmuC_Router.id,
        })
      );
    } else {
      routerBusForm.resetFields();
    }
  }, [editingRouterBus, routerBusForm]);

  return (
    <Form
      name="routerBus"
      form={routerBusForm}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      className="noMargin_form-item"
    >
      <Form.Item
        label={"Loại xe bus"}
        name={"busId"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <BusSelect testId="busSelect" propsOptions={selectBuses} />
      </Form.Item>
      <Form.Item
        label={"Tuyến đường"}
        name={"routerId"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <RouterSelect
          testId="routerSelect"
          locations={selectLocations}
          propsOptions={selectRouters}
        />
      </Form.Item>
      <div className={styles.formItemGroup}>
        <Form.Item
          label={"Giá bán"}
          name={"giaBan"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <MoneyInput min={0} />
        </Form.Item>
        <Form.Item
          label={"Giờ khởi hành"}
          name={"date"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <TimePicker
            data-test-id="busDatePicker"
            style={{ width: "100%" }}
            format={"HH:mm"}
            placeholder="HH:mm"
          />
        </Form.Item>
      </div>

      <div className={styles.formItemGroup}>
        <Form.Item
          label={"Giá thu đại lý"}
          name={"giaDaiLy"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <MoneyInput min={0} />
        </Form.Item>
        <Form.Item
          label={"Giá trả nhà cung cấp"}
          name={"giaTraNCC"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <MoneyInput min={0} />
        </Form.Item>
      </div>
      <Form.Item label={"Giá hiển thị"} name={"giaHienThi"}>
        <MoneyInput min={0} />
      </Form.Item>
    </Form>
  );
};

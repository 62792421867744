import { Select } from "antd";
import { JobTicketStatusEnum, IGroupTicket, GroupTicketType } from "common/define-types";
import { TicketOption } from "./components/TicketOption";
import { useState } from "react";

interface IProps {
  value?: string;
  onChange?: (arg: string) => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  options: IGroupTicket[];
  acceptStatus?: JobTicketStatusEnum[];
  acceptType?: GroupTicketType[];
}
const { Option } = Select;
export const TicketSelect = ({
  value,
  onChange = () => {},
  style,
  readOnly = false,
  options,
  acceptStatus = [],
  acceptType = [],
}: IProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Select
      //   options={options.map((ticket) => ({
      //     label: ticket.fullName,
      //     value: ticket.id,
      //   }))}
      value={value}
      style={style}
      open={dropdownOpen}
      onDropdownVisibleChange={(open) => setDropdownOpen(open)}
      onChange={(value) => onChange(value)}
      disabled={readOnly}
      optionLabelProp="label"
      allowClear
      onClick={(e: any) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {options
        .filter(
          (gTicket) =>
            acceptStatus.length === 0 ||
            acceptStatus.some(
              (status) => status === gTicket.status || !dropdownOpen
            )
        )
        .filter(
          (gTicket) => 
            acceptType.length === 0 ||
            acceptType.some(
              (type) => type === gTicket.type || !dropdownOpen
            )
        )
        .map((ticket) => (
          <Option label={ticket.fullName} value={ticket.id} key={ticket.id}>
            <TicketOption ticket={ticket} />
          </Option>
        ))}
    </Select>
  );
};

import { QRCode, Typography } from "antd";
// import { ITicket } from "common/define-types";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import Utils from "common/Utils";
import {
  IBookingTicket,
  IPaymentMethodBooking,
  PaymentMethodBookingStatus,
} from "common/define-types";
import dayjs from "dayjs";
import TicketIcon from "image/icon/ticket-icon.svg";
import JasmineLogo from "image/jasmine-logo.png";
import React, { ReactNode } from "react";
import { useSelectorRoot } from "store/store";
import styles from "./Billing.module.css";
import { BusTicket } from "./BusTicket";
import ExtraServiceTicket from "./ExtraServiceTicket";

interface IProps {
  booking: any;
  ticket: IBookingTicket;
  index: number;
  borderStyle?: string;
  isErase?: boolean;
  searchQuery?: string;
}
export const TicketWithQRBill = React.forwardRef<HTMLDivElement, IProps>(
  (
    { booking, ticket, index, borderStyle, isErase = false, searchQuery },
    ref
  ) => {
    const selectComboTours = useSelectorRoot(
      (state) => state.booking.comboTours
    );
    const foundComboTour = selectComboTours.find(
      (combo) => combo.Id === ticket.comboTourId
    );

    const ticketPrice = ticket.price ? ticket.price : 0;
    const busTotal = Utils.getBusPrice(ticket);
    const extraServiceTotal = Utils.getExtraServicePrice(ticket);
    // [14:57 05/12/2023][minh_nq]
    // update translate

    const renderHighlightText = (
      input: string,
      icon?: ReactNode,
      highlightColor = "#bd4840"
    ) => {
      if (searchQuery) {
        const index = input.toLowerCase().indexOf(searchQuery.toLowerCase());
        if (index >= 0) {
          return (
            <Typography.Text strong>
              {`${input.substring(0, index)}`}
              <Typography.Text
                strong
                style={{ color: highlightColor, fontSize: 16 }}
              >
                {input.substring(index, index + searchQuery.length)}
              </Typography.Text>
              {input.substring(index + searchQuery.length)}
            </Typography.Text>
          );
        }
      }
      return (
        <Typography.Text strong>
          {icon ?? icon} {`${input}`}
        </Typography.Text>
      );
    };

    return (
      <div
        className={`${styles.ticketWithQR} ${
          isErase ? styles.ticketErased : ""
        } print-element`}
        style={{
          border: borderStyle,
          justifyContent: "center",
        }}
        ref={ref}
      >
        {/* 
        [15:05 05/12/2023][minh_nq][feature/14859]
        update UI qr 
        */}
        <div
          // className={styles.ticketWithQR_wrapper}
          className="print-element-container"
          style={{
            borderRight: borderStyle,
            width: "93%",
            display: "flex",
            flexDirection: "column",
            padding: "0px 5px",
            gap: "8px",
            justifyContent: "center",
          }}
        >
          <div
            className="print-element-header"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            {/* <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 91, 165, 1)",
                padding: "10px",
                borderRadius: "10px",
              }}
              className="jasmine-logo-container"
            >
              <img
                src={AgentHeaderLogo}
                alt="logo"
                style={{ width: "60%", height: "auto" }}
              />
            </div> */}
            {/* 
              [15:05 05/12/2023][minh_nq]
              update print
            */}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                borderRadius: "10px",
              }}
              className="jasmine-logo-container_2"
            >
              <img
                src={JasmineLogo}
                alt="logo"
                style={{ width: "60%", height: "auto" }}
              />
            </div>
            <div className={styles.QRContainer}>
              <QRCode size={120} value={ticket.id || "-"} bordered={false} />
            </div>
          </div>
          <div className={styles.ticketWithQR_item_header}>
            <div className={styles.ticketWithQR_item_header_info}>
              <div className={styles.ticketWithQR_item}>
                <div className={styles.inforWithIcon}>
                  {ticket.fullName ? (
                    <Typography.Text
                      strong
                      className="size12"
                      // style={{ fontSize: 19, fontWeight: 700 }}
                    >{`${ticket.fullName}`}</Typography.Text>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={styles.inforWithIcon}>
                  {ticket.name ? renderHighlightText(ticket.name) : <></>}
                </div>
              </div>
              <div className={styles.ticketWithQR_item}>
                <div className={styles.inforWithIcon}>
                  {ticket.phone ? (
                    <>
                      <PhoneOutlined className="iconStyle" />
                      {renderHighlightText(ticket.phone)}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <Typography.Text
                    strong
                    style={{ fontStyle: "italic" }}
                  >{`Seller: ${
                    booking.sellerFirstName || booking.sellerLastName
                      ? (booking.sellerLastName || "") +
                        " " +
                        (booking.sellerFirstName || "")
                      : "Empty"
                  }`}</Typography.Text>
                </div>
              </div>
              <div className={styles.ticketWithQR_item}>
                <div className={styles.inforWithIcon}>
                  {ticket.email ? (
                    <>
                      <MailOutlined className="iconStyle" />
                      {renderHighlightText(ticket.email)}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.dividerContainer}>
            <div className={styles.fullWidthDivider} />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {/*
              [14:57 05/12/2023][minh_nq]
              update translate 
            */}
            <Typography.Text
              strong
              className="size14"
              // style={{ fontSize: 16, fontWeight: 700 }}
            >
              {"Tour ticket"}{" "}
              {(ticket.busTicketC1 || ticket.busTicketC2) && (
                <>{"& Bus ticket"}</>
              )}
            </Typography.Text>
            <Typography.Text strong style={{ fontSize: 16, fontWeight: 700 }}>
              {/* {Utils.formatCurrency(ticketPrice + busTotal)} */}
            </Typography.Text>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 3,
                width: "43%",
              }}
            >
              <img src={TicketIcon} alt="ticket" className="ticket-icon" />
              <Typography.Text strong className="size12">
                {`${foundComboTour?.Title} ${foundComboTour?.SubTitle} `}
                <Typography.Text style={{ color: "grey", fontSize: 13 }} italic>
                  {foundComboTour?.Description}
                </Typography.Text>
              </Typography.Text>
            </div>
            <div
              style={{
                width: "30%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography.Text strong className="size12">
                {dayjs(ticket.startDate).format("DD/MM/YYYY")}
              </Typography.Text>
            </div>
            <div
              style={{
                width: "27%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography.Text strong className="size12">
                {Utils.formatCurrency(ticketPrice)}
              </Typography.Text>
            </div>
          </div>
          {ticket.busTicketC1 && (
            <BusTicket busTicket={ticket.busTicketC1} isQrTicket={true} />
          )}
          {ticket.busTicketC2 && (
            <BusTicket busTicket={ticket.busTicketC2} isQrTicket={true} />
          )}
          {ticket.datA_TOUR_CHHangHoaDichVuKhacs &&
            ticket.datA_TOUR_CHHangHoaDichVuKhacs.length > 0 && (
              <>
                <div className={styles.dividerContainer}>
                  <div className={styles.shortDivider} />
                </div>
                <ExtraServiceTicket
                  tickets_extraService={ticket.datA_TOUR_CHHangHoaDichVuKhacs}
                />
              </>
            )}
          <div className={styles.dividerContainer}>
            <div className={styles.fullWidthDivider} />
          </div>
          <div className={styles.totalMoneyContainer}>
            <div className={styles.totalTitle}>
              <Typography.Text
                strong
                className="size12"
                // style={{ fontSize: 16, fontWeight: 700, textAlign: 'left' }}
              >
                {/*
                  [14:57 05/12/2023][minh_nq]
                  update translate 
                */}
                {"Total Amount:"}
              </Typography.Text>
            </div>
            <div className={styles.totalValue}>
              <Typography.Text
                strong
                className="size12"
                // style={{ fontSize: 16, fontWeight: 700 }}
              >
                {Utils.formatCurrency(
                  ticketPrice + busTotal + extraServiceTotal
                )}
              </Typography.Text>
            </div>
          </div>
          <div className={styles.dividerContainer}>
            <div className={styles.fullWidthDivider} />
          </div>
          <div
            className={styles.totalMoneyContainer}
            style={{ flexDirection: "column" }}
          >
            <div className={styles.totalMoneyContainer}>
              <div className={styles.totalTitle}>
                <Typography.Text
                  strong
                  className="size12"
                  // style={{ fontSize: 16, fontWeight: 700 }}
                >
                  {/*
                    [14:57 05/12/2023][minh_nq]
                    update translate 
                  */}
                  {"Grand Total:"}
                </Typography.Text>
              </div>
              <div className={styles.totalValue}>
                <Typography.Text
                  strong
                  className="size12"
                  // style={{ fontSize: 16, fontWeight: 700 }}
                >
                  {Utils.formatCurrency(
                    Utils.calculateTotalBookingPrice(booking) +
                      Utils.calculateBookingBusTotal(booking) +
                      Utils.calculateBookingExtraServiceTotal(booking)
                  )}
                </Typography.Text>
              </div>
            </div>
            <div className={styles.totalMoneyContainer}>
              <div className={styles.totalTitle}>
                <Typography.Text
                  strong
                  className="size12"
                  // style={{ fontSize: 16, fontWeight: 700, textAlign: "right" }}
                >
                  {/*
                    [14:57 05/12/2023][minh_nq]
                    update translate 
                  */}
                  {"Paid "}
                  {/* [4-12-2023] [Phuc Thinh] [Update paymentMethodBooking interface] */}
                  {booking.paymentMethodBookings.filter(
                    (payMethod: IPaymentMethodBooking) =>
                      payMethod.status === PaymentMethodBookingStatus.Pay &&
                      payMethod.paymentCode !== "90015"
                  ).length > 0 && (
                    <span style={{ fontSize: 12 }}>
                      (
                      {Utils.toUniqueArr([
                        ...booking.paymentMethodBookings
                          .filter(
                            (payMethod: IPaymentMethodBooking) =>
                              payMethod.status ===
                                PaymentMethodBookingStatus.Pay &&
                              payMethod.paymentCode !== "90015"
                          )
                          .map(
                            (payMethod: IPaymentMethodBooking) =>
                              payMethod.paymentName || ""
                          ),
                      ]).join(", ")}
                      )
                    </span>
                  )}
                  :
                </Typography.Text>
              </div>
              <div className={styles.totalValue}>
                <Typography.Text
                  strong
                  className="size12"
                  // style={{ fontSize: 16, fontWeight: 700 }}
                >
                  {Utils.formatCurrency(
                    Utils.getBookingPayment(booking.paymentMethodBookings).paid
                  )}
                </Typography.Text>
              </div>
            </div>
            <div className={styles.totalMoneyContainer}>
              <div className={styles.totalTitle}>
                <Typography.Text
                  strong
                  className="size12"
                  // style={{ fontSize: 16, fontWeight: 700 }}
                >
                  {/*
                    [14:57 05/12/2023][minh_nq]
                    update translate 
                  */}
                  {"Jasmine Collection:"}
                </Typography.Text>
              </div>
              <div className={styles.totalValue}>
                <Typography.Text
                  strong
                  className="size12"
                  // style={{ fontSize: 16, fontWeight: 700 }}
                >
                  {Utils.formatCurrency(booking.moneyJasmineKeep)}
                </Typography.Text>
              </div>
            </div>
          </div>
          <div className={styles.ending}>
            <Typography.Text
              style={{ fontSize: 12, fontStyle: "italic", textAlign: "center" }}
            >
              {"Thank you for choosing Jasmine Ha Giang Tours!"}
            </Typography.Text>
            <Typography.Text
              style={{ fontSize: 12, fontStyle: "italic", textAlign: "center" }}
            >
              {"Powered by Perfec"}
              <span style={{ fontStyle: "normal" }}>t</span>
              {" Key - HiCAS Solutions"}
            </Typography.Text>
          </div>
        </div>
      </div>
    );
  }
);

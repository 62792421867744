import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import { IAssignRole, IParams } from "common/define-types";
import Utils from "common/Utils";

const api = `${SYSTEM_CONSTANTS.API_URL}/license_manager/users`;

export const getAccountsByIds = (
  accountIds: string[],
  params?: IParams
): Observable<any> => {
  if (params) {
    return HttpClient.post(
      `${api}/list?${Utils.getParamsString(params)}`,
      JSON.stringify(accountIds),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.post(`${api}/list`, JSON.stringify(accountIds), {
      headers: {
        "Content-Type": "application/json",
      },
    }).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};

export const createAccount = (body: ICreateAccount): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const deleteAccount = (emailOrName: string): Observable<any> => {
  return HttpClient.delete(`${api}/${emailOrName}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const updateAccount = (body: IUpdateAccount): Observable<any> => {
  return HttpClient.put(`${api}/${body.id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const assignRoleForAccount = (body: IAssignRole) => {
  return HttpClient.put(
    `${api}/${body.userId}/roles`,
    JSON.stringify({ roleName: body.roleName }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getAvatar = () => {
  return HttpClient.get(`${api}/current/avatar`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getProfile = () => {
  return HttpClient.get(`${api}/current/profile`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updateProfile = (body: IUpdateProfile) => {
  return HttpClient.put(`${api}/current`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updatePassword = (body: IUpdatePassword) => {
  return HttpClient.put(`${api}/current/password`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const resetPassword = (email: string): Observable<any> => {
  return HttpClient.post(
    `${api}/${email}/notify/passwordreset`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const blockUser = (userId: string): Observable<any> => {
  return HttpClient.put(
    `${api}/${userId}/Block`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const blockUsers = (userIds: string[]): Observable<any> => {
  return HttpClient.put(`${api}/blocks`, userIds, {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const unblockUsers = (userIds: string[]): Observable<any> => {
  return HttpClient.put(`${api}/unblocks`, userIds, {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

import { Button, Popconfirm, Typography } from "antd";
import styles from "../StationCategory.module.css";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import {
  deleteStationRouterBus,
  editStationRouterBus,
} from "store/slice/StationCategorySlice";
import { IRouterStationBus } from "common/define-types";
import { BusOption } from "components/Selects/components/RouterStationBusOption";
import Utils from "common/Utils";

interface IProps {
  routerBus: IRouterStationBus;
}
export const RouterBusItem = ({ routerBus }: IProps) => {
  const selectLocations = useSelectorRoot((state) => state.booking.locations);
  const { fromLocation, endLocation } = Utils.getBusLocation(
    selectLocations,
    routerBus.dmuC_Router_Station?.dmuC_Router?.localtionStartId || "",
    routerBus.dmuC_Router_Station?.dmuC_Router?.localtionEndId || ""
  );
  const selectDeletingRouterBusId = useSelectorRoot(
    (state) => state.stationsCategory.deletingStationRouterBusId
  );
  const dispatch = useDispatchRoot();

  const openEditStationRouterBus = (e: any) => {
    e.stopPropagation();
    dispatch(editStationRouterBus(routerBus));
  };

  const handleDeleteStationRouterBus = (e: any) => {
    e.stopPropagation();
    dispatch(deleteStationRouterBus(routerBus?.id));
  };

  return (
    <div className={`${styles.agentItem}`}>
      <div
        className={`${styles.agentItem_wrapper}`}
        style={{ justifyContent: "center" }}
      >
        <div>
          <Typography.Text
            strong
            // style={{ whiteSpace: "nowrap" }}
          >{`${fromLocation?.name || ""} → ${
            endLocation?.name || ""
          }`}</Typography.Text>
          <BusOption bus={routerBus} priceProp="price" />
        </div>
      </div>
      <div
        className={styles.btnContainer}
        style={{ flexBasis: 72, paddingBlock: 8, gap: 8 }}
      >
        <Button
          icon={<EditOutlined style={{ fontSize: 20, color: "#1890FF" }} />}
          type="text"
          onClick={openEditStationRouterBus}
        />

        <Popconfirm
          onConfirm={handleDeleteStationRouterBus}
          onCancel={(e) => e?.stopPropagation()}
          title={"Xóa tuyến xe"}
          description={"Bạn có chắc chắn muốn xóa tuyến xe này ?"}
          okText="Có"
          cancelText="Không"
          placement={"bottomLeft"}
        >
          <Button
            onClick={(e) => e.stopPropagation()}
            icon={<DeleteOutlined style={{ fontSize: 20, color: "red" }} />}
            loading={selectDeletingRouterBusId === routerBus.id}
            type="text"
          />
        </Popconfirm>
      </div>
    </div>
  );
};

import {
  Checkbox,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  Tooltip,
  Typography,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { BusSelect } from 'components/Selects/RouterStationBusSelect';
import { LocationSelect } from 'components/Selects/LocationSelect';
import dayjs, { Dayjs } from 'dayjs';
import styles from '../Booking.module.css';
import { InfoCircleOutlined } from '@ant-design/icons';
import Utils from 'common/Utils';
import { useSelectorRoot } from 'store/store';
import { ComboTourItemType, RoleEnum } from 'common/define-types';
import moment from 'moment';

export interface IBusFilterValue {
  locationFromId: string;
  locationToId: string;
  date: Dayjs | null;
  price: number;
}
interface IProps {
  form: FormInstance<any>;
  readOnly?: boolean;
  arrivalBusFilterValue: IBusFilterValue;
  departBusFilterValue: IBusFilterValue;
  setArrivalBusFilterValue: (args: IBusFilterValue) => void;
  setDepartBusFilterValue: (args: IBusFilterValue) => void;
  isEditing?: boolean;
  isTicketOncoming?: boolean;
  isApplyAll: boolean;
  handleApplyChange: (e: CheckboxChangeEvent) => void;
  handleBusFormChange: (changedValue: any, allValues: any) => void;
  hasBus_comboTourId?: string;
}
const dateFormat = 'DD/MM/YYYY';
const BusForm = ({
  form,
  readOnly,
  arrivalBusFilterValue,
  departBusFilterValue,
  setArrivalBusFilterValue,
  setDepartBusFilterValue,
  isEditing,
  isApplyAll,
  hasBus_comboTourId,
  // isTicketOncoming,
  handleApplyChange,
  handleBusFormChange,
}: IProps) => {
  const selectTicketSelected = useSelectorRoot(
    (state) => state.booking.selectedTicket
  );
  const userRole = useSelectorRoot((state) => state.login.role);
  const hasBusCombo = !!hasBus_comboTourId;
  const disabledDate = (current: Dayjs, daySubtract = 0) => {
    return !!dayjs().subtract(daySubtract, 'days').isAfter(current);
  };
  const hasBusTicket = (filterValue: IBusFilterValue) => {
    return (
      !!filterValue.date &&
      !!filterValue.locationFromId &&
      !!filterValue.locationToId
    );
  };

  const checkDepartBusReadOnly = () => {
    if (!selectTicketSelected) return readOnly;
    if ([RoleEnum.ADMIN, RoleEnum.SYSTEM].every((role) => role !== userRole))
      return readOnly;
    const ticketCountDay = selectTicketSelected.comboTour.ListItem?.find(
      (item) => item.ItemType === ComboTourItemType.DriverPrice
    )?.CountDay;
    const ticketEndDate = moment
      .utc(selectTicketSelected.startDate)
      .add(Utils.toNumber(ticketCountDay, 4), 'days')
      .endOf('day');
    if (selectTicketSelected.busTicketC2) {
      if (
        moment
          .utc()
          .startOf('day')
          .isAfter(
            moment.utc(selectTicketSelected.busTicketC2.dateTime).endOf('day')
          )
      ) {
        return true;
      }
      return false;
    } else {
      if (moment.utc().startOf('day').isAfter(ticketEndDate)) {
        return true;
      }
      return false;
    }
  };
  const departBusReadOnly = checkDepartBusReadOnly();
  return (
    <Form
      className='ticket-card_form'
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      style={{ paddingBottom: 20 }}
      onValuesChange={handleBusFormChange}
    >
      <div className={styles.busFormTitle}>
        <Typography.Text style={{ fontSize: 20, fontWeight: 700 }}>
          Ngày khởi hành và Bus
        </Typography.Text>
        <div style={{ marginTop: 2, display: 'flex' }}>
          <Checkbox
            checked={isApplyAll}
            onChange={handleApplyChange}
            disabled={readOnly}
            style={{ whiteSpace: 'nowrap' }}
          >
            Apply all
          </Checkbox>
          <Tooltip title={'Apply cho các vé thuộc loại tour này'}>
            <InfoCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
          </Tooltip>
        </div>
      </div>

      <Form.Item
        label={'Ngày khởi hành tour (DD/MM/YYYY)'}
        name={'startDate'}
        className={styles.formItem}
        rules={[
          {
            required: true,
            message: 'Không được bỏ trống',
          },
        ]}
      >
        <DatePicker
          disabledDate={(current) => disabledDate(current, 1)}
          style={{ width: '100%' }}
          format={dateFormat}
          // disabled={readOnly || (isEditing && isTicketOncoming)}
          disabled={readOnly}
          allowClear={false}
        />
      </Form.Item>
      <Divider style={{ marginBlock: 8 }} />

      <div style={{ display: 'flex', gap: 8 }}>
        <Form.Item
          label={'Đến Jasmine từ'}
          name={'from'}
          className={styles.formItem}
          style={{ flex: 1 }}
        >
          <LocationSelect
            testId='Arrival-Location'
            onChange={(value) => form.setFieldValue('from', value)}
            readOnly={readOnly}
            allowClear={!hasBusCombo}
            // allowClear={!isEditing || !isTicketOncoming}
          />
        </Form.Item>
        <Form.Item
          label={'Ngày đến'}
          name={'arrivalDate'}
          className={styles.formItem}
          style={{ flex: 1 }}
        >
          <DatePicker
            disabledDate={(current) => disabledDate(current, 2)}
            style={{ width: '100%' }}
            format={dateFormat}
            disabled={readOnly}
            // disabled={readOnly || (isEditing && isTicketOncoming)}
            allowClear={false}
          />
        </Form.Item>
      </div>

      <Form.Item
        label={'Loại Bus đến'}
        name={'fromBus'}
        className={styles.formItem}
        style={{ flex: 1 }}
        rules={[
          {
            required:
              arrivalBusFilterValue.locationFromId &&
              arrivalBusFilterValue.locationFromId !== '' &&
              arrivalBusFilterValue.date !== null
                ? true
                : false,
            message: 'Không được bỏ trống',
          },
        ]}
      >
        {/* [29-3-2024][Phuc Thinh][remove dockerId = 'nocombo'] */}
        <BusSelect
          testId='Arrival-Bus'
          filterValue={{
            ...arrivalBusFilterValue,
            //[11-4-2024][Phuc Thinh][pass dockerId as comboTourId]
            dockerId: selectTicketSelected?.comboTourId,
          }}
          onChange={(value) => {
            form.setFieldValue('fromBus', value?.id);
            setArrivalBusFilterValue({
              ...arrivalBusFilterValue,
              price: value ? Utils.toNumber(value.price, 0) : 0,
            });
          }}
          readOnly={readOnly}
          allowClear={!hasBusCombo}
          // allowClear={!isEditing || !isTicketOncoming}
        />
      </Form.Item>
      {/* [11-12-2023][Phuc Thinh][Feature/14844]
      add vitriDon, linkGGMap to busTicket */}
      <Form.Item
        label={'Vị trí đón'}
        name={'vitriDonC1'}
        className={styles.formItem}
        style={{ flex: 1 }}
      >
        <Input
          placeholder={
            hasBusTicket(arrivalBusFilterValue)
              ? 'Nhập vị trí bus đón'
              : 'Chọn bus trước'
          }
          disabled={readOnly || !hasBusTicket(arrivalBusFilterValue)}
        />
      </Form.Item>
      <Form.Item
        label={'Link GG Map'}
        name={'linkGGMapC1'}
        className={styles.formItem}
        style={{ flex: 1 }}
      >
        <Input
          disabled={readOnly || !hasBusTicket(arrivalBusFilterValue)}
          placeholder={
            hasBusTicket(arrivalBusFilterValue)
              ? 'Nhập link gg map'
              : 'Chọn bus trước'
          }
        />
      </Form.Item>

      <Divider style={{ marginBlock: 8 }} />

      <div style={{ display: 'flex', gap: 8 }}>
        <Form.Item
          label={'Từ Jasmine đến'}
          name={'destination'}
          className={styles.formItem}
          style={{ flex: 1 }}
        >
          <LocationSelect
            testId='Depart-Location'
            onChange={(value) => form.setFieldValue('destination', value)}
            readOnly={departBusReadOnly}
            allowClear={!hasBusCombo}
            // allowClear={!isEditing || !isTicketOncoming}
          />
        </Form.Item>
        <Form.Item
          label={'Ngày đi'}
          name={'departDate'}
          className={styles.formItem}
          style={{ flex: 1 }}
        >
          <DatePicker
            disabledDate={(current) => disabledDate(current, 2)}
            // disabledDate={disabledDate}
            style={{ width: '100%' }}
            format={dateFormat}
            disabled={departBusReadOnly}
            allowClear={false}
          />
        </Form.Item>
      </div>

      <Form.Item
        label={'Loại Bus đi'}
        name={'goBus'}
        className={styles.formItem}
        style={{ flex: 1 }}
        rules={[
          {
            required:
              departBusFilterValue.locationToId &&
              departBusFilterValue.locationToId !== '' &&
              departBusFilterValue.date !== null
                ? true
                : false,
            message: 'Không được bỏ trống',
          },
        ]}
      >
        {/* [29-3-2024][Phuc Thinh][remove dockerId = 'nocombo'] */}
        <BusSelect
          testId='Depart-Bus'
          filterValue={{
            ...departBusFilterValue,
            //[11-4-2024][Phuc Thinh][pass dockerId as comboTourId]
            dockerId: selectTicketSelected?.comboTourId,
          }}
          onChange={(value) => {
            form.setFieldValue('goBus', value?.id);
            setDepartBusFilterValue({
              ...departBusFilterValue,
              price: value ? Utils.toNumber(value.price, 0) : 0,
            });
          }}
          readOnly={departBusReadOnly}
          allowClear={!hasBusCombo}
          // allowClear={!isEditing || !isTicketOncoming}
        />
      </Form.Item>
      <Form.Item
        label={'Ghi chú'}
        name={'noteC2'}
        className={styles.formItem}
        style={{ flex: 1 }}
      >
        <Input
          placeholder={'Nhập ghi chú'}
          disabled={departBusReadOnly || !hasBusTicket(departBusFilterValue)}
        />
      </Form.Item>
      {/* <Form.Item
        label={"Vị trí đón"}
        name={"vitriDonC2"}
        className={styles.formItem}
        style={{ flex: 1 }}
      >
        <Input
          placeholder={
            hasBusTicket(departBusFilterValue)
              ? "Nhập vị trí bus đón"
              : "Chọn bus trước"
          }
          disabled={readOnly || !hasBusTicket(departBusFilterValue)}
        />
      </Form.Item>
      <Form.Item
        label={"Link GG Map"}
        name={"linkGGMapC2"}
        className={styles.formItem}
        style={{ flex: 1 }}
      >
        <Input
          placeholder={
            hasBusTicket(departBusFilterValue)
              ? "Nhập link gg map"
              : "Chọn bus trước"
          }
          disabled={readOnly || !hasBusTicket(departBusFilterValue)}
        />
      </Form.Item> */}
    </Form>
  );
};

export default BusForm;

import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";

const api = `${SYSTEM_CONSTANTS.API_URL}/Devices`;

export const getAllDevices = (): Observable<any> => {
  return HttpClient.get(`${api}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const registerDevice = (body: IRegisterDevice): Observable<any> => {
  return HttpClient.post(`${api}/user/current`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

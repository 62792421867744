import { PlusOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Typography, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { ChangeEvent, useEffect, useState } from "react";
import { addRouter, fetchRouters, setAddModalOpen, setRouterFilterValue } from "store/slice/RouterSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "./Router.module.css";
import { RouterForm } from "./components/RouterForm";
import { RouterTable } from "./components/RouterTable";
export const Router = () => {
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const selectAddModalOpen = useSelectorRoot(
    (state) => state.router.addModalOpen
  );
  const selectErrMsg = useSelectorRoot((state) => state.router.errMsg);
  const selectRouterFilterValue = useSelectorRoot(
    (state) => state.router.filterValue
  );
  const [addRouterForm] = useForm();
  const dispatch = useDispatchRoot();
  useEffect(() => {
    dispatch(fetchRouters({ pageSize: 25 }));
  }, [dispatch]);
  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({
        message: selectErrMsg,
      });
    }
  }, [selectErrMsg]);
  const handleAddRouter = async () => {
    const RouterValue = await addRouterForm
      .validateFields()
      .catch((err) => {
        console.log(err);
        notification.error({ message: "Vui lòng nhập đủ thông tin" });
      });
    if (!RouterValue) return;
    dispatch(
      addRouter({
        ...RouterValue,
      })
    );
  };
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(
        setRouterFilterValue({
          ...selectRouterFilterValue,
          search: e.target.value,
        })
      );
    }, 1000);
    setTimer(timeoutId);
  };
  return (
    <div className={styles.RouterContainer}>
      <div className={styles.tableHeader}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            flexDirection: "row",
          }}
        >
          <Typography.Text strong style={{ fontSize: 20 }}>
            Danh sách tuyến đường
          </Typography.Text>
          <div>
            <Input.Search
              placeholder="Tìm kiếm tuyến đường"
              onChange={handleSearch}
              allowClear
            />
          </div>
        </div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            dispatch(setAddModalOpen(true));
            addRouterForm.resetFields();
          }}
        >
          Thêm mới tuyến đường
        </Button>
      </div>
      <RouterTable />
      {selectAddModalOpen ? (
        <Modal
          title={"Thêm mới tuyến đường"}
          open={selectAddModalOpen}
          onCancel={() => dispatch(setAddModalOpen(false))}
          onOk={handleAddRouter}
          okText="Thêm mới"
          cancelText="Hủy bỏ"
        >
          <RouterForm form={addRouterForm} />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

import {
  Form,
  FormInstance,
  Input,
  Typography,
  Upload,
  message,
  Image,
} from "antd";
import type { UploadProps } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import { TicketSelect } from "components/Selects/TicketSelect";
import { useSelectorRoot } from "store/store";
import { IIssue } from "api/types/issue";
import Utils from "common/Utils";
import { useEffect } from "react";
import { GroupTicketType, JobTicketStatusEnum } from "common/define-types";
import SYSTEM_CONSTANTS from "common/constants";
interface IProps {
  form: FormInstance<any>;
  setPathFile?: (value: string | null) => void;
  detailIssue?: IIssue;
}
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
const { Dragger } = Upload;
export const LeftTourForm = ({ form, setPathFile, detailIssue }: IProps) => {
  const selectGroupTickets = useSelectorRoot(
    (state) => state.group.groupTickets
  );
  const props: UploadProps = {
    name: "file",
    multiple: true,
    maxCount: 1,
    accept: "image/*",
    action: `${SYSTEM_CONSTANTS.API_URL}/Issue/issues/uploadImage`,
    headers: {
      Authorization: Utils.token ? `Bearer ${Utils.token}` : "",
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setPathFile && setPathFile(info.file.response);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setPathFile && setPathFile(null);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (!!detailIssue) {
      form.setFields(Utils.objectToAntFormData(detailIssue));
    }
  }, [form, detailIssue]);

  return (
    <Form form={form} labelCol={{ span: 24 }} className="noMargin_form-item">
      <Form.Item
        label={"Khách"}
        name={"ticketId"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <TicketSelect
          options={selectGroupTickets.filter(gTicket => gTicket.isTicketInGroup === true)}
          onChange={(value) => form.setFieldValue("ticketId", value)}
          readOnly={!!detailIssue}
          acceptStatus={[
            JobTicketStatusEnum.INIT,
            JobTicketStatusEnum.CONFIRM,
            JobTicketStatusEnum.PASS,
          ]}
          acceptType={[
            GroupTicketType.TICKET
          ]}
        />
      </Form.Item>
      <Form.Item
        label={"Lý do"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
        name={"reason"}
      >
        <Input readOnly={!!detailIssue} />
      </Form.Item>
      <Form.Item label={"Ghi chú"} name={"note"}>
        <Input.TextArea readOnly={!!detailIssue} />
      </Form.Item>
      {!!detailIssue && detailIssue.pathFile ? (
        <>
          <Typography.Text strong style={{ paddingBlock: 16 }}>
            Ảnh:
          </Typography.Text>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={`${SYSTEM_CONSTANTS.API_URL}${detailIssue.pathFile}`}
              width={"100%"}
            />
          </div>
        </>
      ) : (
        <Form.Item
          label={"Thêm ảnh"}
          valuePropName="fileList"
          name={"File"}
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Dragger {...props}>
            <FileImageOutlined />
            <p>Drag and drop here</p>
          </Dragger>
        </Form.Item>
      )}
    </Form>
  );
};

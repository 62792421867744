import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  PaginationProps,
  Popconfirm,
  Table,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnsType } from "antd/es/table";
import { getAllLocationsPageable } from "api/location.api";
import Utils from "common/Utils";
import { ILocation, IRouter } from "common/define-types";
import { useEffect, useState } from "react";
import {
  deleteRouter,
  editRouter,
  fetchRouters,
  saveRouter,
} from "store/slice/RouterSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "../Router.module.css";
import { RouterForm } from "./RouterForm";

export const RouterTable = () => {
  const selectRouter = useSelectorRoot((state) => state.router.Routers);
  const [locationList, setLocationList] = useState<ILocation[]>([]);
  const selectRoutersResult = useSelectorRoot(
    (state) => state.router.RoutersResult
  );
  const selectEditingRouter = useSelectorRoot(
    (state) => state.router.editingRouter
  );
  const selectDeletingRouterId = useSelectorRoot(
    (state) => state.router.deletingRouterId
  );
  const selectIsSubmitting = useSelectorRoot(
    (state) => state.router.isSubmitting
  );
  const dispatch = useDispatchRoot();
  const [editRouterForm] = useForm();

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await getAllLocationsPageable().toPromise();
        setLocationList(response || []);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };
    fetchLocations();
  }, []);
  const columns: ColumnsType<IRouter> = [
    {
      title: "Stt",
      dataIndex: "stt",
      render: (text, _, index) => (
        <>
          {index +
            (selectRoutersResult ? selectRoutersResult.firstRowIndex : 1)}
        </>
      ),
      width: '6%',
      align: "center",
    },
    {
      title: "Điểm xuất phát",
      dataIndex: "localtionStartId",
      sorter: {
        compare: (a, b) =>
          a.localtionStartId.localeCompare(b.localtionStartId, "vi"),
        multiple: 3,
      },
      width: "35%",
      render: (localtionStartId) => {
        const location = locationList.find(
          (loc) => loc.id === localtionStartId
        );
        return location ? location.name : "N/A";
      },
    },
    {
      title: "Điểm đến",
      dataIndex: "localtionEndId",
      sorter: {
        compare: (a, b) =>
          a.localtionEndId.localeCompare(b.localtionEndId, "vi"),
        multiple: 3,
      },
      width: "35%",
      render: (localtionEndId) => {
        const location = locationList.find((loc) => loc.id === localtionEndId);
        return location ? location.name : "N/A";
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: "20%",
      render: (_, record) => (
        <div className={styles.btnContainer}>
          <Button
            type="text"
            icon={<EditOutlined style={{ fontSize: 20, color: "#1890FF" }} />}
            onClick={() => handleEditRouter(record)}
          />
          <Popconfirm
            onConfirm={() => handleDeleteRouter(record.id)}
            title={"Xóa vị trí"}
            description={"Bạn chắc chắn muốn xóa vị trí này ?"}
          >
            <Button
              type="text"
              icon={
                <DeleteOutlined style={{ fontSize: 20, color: "#F5222D" }} />
              }
              loading={
                selectDeletingRouterId !== null &&
                selectDeletingRouterId === record.id
              }
              disabled={
                selectDeletingRouterId !== null &&
                selectDeletingRouterId !== record.id
              }
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectRoutersResult) {
      dispatch(
        fetchRouters({
          ...selectRoutersResult,
          page,
        })
      );
    }
  };

  const handleEditRouter = (Router: IRouter) => {
    dispatch(editRouter(Router));
  };

  const handleSaveRouter = async () => {
    if (selectEditingRouter) {
      const RouterValue = await editRouterForm.validateFields().catch((err) => {
        console.log(err);
        notification.error({ message: "Vui lòng nhập đủ thông tin" });
      });
      if (!RouterValue) return;
      dispatch(
        saveRouter({
          ...selectEditingRouter,
          ...RouterValue,
        })
      );
    }
  };

  const handleDeleteRouter = async (RouterId: string) => {
    await dispatch(deleteRouter(RouterId));
    dispatch(fetchRouters({ page: 1 }));
  };

  useEffect(() => {
    if (selectEditingRouter) {
      editRouterForm.setFields(
        Utils.objectToAntFormData({
          ...selectEditingRouter,
        })
      );
    }
  }, [selectEditingRouter, editRouterForm]);

  return (
    <>
      <Table
        dataSource={selectRouter}
        columns={columns}
        className={styles.table}
        rowKey={"id"}
        scroll={{ x: 550 }}
        pagination={{
          pageSize: selectRoutersResult ? selectRoutersResult.pageSize : 10,
          current: selectRoutersResult ? selectRoutersResult.page : 1,
          total: selectRoutersResult ? selectRoutersResult.queryCount : 0,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
      />
      {selectEditingRouter !== null ? (
        <Modal
          title={
            <Typography.Text strong style={{ fontSize: 20 }}>
              Sửa thông tin vị trí
            </Typography.Text>
          }
          okText={"Cập nhật"}
          cancelText="Hủy bỏ"
          centered
          onOk={handleSaveRouter}
          open={selectEditingRouter !== null}
          onCancel={() => dispatch(editRouter(null))}
          destroyOnClose={true}
          okButtonProps={{
            loading: selectIsSubmitting,
          }}
        >
          <RouterForm form={editRouterForm} />
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

import { InputNumber } from "antd";

interface MoneyInputProps {
  min?: number;
  max?: number;
  placeholder?: string;
  value?: number;
  onChange?: (value: number | null) => void;
}
export const MoneyInput = ({
  min,
  max,
  placeholder,
  value,
  onChange,
}: MoneyInputProps) => {
  return (
    <InputNumber
      style={{ width: "100%" }}
      controls={false}
      value={value}
      onChange={onChange}
      max={max}
      min={min}
      placeholder={placeholder}
      formatter={(value) =>
        `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
      }
      parser={(value) => parseInt(value ? value?.replaceAll(".", "") : "0")}
    />
  );
};

import { Layout } from "antd";
import { Redirect, RouteProps, Switch } from "react-router-dom";
import { AgentRoutes } from "../Routes";
import { PrivateRoute } from "../../components/Routes";
import { WaitOverlay } from "../../components/overlay/WaitOverlay";
import { LeftMenu } from "pages/leftMenu/LeftMenu";
import { HeaderSection } from "components/Header/HeaderSection";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { useEffect } from "react";
import {
  fetchComboTours,
  fetchCurrentAgent,
  fetchExtraService,
  fetchLocation,
  fetchPriceReqs,
} from "store/slice/BookingSlice";
import { fetchRouterStationBusData } from "store/slice/RouterStationBus";
import { RoleEnum } from "common/define-types";
import moment from "moment";
import { fetchHangHoaMotorbikes } from "store/slice/HangHoaSlice";
import { fetchRoomTemplates } from "store/slice/RoomTemplateSlice";

const { Content } = Layout;
export const AgentLayout = () => {
  const dispatch = useDispatchRoot();
  const userRole = useSelectorRoot((state) => state.login.role);
  const selectIsLoginSuccess = useSelectorRoot(
    (state) => state.login.isSuccess
  );
  const selectIsRefreshing = useSelectorRoot(
    (state) => state.login.isRefreshing
  );
  const filteredRoutes = AgentRoutes.filter((route) =>
    route.isRoot ? route.rootPermission?.some((r) => r === userRole) : true
  );
  const layoutRoutes = filteredRoutes.map((route) =>
    route.isRoot
      ? route
      : {
          ...route,
          items: route.items?.filter((item) =>
            item.rootPermission?.some((r) => r === userRole)
          ),
        }
  );
  const renderMain = () => {
    var routerProps: {
      label: string;
      routerProps: RouteProps;
      rootPermission?: RoleEnum[];
    }[] = [];
    for (var item of layoutRoutes) {
      if (item.items && item.items.length > 0) {
        for (var child of item.items) {
          if (child.routeProps) {
            routerProps.push({
              label: child.label,
              routerProps: child.routeProps,
              rootPermission: child.rootPermission,
            });
          }
        }
      }
      if (item.routeProps) {
        routerProps.push({
          label: item.label,
          routerProps: item.routeProps,
          rootPermission: item.rootPermission,
        });
      }
    }
    return routerProps.map(({ label, rootPermission, routerProps }) => (
      <PrivateRoute
        allowedRoles={[...(rootPermission ?? [RoleEnum.ALL])]}
        key={`${label}`}
        label={label}
        {...routerProps}
      />
    ));
  };
  useEffect(() => {
    if (selectIsLoginSuccess && !selectIsRefreshing) {
      const today = moment().toISOString();
      dispatch(fetchComboTours({ dateTime: today }));
      // dispatch(fetchTours());
      dispatch(fetchPriceReqs({ dateTime: today }));
      dispatch(fetchLocation());
      dispatch(fetchRouterStationBusData({ dateTime: today }));
      dispatch(fetchExtraService());
      dispatch(fetchRoomTemplates());
      dispatch(fetchHangHoaMotorbikes({ pageSize: 100 }));
      if (userRole === RoleEnum.AGENT) {
        dispatch(fetchCurrentAgent());
      }
    }
  }, [dispatch, userRole, selectIsLoginSuccess, selectIsRefreshing]);
  return (
    <Layout style={{ height: "100%", overflowY: "hidden" }}>
      <HeaderSection isAgentNav />
      <Content>
        <Layout style={{ height: "100%", position: "relative" }}>
          <LeftMenu routes={layoutRoutes} />
          <Switch>
            {renderMain()}
            {/* route for booking detail */}
            <Redirect to="/tours" />
          </Switch>
        </Layout>
      </Content>
      <WaitOverlay />
    </Layout>
  );
};

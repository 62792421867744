import {
  Button,
  Modal,
  PaginationProps,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import styles from "../Driver.module.css";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { ColumnsType } from "antd/es/table";
import { DriverType, IDriver, RoleEnum } from "common/define-types";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  deleteDriver,
  editDriver,
  fetchDrivers,
} from "store/slice/DriverSlice";
import { useForm } from "antd/es/form/Form";
import { DriverForm } from "./DriverForm";
import { useEffect } from "react";
import Utils from "common/Utils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const DriverTable = () => {
  const GENDER = {
    Female: "Nữ",
    Male: "Nam",
    Other: "Khác",
  };
  const userRole = useSelectorRoot((state) => state.login.role);
  const selectDeletingDriverId = useSelectorRoot(
    (state) => state.driver.deletingDriverId
  );
  const columns: ColumnsType<IDriver> = [
    {
      title: "Stt",
      dataIndex: "stt",
      render: (text, _, index) => (
        <>
          {index +
            (selectDriversResult ? selectDriversResult?.firstRowIndex : 1)}
        </>
      ),
      width: 60,
      align: "center",
    },
    {
      title: "Mã nhân viên",
      dataIndex: "accCode",
      width: 120,
      ellipsis: true,
      align: "center",
    },
    {
      title: "Tên tài xế",
      dataIndex: "name",
      width: 200,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name, "vi"),
        multiple: 3,
      },
    },
    {
      title: "Loại tài xế",
      dataIndex: "type",
      width: 150,
      render: (type) => {
        switch (type) {
          case DriverType.CONTRACT:
            return <>{`Theo hợp đồng`}</>;

          case DriverType.FREELANCE:
            return <>{`Thuê ngoài`}</>;

          default:
            break;
        }
      },
    },
    {
      title: "Tuổi",
      dataIndex: "age",
      width: 70,
      align: "center",
      sorter: {
        compare: (a, b) => a.age - b.age,
        multiple: 2,
      },
    },
    {
      title: "Giới tính",
      dataIndex: "sex",
      width: 120,
      align: "center",
      sorter: {
        compare: (a, b) => (a.sex || "").localeCompare(b.sex || ""),
        multiple: 1,
      },
      render: (value: "Female" | "Male" | "Other") => GENDER[value],
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      width: 120,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      width: 220,
    },
    {
      title: "Số CCCD",
      dataIndex: "cccD1",
      width: 130,
    },
    {
      title: "Bằng lái xe",
      dataIndex: "driveLisence1",
      width: 130,
    },
    {
      title: "Tiền công/ngày",
      dataIndex: "salaryDate",
      width: 140,
      render: (value) => <>{Utils.formatCurrency(value)}</>,
    },
    {
      title: "Ngày bắt đầu làm việc",
      dataIndex: "dateStartWork",
      width: 140,
      ellipsis: true,
      render: (value) => (
        <>
          {value === "0001-01-01T00:00:00"
            ? ""
            : dayjs(`${value}Z`).format(Utils.dateFormat)}
        </>
      ),
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      width: 140,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      width: 120,
      render: (_, record) => (
        <div className={styles.btnContainer}>
          <Button
            type="text"
            icon={<EditOutlined style={{ fontSize: 20, color: "#1890FF" }} />}
            onClick={() => handleEditDriver(record)}
          />
          <Popconfirm
            onConfirm={() => handleDeleteDriver(record.id)}
            title={"Xóa tài xế"}
            description={"Bạn chắc chắn muốn xóa tài xế này ?"}
          >
            <Button
              type="text"
              icon={
                <DeleteOutlined style={{ fontSize: 20, color: "#F5222D" }} />
              }
              loading={
                selectDeletingDriverId !== null &&
                selectDeletingDriverId === record.id
              }
              disabled={
                selectDeletingDriverId !== null &&
                selectDeletingDriverId !== record.id
              }
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const selectDrivers = useSelectorRoot((state) => state.driver.drivers);
  const selectDriversResult = useSelectorRoot(
    (state) => state.driver.driversResult
  );
  const selectEditingDriver = useSelectorRoot(
    (state) => state.driver.editingDriver
  );
  const selectEdtingDriverAccount = useSelectorRoot(
    (state) => state.driver.editingDriverAccount
  );
  const dispatch = useDispatchRoot();
  const [editDriverForm] = useForm();

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectDriversResult) {
      dispatch(
        fetchDrivers({
          ...selectDriversResult,
          page,
        })
      );
    }
  };

  const handleEditDriver = (driver: IDriver) => {
    dispatch(editDriver(driver));
  };

  const handleDeleteDriver = (driverId: string) => {
    dispatch(deleteDriver(driverId));
  };

  useEffect(() => {
    let data = {};
    if (selectEditingDriver) {
      data = {
        ...data,
        ...selectEditingDriver,
        dateStartWork: dayjs.utc(`${selectEditingDriver.dateStartWork}`),
        pathImageCCCD: undefined,
        pathImageLicense: undefined,
      };
    }
    if (selectEdtingDriverAccount) {
      data = {
        ...data,
        userName: selectEdtingDriverAccount?.userName,
        email: selectEdtingDriverAccount.email,
        password: "",
      };
    }
    editDriverForm.setFields(Utils.objectToAntFormData(data));
  }, [selectEditingDriver, editDriverForm, selectEdtingDriverAccount]);

  return (
    <>
      <Table
        dataSource={selectDrivers}
        // columns={columns}
        columns={columns
          .filter(
            (column) => userRole === RoleEnum.ADMIN || column.key !== "action"
          )
          .filter((column) => column.key !== "status")}
        className={`${styles.table} driverTable`}
        scroll={{ x: 1500 }}
        pagination={{
          current: selectDriversResult ? selectDriversResult.page : 1,
          pageSize: selectDriversResult ? selectDriversResult.pageSize : 10,
          total: selectDriversResult ? selectDriversResult.queryCount : 0,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
        rowKey={"id"}
      />
      {selectEditingDriver !== null ? (
        <Modal
          title={
            <Typography.Text strong style={{ fontSize: 20 }}>
              Sửa thông tin tài xế
            </Typography.Text>
          }
          okText={"Cập nhật"}
          centered
          onOk={editDriverForm.submit}
          open={selectEditingDriver !== null}
          onCancel={() => dispatch(editDriver(null))}
        >
          <DriverForm
            form={editDriverForm}
            editingDriver={selectEditingDriver}
          />
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

import { Form, FormInstance, Radio } from 'antd';
import { MoneyInput } from 'components/Input/MoneyInput';

export enum DiscountType {
  BY_VALUE = 1,
  BY_PERCENT = 2,
}
interface DiscountFormProps {
  form?: FormInstance<any>;
}
const radioOptions = [
  {
    label: 'Theo %',
    value: DiscountType.BY_PERCENT,
  },
  {
    label: 'Theo giá trị',
    value: DiscountType.BY_VALUE,
  },
];
const MAX = {
  BY_PERCENT: 100,
};
export const DiscountForm = ({ form }: DiscountFormProps) => {
  const [defaultForm] = Form.useForm();
  const controlForm = form || defaultForm;
  const discountType = Form.useWatch('type', controlForm);
  return (
    <Form
      form={controlForm}
      initialValues={{
        type: DiscountType.BY_PERCENT,
        amount: 0,
      }}
    >
      <Form.Item name={'type'}>
        <Radio.Group options={radioOptions} />
      </Form.Item>
      <Form.Item
        name={'amount'}
        rules={[
          {
            required: true,
            message: 'Không được bỏ trống',
          },
        ]}
      >
        <MoneyInput
          // min={0}
          max={discountType === 2 ? MAX.BY_PERCENT : undefined}
        />
      </Form.Item>
    </Form>
  );
};

import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import {
  ICreateBooking,
  IUpdateBooking,
  IUpdatePaymentMethodBooking,
} from "./types/booking";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import {
  IGroupTicket,
  IParams,
  IPaymentMethodBooking,
  ISelectedTicket,
  ITicketOperatorItem,
} from "common/define-types";
import Utils from "common/Utils";

const api = `${SYSTEM_CONSTANTS.API_URL}/Booking`;

export const getBookingById = (id: string): Observable<any> => {
  return HttpClient.get(`${api}/${id}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getAllBooking = (params?: IParams): Observable<any> => {
  if (params) {
    return HttpClient.get(`${api}?${Utils.getParamsString(params)}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(api).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};

export const createBooking = (body: ICreateBooking): Observable<any> => {
  return HttpClient.post(api, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const updateBooking = (body: IUpdateBooking): Observable<any> => {
  return HttpClient.put(`${api}/${body.id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const deleteBookingById = (
  id: string,
  body?: IUpdatePaymentMethodBooking[]
): Observable<any> => {
  return HttpClient.delete(`${api}/${id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const getCurrentBooking = (params?: IParams): Observable<any> => {
  if (params) {
    return HttpClient.get(
      `${api}/current?${Utils.getParamsString(params)}`
    ).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(`${api}/current`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};
export const getCurrentBookingSumarize = (
  params?: IParams
): Observable<any> => {
  if (params) {
    return HttpClient.get(
      `${api}/current/sumarize?${Utils.getParamsString(params)}`
    ).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(`${api}/current`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};
export const updateBookingCollectionFee = (
  bookingId: string,
  payload: IPaymentMethodBooking[],
  ticketId: string
) => {
  return HttpClient.put(
    `${api}/${bookingId}/paymentMethodJasmineKeepMoneys?ticketId=${ticketId}`,
    JSON.stringify(payload),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updateBookingTicket = ({
  ticketId,
  body,
}: {
  ticketId: string;
  body: {
    paymentMethodBookingsConnect?: IPaymentMethodBooking[];
    paymentMethodBookingsDisable?: IPaymentMethodBooking[];
    ticketsUpdate: IGroupTicket | ISelectedTicket | ITicketOperatorItem;
  };
}) => {
  return HttpClient.put(`${api}/ticket/${ticketId}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const checkTicketForUpdate = ({ ticketId }: { ticketId: string }) => {
  return HttpClient.get(`${api}/checkTicket?ticketId=${ticketId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

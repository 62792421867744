import {
  Button,
  Checkbox,
  Divider,
  Empty,
  Popconfirm,
  Select,
  Typography,
  notification,
} from "antd";
import {
  ComboTourStatus,
  IComboTour,
  ISelectedComboTour,
  ITicket,
} from "common/define-types";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "./Booking.module.css";
import { setComboToursSelected } from "store/slice/BookingSlice";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useEffect, useState } from "react";
import Utils from "common/Utils";
//[14-12-2023][Phuc Thinh][Feature/14901]
//Change Tour to TourCombo

interface ICheckedTour extends IComboTour {
  isChecked: boolean;
}
const isSelected = (
  comboTourId: string,
  selectedComboTours: ISelectedComboTour[]
) => {
  return selectedComboTours.some((comboTour) => comboTour.Id === comboTourId);
};
const getTicketsIfExisted = (
  comboTourId: string,
  selectedComboTours: ISelectedComboTour[]
) => {
  let tickets = null;
  selectedComboTours.forEach((comboTour) => {
    if (comboTour.Id === comboTourId) {
      if (comboTour.tickets && comboTour.tickets.length > 0) {
        tickets = comboTour.tickets;
      }
    }
  });
  return tickets;
};

export const TourSelect = () => {
  const selectEditingBooking = useSelectorRoot(
    (state) => state.booking.editingBooking
  );
  const selectComboTours = useSelectorRoot(
    (state) => state.booking.comboTours
  ).filter((combo) => combo.Status === ComboTourStatus.AVAILABLE);
  const selectExtraServices = useSelectorRoot(
    (state) => state.booking.extraServices
  );
  const selectHangHoas = useSelectorRoot((state) => state.hangHoa.motorbikes);
  const selectComboToursSelected = useSelectorRoot(
    (state) => state.booking.selectedComboTours
  );
  // const selectTours = useSelectorRoot((state) => state.booking.tours);
  // const selectToursSelected = useSelectorRoot(
  //   (state) => state.booking.selectedTours
  // );
  // const tours = Utils.mapArray(
  //   selectTours,
  //   selectPriceReqs,
  //   "countDay",
  //   "countDay",
  //   "driverPriceDto"
  // );
  const selectCurrentAgent = useSelectorRoot(
    (state) => state.booking.currentAgent
  );
  const dispatch = useDispatchRoot();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [checkedTours, setCheckedTour] = useState<ICheckedTour[]>();
  const [hasTourSelect, setHasTourSelect] = useState(false);

  const handleCheck = (e: CheckboxChangeEvent, tour: IComboTour) => {
    const checked = e.target.checked;
    if (tour && checkedTours) {
      const foundCheckedTour = selectComboToursSelected.find(
        (selectedComboTour) => selectedComboTour.Id === tour.Id
      );
      if (
        !!selectEditingBooking &&
        foundCheckedTour &&
        foundCheckedTour.tickets &&
        foundCheckedTour.tickets.some(
          (ticket) =>
            Utils.checkOncomingTicket(ticket) ||
            Utils.checkDepartedTicket(ticket)
        ) &&
        !checked
      ) {
        notification.warning({
          message:
            "Tour chứa vé chuẩn bị khởi hành sát giờ hoặc đã khởi hành !",
        });
        return;
      }
      setCheckedTour(
        checkedTours.map((checkedTour) =>
          checkedTour.Id === tour.Id
            ? {
                ...checkedTour,
                isChecked: checked,
              }
            : checkedTour
        )
      );
      setHasTourSelect(true);
    }
  };

  const handleSave = () => {
    if (checkedTours) {
      const newArr = checkedTours
        .filter((tour) => tour.isChecked)
        .map(({ isChecked, ...rest }) => ({ ...rest }));
      dispatch(setComboToursSelected(newArr));
      setIsDropdownOpen(false);
      setHasTourSelect(false);
    }
  };

  const handleReset = () => {
    setCheckedTour(
      selectComboTours.map((comboTour) => ({
        ...comboTour,
        isChecked: isSelected(comboTour.Id, selectComboToursSelected),
      }))
    );
    setIsDropdownOpen(false);
    setHasTourSelect(false);
  };

  useEffect(() => {
    if (selectComboToursSelected) {
      // const toursArray = Utils.mapArray(
      //   selectTours,
      //   selectPriceReqs,
      //   "countDay",
      //   "countDay",
      //   "driverPriceDto"
      // );
      setCheckedTour(
        selectComboTours.map((comboTour, index) => {
          const defaultTicket: ITicket = Utils.getDefaultTicket({
            agent: selectCurrentAgent,
            index,
            comboTour,
            extraServices: selectExtraServices,
            hangHoas: selectHangHoas,
          });
          return {
            ...comboTour,
            tickets: getTicketsIfExisted(
              comboTour.Id,
              selectComboToursSelected
            ) || [defaultTicket],
            isChecked: isSelected(comboTour.Id, selectComboToursSelected),
          };
        })
      );
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    // selectComboTours,
    selectComboToursSelected,
    // selectCurrentAgent,
    isDropdownOpen,
  ]);

  return (
    <Select
      dropdownRender={() => (
        <div className={styles.selectContainer}>
          {checkedTours && checkedTours.length > 0 ? (
            <>
              <div className={`${styles.selectWrapper} customScroll`}>
                {checkedTours.map((tour) => (
                  <Checkbox
                    checked={tour.isChecked}
                    style={{
                      marginInlineStart: 0,
                      padding: 5,
                      display: "flex",
                      gap: 3,
                      fontSize: 15,
                      fontWeight: 500,
                    }}
                    onChange={(e: any) => handleCheck(e, tour)}
                    key={`${tour.Id}`}
                  >
                    {`${tour.Title || ""} ${tour.SubTitle || ""} `}
                    <Typography.Text
                      style={{ color: "grey", fontSize: 14 }}
                      italic
                    >
                      {tour.Description}
                    </Typography.Text>
                  </Checkbox>
                ))}
              </div>
              <Divider style={{ marginBlock: 5 }} />
              <div className={styles.select_btnContainer}>
                <Button
                  type="text"
                  onClick={handleReset}
                  disabled={!hasTourSelect}
                >
                  Reset
                </Button>
                <Popconfirm
                  title="Lưu thay đổi"
                  onConfirm={handleSave}
                  getPopupContainer={(trigger) =>
                    trigger.parentElement as HTMLElement
                  }
                >
                  <Button type="primary">Apply</Button>
                </Popconfirm>
              </div>
            </>
          ) : (
            <Empty />
          )}
        </div>
      )}
      value={`${
        checkedTours ? checkedTours.filter((tour) => tour.isChecked).length : 0
      } loại tour được chọn`}
      style={{ minWidth: 260 }}
      open={isDropdownOpen}
      onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
    />
  );
};

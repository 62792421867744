import { Typography, Button, Tooltip } from "antd";
import styles from "../Agents.module.css";
import {
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined,
  EditOutlined,
  // DeleteOutlined,
  PercentageOutlined,
  UnlockOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { useDispatchRoot } from "store/store";
import {
  blockOrUnblockAgent,
  // deleteAgent,
  // saveAgent,
  editAgent,
  // saveAgent,
  setAgentSelected,
  setDiscountAgent,
} from "store/slice/AgentsSlice";
import { IAgent } from "api/types/agent";
import { useEffect, useRef } from "react";

interface IProps {
  agent: IAgent;
  isActive?: boolean;
}
export const AgentItem = ({ agent, isActive }: IProps) => {
  // const selectDeletingAgentId = useSelectorRoot(
  //   (state) => state.agents.deletingAgentId
  // );
  const dispatch = useDispatchRoot();

  const handleSelectAgent = () => {
    dispatch(setAgentSelected(agent));
  };

  const handleEditAgent = (e: any) => {
    e.stopPropagation();
    dispatch(editAgent(agent));
  };

  const handleOpenDiscountAgent = () => {
    dispatch(setDiscountAgent(agent));
  };

  const handleBlockOrUnblockAgent = () => {
    if (agent) {
      dispatch(
        blockOrUnblockAgent({
          ...agent,
          isBlock: !agent.isBlock,
          agentId: agent.id,
        })
      );
    }
  };

  const agentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isActive && agentRef.current)
      agentRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [isActive]);
  // const handleDeleteAgent = () => {
  //   if (agent) {
  //     dispatch(deleteAgent(agent.id));
  //   }
  // };
  return (
    <div
      className={`${styles.agentItem} ${isActive ? styles.agentSelected : ""} ${
        agent.isErase ? styles.isErased : ""
      }`}
      ref={agentRef}
      onClick={handleSelectAgent}
    >
      <div className={styles.agentItem_wrapper}>
        <div className={styles.spaceBetween}>
          <Typography.Text
            strong
            style={{ fontSize: 20, fontWeight: 700 }}
          >{`${agent.name}`}</Typography.Text>
          <Typography.Text
            style={{ textAlign: "right", fontSize: 20, fontWeight: 700 }}
            strong
          >
            {`${agent.city}`}
          </Typography.Text>
        </div>
        <div className={styles.textWithIcon}>
          <EnvironmentOutlined style={{ fontSize: 20 }} />
          <Typography.Text strong>{`${agent.address}`}</Typography.Text>
        </div>
        <div className={styles.spaceBetween}>
          <div className={styles.textWithIcon} style={{ minWidth: "45%" }}>
            <PhoneOutlined style={{ fontSize: 20 }} rotate={90} />
            <Typography.Text strong>{` ${agent.phone ?? ""}`}</Typography.Text>
          </div>
          <div
            className={styles.textWithIcon}
            style={{ maxWidth: "55%", minWidth: "55%" }}
          >
            <MailOutlined style={{ fontSize: 20 }} />
            <Typography.Text
              style={{
                textAlign: "right",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              strong
              title={` ${agent.email ?? ""}`}
            >
              {` ${agent.email ?? ""}`}
            </Typography.Text>
          </div>
        </div>
        {/* <div>
          <Typography.Text
            style={{ fontWeight: 700, marginRight: 10 }}
          >{`Mức tính hoa hồng: `}</Typography.Text>
          <Typography.Text
            style={{ fontWeight: 500 }}
          >{`12.5%`}</Typography.Text>
        </div> */}
      </div>
      <div className={styles.btnContainer} style={{ flexBasis: 72 }}>
        <Button
          icon={<EditOutlined style={{ fontSize: 20, color: "#1890FF" }} />}
          type="text"
          onClick={handleEditAgent}
        />
        <Tooltip title={agent.isBlock ? "Mở khóa" : "Khóa đại lý"}>
          <Button
            icon={
              agent.isBlock ? (
                <LockOutlined style={{ fontSize: 20, color: "#efc518" }} />
              ) : (
                <UnlockOutlined style={{ fontSize: 20, color: "#52C41A" }} />
              )
            }
            type="text"
            onClick={(e: any) => {
              e.stopPropagation();
              handleBlockOrUnblockAgent();
            }}
          />
        </Tooltip>
        <Tooltip title={"Chiết khấu"}>
          <Button
            icon={
              <PercentageOutlined style={{ fontSize: 20, color: "#ff4d4f" }} />
            }
            type="text"
            onClick={(e: any) => {
              e.stopPropagation();
              handleOpenDiscountAgent();
            }}
          />
        </Tooltip>

        {/* <Popconfirm
          onConfirm={(e) => {
            e?.stopPropagation();
            handleDeleteAgent();
          }}
          onCancel={(e) => e?.stopPropagation()}
          title={"Xóa đại lý"}
          description={"Bạn có chắc chắn muốn xóa đại lý này ?"}
          okText="Có"
          cancelText="Không"
        >
          <Button
            onClick={(e) => e.stopPropagation()}
            icon={<DeleteOutlined style={{ fontSize: 20, color: "red" }} />}
            loading={selectDeletingAgentId === agent.id}
            type="text"
          />
        </Popconfirm> */}
      </div>
    </div>
  );
};

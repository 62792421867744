import {
  Button,
  Dropdown,
  LayoutProps,
  MenuProps,
  Modal,
  Typography,
  notification,
} from "antd";
import React, { useState } from "react";
import { FC } from "react";
import { addStaffTicket, createNewGroup, setGroupForTickets } from "store/slice/GroupSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { PlusOutlined, EllipsisOutlined } from "@ant-design/icons";
import styles from "../Group.module.css";
import { ICreateGroup } from "api/types/group";
import moment from "moment";
import { AddGroupModal } from "./AddGroupModal";
// import { TourType } from "components/Text/TourType";
import Utils from "common/Utils";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import { IGroup, MotorTicketType } from "common/define-types";
import { AddStaffModal } from "./AddStaffModal";

interface IProps {
  disabled?: boolean;
}
export const GroupSelect: FC<LayoutProps & IProps> = ({
  children,
  disabled = false,
}) => {
  const selectGroups = useSelectorRoot((state) => state.group.groups);
  const selectGroupsIndex = useSelectorRoot((state) => state.group.groupsIndex);
  const selectGroupSelected = useSelectorRoot(
    (state) => state.group.groupSelected
  );
  const selectTicketsSelected = useSelectorRoot(
    (state) => state.group.groupTicketsSelected
  );
  const dispatch = useDispatchRoot();
  const selectIsSubmitting = useSelectorRoot(
    (state) => state.group.isSubmitting
  );
  const selectSelectedDate = useSelectorRoot(
    (state) => state.group.selectedDate
  );
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isAddStaffModalOpen, setIsAddStaffModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOptionDropdownOpen, setIsOptionDropdownOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [asigningGroup, setAsigningGroup] = useState<IGroup | null>(null);

  const items: MenuProps["items"] = selectGroups
    .filter((group) => group.id !== selectGroupSelected?.id)
    .map((group) => ({
      key: group.id,
      disabled: disabled,
      label: (
        <span
          onClick={() => {
            if (
              selectGroupSelected?.countTicket === selectTicketsSelected.length
            ) {
              setAsigningGroup(group);
            } else {
              setIsDropdownOpen(false);
              dispatch(
                setGroupForTickets({
                  tickets: selectTicketsSelected,
                  group: group,
                })
              );
            }
          }}
        >
          {group.name}
        </span>
      ),
    }));
  const menuStyle = {
    boxShadow: "none",
    maxHeight: 130,
    overflowY: "auto",
    padding: "4px 12px",
  };

  const handleAddGroup = async (groupInfo: {
    groupName: string;
    tourId: string;
    leaderId?: string;
  }) => {
    if (selectTicketsSelected && selectTicketsSelected.length > 0) {
      const newGroup: ICreateGroup = {
        name: `${groupInfo.groupName}_${
          Utils.getMaxIndex(selectGroupsIndex) + 1
        }`,
        description: "Empty group",
        // prices: "string",
        startDate: moment.utc(selectSelectedDate).startOf("day").toISOString(),
        ticketIds: selectTicketsSelected.map((ticket) => ticket.id),
        // tourId: groupInfo.tourId,
        leaderId: groupInfo.leaderId,
      };
      dispatch(createNewGroup(newGroup));
    }
  };

  const handleAddStaffGroup = async (args: {
    driverId: string;
    motorbikeId?: string;
    typeMotor?: MotorTicketType;
  }) => {
    if (selectGroupSelected) {
      const { driverId, motorbikeId, typeMotor } = args;
      
      dispatch(addStaffTicket({
        driverId,
        motorId: typeMotor === MotorTicketType.Jasmine ? motorbikeId : undefined,
        groupId: selectGroupSelected.id,
        typeMotor
      }))

    }
  };

  return (
    <>
      <div style={{ display: "flex", gap: 10 }}>
        <Dropdown
          open={isDropdownOpen && !disabled}
          onOpenChange={(open) => {
            if (open) {
              if (
                !selectTicketsSelected ||
                selectTicketsSelected.length === 0
              ) {
                notification.info({
                  message: "Bạn phải chọn các vé cần gán cho group trước !",
                });
                notification.destroy();
              } else {
                setIsDropdownOpen(open);
              }
            } else {
              setIsDropdownOpen(open);
            }
          }}
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          dropdownRender={(menu) => (
            <div className={styles.groupSelectDropdown}>
              {items.length > 0 ? (
                React.cloneElement(menu as React.ReactElement, {
                  style: menuStyle,
                  className: "customScroll",
                })
              ) : (
                <EmptyHolder message="No groups" />
              )}
              {/* [25-1-2024][Phuc Thinh]
              [Currently, has some unhandled case from BE when transfer ticket 
              to a brand-new group so the temporary solution now is to prevent it from FE]
              */}
              {
                !selectGroupSelected &&
                <div
                  style={{
                    padding: "4px 8px",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    icon={<PlusOutlined />}
                    type="text"
                    style={{ width: "100%" }}
                    onClick={() => {
                      // if (
                      //   selectGroupSelected?.countTicket ===
                      //   selectTicketsSelected.length
                      // ) {
                      //   setConfirmModalOpen(true);
                      // } else {
                        setIsAddModalOpen(true);
                        setIsDropdownOpen(false);
                      // }
                    }}
                    loading={selectIsSubmitting}
                  >
                    Add new
                  </Button>
                </div>
              }
            </div>
          )}
        >
          {children}
        </Dropdown>
        {
          selectGroupSelected &&
          <Dropdown
            open={isOptionDropdownOpen}
            onOpenChange={(open) => setIsOptionDropdownOpen(open)}
            trigger={["click"]}
            placement="bottomRight"
            dropdownRender={(_) => (
              <div className={styles.groupSelectDropdown}>
                <div
                  style={{
                    padding: "4px 8px",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    icon={<PlusOutlined />}
                    type="text"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setIsAddStaffModalOpen(true);
                      setIsOptionDropdownOpen(false);
                    }}
                  >
                    Thêm nhân viên vào group
                  </Button>
                </div>
              </div>
            )}
          >
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        }
      </div>
      {asigningGroup && (
        <Modal
          open={asigningGroup !== null}
          onOk={() => {
            setAsigningGroup(null);
            setIsDropdownOpen(false);
            dispatch(
              setGroupForTickets({
                tickets: selectTicketsSelected,
                group: asigningGroup,
              })
            );
          }}
          closable={false}
          title={"Chuyển group"}
          width={530}
          zIndex={1051}
          centered
          onCancel={() => setAsigningGroup(null)}
        >
          <Typography>{`Group "${selectGroupSelected?.name}" sẽ bị xóa, Bạn có chắc chắn chuyển Group không ?`}</Typography>
        </Modal>
      )}
      <Modal
        open={confirmModalOpen}
        onOk={() => {
          setConfirmModalOpen(false);
          setIsAddModalOpen(true);
          setIsDropdownOpen(false);
        }}
        closable={false}
        title={"Chuyển group"}
        width={530}
        zIndex={1051}
        centered
        onCancel={() => setConfirmModalOpen(false)}
      >
        <Typography>{`Group "${selectGroupSelected?.name}" sẽ bị xóa, Bạn có chắc chắn chuyển Group không ?`}</Typography>
      </Modal>
      <AddGroupModal
        isOpen={isAddModalOpen}
        handleClose={() => setIsAddModalOpen(false)}
        handleSubmit={handleAddGroup}
      />

      <AddStaffModal
        isOpen={isAddStaffModalOpen}
        handleClose={() => setIsAddStaffModalOpen(false)}
        handleSubmit={handleAddStaffGroup}
      />
    </>
  );
};

import { Modal, Select, Typography, message, notification } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { ChangeRoomForm } from "components/Form/ChangeRoomForm";
import { LeftTourForm } from "components/Form/LeftTourForm";
import { VehicleBreakForm } from "components/Form/VehicleBreakForm";
import styles from "./Modal.module.css";
import {
  IGroupTicket,
  IPaymentMethodBooking,
  IssueStatus,
  IssueType,
  ThanhToanIssueStatus,
} from "common/define-types";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { createNewIssue } from "store/slice/GroupSlice";
import OuterGasPumpForm from "components/Form/OuterGasPumpForm";
import ExtraMealForm from "components/Form/ExtraMealForm";
import ExtraSleptForm from "components/Form/ExtraSleptForm";
import moment from "moment";
import { IRoomTemplate } from "api/types/roomTemplate";
import { ChangeTourForm } from "components/Form/ChangeTourForm";
import { ChangeTypeDriverForm } from "components/Form/ChangeTypeDriverForm";
import { OtherIssueForm } from "components/Form/OtherIssueForm";
interface IProps {
  open: boolean;
  handleClose: () => void;
}
export const AddIssueModal = ({ open, handleClose }: IProps) => {
  const [pathFile, setPathFile] = useState<string | null>(null);
  const selectIsCreating = useSelectorRoot(
    (state) => state.group.isCreatingIssue
  );
  const selectHasCreated = useSelectorRoot(
    (state) => state.group.isIssueCreated
  );
  const selectCreateErrMsg = useSelectorRoot(
    (state) => state.group.issueErrMsg
  );
  const dispatch = useDispatchRoot();
  const selectGroupSelected = useSelectorRoot(
    (state) => state.group.groupSelected
  );
  const selectGroupTickets = useSelectorRoot(
    (state) => state.group.groupTickets
  );
  const selectRoomTemplates = useSelectorRoot(
    (state) => state.roomTemplate.roomTemplates
  );
  const selectMotorbikes = useSelectorRoot(
    (state) => state.group.groupMotorbikes
  );
  const selectJobDateSelected = useSelectorRoot(
    (state) => state.group.jobDateSelected
  );

  const [issueTypeActive, setIssueTypeActive] = useState(
    IssueType.BREAKDOWN_MOTORBIKE
  );
  const options = [
    {
      label: "Hỏng xe",
      value: IssueType.BREAKDOWN_MOTORBIKE,
    },
    {
      label: "Khách bỏ tour",
      value: IssueType.CANCELTOUR,
    },
    {
      label: "Đổi phòng ngủ",
      value: IssueType.CHANGEROOM,
    },
    {
      label: "Đổ xăng lẻ",
      value: IssueType.OUTER_FUEL,
    },
    {
      label: "Khách ăn thêm",
      value: IssueType.EXTRA_MEAL,
    },
    {
      label: "Ngủ thêm đêm cuối",
      value: IssueType.EXTRA_SLEPT,
    },
    {
      label: "Thêm ngày đi tour",
      value: IssueType.CHANGE_TOUR,
    },
    {
      label: "Tự lái sang có lái",
      value: IssueType.CHANGE_TO_WITH_DRIVER,
    },
    {
      label: "Có lái sang tự lái",
      value: IssueType.CHANGE_TO_WITHOUT_DRIVER,
    },
    {
      label: "Sự cố khác",
      value: IssueType.OtherIssue,
    },
  ];
  const [issueForm] = useForm();
  const renderIssueForm = () => {
    switch (issueTypeActive) {
      case IssueType.CHANGEROOM:
        return <ChangeRoomForm form={issueForm} />;
      case IssueType.CANCELTOUR:
        return (
          <LeftTourForm
            form={issueForm}
            setPathFile={(value) => setPathFile(value)}
          />
        );
      case IssueType.OtherIssue:
          return (
            <OtherIssueForm
              form={issueForm}
              setPathFile={(value) => setPathFile(value)}
            />
          );
      case IssueType.BREAKDOWN_MOTORBIKE:
        return (
          <VehicleBreakForm
            form={issueForm}
            setPathFile={(value) => setPathFile(value)}
          />
        );
      case IssueType.OUTER_FUEL:
        return (
          <OuterGasPumpForm
            form={issueForm}
            setPathFile={(value) => setPathFile(value)}
          />
        );
      case IssueType.EXTRA_MEAL:
        return (
          <ExtraMealForm
            form={issueForm}
            setPathFile={(value) => setPathFile(value)}
          />
        );
      case IssueType.EXTRA_SLEPT:
        return <ExtraSleptForm form={issueForm} />;
      case IssueType.CHANGE_TOUR:
        return <ChangeTourForm form={issueForm} />;
      case IssueType.CHANGE_TO_WITH_DRIVER:
        return (
          <ChangeTypeDriverForm
            form={issueForm}
            type={IssueType.CHANGE_TO_WITH_DRIVER}
          />
        );
      case IssueType.CHANGE_TO_WITHOUT_DRIVER:
        return (
          <ChangeTypeDriverForm
            form={issueForm}
            type={IssueType.CHANGE_TO_WITHOUT_DRIVER}
          />
        );

      default:
        return <></>;
    }
  };

  const getIssueDate = (
    issueType: IssueType,
    additionalParams: {
      roomTemplate?: IRoomTemplate;
      groupTicket?: IGroupTicket;
      day?: number;
      startDate?: string;
    }
  ) => {
    const { roomTemplate, groupTicket, day, startDate } = additionalParams;
    const tzOffset = new Date().getTimezoneOffset();
    const today = moment().subtract(tzOffset, 'minutes');
    const groupStartDate = moment.utc(startDate);
    switch (issueType) {
      case IssueType.CHANGEROOM:
        return moment
          .utc(groupTicket?.startDate)
          .add(
            roomTemplate
              ? roomTemplate?.date - 1
              : typeof selectJobDateSelected === "string"
              ? parseInt(selectJobDateSelected) - 1
              : selectJobDateSelected - 1,
            "days"
          )
          .toISOString();

      case IssueType.EXTRA_SLEPT:
        return groupTicket?.endDate;
      case IssueType.CHANGE_TO_WITHOUT_DRIVER:
        return today.toISOString();
      case IssueType.CHANGE_TO_WITH_DRIVER:
        return groupStartDate.clone().add((day || 1) - 1, 'days').toISOString();

      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (selectCreateErrMsg) {
      message.error("Tạo phát sinh không thành công");
    }
  }, [selectCreateErrMsg]);

  useEffect(() => {
    if (selectHasCreated) {
      message.success("Tạo phát sinh thành công");
    }
  }, [selectHasCreated]);

  const handleCreateIssue = async () => {
    const issueValue = await issueForm.validateFields().catch((err) => {
      console.log(err);
      notification.error({ message: "Vui lòng nhập đủ thông tin" });
    });
    if (!issueValue || !selectGroupSelected) return;

    //
    const paymentMethodRefund: IPaymentMethodBooking[] = [];
    if (issueTypeActive === IssueType.CHANGE_TO_WITHOUT_DRIVER) {
      if (issueValue["90001"]) {
        paymentMethodRefund.push({
          paymentCode: "90001",
          amount: issueValue["90001"],
        });
      }
      if (issueValue["90013"]) {
        paymentMethodRefund.push({
          paymentCode: "90013",
          amount: issueValue["90013"],
        });
      }
    }
    const getAmountMoney = () => {
      switch (issueTypeActive) {
        case IssueType.CHANGEROOM:
        case IssueType.EXTRA_SLEPT:
          return selectRoomTemplates
            .filter((room) => room.id === issueValue.roomTemplateId)[0]
            .price.toString();
        case IssueType.CHANGE_TO_WITHOUT_DRIVER:
          return paymentMethodRefund.reduce(
            (prev, current) => prev + current.amount,
            0
          );

        default:
          return issueValue.amount_Money || undefined;
      }
    };
    const amount_Money = getAmountMoney();
    const foundMotorbike = selectMotorbikes.find(
      (motorbike) => motorbike.motorNumber === issueValue?.motobikeNumber
    );
    
    var body = {
      ...issueValue,
      amount_Money: amount_Money,
      pathFile: pathFile,
      /* eslint-disable eqeqeq */
      type: issueTypeActive,
      groupId: selectGroupSelected?.id,
      status: IssueStatus.Approve,
      motobikeId: foundMotorbike ? foundMotorbike.id : undefined,
      date: getIssueDate(issueTypeActive, {
        roomTemplate: selectRoomTemplates.find(
          (room) => room.id === issueValue.roomTemplateId
        ),
        groupTicket: selectGroupTickets.find(
          (gTicket) => gTicket.id === issueValue?.ticketId
        ),
        day: issueValue.day,
        startDate: selectGroupSelected.startDate || undefined,
      }),
      thanhToanIssueStatus: ThanhToanIssueStatus.PAID_BY_LEADER,
      paymentMethodsRefund:
        issueTypeActive === IssueType.CHANGE_TO_WITHOUT_DRIVER &&
        paymentMethodRefund.length
          ? paymentMethodRefund
          : undefined,
    }
    dispatch(
      createNewIssue(body)
    );
    handleClose();
    issueForm.resetFields();
    setPathFile(null);
  };
  return (
    <Modal
      open={open}
      // cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ loading: selectIsCreating }}
      okText="Thêm phát sinh"
      onOk={handleCreateIssue}
      destroyOnClose={true}
      onCancel={() => {
        handleClose();
        issueForm.resetFields();
        setPathFile(null);
      }}
      centered
      title={
        <Typography.Text strong style={{ fontSize: 20 }}>
          Tạo phát sinh
        </Typography.Text>
      }
    >
      <div className={styles.addIssueModal}>
        <Typography.Text strong>Loại phát sinh</Typography.Text>
        <Select
          options={options}
          value={issueTypeActive}
          onChange={(value) => setIssueTypeActive(value)}
          style={{ width: "100%" }}
        />
        {renderIssueForm()}
      </div>
    </Modal>
  );
};

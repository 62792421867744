import { ExtraServiceCode, ITicket_ExtraService } from "common/define-types";
import { useSelectorRoot } from "store/store";
import styles from "./Billing.module.css";
import { Typography } from "antd";
import Utils from "common/Utils";
import { ServiceType } from "pages/Admin/pages/ExtraService/components/ExtraServiceForm";

interface IProps {
  tickets_extraService: ITicket_ExtraService[];
}
const ExtraServiceTicket = ({ tickets_extraService }: IProps) => {
  const selectExtraServices = useSelectorRoot(
    (state) => state.booking.extraServices
  );
  const selectRoomTemplates = useSelectorRoot(
    (state) => state.roomTemplate.roomTemplates
  );
  const selectAllHangHoa = useSelectorRoot((state) => state.hangHoa.motorbikes);
  const groupTicketExtraServices = Utils.groupBy(
    tickets_extraService,
    "dmuC_CHHangHoaDichVuKhacId"
  );
  const renderExtraService = (
    ticket_extraService: ITicket_ExtraService
    // code: string
  ) => {
    const foundExtraService = selectExtraServices.find(
      (extraService) =>
        extraService.id === ticket_extraService.dmuC_CHHangHoaDichVuKhacId
    );
    if (!foundExtraService) return;
    if (foundExtraService.code === ExtraServiceCode.CHANGE_ROOM) {
      const foundRoom = selectRoomTemplates.find(
        (room) => room.id === ticket_extraService?.roomTemplateId
      );
      if (foundRoom) {
        return (
          <>
            <Typography.Text
              // style={{
              //   whiteSpace: "nowrap",
              //   overflow: "hidden",
              //   textOverflow: "ellipsis",
              // }}
              title={foundRoom.name}
              className="size12"
            >
              {foundRoom.name}
            </Typography.Text>
            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography.Text
                // style={{ whiteSpace: "nowrap" }}
                className="size12"
              >
                {Utils.formatCurrency(foundRoom.price)}
              </Typography.Text>
            </div>
          </>
        );
      }
    } else {
      switch (foundExtraService.type) {
        case ServiceType.HANGHOA:
          const foundHangHoa = selectAllHangHoa.find(
            (hangHoa) => hangHoa.id === ticket_extraService.dmuC_HangHoaId
          );
          if (!foundHangHoa) return <></>;
          return (
            <>
              <Typography.Text className="size12">{`${foundHangHoa.name}: `}</Typography.Text>
              <Typography.Text
                // style={{ whiteSpace: "nowrap" }}
                strong
                className="size12"
              >
                {Utils.formatCurrency(ticket_extraService.price)}
              </Typography.Text>
            </>
          );
        case ServiceType.NORMAL:
          const foundExtraService = selectExtraServices.find(
            (extraService) =>
              extraService.id === ticket_extraService.dmuC_CHHangHoaDichVuKhacId
          );
          if (!foundExtraService) return <></>;
          return (
            <>
              <Typography.Text
                strong
                className="size12"
              >{`${foundExtraService.name} :`}</Typography.Text>
              <Typography.Text
                // style={{ whiteSpace: "nowrap" }}
                strong
                className="size12"
              >
                {Utils.formatCurrency(ticket_extraService.price)}
              </Typography.Text>
            </>
          );

        default:
          break;
      }
    }
  };
  return (
    <div className={styles.extraServiceContainer} style={{ gap: "0px" }}>
      <Typography.Text
        strong
        className="size14"
        // style={{ fontSize: 16, marginBottom: 4 }}
      >
        Extra service
      </Typography.Text>
      {Object.keys(groupTicketExtraServices).map((key) => {
        const foundExtraService = selectExtraServices.find(
          (extraService) => extraService.id === key
        );
        if (!foundExtraService) return <></>;
        return (
          <div key={`${key}`} style={{ marginBlock: 2 }}>
            {foundExtraService.code === ExtraServiceCode.CHANGE_ROOM ||
            foundExtraService.type === ServiceType.HANGHOA ? (
              <div>
                <div>
                  <Typography.Text
                    strong
                    className="size12"
                    // style={{ fontSize: 16, marginBottom: 4 }}
                  >
                    {foundExtraService.name}
                  </Typography.Text>
                </div>
                {groupTicketExtraServices[key].map(
                  (extraServiceValue: ITicket_ExtraService, index: number) => (
                    <div
                      className={styles.extraServiceItem}
                      style={{ marginLeft: "10px" }}
                      key={`${extraServiceValue.dmuC_CHHangHoaDichVuKhacId}-${index}`}
                    >
                      {renderExtraService(extraServiceValue)}
                    </div>
                  )
                )}
              </div>
            ) : (
              groupTicketExtraServices[key].map(
                (extraServiceValue: ITicket_ExtraService) => (
                  <div className={styles.extraServiceItem}>
                    {renderExtraService(extraServiceValue)}
                  </div>
                )
              )
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ExtraServiceTicket;

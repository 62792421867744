import { combineReducers } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { LoginEpics, loginReducer } from "./controls/LoginEpic";
import { BootstrapEpics, bootstrapReducer } from "./controls/bootstrap.slice";
import { AgentsEpics, agentsReducer } from "./slice/AgentsSlice";
import {
  BookingRevenueEpics,
  bookingRevenueReducer,
} from "./slice/BookingRevenueSlice";
import { BookingEpics, bookingReducer } from "./slice/BookingSlice";
import {
  ComboTourCategoryEpics,
  comboTourCategoryReducer,
} from "./slice/ComboTourCategorySlice";
import { DevicesEpics, devicesReducer } from "./slice/DevicesSlice";
import {
  OperatorDriverEpics,
  operatorDriverReducer,
} from "./slice/DriverOperatorSlice";
import { DriverEpics, driverReducer } from "./slice/DriverSlice";
import {
  ExtraServiceCategoryEpics,
  extraServiceCategoryReducer,
} from "./slice/ExtraServiceCategorySlice";
import { GroupEpics, groupReducer } from "./slice/GroupSlice";
import { HangHoaEpics, hangHoaReducer } from "./slice/HangHoaSlice";
import { locationEpics, locationReducer } from "./slice/LocationSlice";
import {
  OperatorMotorbikeEpics,
  operatorMotorbikeReducer,
} from "./slice/MotorbikeOperatorSlice";
import { MotorbikeEpics, motorbikeReducer } from "./slice/MotorbikeSlice";
import { NightAuditEpics, nightAuditReducer } from "./slice/NightAuditorSlice";
import {
  nightBookingEpics,
  nightBookingReducer,
} from "./slice/NightBooking.slice";
import {
  RoomTemplateEpics,
  roomTemplateReducer,
} from "./slice/RoomTemplateSlice";
import {
  RouterStationBusEpics,
  routerStationBusReducer,
} from "./slice/RouterStationBus";
import { StationsEpics, stationsReducer } from "./slice/StationCategorySlice";
import { SupplierEpics, supplierReducer } from "./slice/SupplierSlice";
import {
  SuppliersEpics,
  suppliersReducer,
} from "./slice/SuppliersCategorySlice";
import {
  OperatorTicketEpics,
  operatorTicketReducer,
} from "./slice/TIcketOperatorSlice";
import {
  TourCategoryEpics,
  tourCategoryReducer,
} from "./slice/TourCategorySlice";
import { ControlEpics, controlReducer } from "./slice/controlSlice";
import { BusEpics, BusReducer } from "./slice/BusSlice";
import { RouterEpics, routerReducer } from "./slice/RouterSlice";

const rootReducer = combineReducers({
  login: loginReducer,
  bootstrap: bootstrapReducer,
  control: controlReducer,
  booking: bookingReducer,
  group: groupReducer,
  bookingRevenue: bookingRevenueReducer,
  agents: agentsReducer,
  routerStationBus: routerStationBusReducer,
  nightAuditor: nightAuditReducer,
  nightBooking: nightBookingReducer,
  driver: driverReducer,
  motorbike: motorbikeReducer,
  roomTemplate: roomTemplateReducer,
  operatorDriver: operatorDriverReducer,
  operatorMotorbike: operatorMotorbikeReducer,
  supplier: supplierReducer,
  hangHoa: hangHoaReducer,
  operatorTicket: operatorTicketReducer,
  devices: devicesReducer,
  location: locationReducer,
  comboTourCategory: comboTourCategoryReducer,
  tourCategory: tourCategoryReducer,
  extraServiceCategory: extraServiceCategoryReducer,
  suppliersCategory: suppliersReducer,
  stationsCategory: stationsReducer,
  bus: BusReducer,
  router: routerReducer,
});

export const rootEpic = combineEpics(
  ...LoginEpics,
  ...BootstrapEpics,
  ...ControlEpics,
  ...BookingEpics,
  ...GroupEpics,
  ...BookingRevenueEpics,
  ...AgentsEpics,
  ...RouterStationBusEpics,
  ...NightAuditEpics,
  ...DriverEpics,
  ...MotorbikeEpics,
  ...RoomTemplateEpics,
  ...OperatorDriverEpics,
  ...OperatorMotorbikeEpics,
  ...SupplierEpics,
  ...HangHoaEpics,
  ...OperatorTicketEpics,
  ...nightBookingEpics,
  ...DevicesEpics,
  ...locationEpics,
  ...ComboTourCategoryEpics,
  ...TourCategoryEpics,
  ...ExtraServiceCategoryEpics,
  ...SuppliersEpics,
  ...StationsEpics,
  ...BusEpics,
  ...RouterEpics,
);
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

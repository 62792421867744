import { Spin, Typography, Input, notification } from "antd";
import { IPriceDetail, IRouterStationBus } from "common/define-types";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelectorRoot } from "store/store";
import styles from "../Agents.module.css";
import { getAllRouterStationBuses } from "api/routerStationBus.api";
import { BusOption } from "components/Selects/components/RouterStationBusOption";
import { DiscountBusModal } from "./DiscountBusModal";
import { updateBusDiscountForAgent } from "api/agent.api";

const RouterStationBusItem = ({
  routerStationBus,
  handleClick,
}: {
  routerStationBus: IRouterStationBus;
  handleClick?: () => void;
}) => {
  const selectLocations = useSelectorRoot((state) => state.booking.locations);
  const fromLocation = selectLocations.find(
    (location) =>
      location.id ===
      routerStationBus.dmuC_Router_Station?.dmuC_Router.localtionStartId
  );
  const toLocation = selectLocations.find(
    (location) =>
      location.id ===
      routerStationBus.dmuC_Router_Station?.dmuC_Router.localtionEndId
  );
  return (
    <div onClick={handleClick} className={styles.comboTourItem}>
      <Typography.Text strong>{`${fromLocation?.name || ""} → ${
        toLocation?.name || ""
      }`}</Typography.Text>
      <BusOption bus={routerStationBus} priceProp="price" />
    </div>
  );
};

export const DiscountBus = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [query, setQuery] = useState("");
  const [routerStationBuses, setRouterStationBuses] = useState<
    IRouterStationBus[]
  >([]);
  const [
    selectedDiscountRouterStationBuses,
    setSelectedDiscountRouterStationBuses,
  ] = useState<IRouterStationBus | null>(null);
  const selectDiscountAgent = useSelectorRoot(
    (state) => state.agents.discountAgent
  );
  const selectLocations = useSelectorRoot((state) => state.booking.locations);

  useEffect(() => {
    const today = moment().toISOString();
    setIsFetching(true);
    getAllRouterStationBuses({ dateTime: today }).subscribe(
      (res) => {
        res.length && setRouterStationBuses(res || []);
        setIsFetching(false);
      },
      (err) => {
        console.log(err);
        setIsFetching(false);
      }
    );
  }, []);

  const getFilteredBus = (
    routerStationBuses: IRouterStationBus[],
    query: string
  ) => {
    const lowerCaseQuery = query.toLowerCase();
    return routerStationBuses.filter((bus) => {
      const fromLocation = selectLocations.find(
        (location) =>
          location.id === bus.dmuC_Router_Station?.dmuC_Router.localtionStartId
      );
      const toLocation = selectLocations.find(
        (location) =>
          location.id === bus.dmuC_Router_Station?.dmuC_Router.localtionEndId
      );
      return (
        fromLocation?.name.toLowerCase().includes(lowerCaseQuery) ||
        toLocation?.name.toLowerCase().includes(lowerCaseQuery) ||
        bus.dmuC_Bus.name.toLowerCase().includes(lowerCaseQuery) ||
        bus.dmuC_Router_Station?.dmuC_Station.name
          .toLowerCase()
          .includes(lowerCaseQuery)
      );
    });
  };

  const handleOpenDiscountForm = (bus: IRouterStationBus) => {
    setSelectedDiscountRouterStationBuses(bus);
  };

  const handleCloseDiscountForm = () => {
    setSelectedDiscountRouterStationBuses(null);
  };

  const onUpdateDiscountRouterStationBus = ({
    discountValue,
  }: {
    discountValue: IPriceDetail;
  }) => {
    if (selectDiscountAgent && selectedDiscountRouterStationBuses) {
      // TODO: dispatch action to update discount of combotour
      updateBusDiscountForAgent(selectDiscountAgent.id, {
        itemId: selectedDiscountRouterStationBuses.id,
        priceDetailRequestDto: {
          ...discountValue,
          itemId: selectedDiscountRouterStationBuses.id,
        },
      }).subscribe(
        (res) => {
          if (res) {
            setSelectedDiscountRouterStationBuses(null);
            notification.success({
              message: "Lưu thông tin thành công",
            });
          }
        },
        (err) => {
          notification.error({
            message: err.response.Message || "Lưu không thành công",
          });
        }
      );
    }
  };

  return (
    <Spin spinning={isFetching}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 20,
        }}
      >
        <Input.Search
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          style={{ maxWidth: 200 }}
          placeholder="Tìm kiếm"
        />
      </div>
      <div
        style={{
          minHeight: 600,
          maxHeight: 600,
          overflowY: "auto",
          display: "flex",
          gap: 10,
          flexDirection: "column",
        }}
        className="customScroll"
      >
        {routerStationBuses.length
          ? getFilteredBus(routerStationBuses, query).map((bus) => (
              <RouterStationBusItem
                routerStationBus={bus}
                handleClick={() => handleOpenDiscountForm(bus)}
              />
            ))
          : !isFetching && <EmptyHolder message="Trống" />}
      </div>
      {!!selectedDiscountRouterStationBuses ? (
        <DiscountBusModal
          open={!!selectedDiscountRouterStationBuses}
          onCancel={handleCloseDiscountForm}
          okText="Lưu"
          cancelText="Hủy"
          onOk={onUpdateDiscountRouterStationBus}
          centered
          selectedBus={selectedDiscountRouterStationBuses}
        />
      ) : (
        <></>
      )}
    </Spin>
  );
};

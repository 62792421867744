import { Form, FormInstance, Input } from "antd";
import styles from "../Agents.module.css";

interface IProps {
  form?: FormInstance<any>;
  isEditForm?: boolean;
}
export const AgentForm = ({ form, isEditForm }: IProps) => {
  return (
    <Form
      name="agent"
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Form.Item
        label={"Mã đại lý"}
        name={"accCode"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input disabled={!!isEditForm} />
      </Form.Item>
      <Form.Item
        label={"Tên đại lý"}
        name={"name"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Email"}
        name={"email"}
        style={{ flex: 1 }}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
          {
            type: "email",
            message: "Phải là email hợp lệ",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <div className={styles.spaceBetween} style={{ gap: 8 }}>
        <Form.Item
          label={"Số điện thoại"}
          name={"phone"}
          style={{ flex: 1 }}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={"Tỉnh"} name={"city"} style={{ flex: 1 }}>
          <Input />
        </Form.Item>
      </div>
      <Form.Item
        label={"Địa chỉ"}
        name={"address"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input.TextArea autoSize={{ maxRows: 4 }} />
      </Form.Item>
    </Form>
  );
};

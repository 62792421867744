import { Typography, Button, Popconfirm } from "antd";
import styles from "../Suppliers.module.css";
import {
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined,
  EditOutlined,
  DeleteOutlined,
  // PercentageOutlined,
  // UnlockOutlined,
  // LockOutlined,
} from "@ant-design/icons";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { useEffect, useRef } from "react";
import { ISupplier } from "api/types/supplier";
import {
  deleteSupplier,
  editSupplier,
  setSupplierSelected,
} from "store/slice/SuppliersCategorySlice";

interface IProps {
  supplier: ISupplier;
  isActive?: boolean;
}
export const SupplierItem = ({ supplier, isActive }: IProps) => {
  const selectDeletingSupplierId = useSelectorRoot(
    (state) => state.agents.deletingAgentId
  );
  const dispatch = useDispatchRoot();

  const handleSelectSupplier = () => {
    if (!isActive) {
      dispatch(setSupplierSelected(supplier));
    }
  };

  const agentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isActive && agentRef.current)
      agentRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [isActive]);
  // const handleDeleteAgent = () => {
  //   if (agent) {
  //     dispatch(deleteAgent(agent.id));
  //   }
  // };

  const openEditSupplier = (e: any) => {
    e.stopPropagation();
    dispatch(editSupplier(supplier));
  };

  const handleDeleteSupplier = () => {
    dispatch(deleteSupplier(supplier.id));
  };

  return (
    <div
      className={`${styles.agentItem} ${isActive ? styles.agentSelected : ""}`}
      ref={agentRef}
      onClick={handleSelectSupplier}
    >
      <div className={styles.agentItem_wrapper}>
        <div className={styles.spaceBetween}>
          <Typography.Text
            strong
            style={{ fontSize: 20, fontWeight: 700 }}
          >{`${supplier.name}`}</Typography.Text>
          {supplier.accCode && (
            <Typography.Text
              strong
              italic
            >{`${supplier.accCode}`}</Typography.Text>
          )}
        </div>
        {supplier.address && (
          <div className={styles.textWithIcon}>
            <EnvironmentOutlined style={{ fontSize: 20 }} />
            <Typography.Text strong>{`${supplier.address}`}</Typography.Text>
          </div>
        )}
        <div className={styles.spaceBetween}>
          {supplier.phone && (
            <div className={styles.textWithIcon} style={{ minWidth: "45%" }}>
              <PhoneOutlined style={{ fontSize: 20 }} rotate={90} />
              <Typography.Text strong>{` ${
                supplier.phone ?? ""
              }`}</Typography.Text>
            </div>
          )}
          {supplier.email && (
            <div
              className={styles.textWithIcon}
              style={{ maxWidth: "55%", minWidth: "55%" }}
            >
              <MailOutlined style={{ fontSize: 20 }} />
              <Typography.Text
                style={{
                  textAlign: "right",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                strong
                title={`${supplier.email ?? ""}`}
              >
                {`${supplier.email ?? ""}`}
              </Typography.Text>
            </div>
          )}
        </div>
        {/* <div>
          <Typography.Text
            style={{ fontWeight: 700, marginRight: 10 }}
          >{`Mức tính hoa hồng: `}</Typography.Text>
          <Typography.Text
            style={{ fontWeight: 500 }}
          >{`12.5%`}</Typography.Text>
        </div> */}
      </div>
      <div className={styles.btnContainer} style={{ flexBasis: 72 }}>
        <Button
          icon={<EditOutlined style={{ fontSize: 20, color: "#1890FF" }} />}
          type="text"
          onClick={openEditSupplier}
        />

        <Popconfirm
          onConfirm={(e) => {
            e?.stopPropagation();
            handleDeleteSupplier();
          }}
          onCancel={(e) => e?.stopPropagation()}
          title={"Xóa nhà cung cấp"}
          description={"Bạn có chắc chắn muốn xóa nhà cung cấp này ?"}
          okText="Có"
          cancelText="Không"
        >
          <Button
            onClick={(e) => e.stopPropagation()}
            icon={<DeleteOutlined style={{ fontSize: 20, color: "red" }} />}
            loading={selectDeletingSupplierId === supplier.id}
            type="text"
          />
        </Popconfirm>
      </div>
    </div>
  );
};

import { ConfigProvider } from "antd";
import { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Root } from "./pages/Root";
import "./App.css";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const App = () => {
  const { height } = useWindowDimensions();
  useEffect(() => {
    const metaViewport = document.querySelector("meta[name=viewport]");
    document.addEventListener("resize", () => {
      metaViewport?.setAttribute(
        "content",
        "height=" + height + "px, width=device-width, initial-scale=1.0"
      );
    });

    return () => {
      metaViewport?.setAttribute(
        "content",
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      );
    };
  }, [height]);
  return (
    <DndProvider backend={HTML5Backend}>
      <ConfigProvider prefixCls="ant">
        <BrowserRouter basename="/">
          <Switch>
            <Route path="/" component={Root} />
          </Switch>
        </BrowserRouter>
      </ConfigProvider>
    </DndProvider>
  );
};

import SYSTEM_CONSTANTS from "common/constants";
import { IUpdateDiscount } from "common/define-types";
import { Observable, throwError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import HttpClient from "./http-client";

const api = `${SYSTEM_CONSTANTS.API_URL}/DMUC_ChietKhauDetail`;

export const getDiscountByJobItemId = (jobItemId: string): Observable<any> => {
  return HttpClient.get(`${api}/jobItem/${jobItemId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updateDiscount = (body: IUpdateDiscount): Observable<any> => {
  return HttpClient.put(`${api}/${body.id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

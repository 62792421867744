import { Typography, Button, Checkbox, Input, Select } from "antd";
import styles from "../Group.module.css";
import { GroupSelect } from "./GroupSelect";
import { useSelectorRoot, useDispatchRoot } from "store/store";
import { PlusOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { checkAllTickets, uncheckTickets } from "store/slice/GroupSlice";
import {
  GroupStatusEnum,
  GroupTicketType,
  IGroupTicketsParams,
  IsDriverType,
  JobTicketStatusEnum,
} from "common/define-types";
import { AgentSelect } from "components/Selects/AgentSelect";
import Utils from "common/Utils";
import { ChangeEvent, useState } from "react";
// import React from "react";

interface IProps {
  filterValue: IGroupTicketsParams;
  onChange: (value: IGroupTicketsParams) => void;
}
export const TicketListHeader = ({ filterValue, onChange }: IProps) => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const dispatch = useDispatchRoot();
  const selectTicketsSelected = useSelectorRoot(
    (state) => state.group.groupTicketsSelected
  );
  const selectGroupsTickets = useSelectorRoot(
    (state) => state.group.groupTickets
  );
  const selectGroupSelected = useSelectorRoot(
    (state) => state.group.groupSelected
  );
  const selectTours = useSelectorRoot((state) => state.booking.tours);

  const handleCheckAllTickets = (e: CheckboxChangeEvent) => {
    const filteredTickets = selectGroupsTickets.filter(
      (ticket) =>
        ticket.status !== JobTicketStatusEnum.BREAKUPTOUR &&
        ticket.type === GroupTicketType.TICKET
    );
    if (e.target.checked) {
      dispatch(checkAllTickets(filteredTickets));
    } else {
      dispatch(uncheckTickets(filteredTickets));
    }
  };
  // [5-12-2023][Phuc Thinh] [show preview ticket selected]
  // handle debounce search ticket
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      onChange({ ...filterValue, search: e.target.value });
    }, 800);
    setTimer(timeoutId);
  };

  const hasFilterValue = !!filterValue && !Utils.isObjectEmpty(filterValue);
  return (
    <>
      <div className={styles.listContainer_header}>
        <Typography.Text strong style={{ fontSize: 20 }}>
          Danh sách tickets
        </Typography.Text>
        {!!selectGroupSelected &&
        selectGroupSelected.status === GroupStatusEnum.END ? (
          <></>
        ) : (
          <GroupSelect
            disabled={
              !selectTicketsSelected || selectTicketsSelected.length === 0
            }
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              disabled={
                !selectTicketsSelected || selectTicketsSelected.length === 0
              }
            >
              Nhóm
            </Button>
          </GroupSelect>
        )}
      </div>
      <div className={styles.searchBox}>
        {hasFilterValue ? (
          <Typography.Text>{`Đã chọn (${selectTicketsSelected.length})`}</Typography.Text>
        ) : (
          // [5-12-2023][Phuc Thinh] [show preview ticket selected]
          // clean up code
          <Checkbox
            style={{ minWidth: 110 }}
            checked={
              selectGroupsTickets.filter(
                (gTicket) =>
                  gTicket.status !== JobTicketStatusEnum.BREAKUPTOUR &&
                  gTicket.type === GroupTicketType.TICKET
              ).length ===
                selectTicketsSelected.filter(
                  (ticket) => ticket.status !== JobTicketStatusEnum.BREAKUPTOUR
                ).length && selectTicketsSelected.length !== 0
            }
            onChange={handleCheckAllTickets}
          >{`Đã chọn (${selectTicketsSelected.length})`}</Checkbox>
        )}
        <Input.Search
          placeholder="Tìm kiếm khách"
          allowClear
          style={{ flex: 1 }}
          onChange={handleSearch}
        />
      </div>
      <div className={styles.searchBox}>
        <div className={styles.searchBoxItem}>
          <Typography.Text style={{ whiteSpace: "nowrap" }}>
            Tour:{" "}
          </Typography.Text>
          <Select
            allowClear
            style={{ minWidth: 150, flex: 1 }}
            options={selectTours.map((tour) => ({
              label: tour.description,
              value: tour.id,
            }))}
            placeholder="-- Chọn loại tour --"
            value={filterValue.tourId}
            onChange={(value) => onChange({ ...filterValue, tourId: value })}
          />
        </div>
        <div className={styles.searchBoxItem}>
          <Typography.Text style={{ whiteSpace: "nowrap" }}>
            Đại lý:{" "}
          </Typography.Text>
          <AgentSelect
            style={{ minWidth: 150, flex: 1 }}
            value={filterValue.agentId}
            onChange={(value) => onChange({ ...filterValue, agentId: value })}
          />
        </div>
        <div className={styles.searchBoxItem}>
          <Typography.Text style={{ whiteSpace: "nowrap" }}>
            Loại vé xe:{" "}
          </Typography.Text>
          <Select
            value={filterValue.selfDrive}
            style={{ minWidth: 150, flex: 1 }}
            placeholder="-- Chọn loại vé xe --"
            allowClear
            options={[
              {
                label: "Tự lái",
                value: IsDriverType.NO,
              },
              {
                label: "Có lái",
                value: IsDriverType.YES,
              },
            ]}
            onChange={(value) => onChange({ ...filterValue, selfDrive: value })}
          />
        </div>
      </div>
    </>
  );
};

import SYSTEM_CONSTANTS from "common/constants";
import { ICreateGroupJobItem, IUpdateGroupJobItem } from "common/define-types";
import { Observable, throwError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import HttpClient from "./http-client";

const api = `${SYSTEM_CONSTANTS.API_URL}/GroupItem`;

export const createGroupJobItem = (
  body: ICreateGroupJobItem
): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const updateGroupJobItem = (
  body: IUpdateGroupJobItem
): Observable<any> => {
  return HttpClient.put(`${api}?id=${body.id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const deleteGroupJobItem = (groupJobId: string): Observable<any> => {
  return HttpClient.delete(`${api}/${groupJobId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

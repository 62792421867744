import { Form, FormInstance, Input } from "antd";
import Utils from "common/Utils";
import { IGroupJobItem } from "common/define-types";
import { useEffect } from "react";

interface GroupJobFormProps {
  form?: FormInstance<any>;
  editingGroupJob?: IGroupJobItem;
}

export const GroupJobForm = ({ form, editingGroupJob }: GroupJobFormProps) => {
  const [defaultForm] = Form.useForm();
  const groupJobItemForm = form || defaultForm;
  useEffect(() => {
    if (editingGroupJob) {
      groupJobItemForm.setFields(Utils.objectToAntFormData(editingGroupJob));
    }
  }, [editingGroupJob, groupJobItemForm]);

  return (
    <Form
      form={groupJobItemForm}
      labelAlign="left"
      className="noMargin_form-item"
      labelCol={{ span: 24 }}
    >
      <Form.Item
        label="Tên"
        name={"name"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

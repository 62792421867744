import {
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Spin,
  Typography,
} from "antd";
import { useWatch } from "antd/es/form/Form";
import { getIssuePriceByType } from "api/issuePrice.api";
import { IIssue } from "api/types/issue";
import Utils from "common/Utils";
import SYSTEM_CONSTANTS from "common/constants";
import {
  GetIssuePriceByTypeParams,
  GroupTicketType,
  IssueType,
  JobTicketStatusEnum,
} from "common/define-types";
import { TicketSelect } from "components/Selects/TicketSelect";
import { useEffect, useState } from "react";
import { useSelectorRoot } from "store/store";
import VndIcon from "image/icon/vnd-icon.svg";
import BankTransIcon from "image/icon/bank-transfer-icon.svg";
import { MethodItem } from "pages/Booking/components/Billing/MethodItem";

interface IProps {
  form: FormInstance<any>;
  detailIssue?: IIssue;
  type: IssueType;
}
const methods = [
  {
    name: "CASH - VND",
    image: VndIcon,
    code: 90001,
  },
  {
    name: "BANK TRANSFER",
    image: BankTransIcon,
    code: 90013,
  },
];
export const ChangeTypeDriverForm = ({ form, detailIssue, type }: IProps) => {
  const AMOUNT_MONEY = {
    MAX: 8000000,
    MIN: 0,
  };
  // const DAY = {
  //   MAX: 4,
  //   MIN: 1,
  // };
  const changeToWithDriver = type === IssueType.CHANGE_TO_WITH_DRIVER;
  const changeToWithOutDriver = type === IssueType.CHANGE_TO_WITHOUT_DRIVER;
  const selectGroupTickets = useSelectorRoot(
    (state) => state.group.groupTickets
  );
  const day = useWatch("day", form);
  const ticketId = useWatch("ticketId", form);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!!detailIssue) {
      form.setFields(Utils.objectToAntFormData(detailIssue));
    }
    return () => {
      form.resetFields();
    };
  }, [form, detailIssue]);

  useEffect(() => {
    const foundTicket = selectGroupTickets.find(
      (gTicket) => gTicket.id === ticketId
    );
    if (
      !detailIssue &&
      type === IssueType.CHANGE_TO_WITH_DRIVER &&
      day &&
      foundTicket
    ) {
      const params: GetIssuePriceByTypeParams = {
        type: IssueType.CHANGE_TO_WITH_DRIVER,
        day: day,
        isDriver: 0,
        tourId: foundTicket.tourId,
      };
      setIsLoading(true);
      getIssuePriceByType(params).subscribe(
        (res) => {
          if (res && !isNaN(res)) {
            form.setFieldValue("amount_Money", res);
          }
        },
        (err) => {
          console.log(err);
          setIsLoading(false);
        },
        () => setIsLoading(false)
      );
    }
  }, [detailIssue, type, day, form, selectGroupTickets, ticketId]);

  return (
    <Spin spinning={isLoading}>
      <Form form={form} labelCol={{ span: 24 }} className="noMargin_form-item">
        <Form.Item
          label={"Khách"}
          name={"ticketId"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <TicketSelect
            options={selectGroupTickets.filter(
              (gTicket) => gTicket.isTicketInGroup === true
            )}
            onChange={(value) => form.setFieldValue("ticketId", value)}
            readOnly={!!detailIssue}
            acceptStatus={[
              JobTicketStatusEnum.INIT,
              JobTicketStatusEnum.CONFIRM,
              JobTicketStatusEnum.PASS,
            ]}
            acceptType={[GroupTicketType.TICKET]}
          />
        </Form.Item>
        {changeToWithDriver && (
          <>
            {!detailIssue && (
              <Form.Item
                label={"Ngày trong tour"}
                name={"day"}
                rules={[
                  {
                    required: true,
                    message: "Không được bỏ trống",
                  },
                ]}
              >
                <Select
                  options={[
                    {
                      label: "Ngày 1",
                      value: 1,
                    },
                    {
                      label: "Ngày 2",
                      value: 2,
                    },
                    {
                      label: "Ngày 3",
                      value: 3,
                    },
                    {
                      label: "Ngày 4",
                      value: 4,
                    },
                  ]}
                  disabled={!!detailIssue}
                  placeholder="Chọn ngày đi tour"
                />
                {/* <InputNumber
                style={{ width: "100%" }}
                stringMode={true}
                readOnly={!!detailIssue}
                controls={false}
                max={DAY.MAX}
                min={DAY.MIN}
                formatter={(value) =>
                  `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                }
                parser={(value) =>
                  parseInt(value ? value?.replaceAll(".", "") : "0")
                }
              /> */}
              </Form.Item>
            )}
            <Form.Item
              label={"Số tiền"}
              name={"amount_Money"}
              rules={[
                {
                  required: true,
                  message: "Không được bỏ trống",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                stringMode={true}
                readOnly={!!detailIssue}
                controls={false}
                max={AMOUNT_MONEY.MAX}
                min={AMOUNT_MONEY.MIN}
                formatter={(value) =>
                  `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                }
                parser={(value) =>
                  parseInt(value ? value?.replaceAll(".", "") : "0")
                }
              />
            </Form.Item>
          </>
        )}
        {changeToWithOutDriver && (
          <>
            {!!detailIssue ? (
              <Form.Item
                label={"Tổng tiền hoàn"}
                name={"amount_Money"}
                rules={[
                  {
                    required: true,
                    message: "Không được bỏ trống",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  stringMode={true}
                  readOnly={!!detailIssue}
                  controls={false}
                  max={AMOUNT_MONEY.MAX}
                  min={AMOUNT_MONEY.MIN}
                  formatter={(value) =>
                    `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                  }
                  parser={(value) =>
                    parseInt(value ? value?.replaceAll(".", "") : "0")
                  }
                />
              </Form.Item>
            ) : (
              <Form.Item label={"Hoàn tiền"}>
                <div style={{ paddingInline: 40 }}>
                  {methods.map((method) => (
                    <MethodItem
                      code={method.code}
                      image={method.image}
                      label={method.name}
                    />
                  ))}
                </div>
              </Form.Item>
            )}
          </>
        )}
        <Form.Item label={"Ghi chú"} name={"note"}>
          <Input.TextArea readOnly={!!detailIssue} />
        </Form.Item>
        {!!detailIssue && detailIssue.pathFile && (
          <>
            <Typography.Text strong style={{ paddingBlock: 16 }}>
              Ảnh:
            </Typography.Text>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={`${SYSTEM_CONSTANTS.API_URL}${detailIssue.pathFile}`}
                alt="issue"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </div>
          </>
        )}
      </Form>
    </Spin>
  );
};

import { Divider, Modal, Spin, Typography, notification } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import Utils from "common/Utils";
import {
  IPaymentMethodBooking,
  ITicketOperatorItem,
  IssueStatus,
  IssueType,
  ThanhToanIssueStatus,
} from "common/define-types";
import { MethodForm } from "pages/Booking/components/Billing/MethodForm";
import { useEffect, useState } from "react";
import commonStyles from "../../TicketOperator.module.css";
import styles from "./CollectIssueFee.module.css";
import IssueList from "./IssueList";
import { IIssue } from "api/types/issue";
import { getTicketIssues } from "api/issue.api";
import { useSelectorRoot } from "store/store";

interface IProps {
  collectIssueFeeTicket: ITicketOperatorItem;
  handleClose: () => void;
  handleSubmit: (payload: IPaymentMethodBooking[]) => void;
}
//[12-12-2023][Phuc Thinh][Feature/14880]
const CollectIssueFeeModal = ({
  collectIssueFeeTicket,
  handleClose,
  handleSubmit,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const [payAmount, setPayAmount] = useState(0);
  const [methodForm] = useForm();
  const [issueList, setIssueList] = useState<IIssue[]>([]);
  const [total, setTotal] = useState(0);
  const isSubmitting = useSelectorRoot(
    (state) => state.operatorTicket.isSubmitting
  );
  const handleOk = async () => {
    if (total - payAmount > 0) {
      notification.info({
        message: `Bạn phải nhập đủ số tiền cần trả`,
      });
      return;
    }
    const paymentMethodBookingsValue = await methodForm.validateFields();
    const paymentMethodBookings: IPaymentMethodBooking[] = [];
    for (const key in paymentMethodBookingsValue) {
      if (
        paymentMethodBookingsValue[key] &&
        paymentMethodBookingsValue[key] > 0
      ) {
        paymentMethodBookings.push({
          paymentCode: key,
          amount: paymentMethodBookingsValue[key],
        });
      }
    }
    if (total !== 0 && paymentMethodBookings.length === 0) {
      notification.info({
        message: "Bạn chưa nhập các phương thức thanh toán",
      });
      return;
    }
    handleSubmit(paymentMethodBookings);
  };

  useEffect(() => {
    if (collectIssueFeeTicket) {
      setLoading(true);
      getTicketIssues(collectIssueFeeTicket.id).subscribe(
        (res) => res && setIssueList(res),
        (err) => {
          console.log(err);
          setLoading(false);
        },
        () => setLoading(false)
      );
    }
  }, [collectIssueFeeTicket]);

  useEffect(() => {
    let totalAmount = 0;
    if (issueList && issueList.length && issueList.length > 0) {
      issueList.forEach((issue) => {
        if (
          // Types of issue need to pay
          [
            IssueType.CHANGEROOM,
            IssueType.EXTRA_SLEPT,
            IssueType.CHANGE_TOUR,
            IssueType.CHANGE_TO_WITH_DRIVER,
          ].some((type) => type === issue.type) &&
          issue.status === IssueStatus.Approve &&
          issue.thanhToanIssueStatus === ThanhToanIssueStatus.NOT_YET
        ) {
          totalAmount += parseInt(issue.amount_Money);
        }
      });
    }
    setTotal(totalAmount);
  }, [issueList]);

  return (
    <Modal
      width={"clamp(20rem, 90vw, 60rem)"}
      centered
      okText="Lưu"
      cancelText="Hủy"
      open={!!collectIssueFeeTicket}
      okButtonProps={{
        style: {
          display: total > 0 ? "inline-block" : "none",
        },
        loading: isSubmitting,
      }}
      onCancel={handleClose}
      onOk={handleOk}
      title={
        <>
          {`Danh sách phát sinh: `}
          <Typography.Text strong style={{ fontSize: 18, color: "#1890ff" }}>
            {collectIssueFeeTicket.name}
          </Typography.Text>
        </>
      }
    >
      <Spin spinning={loading}>
        <div
          className={`${commonStyles.collectionFee_modal_container} customScroll`}
        >
          <div>
            <div className={styles.ticketItem}>
              <div className={commonStyles.spaceBetween}>
                <Typography.Text strong className={styles.textInfor}>
                  {collectIssueFeeTicket.fullName}
                </Typography.Text>
                {collectIssueFeeTicket.phone && (
                  <div className={styles.inforWithIcon}>
                    <PhoneOutlined />
                    <Typography.Text strong className={styles.textInfor}>
                      {collectIssueFeeTicket.phone || ""}
                    </Typography.Text>
                  </div>
                )}
              </div>
              <div>
                {collectIssueFeeTicket.email && (
                  <Typography.Text
                    strong
                    className={styles.textInfor}
                  >{`Email: ${
                    collectIssueFeeTicket.email || ""
                  }`}</Typography.Text>
                )}
              </div>
            </div>
            <Divider />
            <IssueList issues={issueList} />
          </div>
          <div
            className={commonStyles.collectionFee_modal_methodForm_container}
          >
            <div className={commonStyles.spaceBetween}>
              <Typography.Text
                strong
                style={{ fontSize: 20 }}
              >{`Tổng: ${Utils.formatCurrency(total)}`}</Typography.Text>
              <Typography.Text
                strong
                style={{ fontSize: 20 }}
              >{`Còn lại: ${Utils.formatCurrency(
                total - payAmount
              )}`}</Typography.Text>
            </div>
            <Typography.Text>Chọn phương thức trả</Typography.Text>

            <MethodForm
              totalAmount={total > 0 ? total : -total}
              setPayAmount={(amount) => setPayAmount(amount)}
              payAmount={payAmount}
              paymentForm={methodForm}
              hasDebtItem={false}
            />
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default CollectIssueFeeModal;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { toursData } from 'DummyData/tours';
import {
  BookingStepEnum,
  IBooking,
  IBookingTicket,
  IBus,
  ICodePay,
  IComboTour,
  IComboTourParams,
  IExtraService,
  IHangHoaItem,
  ILocation,
  IParams,
  IPriceReq,
  ISelectedComboTour,
  ISelectedTicket,
  // ISelectedTour,
  ITicket,
  ITour,
  RootEpic,
  TourStepEnum,
} from "common/define-types";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { getAllTours } from "api/tour.api";
import { AjaxError } from "rxjs/ajax";
import { createBooking, updateBooking } from "api/booking.api";
import { ICreateBooking, IUpdateBooking } from "api/types/booking";
import { v4 as uuidv4 } from "uuid";
import Utils from "common/Utils";
import { getAllPriceReqs } from "api/ticket.api";
import { getAllBus } from "api/bus.api";
import { getAllLocations } from "api/location.api";
import { getAllCodePay } from "api/codepay.api";
import { IAgent } from "api/types/agent";
import { getCurrentAgent } from "api/agent.api";
import moment from "moment";
import { getAllExtraServices } from "api/extraService.api";
import { getAllComboTours } from "api/comboTour.api";
//[14-12-2023][Phuc Thinh][Feature/14901]
//Change Tour to TourCombo
interface IAddTickets {
  tickets: ITicket[];
  combotour: IComboTour;
}
interface IRemoveTicket {
  ticketId: string;
  combotour: IComboTour;
}
interface IUpdateTicket {
  ticket: ISelectedTicket;
  isApplyAll?: boolean;
  onlyUpdateTicket?: boolean;
}
interface IEditingBooking extends IBooking {
  bookingId: string;
}
export interface BookingState {
  isLoading: boolean;
  extraServiceLoading: boolean;
  isSubmitting: boolean;
  tours: ITour[] | [];
  comboTours: IComboTour[] | [];
  priceReqs: IPriceReq[] | [];
  isBookingCompleted: boolean;
  selectedComboTours: ISelectedComboTour[] | [];
  selectedTicket: ISelectedTicket | null;
  tourStep: TourStepEnum;
  bookingStep: BookingStepEnum;
  errMsg: string | null;
  bookingResult: any | null;
  bookingResultMsg: string | null;
  editingBooking: IEditingBooking | null;
  buses: IBus[] | [];
  locations: ILocation[] | [];
  codePay: ICodePay[];
  currentAgent: IAgent | null;
  dockerId?: string;
  extraServices: IExtraService[];
  selectedExtraServices: any;
}

const initialState: BookingState = {
  isLoading: false,
  extraServiceLoading: false,
  isSubmitting: false,
  isBookingCompleted: false,
  tours: [],
  comboTours: [],
  priceReqs: [],
  errMsg: null,
  selectedComboTours: [],
  selectedTicket: null,
  tourStep: TourStepEnum.TOURS,
  bookingStep: BookingStepEnum.BOOKING_DETAIL,
  bookingResult: null,
  bookingResultMsg: null,
  editingBooking: null,
  buses: [],
  locations: [],
  codePay: [],
  currentAgent: null,
  extraServices: [],
  // selectedExtraServices: extraServices.reduce(
  //   (prev, current) => ({ ...prev, [current.code]: false }),
  //   {}
  // ),
  selectedExtraServices: {},
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchTours: (state, action: PayloadAction) => {
      state.isLoading = true;
    },
    fetchComboTours: (state, action: PayloadAction<IComboTourParams>) => {
      state.isLoading = true;
    },
    fetchPriceReqs: (
      state,
      action: PayloadAction<{ dateTime: string | null }>
    ) => {
      state.isLoading = true;
    },
    fetchBus: (state, action: PayloadAction) => {
      state.isLoading = true;
    },
    fetchLocation: (state, action: PayloadAction<IParams | undefined>) => {
      state.isLoading = true;
    },
    fetchCodePay: (state, action: PayloadAction) => {
      state.isLoading = true;
    },
    fetchCurrentAgent: (state, action: PayloadAction) => {
      state.errMsg = null;
    },
    fetchExtraService: (state, action: PayloadAction) => {
      state.extraServiceLoading = true;
    },
    setExtraService: (state, action: PayloadAction<IExtraService[]>) => {
      state.extraServiceLoading = false;
      state.extraServices = action.payload;
      if (
        state.selectedTicket &&
        state.selectedTicket.datA_TOUR_CHHangHoaDichVuKhacs &&
        state.selectedTicket.datA_TOUR_CHHangHoaDichVuKhacs.length > 0
      ) {
        state.selectedExtraServices =
          state.selectedTicket.datA_TOUR_CHHangHoaDichVuKhacs.reduce(
            (prev, current) => {
              const foundService = action.payload.find(
                (service) => service.id === current.dmuC_CHHangHoaDichVuKhacId
              );
              if (foundService) {
                return {
                  ...prev,
                  [foundService.id]: true,
                };
              } else {
                return {
                  ...prev,
                };
              }
            },
            {}
          );
      } else {
        state.selectedExtraServices = action.payload.reduce(
          (prev, current) => ({ ...prev, [current.id]: false }),
          {}
        );
      }
    },
    setExtraServiceSelected: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>
    ) => {
      const { key, value } = action.payload;
      state.selectedExtraServices = {
        ...state.selectedExtraServices,
        [key]: value,
      };
    },
    submitBooking: (state, action: PayloadAction<ICreateBooking>) => {
      state.isSubmitting = true;
    },
    saveBooking: (state, action: PayloadAction<IUpdateBooking>) => {
      state.isSubmitting = true;
    },
    setTours: (state, action: PayloadAction<ITour[]>) => {
      state.tours = action.payload;
      state.isLoading = false;
    },
    setComboTours: (state, action: PayloadAction<IComboTour[]>) => {
      state.comboTours = Utils.sortComboTour(
        action.payload.filter(
          (comboTour) => comboTour.ListItem && comboTour.ListItem?.length > 0
        )
      );
      state.isLoading = false;
    },
    setPriceReqs: (state, action: PayloadAction<IPriceReq[]>) => {
      state.priceReqs = action.payload;
      state.isLoading = false;
    },
    setBuses: (state, action: PayloadAction<IBus[]>) => {
      state.buses = action.payload;
      state.isLoading = false;
    },
    setLocations: (state, action: PayloadAction<ILocation[]>) => {
      state.locations = action.payload;
      state.isLoading = false;
    },
    setCodePay: (state, action: PayloadAction<ICodePay[]>) => {
      state.codePay = action.payload;
      state.isLoading = false;
    },
    bookTour: (
      state,
      action: PayloadAction<{
        body: ISelectedComboTour;
        hangHoas?: IHangHoaItem[];
      }>
    ) => {
      const selectedComboTours = state.selectedComboTours;
      const comboTour = action.payload.body;
      const currentAgent = state.currentAgent;
      const defaultTicket: ITicket = Utils.getDefaultTicket({
        agent: currentAgent,
        index: selectedComboTours.length,
        comboTour,
        extraServices: state.extraServices,
        hangHoas: action.payload.hangHoas || [],
      });
      const newSelectedComboTours = [
        ...state.selectedComboTours,
        {
          ...action.payload.body,
          tickets: [defaultTicket],
        },
      ];
      state.selectedComboTours = Utils.sortSelectedComboToursByComboTours(
        newSelectedComboTours,
        state.comboTours
      );
    },
    unbookTour: (state, action: PayloadAction<ISelectedComboTour>) => {
      const newSelectedComboTours = state.selectedComboTours.filter(
        (selectedComboTour) => selectedComboTour.Id !== action.payload.Id
      );
      state.selectedComboTours = Utils.sortSelectedComboToursByComboTours(
        newSelectedComboTours,
        state.comboTours
      );
    },
    setComboToursSelected: (
      state,
      action: PayloadAction<ISelectedComboTour[]>
    ) => {
      const newSelectedComboTours = action.payload;
      state.selectedComboTours = Utils.sortSelectedComboToursByComboTours(
        newSelectedComboTours,
        state.comboTours
      );
      if (
        !newSelectedComboTours.some((selectedComboTour) =>
          selectedComboTour.tickets?.some(
            (ticket) => ticket.id === state.selectedTicket?.id
          )
        )
      ) {
        state.selectedTicket = null;
      }
    },
    addTicketsToTour: (state, action: PayloadAction<IAddTickets>) => {
      // const comboTour = action.payload.combotour;
      // const driverPriceDto = comboTour.ListItem?.find(item => item.ItemType === ComboTourItemType.DriverPrice);
      const comboTourId = action.payload.combotour.Id;
      const tickets = action.payload.tickets;
      const newSeletedComboTour = state.selectedComboTours.map(
        (selectedComboTour) =>
          selectedComboTour.Id === comboTourId
            ? {
                ...selectedComboTour,
                tickets: selectedComboTour.tickets
                  ? [...selectedComboTour.tickets, ...tickets]
                  : tickets,
              }
            : selectedComboTour
      );
      state.selectedComboTours = newSeletedComboTour;
      // state.selectedTicket = {
      //   ...ticket,
      //   tour: action.payload.tour,
      // };
    },
    removeTicketFromTour: (state, action: PayloadAction<IRemoveTicket>) => {
      const ticketId = action.payload.ticketId;
      const comboTourId = action.payload.combotour.Id;
      const newSeletedComboTours = state.selectedComboTours.map(
        (selectedComboTour) =>
          selectedComboTour.Id === comboTourId
            ? {
                ...selectedComboTour,
                tickets: selectedComboTour.tickets?.filter(
                  (ticket) => ticket.id !== ticketId
                ),
              }
            : selectedComboTour
      );
      state.selectedComboTours = newSeletedComboTours.filter(
        (tour) => tour.tickets && tour.tickets.length > 0
      );
    },
    selectTicket: (state, action: PayloadAction<ISelectedTicket | null>) => {
      state.selectedTicket = action.payload
        ? {
            ...action.payload,
            datA_TOUR_CHHangHoaDichVuKhacs: action.payload
              .datA_TOUR_CHHangHoaDichVuKhacs
              ? action.payload.datA_TOUR_CHHangHoaDichVuKhacs.map((data) =>
                  data.extraService
                    ? data
                    : {
                        ...data,
                        extraService: state.extraServices.find(
                          (extraService) =>
                            extraService.id === data.dmuC_CHHangHoaDichVuKhacId
                        ),
                      }
                )
              : undefined,
          }
        : action.payload;
      if (
        action.payload &&
        action.payload.datA_TOUR_CHHangHoaDichVuKhacs &&
        action.payload.datA_TOUR_CHHangHoaDichVuKhacs.length > 0
      ) {
        state.selectedExtraServices =
          action.payload.datA_TOUR_CHHangHoaDichVuKhacs.reduce(
            (prev, current) => {
              const foundService = state.extraServices.find(
                (service) => service.id === current.dmuC_CHHangHoaDichVuKhacId
              );
              if (foundService) {
                return {
                  ...prev,
                  [foundService.id]: true,
                };
              } else {
                return {
                  ...prev,
                };
              }
            },
            {}
          );
      } else {
        state.selectedExtraServices = state.extraServices.reduce(
          (prev, current) => ({ ...prev, [current.id]: false }),
          {}
        );
      }
    },
    updateTicketSelected: (state, action: PayloadAction<IUpdateTicket>) => {
      const updatedTicket = action.payload.ticket;

      if (!action.payload.onlyUpdateTicket) {
        const comboTourId = action.payload.ticket.comboTour.Id;
        if (action.payload.isApplyAll) {
          state.selectedComboTours = state.selectedComboTours.map(
            (selectedComboTour) =>
              selectedComboTour.Id === comboTourId
                ? {
                    ...selectedComboTour,
                    tickets: selectedComboTour.tickets?.map((ticket) =>
                      ticket.id === updatedTicket.id
                        ? {
                            ...updatedTicket,
                          }
                        : {
                            // update only bus value
                            ...ticket,
                            startDate: updatedTicket.startDate,
                            busTicketC1: updatedTicket.busTicketC1,
                            busTicketC2: updatedTicket.busTicketC2,
                            price: updatedTicket.price,
                          }
                    ),
                  }
                : selectedComboTour
          );
        } else {
          state.selectedComboTours = state.selectedComboTours.map(
            (selectedComboTour) =>
              selectedComboTour.Id === comboTourId
                ? {
                    ...selectedComboTour,
                    tickets: selectedComboTour.tickets?.map((ticket) =>
                      ticket.id === updatedTicket.id
                        ? {
                            ...updatedTicket,
                          }
                        : ticket
                    ),
                  }
                : selectedComboTour
          );
        }
      }
      state.selectedTicket = updatedTicket;
    },
    setTourStep: (state, action: PayloadAction<TourStepEnum>) => {
      state.tourStep = action.payload;
    },
    setBookingStep: (state, action: PayloadAction<BookingStepEnum>) => {
      state.bookingStep = action.payload;
    },
    setBookingResult: (state, action: PayloadAction<any>) => {
      state.bookingResult = action.payload;
      state.isSubmitting = false;
    },
    setBookingResultMsg: (state, action: PayloadAction<string | null>) => {
      state.bookingResultMsg = action.payload;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
    setCurrentAgent: (state, action: PayloadAction<IAgent | null>) => {
      state.currentAgent = action.payload;
      state.errMsg = null;
    },
    editBooking: (state, action: PayloadAction<IBooking | null>) => {
      if (action.payload === null) {
        state.editingBooking = action.payload;
      } else {
        const payload = action.payload;
        state.editingBooking = {
          ...payload,
          bookingId: payload.id,
        };
        const bookingTickets = payload.tickets;
        if (bookingTickets.some((ticket) => !!ticket.dockerId))
          state.dockerId = bookingTickets.filter(
            (ticket) => !!ticket.dockerId
          )[0].dockerId;
        else {
          bookingSlice.caseReducers.generateDockerId(state);
          // const newDockerId = uuidv4();
          // state.dockerId = newDockerId;
        }
        const groupByComboTourTickets = Utils.groupBy(
          bookingTickets,
          "comboTourId"
        );
        let selectedComboTours: ISelectedComboTour[] = [];

        for (const key in groupByComboTourTickets) {
          const foundComboTour = state.comboTours.find(
            (comboTour) => comboTour.Id === key
          );
          if (
            foundComboTour &&
            groupByComboTourTickets[key].filter(
              (ticket: IBookingTicket) => !ticket.isErase
            ).length > 0
          ) {
            selectedComboTours.push({
              ...foundComboTour,
              tickets: groupByComboTourTickets[key]
                .filter((ticket: IBookingTicket) => !ticket.isErase)
                .map((ticket: IBookingTicket, index: number) => ({
                  ...ticket,
                  id: ticket.id,
                  name: ticket.name,
                  fullName: ticket.fullName,
                  phone: ticket.phone,
                  passportId: ticket.passportId,
                  startDate: moment.utc(ticket.startDate).toISOString(),
                  email: ticket.email || null,
                  driverId: ticket.driverId || "",
                  groupId: ticket.groupId || "",
                  driverPriceId: ticket.driverPriceId,
                  busTicketC1: ticket.busTicketC1 || null,
                  busTicketC2: ticket.busTicketC2 || null,
                  datA_TOUR_CHHangHoaDichVuKhacs:
                    ticket.datA_TOUR_CHHangHoaDichVuKhacs
                      ? ticket.datA_TOUR_CHHangHoaDichVuKhacs.map((data) => ({
                          ...data,
                          extraService: state.extraServices.find(
                            (extraService) =>
                              extraService.id ===
                              data.dmuC_CHHangHoaDichVuKhacId
                          ),
                        }))
                      : undefined,
                })),
            });
          }
        }
        state.selectedComboTours = selectedComboTours;
        if (
          selectedComboTours.length > 0 &&
          selectedComboTours[0].tickets &&
          selectedComboTours[0].tickets.length > 0
        ) {
          state.selectedTicket = {
            ...selectedComboTours[0].tickets[0],
            comboTour: selectedComboTours[0],
          };
          if (
            selectedComboTours[0].tickets[0] &&
            selectedComboTours[0].tickets[0].datA_TOUR_CHHangHoaDichVuKhacs &&
            selectedComboTours[0].tickets[0].datA_TOUR_CHHangHoaDichVuKhacs
              .length > 0
          ) {
            state.selectedExtraServices =
              selectedComboTours[0].tickets[0].datA_TOUR_CHHangHoaDichVuKhacs.reduce(
                (prev, current) => {
                  const foundService = state.extraServices.find(
                    (service) =>
                      service.id === current.dmuC_CHHangHoaDichVuKhacId
                  );
                  if (foundService) {
                    return {
                      ...prev,
                      [foundService.id]: true,
                    };
                  } else {
                    return {
                      ...prev,
                    };
                  }
                },
                {}
              );
          } else {
            state.selectedExtraServices = state.extraServices.reduce(
              (prev, current) => ({ ...prev, [current.id]: false }),
              {}
            );
          }
        }
        state.tourStep = TourStepEnum.DETAILS;
        state.bookingStep = BookingStepEnum.BOOKING_DETAIL;
      }
    },
    generateDockerId: (state) => {
      const newDockerId = uuidv4();
      state.dockerId = newDockerId;
    },
    setSelectedService: (state, action: PayloadAction<any>) => {
      state.selectedExtraServices = action.payload;
    },
    toggleService: (state, action: PayloadAction<string>) => {
      const serviceKey = action.payload || "";
      state.selectedExtraServices = {
        ...state.selectedExtraServices,
        [serviceKey]: !state.selectedExtraServices[serviceKey],
      };
    },
    clearBooking: (state, action: PayloadAction) => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isBookingCompleted: false,
        errMsg: null,
        selectedComboTours: [],
        selectedTicket: null,
        tourStep: TourStepEnum.TOURS,
        bookingStep: BookingStepEnum.BOOKING_DETAIL,
        bookingResult: null,
        editingBooking: null,
      };
    },
  },
});
const getTours$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchTours.match),
    switchMap((re) => {
      return getAllTours().pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.length) {
            const tours = res.length ? res : [];
            return [
              // bookingSlice.actions.setTours(tours.map((tour: any) => ({
              //     id: tour.id,
              //     imgSrc: 'https://source.unsplash.com/Yizrl9N_eDA',
              //     type: tour.description,
              //     vehicleType: 'SELF-RIDING',
              //     price: tour.prices || '0' ,
              // }))),
              bookingSlice.actions.setTours(
                tours.filter((tour: any) => tour.status !== "inactive")
              ),
            ];
          } else {
            return [bookingSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          bookingSlice.actions.setErrMsg("Có lỗi xảy ra"),
        ])
      );
    })
  );
const getComboTours$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchComboTours.match),
    switchMap((re) => {
      return getAllComboTours(re.payload || {}).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.length) {
            const comboTours = res.length ? res : [];
            return [bookingSlice.actions.setComboTours(comboTours)];
          } else {
            return [
              bookingSlice.actions.setErrMsg(res?.response.error),
              bookingSlice.actions.setComboTours([]),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          bookingSlice.actions.setErrMsg("Có lỗi xảy ra"),
          bookingSlice.actions.setComboTours([]),
        ])
      );
    })
  );
const getPriceReqs$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchPriceReqs.match),
    switchMap((re) => {
      return getAllPriceReqs(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.length) {
            const prices = res ?? [];
            return [bookingSlice.actions.setPriceReqs(prices)];
          } else {
            return [bookingSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          bookingSlice.actions.setErrMsg("Có lỗi xảy ra"),
        ])
      );
    })
  );
const getBuses$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchBus.match),
    switchMap((re) => {
      return getAllBus().pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.length) {
            const buses = res ?? [];
            return [bookingSlice.actions.setBuses(buses)];
          } else {
            return [bookingSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          bookingSlice.actions.setErrMsg("Có lỗi xảy ra"),
        ])
      );
    })
  );
const getLocations$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchLocation.match),
    switchMap((re) => {
      return getAllLocations(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.length) {
            const locations = res;
            return [bookingSlice.actions.setLocations(locations)];
          } else {
            return [bookingSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          bookingSlice.actions.setErrMsg("Có lỗi xảy ra"),
        ])
      );
    })
  );
const getCodePay$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchCodePay.match),
    switchMap((re) => {
      return getAllCodePay().pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.length) {
            const codePays = res ?? [];
            return [bookingSlice.actions.setCodePay(codePays)];
          } else {
            return [bookingSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          bookingSlice.actions.setErrMsg("Có lỗi xảy ra"),
        ])
      );
    })
  );
const getExtraServices$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchExtraService.match),
    switchMap((re) => {
      return getAllExtraServices().pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.length) {
            const services = res ?? [];
            return [bookingSlice.actions.setExtraService(services)];
          } else {
            return [bookingSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          bookingSlice.actions.setErrMsg("Có lỗi xảy ra"),
        ])
      );
    })
  );

const createBooking$: RootEpic = (action$) =>
  action$.pipe(
    filter(submitBooking.match),
    switchMap((re) => {
      return createBooking({
        ...re.payload,
        createDate: Utils.convertToVNTimeZone(new Date()),
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.id) {
            return [
              bookingSlice.actions.setBookingResult(res),
              bookingSlice.actions.setBookingResultMsg(
                "Đặt vé thành công. Vé điện tử sẽ được gửi vào thông tin email bạn đã nhập"
              ),
              bookingSlice.actions.setBookingStep(BookingStepEnum.COMPLETED),
            ];
          } else {
            return [bookingSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          bookingSlice.actions.setErrMsg("Đặt vé không thành công"),
        ])
      );
    })
  );
const saveBooking$: RootEpic = (action$) =>
  action$.pipe(
    filter(saveBooking.match),
    switchMap((re) => {
      return updateBooking(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.id) {
            return [
              bookingSlice.actions.setBookingResult(res),
              bookingSlice.actions.setBookingResultMsg(
                "Cập nhật booking thành công"
              ),
              bookingSlice.actions.setBookingStep(BookingStepEnum.COMPLETED),
            ];
          } else {
            return [bookingSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          bookingSlice.actions.setErrMsg(
            e.response?.Message || "Cập nhật không thành công"
          ),
        ])
      );
    })
  );
const fetchCurrentAgent$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchCurrentAgent.match),
    switchMap((re) => {
      return getCurrentAgent().pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [bookingSlice.actions.setCurrentAgent(res)];
          } else {
            return [
              bookingSlice.actions.setErrMsg(res?.response.error),
              bookingSlice.actions.setCurrentAgent(null),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          bookingSlice.actions.setErrMsg(
            "Không thể lấy thông tin đại lý. Hãy thử reload lại trang"
          ),
          bookingSlice.actions.setCurrentAgent(null),
        ])
      );
    })
  );
export const {
  setTours,
  setPriceReqs,
  setBuses,
  bookTour,
  unbookTour,
  // setToursSelected,
  setComboToursSelected,
  addTicketsToTour,
  selectTicket,
  updateTicketSelected,
  removeTicketFromTour,
  setTourStep,
  setBookingStep,
  setLocations,
  setCodePay,
  fetchTours,
  fetchPriceReqs,
  fetchLocation,
  fetchCodePay,
  fetchBus,
  submitBooking,
  saveBooking,
  setBookingResult,
  setBookingResultMsg,
  setErrMsg,
  editBooking,
  clearBooking,
  setCurrentAgent,
  fetchCurrentAgent,
  generateDockerId,
  toggleService,
  setSelectedService,
  fetchExtraService,
  setExtraService,
  setExtraServiceSelected,
  fetchComboTours,
  setComboTours,
} = bookingSlice.actions;

export const BookingEpics = [
  getTours$,
  getPriceReqs$,
  createBooking$,
  saveBooking$,
  getBuses$,
  getLocations$,
  getCodePay$,
  fetchCurrentAgent$,
  getExtraServices$,
  getComboTours$,
];

export const bookingReducer = bookingSlice.reducer;

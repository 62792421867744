import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  //   IJobItem,
  IParams,
  IStation,
  //   ICreateStation,
  IUpdateStation,
  RootEpic,
  IRouterStationBus,
  IRouterStationBusParams,
  ICreateStation,
  IUpdateRouterStationBus,
  ICreateRouterStationBus,
  IBus,
  IRouter,
  ILocation,
} from "common/define-types";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import {
  createStation,
  getAllStationsPageable,
  removeStation,
  updateStation,
} from "api/station.api";
import {
  createRouterStationBus,
  getRouterStationBusesByStationId,
  removeRouterStationBus,
  updateRouterStationBus,
} from "api/routerStationBus.api";
import { getAllBus } from "api/bus.api";
import { getAllRouters } from "api/router.api";
import { getAllLocations } from "api/location.api";

export interface StationsState {
  loading: {
    isFetching: boolean;
    isSubmitting: boolean;
    isRouterBusFetching: boolean;
    isRouterBusSubmitting: boolean;
  };
  addStationOpen: boolean;
  addRouterBusStationOpen: boolean;
  locations: ILocation[] | [];
  buses: IBus[] | [];
  routers: IRouter[] | [];
  stations: IStation[] | [];
  stationsResults: any | null;
  stationSelected: IStation | null;
  stationSelectedRouterBuses: IRouterStationBus[];
  routerBusResults: any | null;
  deletingStationId: string | null;
  deletingStationRouterBusId: string | null;
  editingStation: IStation | null;
  editingStationRouterBus: IRouterStationBus | null;
  errMsg: string | null;
}

const initialState: StationsState = {
  loading: {
    isFetching: false,
    isSubmitting: false,
    isRouterBusFetching: false,
    isRouterBusSubmitting: false,
  },
  addStationOpen: false,
  addRouterBusStationOpen: false,
  locations: [],
  buses: [],
  routers: [],
  stations: [],
  stationsResults: null,
  stationSelected: null,
  stationSelectedRouterBuses: [],
  routerBusResults: null,
  deletingStationId: null,
  deletingStationRouterBusId: null,
  editingStation: null,
  editingStationRouterBus: null,
  errMsg: null,
};

export const stationsSlice = createSlice({
  name: "stations",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchLocations: (state, action: PayloadAction<IParams | undefined>) => {
      state.loading.isFetching = true;
    },
    fetchBuses: (state, action: PayloadAction<IParams | undefined>) => {
      state.loading.isFetching = true;
    },
    fetchRouters: (state, action: PayloadAction<IParams | undefined>) => {
      state.loading.isFetching = true;
    },
    fetchStations: (state, action: PayloadAction<IParams | undefined>) => {
      state.loading.isFetching = true;
    },
    fetchStationRouterBuses: (
      state,
      action: PayloadAction<IRouterStationBusParams | undefined>
    ) => {
      state.loading.isRouterBusFetching = true;
    },
    setLocations: (state, action: PayloadAction<ILocation[]>) => {
      state.locations = action.payload;
      state.loading.isFetching = false;
    },
    setBuses: (state, action: PayloadAction<IBus[]>) => {
      state.buses = action.payload;
      state.loading.isFetching = false;
    },
    setRouters: (state, action: PayloadAction<IRouter[]>) => {
      state.routers = action.payload;
      state.loading.isFetching = false;
    },
    setStations: (state, action: PayloadAction<IStation[]>) => {
      state.stations = action.payload;
      state.loading.isFetching = false;
      state.loading.isSubmitting = false;
      state.loading.isRouterBusSubmitting = false;
      state.deletingStationId = null;
    },
    setStationsResults: (state, action: PayloadAction<any>) => {
      state.stationsResults = action.payload;
      state.loading.isFetching = false;
      state.loading.isSubmitting = false;
      state.loading.isRouterBusSubmitting = false;
    },
    setRouterBusResults: (state, action: PayloadAction<any>) => {
      state.routerBusResults = action.payload;
      state.loading.isRouterBusFetching = false;
      state.loading.isRouterBusSubmitting = false;
    },
    setStationRouterBuses: (
      state,
      action: PayloadAction<IRouterStationBus[]>
    ) => {
      state.stationSelectedRouterBuses = action.payload;
      state.loading.isFetching = false;
      state.loading.isRouterBusFetching = false;
      state.deletingStationRouterBusId = null;
    },
    setStationSelected: (state, action: PayloadAction<IStation | null>) => {
      state.stationSelected = action.payload;
      //[2-4-2024][Phuc Thinh][Fix only loading when had station selected]
      state.loading.isRouterBusFetching = !!action.payload;
      state.errMsg = null;
    },
    deleteStation: (state, action: PayloadAction<string>) => {
      state.deletingStationId = action.payload;
    },
    deleteStationRouterBus: (state, action: PayloadAction<string>) => {
      state.deletingStationRouterBusId = action.payload;
    },
    editStation: (state, action: PayloadAction<IStation | null>) => {
      state.editingStation = action.payload;
    },
    editStationRouterBus: (
      state,
      action: PayloadAction<IRouterStationBus | null>
    ) => {
      state.editingStationRouterBus = action.payload;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.loading.isFetching = false;
      state.deletingStationId = null;
      state.loading.isSubmitting = false;
      state.loading.isRouterBusFetching = false;
      state.loading.isRouterBusSubmitting = false;
      state.deletingStationRouterBusId = null;
    },
    saveStation: (state, action: PayloadAction<IUpdateStation>) => {
      state.loading.isSubmitting = true;
      state.errMsg = null;
    },
    addStation: (state, action: PayloadAction<ICreateStation>) => {
      state.loading.isSubmitting = true;
      state.errMsg = null;
    },
    setAddStationOpen: (state, action: PayloadAction<boolean>) => {
      state.addStationOpen = action.payload;
    },
    saveStationBus: (state, action: PayloadAction<IUpdateRouterStationBus>) => {
      state.loading.isSubmitting = true;
      state.errMsg = null;
    },
    addStationBus: (state, action: PayloadAction<ICreateRouterStationBus>) => {
      state.loading.isSubmitting = true;
      state.errMsg = null;
    },
    setAddStationBusOpen: (state, action: PayloadAction<boolean>) => {
      state.addRouterBusStationOpen = action.payload;
    },
  },
});
const getLocations$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchLocations.match),
    switchMap((re) => {
      return getAllLocations({
        ...re.payload,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res) {
            const locations = res;
            // if (res && !res?.response?.error && res.results) {
            //   const stations = res.results;
            if (locations.length > 0) {
              return [stationsSlice.actions.setLocations(locations)];
            }
            return [stationsSlice.actions.setLocations([])];
          } else {
            return [stationsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          e.response?.Message ||
            stationsSlice.actions.setErrMsg(
              "Có lỗi xảy ra khi lấy danh sách địa điểm"
            ),
        ])
      );
    })
  );
const getBuses$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchBuses.match),
    switchMap((re) => {
      return getAllBus().pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res) {
            const buses = res;
            // if (res && !res?.response?.error && res.results) {
            //   const stations = res.results;
            if (buses.length > 0) {
              return [stationsSlice.actions.setBuses(buses)];
            }
            return [stationsSlice.actions.setBuses([])];
          } else {
            return [stationsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          e.response?.Message ||
            stationsSlice.actions.setErrMsg(
              "Có lỗi xảy ra khi lấy danh mục bus"
            ),
        ])
      );
    })
  );
const getRouters$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchRouters.match),
    switchMap((re) => {
      return getAllRouters({
        ...re.payload,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res) {
            const routers = res;
            // if (res && !res?.response?.error && res.results) {
            //   const stations = res.results;
            if (routers.length > 0) {
              return [stationsSlice.actions.setRouters(routers)];
            }
            return [stationsSlice.actions.setRouters([])];
          } else {
            return [stationsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          e.response?.Message ||
            stationsSlice.actions.setErrMsg(
              "Có lỗi xảy ra khi lấy danh sách tuyến đường"
            ),
        ])
      );
    })
  );
const getStations$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchStations.match),
    switchMap((re) => {
      return getAllStationsPageable({
        ...re.payload,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const { results: stations, ...rest } = res;
            if (stations.length > 0) {
              return [
                stationsSlice.actions.setStations(stations),
                stationsSlice.actions.setStationSelected(stations[0]),
                stationsSlice.actions.setStationsResults(rest),
              ];
            }
            return [
              stationsSlice.actions.setStations([]),
              //[2-4-2024][Phuc Thinh][reset buses state when return no station]
              stationsSlice.actions.setStationRouterBuses([]),
              stationsSlice.actions.setStationSelected(null),
              stationsSlice.actions.setStationsResults(null),
              stationsSlice.actions.setRouterBusResults(null),
            ];
          } else {
            return [stationsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          stationsSlice.actions.setStations([]),
          stationsSlice.actions.setStationSelected(null),
          stationsSlice.actions.setStationsResults(null),
          stationsSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra"
          ),
        ])
      );
    })
  );

// const deleteStation$: RootEpic = (action$) =>
//   action$.pipe(
//     filter(deleteStation.match),
//     switchMap((re) => {
//       return deleteStationById(re.payload).pipe(
//         mergeMap((res: any) => {
//           if (res && !res?.response?.error && res === 1) {
//             return [stationsSlice.actions.deleteStationFullfilled(res)];
//           } else {
//             return [stationsSlice.actions.setErrMsg(res?.response.error)];
//           }
//         }),
//         catchError((e: AjaxError) => [
//           stationsSlice.actions.setErrMsg("Có lỗi xảy ra khi xóa nhà cung cấp"),
//         ])
//       );
//     })
//   );
const getStationBuses$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setStationSelected.match),
    switchMap((re) => {
      if (!re.payload) return [];
      return getRouterStationBusesByStationId(re.payload.id, {
        pageSize: 25,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const { results: buses, ...rest } = res;
            return [
              stationsSlice.actions.setStationRouterBuses(buses ?? []),
              stationsSlice.actions.setRouterBusResults(rest),
            ];
          } else {
            return [stationsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          stationsSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách tuyến bus"
          ),
        ])
      );
    })
  );
const fetchStationBuses$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchStationRouterBuses.match),
    switchMap((re) => {
      if (!state$.value.stationsCategory.stationSelected) return [];
      return getRouterStationBusesByStationId(
        state$.value.stationsCategory.stationSelected.id,
        re.payload
      ).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const { results: buses, ...rest } = res;
            return [
              stationsSlice.actions.setStationRouterBuses(buses ?? []),
              stationsSlice.actions.setRouterBusResults(rest),
            ];
          } else {
            return [stationsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          stationsSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách tuyến bus"
          ),
        ])
      );
    })
  );
const addStations$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addStation.match),
    switchMap((re) => {
      return createStation([re.payload]).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const stationResults =
              state$.value.stationsCategory.stationsResults;
            if (stationResults) {
              return [
                stationsSlice.actions.setAddStationOpen(false),
                stationsSlice.actions.fetchStations({
                  ...stationResults,
                }),
              ];
            } else {
              return [
                stationsSlice.actions.setAddStationOpen(false),
                stationsSlice.actions.fetchStations(),
              ];
            }
          } else {
            return [stationsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          stationsSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi tạo nhà xe"
          ),
        ])
      );
    })
  );
const saveStations$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveStation.match),
    switchMap((re) => {
      return updateStation(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.id) {
            const stationResults =
              state$.value.stationsCategory.stationsResults;
            if (stationResults) {
              return [
                stationsSlice.actions.editStation(null),
                stationsSlice.actions.fetchStations({ ...stationResults }),
              ];
            } else {
              return [
                stationsSlice.actions.editStation(null),
                stationsSlice.actions.fetchStations(),
              ];
            }
          } else {
            return [stationsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          stationsSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi lưu thông tin"
          ),
        ])
      );
    })
  );
const deleteStations$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteStation.match),
    switchMap((re) => {
      return removeStation(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const stationResults =
              state$.value.stationsCategory.stationsResults;
            if (stationResults) {
              return [
                stationsSlice.actions.fetchStations({ ...stationResults }),
              ];
            } else {
              return [stationsSlice.actions.fetchStations()];
            }
          } else {
            return [stationsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          stationsSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi xóa"
          ),
        ])
      );
    })
  );
const addStationBus$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addStationBus.match),
    switchMap((re) => {
      return createRouterStationBus(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const routerBusResults =
              state$.value.stationsCategory.routerBusResults;
            if (routerBusResults) {
              return [
                stationsSlice.actions.setAddStationBusOpen(false),
                stationsSlice.actions.fetchStationRouterBuses({
                  ...routerBusResults,
                }),
              ];
            } else {
              return [
                stationsSlice.actions.setAddStationBusOpen(false),
                stationsSlice.actions.fetchStationRouterBuses(),
              ];
            }
          } else {
            return [stationsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          stationsSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi tạo tuyến xe"
          ),
        ])
      );
    })
  );
const saveStationBus$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveStationBus.match),
    switchMap((re) => {
      return updateRouterStationBus(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const routerBusResults =
              state$.value.stationsCategory.routerBusResults;
            if (routerBusResults) {
              return [
                stationsSlice.actions.editStationRouterBus(null),
                stationsSlice.actions.fetchStationRouterBuses({
                  ...routerBusResults,
                }),
              ];
            } else {
              return [
                stationsSlice.actions.editStationRouterBus(null),
                stationsSlice.actions.fetchStationRouterBuses(),
              ];
            }
          } else {
            return [stationsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          stationsSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi lưu thông tin"
          ),
        ])
      );
    })
  );
const deleteStationRouterBus$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteStationRouterBus.match),
    switchMap((re) => {
      return removeRouterStationBus(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const routerBusResults =
              state$.value.stationsCategory.routerBusResults;
            if (routerBusResults) {
              return [
                stationsSlice.actions.fetchStationRouterBuses({
                  ...routerBusResults,
                  page: 1,
                }),
              ];
            } else {
              return [stationsSlice.actions.fetchStationRouterBuses()];
            }
          } else {
            return [stationsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          stationsSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi xóa"
          ),
        ])
      );
    })
  );

export const {
  fetchLocations,
  fetchBuses,
  fetchRouters,
  fetchStations,
  fetchStationRouterBuses,
  setStationsResults,
  setStationRouterBuses,
  setStationSelected,
  setStations,
  deleteStation,
  deleteStationRouterBus,
  editStation,
  editStationRouterBus,
  setErrMsg,
  addStation,
  saveStation,
  setAddStationOpen,
  saveStationBus,
  setAddStationBusOpen,
  setRouterBusResults,
  addStationBus,
} = stationsSlice.actions;

export const StationsEpics = [
  getLocations$,
  getBuses$,
  getRouters$,
  getStations$,
  getStationBuses$,
  fetchStationBuses$,
  addStations$,
  saveStations$,
  deleteStations$,
  addStationBus$,
  saveStationBus$,
  deleteStationRouterBus$,
];

export const stationsReducer = stationsSlice.reducer;

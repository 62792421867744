import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip, Typography } from 'antd';
import {
  DriverType,
  GroupStatusEnum,
  IDriver,
  IGroup,
} from 'common/define-types';
import Utils from 'common/Utils';
import { GroupDriverSelect } from 'components/Selects/GroupDriverSelect';
import DriverIcon from 'image/icon/driver-icon.svg';
import TicketIcon from 'image/icon/ticket-icon.svg';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import {
  deleteGroup,
  fetchAdditionalGroupData,
  fetchGroup,
  saveGroup,
  startGroup,
} from 'store/slice/GroupSlice';
import { useDispatchRoot, useSelectorRoot } from 'store/store';
import styles from '../Group.module.css';
// import { TourType } from "components/Text/TourType";

interface IProps {
  group: IGroup | null;
  readOnly?: boolean;
}
export const GroupItem = ({ group, readOnly = false }: IProps) => {
  const dispatch = useDispatchRoot();
  const selectGroupSelected = useSelectorRoot(
    (state) => state.group.groupSelected
  );
  const selectDeletingGroupId = useSelectorRoot(
    (state) => state.group.deletingGroupId
  );
  const selectUnGroupTicketsCount = useSelectorRoot(
    (state) => state.group.unGroupTicketCount
  );
  const selectDrivers = useSelectorRoot((state) => state.group.allDrivers);

  const isMissingLead = false;
  // const isMissingLead = Utils.checkGroupEndDateIsBeforeCountDay(group);

  //[10-5-2024][Phuc Thinh][get the extend day in group is missing lead]
  const dayMissingLead = group
    ? moment(group.endDate).diff(moment(group?.startDate), 'days') + 1 + 1
    : undefined;
  const isActive =
    group === null || selectGroupSelected === null
      ? selectGroupSelected === group
      : selectGroupSelected.id === group.id;

  const groupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isActive && groupRef.current)
      groupRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [isActive]);

  // const startOnToday = moment(group?.startDate)
  //   .startOf("day")
  //   .isSame(moment().startOf("day"));
  const handleGroupClick = () => {
    if (selectGroupSelected === null && group === null) {
      return;
    }
    if (selectGroupSelected && group && selectGroupSelected.id === group.id) {
      return;
    }
    dispatch(fetchGroup(group));
    if (group) {
      dispatch(fetchAdditionalGroupData(group));
    }
  };

  const handleChangeLeader = (driver: IDriver) => {
    if (group) {
      const groupCreateDate = group.name.slice(
        group.name.indexOf('_') + 1,
        group.name.length
      );
      const isGroupHappened = group.status !== GroupStatusEnum.NOT_RUNNING;
      // const isGroupHappened = moment(group.startDate)
      //   .set({ ...Utils.startDateOfGroup })
      //   .isBefore(moment());

      dispatch(
        saveGroup({
          ...group,
          leaderId: driver.accountId,
          name: isGroupHappened
            ? group.name
            : driver
            ? `${Utils.getName(driver.name).firstName}_${groupCreateDate}`
            : // ? `${Utils.getName(leaderInfor.name).firstName}_${moment(
              //     group.startDate
              //   ).format("DDMMYYYY-HHmm")}`
              group.name,
        })
      );
    }
  };

  const handleStartGroup = () => {
    if (group) {
      dispatch(startGroup(group.id));
    }
  };

  const renderGroupStatus = () => {
    if (group) {
      switch (group.status) {
        case GroupStatusEnum.NOT_RUNNING:
          return moment
            .utc(group.startDate)
            .local()
            .startOf('day')
            .isAfter(moment().endOf('day')) ? (
            <Tooltip
              title={`Group có ngày bắt đầu vào ${moment
                .utc(group.startDate)
                .format(Utils.dateFormat)}`}
            >
              <Button
                type='primary'
                onClick={(e) => {
                  e.stopPropagation();
                  handleStartGroup();
                }}
                disabled={true}
              >
                Bắt đầu
              </Button>
            </Tooltip>
          ) : (
            <Button
              type='primary'
              onClick={(e) => {
                e.stopPropagation();
                handleStartGroup();
              }}
              disabled={group.leaderId === null}
            >
              Bắt đầu
            </Button>
          );
        case GroupStatusEnum.RUNNING:
          return (
            <Typography.Text strong style={{ color: '#52C41A' }}>
              Đang diễn ra
            </Typography.Text>
          );
        case GroupStatusEnum.END:
          return <Typography.Text strong>Đã kết thúc</Typography.Text>;

        default:
          break;
      }
    }
  };

  return (
    <div
      className={`${styles.groupItem} ${isActive ? styles.selected : ''}`}
      onClick={handleGroupClick}
      aria-selected={isActive}
      ref={groupRef}
    >
      <div className={`${styles.groupItem_Wrapper}`}>
        {group ? (
          <>
            <Typography.Title className={styles.groupName} level={4}>
              {group.name}
            </Typography.Title>
            <div className={styles.groupItem_Info}>
              <img src={DriverIcon} alt='driver' />
              <GroupDriverSelect
                testId='Leader-Group'
                defaultOptions={selectDrivers}
                value={group.leaderId}
                style={{ minWidth: 170 }}
                valuePropName='accountId'
                onChange={handleChangeLeader}
                readOnly={readOnly}
                groupId={group.id}
                // [4-4-2024][Phuc Thinh][Only get contract driver]
                acceptTypes={[DriverType.CONTRACT]}
                params={{
                  startDate: moment(group.startDate)
                    .set({ ...Utils.startDateOfGroup })
                    .toISOString(),
                  endDate: moment(group.endDate).endOf('day').toISOString(),
                  isAvailable: true,
                }}
              />
            </div>
          </>
        ) : (
          <Typography.Title
            className={styles.groupName}
            level={4}
            style={{ whiteSpace: 'nowrap', paddingRight: 10 }}
          >
            Chưa gắn nhóm
          </Typography.Title>
        )}
        <div className={styles.groupItem_Info}>
          <img src={TicketIcon} alt='driver' />
          <Typography.Text strong>{`Số lượng khách: ${
            group ? group.countTicket : selectUnGroupTicketsCount
          }`}</Typography.Text>
        </div>
      </div>
      {group && (
        <div className={styles.groupItem_Buttons}>
          {isMissingLead ? (
            <Typography.Text
              type='danger'
              strong
              style={{ whiteSpace: 'nowrap' }}
            >
              {`Ngày ${dayMissingLead || 4} chưa có Lead`}
            </Typography.Text>
          ) : (
            <span>{''}</span>
          )}

          {/* [06-12-2023][Phuc Thinh] [Feature/14882]
            Allow delete group */}

          <Popconfirm
            title='Xóa group'
            description='Bạn chắc chắn muốn xóa group này'
            onCancel={(e: any) => e?.stopPropagation()}
            onConfirm={(e: any) => {
              e?.stopPropagation();
              dispatch(deleteGroup(group.id));
            }}
            okButtonProps={{
              loading: selectDeletingGroupId === group.id,
            }}
            disabled={
              (selectDeletingGroupId !== null &&
                selectDeletingGroupId !== group.id) ||
              readOnly ||
              group.status !== GroupStatusEnum.NOT_RUNNING
            }
          >
            <Button
              onClick={(e: any) => e.stopPropagation()}
              type='text'
              icon={
                <DeleteOutlined
                  style={{
                    fontSize: 20,
                    color:
                      readOnly || group.status !== GroupStatusEnum.NOT_RUNNING
                        ? 'grey'
                        : 'red',
                  }}
                />
              }
              // disabled={
              //   (selectDeletingGroupId !== null &&
              //     selectDeletingGroupId !== group.id) ||
              //   readOnly ||
              //   group.countTicket !== 0
              // }
            />
          </Popconfirm>
          {renderGroupStatus()}
        </div>
      )}
    </div>
  );
};

import { Select } from 'antd';
import { getAllDrivers } from 'api/driver.api';
import { getDriversByGroupId } from 'api/group.api';
import Utils from 'common/Utils';
import { DriverType, IDriver } from 'common/define-types';
import { useState, useEffect, useCallback } from 'react';
import { useSelectorRoot } from 'store/store';

interface IProps {
  value?: string | null;
  onChange?: (arg: IDriver) => void;
  onClear?: () => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  driversOptions?: IDriver[];
  valuePropName?: 'accountId' | 'id';
  groupId?: string;
  testId: string;
  defaultOptions: IDriver[];
  acceptTypes?: DriverType[];
  params: {
    startDate: string | null;
    endDate: string | null;
    isAvailable: boolean;
    groupId?: string;
    leaderGroupId?: string;
  };
}
export const GroupDriverSelect = ({
  value,
  onChange = () => {},
  onClear,
  style,
  readOnly = false,
  valuePropName = 'id',
  testId,
  defaultOptions = [],
  groupId,
  acceptTypes,
  params,
}: IProps) => {
  const [currentDriver, setCurrentDriver] = useState<IDriver | undefined>();
  const [drivers, setDrivers] = useState<IDriver[]>([]);
  // const [driversOptions, setDriversOptions] = useState<IDriver[]>([]);
  const selectIsRefreshing = useSelectorRoot(
    (state) => state.login.isRefreshing
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const options = dropdownVisible ? drivers : defaultOptions;
  const fetchDrivers = useCallback(() => {
    // [27-1-2024][Phuc Thinh][Fix bug call API multiple time]
    const { leaderGroupId, ...rest } = params;
    setIsLoading(true);
    if (dropdownVisible) {
      if (groupId) {
        getDriversByGroupId(groupId).subscribe(
          (driversRes) => {
            if (driversRes.results && driversRes.results.length > 0) {
              setDrivers(driversRes.results);
              setIsLoading(false);
            } else {
              getAllDrivers({
                ...rest,
                pageSize: Utils.RANGE_PAGESIZE.MAX_INTERGER,
                groupId: leaderGroupId,
                isLeader: !!leaderGroupId || undefined,
              }).subscribe((driversRes) => {
                setDrivers(driversRes.results);
                setIsLoading(false);
              });
            }
          },
          (err) => setIsLoading(false)
        );
      } else {
        getAllDrivers({
          ...rest,
          pageSize: Utils.RANGE_PAGESIZE.MAX_INTERGER,
          groupId: leaderGroupId,
          isLeader: !!leaderGroupId || undefined,
        }).subscribe(
          (driversRes) => {
            setDrivers(driversRes.results);
            setIsLoading(false);
          },
          (err) => setIsLoading(false)
        );
      }
    }
  }, [params, dropdownVisible, groupId]);

  useEffect(() => {
    if (!selectIsRefreshing && dropdownVisible) fetchDrivers();
  }, [fetchDrivers, dropdownVisible, selectIsRefreshing]);

  useEffect(() => {
    if (!value) setCurrentDriver(undefined);
    if (value && options.find((driver) => driver[valuePropName] === value)) {
      setCurrentDriver(
        options.find((driver) => driver[valuePropName] === value)
      );
    }
  }, [value, options, valuePropName]);

  return (
    <Select
      data-test-id={testId}
      options={
        isLoading
          ? []
          : options
              .filter(
                (driver) =>
                  !acceptTypes ||
                  acceptTypes.some((type) => type === driver.type)
              )
              ?.map((driver) => ({
                label: driver.name,
                value: driver[valuePropName],
              }))
      }
      value={
        isLoading
          ? undefined
          : dropdownVisible &&
            options.find((driver) => driver[valuePropName] === value)
          ? value
          : currentDriver?.name
      }
      style={{
        minWidth: 100,
        ...style,
      }}
      loading={isLoading}
      allowClear={!!onClear}
      onChange={(value) =>
        onChange(options.filter((driver) => driver[valuePropName] === value)[0])
      }
      onClear={onClear}
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      optionFilterProp='children'
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '')
          .toLowerCase()
          .localeCompare((optionB?.label ?? '').toLowerCase())
      }
      open={dropdownVisible}
      disabled={readOnly}
      onDropdownVisibleChange={(open) => {
        if (open && isLoading) {
          setDropdownVisible(false);
        } else {
          setDropdownVisible(open);
        }
      }}
      onClick={(e: any) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    />
  );
};

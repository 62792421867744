import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import { IParams, IPaymentMethodBooking } from "common/define-types";
import { ICreateIssue, IUpdateIssue } from "./types/issue";

const api = `${SYSTEM_CONSTANTS.API_URL}/Issue`;

export const getIssueById = (id: string): Observable<any> => {
  return HttpClient.get(`${api}/${id}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const getAllIssues = (params?: IParams): Observable<any> => {
  if (params) {
    const { page, pageSize } = params;
    return HttpClient.get(
      `${api}?
        ${page ? `page=${page}&` : ""} 
        ${pageSize ? `size=${pageSize}&` : ""}`
    ).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(`${api}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};

export const createIssue = (body: ICreateIssue): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const updateIssue = (body: IUpdateIssue): Observable<any> => {
  return HttpClient.put(`${api}/${body.issueId}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const deleteIssueById = (issueId: string): Observable<any> => {
  return HttpClient.delete(`${api}/${issueId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const approveIssueByIds = (issueIds: string[]): Observable<any> => {
  return HttpClient.put(`${api}/approve`, JSON.stringify(issueIds), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const rejectIssueByIds = (issueIds: string[]): Observable<any> => {
  return HttpClient.put(`${api}/reject`, JSON.stringify(issueIds), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getTicketIssues = (ticketId: string) => {
  return HttpClient.get(`${api}/getIssuesTicket?ticketId=${ticketId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updatePaymentIssue = (body: {
  ticketId: string;
  paymentMethods: IPaymentMethodBooking[];
}): Observable<any> => {
  const { ticketId, paymentMethods } = body;
  return HttpClient.put(
    `${api}/paymentMethodIssues?ticketId=${ticketId}`,
    JSON.stringify(paymentMethods),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updatePaymentRefundIssue = (body: {
  issueId: string;
  paymentMethods: IPaymentMethodBooking[];
}): Observable<any> => {
  const { issueId, paymentMethods } = body;
  return HttpClient.put(
    `${api}/paymentMethodRefundIssues?issueId=${issueId}`,
    JSON.stringify(paymentMethods),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

import { Button, Modal, Popconfirm, Typography, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import {
  BookingStepEnum,
  ComboTourItemType,
  ComboTourStatus,
  IPaymentMethodBooking,
  ISelectedTicket,
  ITicket,
  ITicketOperatorItem,
} from 'common/define-types';
import Utils from 'common/Utils';
import ComboTourSelect from 'components/Selects/ComboTourSelect';
import moment from 'moment';
import { MethodForm } from 'pages/Booking/components/Billing/MethodForm';
import { TicketCard } from 'pages/Booking/components/TicketCard';
import { useState } from 'react';
import { updateTicketSelected } from 'store/slice/BookingSlice';
import { useDispatchRoot, useSelectorRoot } from 'store/store';
import styles from './EditTicket.module.css';
import { RefundSection } from './RefundSection';

interface IProps {
  open: boolean;
  isOnlyView?: boolean;
  currentTicket: ITicket | ITicketOperatorItem | undefined;
  handleClose?: () => void;
  handleSubmit: (
    ticketUpdated: ISelectedTicket,
    paymentMethodBookingsConnect?: IPaymentMethodBooking[],
    paymentMethodBookingsDisable?: IPaymentMethodBooking[]
  ) => void;
}

const EditTicketModal = ({
  open,
  currentTicket,
  isOnlyView,
  handleClose,
  handleSubmit,
}: IProps) => {
  // const [refundAmount, setRefundAmount] = useState(0);
  const [refundValue, setRefundValue] = useState<any>(null);
  const [payAmount, setPayAmount] = useState(0);
  const [payForm] = useForm();
  const [refundForm] = useForm();
  const [currentStep, setCurrentStep] = useState(
    BookingStepEnum.BOOKING_DETAIL
  );
  const dispatch = useDispatchRoot();
  const ticketSelected = useSelectorRoot(
    (state) => state.booking.selectedTicket
  );
  const isSubmitting = useSelectorRoot(
    (state) => state.operatorTicket.isSubmitting
  );
  const selectComboTours = useSelectorRoot((state) => state.booking.comboTours);
  const tours = useSelectorRoot((state) => state.booking.tours);
  const foundComboTourOfTicket = selectComboTours.find(
    (combo) => combo.Id === ticketSelected?.comboTourId
  );
  const hasExtraServiceCombo = foundComboTourOfTicket?.ListItem?.some(
    (item) =>
      item.ItemType === ComboTourItemType.HangHoa ||
      item.ItemType === ComboTourItemType.RoomTemplate
  );
  const newPrice = ticketSelected
    ? (ticketSelected.price || 0) +
      Utils.getBusPrice(ticketSelected) +
      Utils.getExtraServicePrice(ticketSelected)
    : 0;
  // const paidPrice = currentTicket
  //   ? (currentTicket.price || 0) +
  //     Utils.getBusPrice(currentTicket) +
  //     Utils.getExtraServicePrice(currentTicket)
  //   : 0;

  const checkReadOnlyTicket = () => {
    if (!ticketSelected) return true;
    // Calculating the time difference
    // of two dates
    let Difference_In_Time =
      moment.utc().toDate().getTime() -
      moment.utc(ticketSelected.startDate).toDate().getTime();

    // Calculating the no. of days between
    // two dates
    let Difference_In_Days =
    Math.round
        (Difference_In_Time / (1000 * 3600 * 24));
    var selectedTourDays = tours.find(x => foundComboTourOfTicket?.ListItem?.find(y => y.Id === x.id) != null)?.countDay ?? 0
    if (
      Difference_In_Days >= selectedTourDays || isOnlyView
    ) {
      return true;
    }

    return false;
  };
  const { busC1Price, busC2Price, ticketPrice, extraServicePrice } =
    ticketSelected && currentTicket
      ? Utils.getDiffFromNewToCurrent(ticketSelected, currentTicket)
      : {
          busC1Price: 0,
          busC2Price: 0,
          ticketPrice: 0,
          extraServicePrice: 0,
        };
  const HAS_TO_PAY_AMOUNT =
    ticketSelected && currentTicket
      ? (ticketPrice > 0
          ? ticketPrice - parseInt(refundValue?.RefundTicket ?? 0)
          : -parseInt(refundValue?.RefundTicket ?? 0)) +
        (busC1Price > 0
          ? busC1Price - parseInt(refundValue?.RefundBusC1 ?? 0)
          : -parseInt(refundValue?.RefundBusC1 ?? 0)) +
        (busC2Price > 0
          ? busC2Price - parseInt(refundValue?.RefundBusC2 ?? 0)
          : -parseInt(refundValue?.RefundBusC2 ?? 0)) +
        extraServicePrice
      : 0;
  // const HAS_TO_PAY_AMOUNT = newPrice - paidPrice;

  const renderBillResult = () => {
    let leftAmount = Math.abs(HAS_TO_PAY_AMOUNT) - payAmount;
    console.log(HAS_TO_PAY_AMOUNT);
    if (HAS_TO_PAY_AMOUNT === 0)
      return (
        <>
          Còn lại <br /> {Utils.formatCurrency(0)}
        </>
      );
    if (HAS_TO_PAY_AMOUNT > 0)
      return (
        <>
          Còn thiếu <br /> {Utils.formatCurrency(leftAmount)}
        </>
      );
    if (HAS_TO_PAY_AMOUNT < 0)
      return (
        <>
          Còn thừa <br /> {Utils.formatCurrency(leftAmount)}
        </>
      );
  };

  const onSaveUpdate = async () => {
    if (!ticketSelected) return;
    if (HAS_TO_PAY_AMOUNT >= 0) {
      if (HAS_TO_PAY_AMOUNT - payAmount > 0) {
        notification.info({
          message: `Bạn phải nhập đủ số tiền cần trả`,
        });
        return;
      }
    } else {
      if (HAS_TO_PAY_AMOUNT + payAmount < 0) {
        notification.info({
          message: `Bạn phải nhập đủ số tiền cần hoàn`,
        });
        return;
      }
    }
    const paymentMethodBookingsValue = await payForm.validateFields();
    const paymentMethodBookings: IPaymentMethodBooking[] = [];
    for (const key in paymentMethodBookingsValue) {
      if (
        paymentMethodBookingsValue[key] &&
        paymentMethodBookingsValue[key] > 0
      ) {
        paymentMethodBookings.push({
          paymentCode: key,
          amount: paymentMethodBookingsValue[key],
        });
      }
    }
    if (HAS_TO_PAY_AMOUNT !== 0 && paymentMethodBookings.length === 0) {
      notification.info({
        message: 'Bạn chưa nhập các phương thức thanh toán',
      });
      return;
    }
    const { RefundTicket, RefundBusC1, RefundBusC2 } = refundValue;
    const updatedTicket: ISelectedTicket = {
      ...ticketSelected,
      refund: parseInt(RefundTicket || '0'),
      refundBus1: parseInt(RefundBusC1 || '0'),
      refundBus2: parseInt(RefundBusC2 || '0'),
    };
    if (HAS_TO_PAY_AMOUNT >= 0) {
      handleSubmit(updatedTicket, paymentMethodBookings, undefined);
    } else {
      handleSubmit(updatedTicket, undefined, paymentMethodBookings);
    }
  };

  const moveToPayment = async () => {
    const refundValue = await refundForm
      .validateFields()
      .catch((err) => console.log(err));
    // let refundTotal = 0;
    // if (refundValue) {
    //   for (var key in refundValue) {
    //     if (!isNaN(refundValue[key])) {
    //       refundTotal += parseInt(refundValue[key]);
    //     }
    //   }
    // }
    // setRefundAmount(refundTotal);
    setRefundValue(refundValue);
    setCurrentStep(BookingStepEnum.PAYMENT);
  };

  const renderByStep = (step: BookingStepEnum) => {
    switch (step) {
      case BookingStepEnum.BOOKING_DETAIL:
        return (
          <>
            <div className={styles.header}>
              <div></div>
              {foundComboTourOfTicket && (
                <ComboTourSelect
                  testId='select-ComboTour'
                  defaultOptions={selectComboTours}
                  filterValue={{
                    dateTime: ticketSelected?.startDate,
                  }}
                  acceptTypes={[foundComboTourOfTicket.Type]}
                  acceptStatus={[ComboTourStatus.AVAILABLE]}
                  style={{ minWidth: 320 }}
                  value={ticketSelected?.comboTourId}
                  readOnly={!!hasExtraServiceCombo}
                  onChange={(comboTour) => {
                    if (!ticketSelected || !comboTour) return;
                    const foundDriverPrice = comboTour?.ListItem?.find(
                      (item) => item.ItemType === ComboTourItemType.DriverPrice
                    );
                    const foundMotorPrice = comboTour?.ListItem?.find(
                      (item) => item.ItemType === ComboTourItemType.MotorPrice
                    );
                    dispatch(
                      updateTicketSelected({
                        ticket: {
                          ...ticketSelected,
                          comboTour: comboTour,
                          comboTourId: comboTour?.Id,
                          driverPriceId: foundDriverPrice?.Id,
                          dmuC_MotorPriceId: foundMotorPrice?.Id,
                          selfMotor: foundMotorPrice?.Value,
                          tourId: comboTour?.ListItem?.find(
                            (item) => item.ItemType === ComboTourItemType.Tour
                          )?.Id,
                          price: comboTour.Price,
                        },
                        isApplyAll: false,
                        onlyUpdateTicket: true,
                      })
                    );
                  }}
                />
              )}
            </div>
            <div className={styles.ticketSection}>
              <TicketCard
                hasInGroupCheck={false}
                readOnly={checkReadOnlyTicket()}
                onSave={(args) =>
                  dispatch(
                    updateTicketSelected({ ...args, onlyUpdateTicket: true })
                  )
                }
              />
              {currentTicket && ticketSelected && (
                <RefundSection
                  currentTicket={currentTicket}
                  newTicket={ticketSelected}
                  form={refundForm}
                />
              )}
            </div>
            {!checkReadOnlyTicket() && (
              <div
                className={styles.buttonsContainer}
                style={{ marginTop: 20 }}
              >
                <Popconfirm
                  onConfirm={moveToPayment}
                  title='Cập nhật thông tin vé'
                  description={
                    'Nếu có thay đổi gì về thông tin vé, hãy chắc chắn bạn đã nhấn Apply.'
                  }
                >
                  <Button type='primary'>Thanh toán</Button>
                </Popconfirm>
              </div>
            )}
          </>
        );
      case BookingStepEnum.PAYMENT:
        return (
          <>
            <div
              className={styles.justifyBetween}
              style={{ padding: '0 30px 12px', minWidth: 300 }}
            >
              <Typography.Text strong className={styles.billPrice}>
                Tổng <br /> {Utils.formatCurrency(newPrice)}
              </Typography.Text>
              <Typography.Text strong className={styles.billPrice}>
                {renderBillResult()}
              </Typography.Text>
            </div>
            <MethodForm
              paymentForm={payForm}
              payAmount={payAmount}
              setPayAmount={(value) => setPayAmount(value)}
              totalAmount={Math.abs(HAS_TO_PAY_AMOUNT)}
              hasDebtItem={false}
            />
            <div
              className={styles.buttonsContainer}
              style={{ justifyContent: 'space-between' }}
            >
              <Button
                onClick={() => setCurrentStep(BookingStepEnum.BOOKING_DETAIL)}
              >
                Quay lại
              </Button>
              <Button
                type='primary'
                loading={isSubmitting}
                onClick={onSaveUpdate}
              >
                Cập nhật
              </Button>
            </div>
          </>
        );

      default:
        break;
    }
  };
  return (
    <Modal
      title={'Chỉnh sửa thông tin vé'}
      open={open}
      onCancel={handleClose}
      //   onCancel={() => dispatch(selectTicket(null))}
      centered
      width={'unset'}
      style={{ marginBlock: 8 }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div style={{ minHeight: 600 }}>{renderByStep(currentStep)}</div>
    </Modal>
  );
};

export default EditTicketModal;

import Utils from "common/Utils";
import SYSTEM_CONSTANTS from "common/constants";
import { ICreateLocation, IParams, IUpdateLocation } from "common/define-types";
import { Observable, throwError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import HttpClient from "./http-client";

const api = `${SYSTEM_CONSTANTS.API_URL}/Location`;

export const getLocationById = (id: string): Observable<any> => {
  return HttpClient.get(`${api}/${id}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const getAllLocations = (params?: IParams): Observable<any> => {
  if (params) {
    return HttpClient.get(`${api}?${Utils.getParamsString(params)}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(`${api}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};
export const getAllLocationsPageable = (params?: IParams): Observable<any> => {
  if (params) {
    return HttpClient.get(`${api}/allLocation?${Utils.getParamsString(params)}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(`${api}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};
export const createLocation = (body: ICreateLocation[]): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const updateLocation = (body: IUpdateLocation): Observable<any> => {
  return HttpClient.put(`${api}?id=${body.id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const deleteLocationById = (locationId: string): Observable<any> => {
  return HttpClient.delete(`${api}/${locationId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

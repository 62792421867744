import { Button, Spin } from "antd";
import { getMotorbikeTicketsByMotorbikeId } from "api/motorbike.api";
import { ISaveBrokeMotorbikeTicket } from "api/types/motorbikeTicket";
import Utils from "common/Utils";
import { IMotorbikeTicket } from "common/define-types";
import moment from "moment";
import { useEffect, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import { saveBrokeMotorbikeTicket } from "store/slice/MotorbikeSlice";
import { useDispatchRoot } from "store/store";

interface IProps {
  motorbikeId: string;
  readOnly?: boolean;
  handleClose: () => void;
}
export const BrokeMotorbikeCalendar = ({
  motorbikeId,
  readOnly = false,
  handleClose,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dateValues, setDateValues] = useState<any>([]);
  const [currentViewDate, setCurrentViewDate] = useState(new Date());
  const dispatch = useDispatchRoot();
  const [currentBrokeMotorbikeDates, setCurrentBrokeMotorbikeDates] =
    useState<any>([]);

  useEffect(() => {
    setIsLoading(true);
    const startDate = moment(currentViewDate).startOf('month');
    const endDate = moment(currentViewDate).endOf('month');
    getMotorbikeTicketsByMotorbikeId(motorbikeId, {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    }).subscribe((res) => {
      if (
        res &&
        res.dmuC_Motorbike_Tickets &&
        res.dmuC_Motorbike_Tickets.length > 0
      ) {
        const brokeMotorbikeDates = res.dmuC_Motorbike_Tickets
          .filter((mTicket: IMotorbikeTicket) => mTicket.status === 4)
          .map((mTicket: IMotorbikeTicket) => new Date(mTicket.date));
        setCurrentBrokeMotorbikeDates(brokeMotorbikeDates);
        setDateValues(brokeMotorbikeDates);
      }
      setIsLoading(false);
    }, err => {
      console.log(err);
      setIsLoading(false)
    }
    );
  }, [motorbikeId, currentViewDate]);

  const handleSaveBrokeMotorbikeDates = () => {
    const postValue: ISaveBrokeMotorbikeTicket[] = [];
    if (dateValues)
      dateValues
        // .map((dateObject: DateObject) => dateObject.toDate())
        .forEach((value: Date) => {
          postValue.push({
            motorId: motorbikeId,
            date: moment(value).startOf("day").add(7, "hours").toISOString(),
            isError: true,
          });
        });
    currentBrokeMotorbikeDates.forEach((currentDate: Date) => {
      if (
        dateValues
          // .map((dateObject: DateObject) => dateObject.toDate())
          .every(
            (dateValue: Date) =>
              !moment(dateValue)
                .startOf("day")
                .add(7, "hours")
                .isSame(moment(currentDate).startOf("day").add(7, "hours"))
          )
      ) {
        postValue.push({
          motorId: motorbikeId,
          date: Utils.convertToVNTimeZone(currentDate),
          isError: false,
        });
      }
    });
    //TODO: save broke motorbike
    if (postValue.length > 0) {
      dispatch(saveBrokeMotorbikeTicket(postValue));
    }
    handleClose();
  };

  const handleChangeDate = (values: any) => {
    const oldDateValues: Date[] = dateValues;
    const newDateValues: Date[] = values.map((value: DateObject) => {
      return value.toDate();
    });
    // if (
    //   currentBrokeMotorbikeDates.some((oldValue: Date) =>
    //     newDateValues.every(
    //       (newValue) =>
    //         !moment(newValue)
    //           .startOf("day")
    //           .isSame(moment(oldValue).startOf("day"))
    //     )
    //   )
    // ) {
    //   console.log("hii");

    //   return false;
    // }
    const diffDateValues = newDateValues.filter((newValue) =>
      oldDateValues.every(
        (oldValue) =>
          !moment(oldValue)
            .startOf("day")
            .isSame(moment(newValue).startOf("day"))
      )
    );
    const otherDiffDateValues = oldDateValues.filter((oldValue) =>
      newDateValues.every(
        (newValue) =>
          !moment(newValue)
            .startOf("day")
            .isSame(moment(oldValue).startOf("day"))
      )
    );
    if (
      diffDateValues.some((date) =>
        moment(date).endOf("day").isBefore(moment())
      ) ||
      otherDiffDateValues.some((date) =>
        moment(date).endOf("day").isBefore(moment())
      )
    ) {
      return false;
    }
    setDateValues(values.map((dateObj: DateObject) => dateObj.toDate()));
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 8,
        minHeight: 340,
      }}
    >
      <Spin tip={"Đang tải dữ liệu.."} spinning={isLoading}>
        <>
          <Calendar
            multiple
            value={dateValues}
            onChange={handleChangeDate}
            onMonthChange={(date) => setCurrentViewDate(date.toDate())}
            onYearChange={(date) => setCurrentViewDate(date.toDate())}
            mapDays={(object) => {
              const date = object.date.toDate();
              if (moment(date).endOf("day").isBefore(moment())) {
                return {
                  style: {
                    pointerEvents: "none",
                    color: "#d9d9d9",
                  },
                };
              }
            }}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" onClick={handleSaveBrokeMotorbikeDates}>
              Thêm
            </Button>
          </div>
        </>
      </Spin>
    </div>
  );
};

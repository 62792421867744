import {
  Typography,
  Input,
  Skeleton,
  Pagination,
  Button,
  PaginationProps,
} from "antd";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import styles from "../Suppliers.module.css";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { SupplierItem } from "./SuppilerItem";
import { ChangeEvent, useState } from "react";
import { RoleEnum } from "common/define-types";
import {
  fetchSuppliers,
  setAddSupplierOpen,
  setAscendingSort,
  setSuppliersResults,
} from "store/slice/SuppliersCategorySlice";

const TITLE = "nhà cung cấp";
export const SupplierList = () => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const selectIsLoading = useSelectorRoot(
    (state) => state.suppliersCategory.isLoading
  );
  const userRole = useSelectorRoot((state) => state.login.role);
  const selectSuppliers = useSelectorRoot(
    (state) => state.suppliersCategory.suppliers
  );
  const selectSupplierSelected = useSelectorRoot(
    (state) => state.suppliersCategory.supplierSelected
  );
  const selectSuppliersResults = useSelectorRoot(
    (state) => state.suppliersCategory.suppliersResults
  );
  const selectIsAscendingSort = useSelectorRoot(
    (state) => state.suppliersCategory.isAscendingSort
  );
  const dispatch = useDispatchRoot();

  const setIsAscendingSort = (value: boolean) => {
    dispatch(setAscendingSort(value));
  };

  const handleChangePagi: PaginationProps["onChange"] = (page) => {
    dispatch(
      fetchSuppliers({
        ...selectSuppliersResults,
        page: page,
      })
    );
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      //[2-4-2024][Phuc Thinh][No need to check for old params]
      dispatch(
        fetchSuppliers({
          ...selectSuppliersResults,
          search: query,
        })
      );
      dispatch(
        setSuppliersResults({
          ...selectSuppliersResults,
          search: query,
        })
      );
    }, 800);
    setTimer(timeoutId);
  };

  const openAddSupplier = () => {
    dispatch(setAddSupplierOpen(true));
  };

  return (
    <div className={styles.listContainer}>
      <div
        className={styles.listContainer_header}
        style={{ justifyContent: "space-between" }}
      >
        <Typography.Text strong style={{ fontSize: 20 }}>
          {`Danh sách ${TITLE}`}
        </Typography.Text>
        <div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled={userRole !== RoleEnum.ADMIN}
            onClick={openAddSupplier}
          >
            {`Thêm ${TITLE}`}
          </Button>
        </div>
      </div>
      <div className={styles.searchContainer}>
        <Button
          icon={
            selectIsAscendingSort ? (
              <SortAscendingOutlined style={{ fontSize: 18 }} />
            ) : (
              <SortDescendingOutlined style={{ fontSize: 18 }} />
            )
          }
          onClick={() => setIsAscendingSort(!selectIsAscendingSort)}
        >
          Sắp xếp
        </Button>
        <Input.Search
          allowClear
          style={{ flex: 1 }}
          placeholder={`Tìm kiếm ${TITLE}`}
          onChange={handleSearch}
        />
      </div>
      <div className={styles.listWrapper}>
        <div className={`${styles.listWrapper_List} customScroll`}>
          {selectIsLoading ? (
            <>
              <Skeleton paragraph={{ rows: 4 }} />
              <Skeleton paragraph={{ rows: 4 }} />
              <Skeleton paragraph={{ rows: 4 }} />
            </>
          ) : selectSuppliers && selectSuppliers.length > 0 ? (
            [...selectSuppliers]
              .sort((a, b) =>
                selectIsAscendingSort
                  ? a.name.localeCompare(b.name, "vn")
                  : b.name.localeCompare(a.name, "vn")
              )
              .map((supplier) => (
                <SupplierItem
                  supplier={supplier}
                  key={`Supplier-${supplier.id}`}
                  isActive={selectSupplierSelected?.id === supplier.id}
                />
              ))
          ) : (
            <EmptyHolder message={`Chưa có ${TITLE}`} />
          )}
        </div>
      </div>
      <div className={styles.listContainer_footer}>
        <Pagination
          total={selectSuppliersResults?.queryCount || selectSuppliers.length}
          pageSize={selectSuppliersResults?.pageSize || 25}
          current={selectSuppliersResults?.page || 1}
          showTotal={(total, range) =>
            `Hiển thị ${
              range[1] === 0 && range[1] - range[0] === 0
                ? 0
                : range[1] - range[0] + 1
            }/${total} ${TITLE}`
          }
          onChange={handleChangePagi}
        />
      </div>
    </div>
  );
};

import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import { ICreateTour, IParams, IUpdateTour } from "common/define-types";
import Utils from "common/Utils";

const api = `${SYSTEM_CONSTANTS.API_URL}/Tour`;

export const getAllTours = (): Observable<any> => {
  return HttpClient.get(`${api}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getAllToursPageable = (params?: IParams): Observable<any> => {
  return HttpClient.get(
    `${api}/PageableTour?${Utils.getParamsString(params || {})}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const createTour = (body: ICreateTour): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updateTour = (body: IUpdateTour): Observable<any> => {
  return HttpClient.put(`${api}/${body.id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const removeTour = (tourId: string): Observable<any> => {
  return HttpClient.delete(`${api}/${tourId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

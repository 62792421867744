import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Modal,
  PaginationProps,
  Table,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { checkTicketForUpdate } from "api/booking.api";
import { getAllExtraServices } from "api/extraService.api";
import {
  IExtraService,
  IGroupTicket,
  IPaymentMethodBooking,
  IsDriverNumberType,
  IsDriverType,
  ISelectedTicket,
  ITicketOperatorItem,
  ThuHoStatus,
  TourStatus,
} from "common/define-types";
import Utils from "common/Utils";
import moment from "moment";
import { useEffect, useState } from "react";
import { selectTicket } from "store/slice/BookingSlice";
import {
  checkInTicket,
  fetchOperatorTickets,
  saveBookingCollectionFee,
  saveBookingTicket,
  saveIssueCollectionFee,
  setCollectFeeBooking,
  setCollectIssueFeeTicket,
  uncheckInTicket,
} from "store/slice/TIcketOperatorSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import styles from "../TicketOperator.module.css";
import CollectionFeeModal from "./CollectionFeeModal";
import CollectIssueFeeModal from "./CollectIssueFee/CollectIssueFeeModal";
import EditTicketModal from "./EditTicket/EditTicketModal";
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const TicketOperatorTable = () => {
  const [extraServices, setExtraServices] = useState<IExtraService[]>([]);
  const { height } = useWindowDimensions();
  const INVALID_DATE = "0001-01-01T00:00:00";
  const [modal, modalContext] = Modal.useModal();
  const dispatch = useDispatchRoot();
  const selectLoading = useSelectorRoot(
    (state) => state.operatorTicket.isLoading
  );
  const selectOpTickets = useSelectorRoot(
    (state) => state.operatorTicket.operator_tickets
  );
  console.log(selectOpTickets);
  const selectTicketResult = useSelectorRoot(
    (state) => state.operatorTicket.ticketResult
  );
  const selectCollectFeeBooking = useSelectorRoot(
    (state) => state.operatorTicket.collectFeeBooking
  );
  const selectCollectIssueFeeTicket = useSelectorRoot(
    (state) => state.operatorTicket.collectIssueFeeTicket
  );
  const selectTicketSelected = useSelectorRoot(
    (state) => state.booking.selectedTicket
  );
  const selectComboTours = useSelectorRoot((state) => state.booking.comboTours);
  const [isOnlyVIew, setIsOnlyView] = useState<boolean>(false);
  useEffect(() => {
    getAllExtraServices().subscribe(
      (res) => {
        if (res && res.length) setExtraServices(res);
      },
      (err) => console.log(err)
    );
  }, []);

  // [5-12-2023][Phuc Thinh] [add checkbox for checkin ticket]
  // add handle click
  // [8-12-2023][Phuc Thinh]
  // prevent multiple click
  const handleClickCheckin = (
    record: ITicketOperatorItem,
    isChecked: boolean
  ) => {
    Utils.throttle(() => {
      modal.confirm({
        title: "Check-in",
        content: `Bạn có chắc chắn muốn ${
          isChecked ? "hủy check-in" : "check-in"
        }  cho vé ${record.name}`,
        onOk: () =>
          isChecked ? handleUnCheckin(record) : handleCheckin(record),
      });
    }, 500);
  };

  const handleCheckin = (record: ITicketOperatorItem) => {
    const tzOffset = new Date().getTimezoneOffset(); // offset in minutes
    dispatch(
      checkInTicket({
        ticketId: record.id,
        time: moment().subtract(tzOffset, "minutes").toISOString(),
      })
    );
  };
  const handleUnCheckin = (record: ITicketOperatorItem) => {
    dispatch(uncheckInTicket(record.id));
  };

  const Row = (props: RowProps) => {
    const id = props["data-row-key"];
    const foundRecord = selectOpTickets.find((ticket) => ticket.id === id);
    const hasNotPaidCollectionFee =
      foundRecord && foundRecord.statusThuHo === ThuHoStatus.NOT_YET;
    const style: React.CSSProperties = {
      ...props.style,
      backgroundColor: hasNotPaidCollectionFee ? "#F5222D33" : "#ffffff",
    };
    const className = hasNotPaidCollectionFee ? "collectionFee-row" : "";

    return <tr {...props} style={style} className={className} />;
  };
  const getIsDriver = (ticket: IGroupTicket) => {
    if (ticket.isDriver === IsDriverNumberType.DRIVER_PRICE)  return ticket.driverPrice?.isDriver === IsDriverType.YES
    return ticket.isDriver === IsDriverNumberType.YES;
  };
  //check in -> tên khách -> mã đại lý -> thu hộ -> sdt
  // -> ngày, loại, địa điểm, giờ đón -> ngày đi tour
  // -> ngày, loại, địa điểm, giờ về -> mã  vé -> mã booking
  // -> ng bán vé -> ngày đặt vé -> dịch vụ thêm
  const columns: ColumnsType<ITicketOperatorItem> = [
    // [5-12-2023][Phuc Thinh] [add checkbox for checkin ticket]
    // add column for checkin ticket
    {
      title: "Check-in",
      dataIndex: "tourStatus",
      width: 100,
      align: "center",
      render: (value, record) => {
        // checked in tourStatus
        const isTicketStarted = moment
          .utc(record.startDate)
          .endOf("day")
          .isBefore(moment().startOf("day"));
        const isChecked = [
          TourStatus.CheckIn,
          TourStatus.Running,
          TourStatus.EndTour,
        ].some((status) => status === value);
        return (
          <Checkbox
            checked={isChecked}
            // [8-12-2023][Phuc Thinh]
            // prevent multiple click
            onClick={(e) =>
              (!e.detail || e.detail === 1) &&
              handleClickCheckin(record, isChecked)
            }
            disabled={
              isTicketStarted ||
              [TourStatus.Running, TourStatus.EndTour].some(
                (status) => status === record.tourStatus
              )
            }
          />
        );
      },
    },
    {
      title: "Tên khách hàng",
      dataIndex: "fullName",
      width: 200,
    },
    {
      title: "Thời gian check-in",
      dataIndex: "checkInTime",
      width: 130,
      render: (value) =>
        !!value &&
        value !== INVALID_DATE &&
        moment(value).format(`${Utils.dateFormat} HH:mm:ss`),
    },
    {
      title: "Mã đại lý",
      dataIndex: "agentCode",
      width: 130,
    },
    {
      title: "Jasmine thu hộ",
      dataIndex: "jasmineKeepMoney",
      width: 130,
      render: (value, record) => (
        <>{!!value ? Utils.formatCurrency(value) : 0}</>
      ),
    },
    {
      title: "Loại tour",
      dataIndex: "comboTour",
      width: 130,
      render: (value, record) => {
        return `${record.comboTour?.name ?? ''} ${record.comboTour?.description ?? ''} - ${getIsDriver(record) ? 'Easy rider' : 'Self riding'}`
      },
    },
    {
      title: "Bus đón",
      dataIndex: "busStart",
      width: 200,
      render: (_, { busStartDate, busStartName, busTicketC1, routerStartName, stationStartName }) => {
        let startDate =
          !!busStartDate &&
          busStartDate !== INVALID_DATE &&
          moment(busStartDate).format(Utils.dateFormat);
        if(busTicketC1?.dmuC_Router_Station_Bus?.date) {
          startDate = moment(busTicketC1?.dmuC_Router_Station_Bus?.date).format('hh:mm ') + startDate
        }
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            
            {busStartName && <Typography.Text>{busStartName}</Typography.Text>}
            {stationStartName && <Typography.Text>{stationStartName}</Typography.Text>}
            {startDate && <Typography.Text>{startDate}</Typography.Text>}
            {routerStartName && <Typography.Text>{routerStartName}</Typography.Text>}
            {busTicketC1 && busTicketC1.linkGGMap && (
              <>
                <Typography.Link
                  ellipsis={true}
                  target="_blank"
                  href={`${busTicketC1.linkGGMap}`}
                  title={`${busTicketC1.linkGGMap}`}
                >
                  {busTicketC1.linkGGMap}
                </Typography.Link>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: "Ngày đi tour",
      dataIndex: "startDate",
      width: 130,
      render: (value) => !!value && moment(value).format(Utils.dateFormat),
    },
    {
      title: "Bus trả",
      dataIndex: "busEnd",
      width: 200,
      render: (_, { busEndDate, busEndName, busTicketC2, routerEndName, stationEndName }) => {
        let endDate =
          !!busEndDate &&
          busEndDate !== INVALID_DATE &&
          moment(busEndDate).format(Utils.dateFormat);
        if(busTicketC2?.dmuC_Router_Station_Bus?.date) {
          endDate = moment(busTicketC2?.dmuC_Router_Station_Bus?.date).format('hh:mm ') + endDate
        }
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            
            {busEndName && <Typography.Text>{busEndName}</Typography.Text>}
            {stationEndName && <Typography.Text>{stationEndName}</Typography.Text>}
            {endDate && <Typography.Text>{endDate}</Typography.Text>}
            {routerEndName && <Typography.Text>{routerEndName}</Typography.Text>}
            
            {busTicketC2 && busTicketC2.vitriDon && (
              <>
                <Typography.Text>{`Ghi chú: ${busTicketC2.vitriDon}`}</Typography.Text>
              </>
            )}
          </div>
        );
      },
    },
    // {
    //   title: "Vị trí Bus đón chiều về",
    //   width: 200,
    //   render: (_, record) => {
    //     const { busTicketC2 } = record;
    //     if (!busTicketC2) return "";
    //     return (
    //       <div style={{ display: "flex", flexDirection: "column" }}>
    //         <Typography.Text>{busTicketC2.vitriDon}</Typography.Text>
    //         <Typography.Link target="_blank" href={`${busTicketC2.linkGGMap}`}>
    //           {busTicketC2.linkGGMap}
    //         </Typography.Link>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Mã vé",
      dataIndex: "name",
      width: 150,
      render: (value, record) => {
        return record.daThanhToanIssue ? (
          <>{value}</>
        ) : (
          <Tooltip title="Thu tiền phát sinh">
            <Typography.Link
              underline
              onClick={() => dispatch(setCollectIssueFeeTicket(record))}
              style={{
                cursor: "pointer",
                color: "#F9A000",
              }}
            >
              {value}
            </Typography.Link>
          </Tooltip>
        );
      },
    },
    {
      title: "Mã booking",
      dataIndex: "bookingCode",
      width: 150,
      render: (value, record) => {
        return (
          <Typography.Link
            underline
            onClick={() => dispatch(setCollectFeeBooking(record))}
            style={{ cursor: "pointer" }}
          >
            {value}
          </Typography.Link>
        );
      },
    },
    {
      title: "Ngày đặt vé",
      dataIndex: "createdTime",
      width: 130,
      render: (value) => !!value && moment(value).format(Utils.dateFormat),
    },
    {
      title: "Dịch vụ thêm",
      width: 150,
      render(_, record) {
        if (
          record.datA_TOUR_CHHangHoaDichVuKhacs &&
          record.datA_TOUR_CHHangHoaDichVuKhacs.length > 0
        ) {
          const filteredService = extraServices.filter((service) => {
            return record.datA_TOUR_CHHangHoaDichVuKhacs?.some(
              (item) => item.dmuC_CHHangHoaDichVuKhacId === service.id
            );
          });
          return (
            <>{filteredService.map((service) => service.name).join(", ")}</>
          );
        }
        return <></>;
      },
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      width: 140,
    },
    {
      title: "Người bán vé",
      ellipsis: true,
      width: 130,
      render: (_, record) => (
        <>{(record.sellerLastName || "") + (record.sellerFirstName || "")}</>
      ),
    },
    {
      title: "",
      width: 70,
      fixed: "right",
      render(_, record) {
        // [7-12-2023][Phuc Thinh][Feature/14861]
        // disable edit button when ticket started
        // const isTicketStarted = moment
        //   .utc(record.startDate)
        //   .endOf("day")
        //   .isBefore(moment().startOf("day"));
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* {isTicketStarted ||
            [TourStatus.Running, TourStatus.EndTour].some(
              (status) => status === record.tourStatus
            ) ? (
              <Tooltip title="Vé đã bắt đầu">
                <Button
                  icon={
                    <EditOutlined
                      style={{
                        color: "grey",
                      }}
                    />
                  }
                  type="text"
                  title="Chỉnh sửa vé"
                  disabled={true}
                />
              </Tooltip>
            ) : ( */}
            <Button
              icon={
                <EditOutlined
                  style={{
                    color: "rgb(24, 144, 255)",
                  }}
                />
              }
              type="text"
              title="Chỉnh sửa vé"
              onClick={() => {
                handleUpdateTicket(record);
              }}
            />
            {/* )} */}
          </div>
        );
      },
    },
  ];

  const handleUpdateTicket = (record: ITicketOperatorItem) => {
    const foundComboTour = selectComboTours.find(
      (comboTour) => comboTour.Id === record.comboTourId
    );
    if (!foundComboTour) return;
    dispatch(
      selectTicket({
        ...record,
        comboTour: foundComboTour,
      })
    );
    checkTicketForUpdate({ ticketId: record.id }).subscribe(
      (res) => {
        console.log('checkTicketForUpdate', res)
        setIsOnlyView(res !== true);
        // if (res && res === true) {
          
        // } else {
        //   notification.error({
        //     message:
        //       "Hiện tại không được phép update vé ( Từ trước 24h đến ngày xuất phát của vé mới được sửa )",
        //   });
        // }
      },
      (err) => {
        console.log(err);
        notification.error({
          message: "Có lỗi xảy ra",
        });
      }
    );
  };

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectTicketResult) {
      dispatch(
        fetchOperatorTickets({
          ...selectTicketResult,
          page,
        })
      );
    }
  };

  const handleUpdateBookingCollectFee = (
    paymentMethodBookings: IPaymentMethodBooking[]
  ) => {
    if (selectCollectFeeBooking) {
      dispatch(
        saveBookingCollectionFee({
          bookingId: selectCollectFeeBooking.bookingId,
          payload: paymentMethodBookings,
          ticketId: selectCollectFeeBooking.id,
        })
      );
    }
  };

  const handleUpdateIssueFee = (
    paymentMethodBookings: IPaymentMethodBooking[]
  ) => {
    if (selectCollectIssueFeeTicket) {
      dispatch(
        saveIssueCollectionFee({
          paymentMethods: paymentMethodBookings,
          ticketId: selectCollectIssueFeeTicket.id,
        })
      );
    }
  };

  const handleUpdateBookingTicket = (
    ticketUpdated: ISelectedTicket,
    paymentMethodBookingsConnect?: IPaymentMethodBooking[],
    paymentMethodBookingsDisable?: IPaymentMethodBooking[]
  ) => {
    if (selectTicketSelected) {
      dispatch(
        saveBookingTicket({
          paymentMethodBookingsConnect,
          paymentMethodBookingsDisable,
          ticketsUpdate: ticketUpdated,
        })
      );
    }
  };

  return (
    <>
      {modalContext}
      {!!selectTicketSelected && (
        // [06-12-2023][Phuc Thinh] [Feature/14861]
        // Add Edit Ticket Component
        // [15-12-2023][Phuc Thinh] [Feature/14861]
        // Add API for Edit Ticket
        <EditTicketModal
          open={!!selectTicketSelected}
          isOnlyView={isOnlyVIew}
          handleClose={() => dispatch(selectTicket(null))}
          currentTicket={selectOpTickets.find(
            (ticket) => ticket.id === selectTicketSelected.id
          )}
          handleSubmit={(...args) => handleUpdateBookingTicket(...args)}
        />
      )}
      {selectCollectFeeBooking && (
        <CollectionFeeModal
          collectFeeBooking={selectCollectFeeBooking}
          handleClose={() => dispatch(setCollectFeeBooking(null))}
          handleSubmit={(payload) => handleUpdateBookingCollectFee(payload)}
        />
      )}
      {selectCollectIssueFeeTicket && (
        <CollectIssueFeeModal
          collectIssueFeeTicket={selectCollectIssueFeeTicket}
          handleClose={() => dispatch(setCollectIssueFeeTicket(null))}
          handleSubmit={(payload) => handleUpdateIssueFee(payload)}
        />
      )}
      <Table
        dataSource={selectOpTickets}
        columns={columns}
        className={`${styles.table} ticketTable horizontalDragScroll customScrollTable`}
        scroll={{ x: 1050, y: height - 380 }}
        components={{
          body: {
            row: Row,
          },
        }}
        pagination={{
          current: selectTicketResult ? selectTicketResult.page : 1,
          pageSize: selectTicketResult ? selectTicketResult.pageSize : 10,
          total: selectTicketResult ? selectTicketResult.queryCount : 0,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
        onChange={(_, filters) => console.log(filters)}
        rowKey={"id"}
        loading={selectLoading}
        //[1-12-2023] [Phuc Thinh]
        //Thêm phần summary cho table
        summary={(pageData) => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <Typography.Text strong>Tổng số khách </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="center">
                  <Typography.Text>
                    {selectTicketResult?.queryCount}
                  </Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </>
  );
};

export default TicketOperatorTable;

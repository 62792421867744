import { Form, Modal, ModalProps, Spin, Typography } from 'antd';
import { getPriceBusForAgent } from 'api/agent.api';
import Utils from 'common/Utils';
import { IPriceDetail, IRouterStationBus } from 'common/define-types';
import { DiscountForm, DiscountType } from 'components/Form/DiscountForm';
import { useEffect, useState } from 'react';
import { useSelectorRoot } from 'store/store';

interface DiscountTourModal extends Omit<ModalProps, 'onOk'> {
  selectedBus: IRouterStationBus;
  onOk: ({ discountValue }: { discountValue: IPriceDetail }) => void;
}
export const DiscountBusModal = ({
  selectedBus,
  onOk,
  ...rest
}: DiscountTourModal) => {
  const [discountDetail, setDiscountDetail] = useState<IPriceDetail | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [discountForm] = Form.useForm();
  const selectDiscountAgent = useSelectorRoot(
    (state) => state.agents.discountAgent
  );
  const selectLocations = useSelectorRoot((state) => state.booking.locations);
  const fromLocation = selectLocations.find(
    (location) =>
      location.id ===
      selectedBus.dmuC_Router_Station?.dmuC_Router.localtionStartId
  );
  const toLocation = selectLocations.find(
    (location) =>
      location.id ===
      selectedBus.dmuC_Router_Station?.dmuC_Router.localtionEndId
  );

  const handleSubmit = async () => {
    if (discountDetail) {
      const discountFormValue = await discountForm
        .validateFields()
        .catch((err) => console.log(err));
      onOk({
        discountValue: {
          ...discountDetail,
          discountValue:
            discountFormValue.type === DiscountType.BY_VALUE
              ? discountFormValue.amount
              : 0,
          discountPercent:
            discountFormValue.type === DiscountType.BY_PERCENT
              ? discountFormValue.amount
              : 0,
        },
      });
    }
  };

  useEffect(() => {
    if (selectedBus && selectDiscountAgent) {
      setLoading(true);
      getPriceBusForAgent(selectDiscountAgent.id, {
        routerBusId: selectedBus.id,
      }).subscribe(
        (res) => {
          if (res) {
            setDiscountDetail(res);
          } else {
            setDiscountDetail(null);
          }
        },
        (err) => {
          console.log(err);
          setLoading(false);
        },
        () => setLoading(false)
      );
    }
  }, [selectedBus, selectDiscountAgent, discountForm]);

  useEffect(() => {
    if (discountDetail) {
      const isDiscountValue = discountDetail.discountValue !== 0;
      const discountType = isDiscountValue
        ? DiscountType.BY_VALUE
        : DiscountType.BY_PERCENT;
      if (discountType === DiscountType.BY_PERCENT) {
        discountForm.setFields(
          Utils.objectToAntFormData({
            type: discountType,
            amount: discountDetail.discountPercent,
          })
        );
      } else if (discountType === DiscountType.BY_VALUE) {
        discountForm.setFields(
          Utils.objectToAntFormData({
            type: discountType,
            amount: discountDetail.discountValue,
          })
        );
      }
    } else {
      discountForm.resetFields();
    }
  }, [discountDetail, discountForm]);

  return (
    <Modal {...rest} onOk={handleSubmit}>
      <Spin spinning={loading}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography.Text strong>{`${fromLocation?.name || ''} → ${
            toLocation?.name || ''
          }`}</Typography.Text>
          <div>
            <Typography.Text strong>
              {selectedBus.dmuC_Bus.name}
            </Typography.Text>
            <Typography.Text>{` - ${
              selectedBus.dmuC_Router_Station?.dmuC_Station.name || ''
            }`}</Typography.Text>
            <Typography.Text>{` - ${Utils.formatCurrency(
              selectedBus.price
            )}`}</Typography.Text>
          </div>
        </div>
        <DiscountForm form={discountForm} />
      </Spin>
    </Modal>
  );
};

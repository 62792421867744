import { Button, Spin, Tabs, Typography } from 'antd';
import {
  // GroupItemType,
  IGroupJobItem,
  ITour,
  JobTourExtraType,
  MoveJobArgs,
} from 'common/define-types';
import { JobTourCard } from './JobTourCard';
import styles from '../TourCategory.module.css';
import { useCallback } from 'react';
import { useDispatchRoot, useSelectorRoot } from 'store/store';
import {
  moveJob,
  removeJob,
  saveJobTours,
  setSelectedOrdinalDay,
} from 'store/slice/TourCategorySlice';
import { EmptyHolder } from 'components/Empty/EmptyHolder';
// import Utils from "common/Utils";

interface JobTourTabProps {
  tour: ITour;
}

export const JobTourTab = ({ tour }: JobTourTabProps) => {
  const dispatch = useDispatchRoot();
  const { isFetchingJobTour } = useSelectorRoot(
    (state) => state.tourCategory.loading
  );
  const selectedOrdinalDay = useSelectorRoot(
    (state) => state.tourCategory.selectedOrdinalDay
  );
  const selectJobToursGroupByDay = useSelectorRoot(
    (state) => state.tourCategory.jobToursGroupByDay
  );
  const selectJobToursByTour = useSelectorRoot(
    (state) => state.tourCategory.jobToursByTour
  );

  const currentJobTours = selectJobToursGroupByDay[selectedOrdinalDay] || [];

  const jobToursDays = new Array(tour.countDay)
    .fill(null)
    .map((_, index) => ({
      label: `Ngày ${index + 1}`,
      key: `${index + 1}`,
    }))
    .concat([
      {
        label: `Extra - Ngày ${tour.countDay}`,
        key: `extra-${tour.countDay}`,
      },
      {
        label: `Extra - Ngày ${tour.countDay + 1}`,
        key: `extra-${tour.countDay + 1}`,
      },
    ]);

  const handleMoveJob = useCallback((args: MoveJobArgs) => {
    dispatch(moveJob(args));

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const handleDeleteJob = useCallback((jobTourId: string) => {
    dispatch(removeJob({ jobTourId }));

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const handleChangeOrdinalDay = useCallback((day: string) => {
    dispatch(setSelectedOrdinalDay(day));

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const handleSaveJobTours = useCallback(() => {
    let groupItemsConnects: IGroupJobItem[] = [];
    let groupItemsDisable: IGroupJobItem[] = [];
    let newJobToursList: IGroupJobItem[] = [];
    let key: any;
    for (key in selectJobToursGroupByDay) {
      if (
        selectJobToursGroupByDay[key] !== null &&
        selectJobToursGroupByDay[key] !== undefined
      ) {
        if (key.includes('extra')) {
          newJobToursList = [
            ...newJobToursList,
            ...selectJobToursGroupByDay[key].map((groupJob) => ({
              ...groupJob,
              extraType: JobTourExtraType.Extra,
            })),
          ];
        } else {
          newJobToursList = [
            ...newJobToursList,
            ...selectJobToursGroupByDay[key].map((groupJob) => ({
              ...groupJob,
              extraType: JobTourExtraType.Normal,
            })),
          ];
        }
      }
    }
    groupItemsDisable = selectJobToursByTour.filter(
      (jobByTour) =>
        !newJobToursList.some((newJob) => {
          return newJob.id === jobByTour.id;
        }) || jobByTour.day === 0
    );
    groupItemsConnects = newJobToursList.map((newJob, index) => {
      const newStt = (index + 1).toString();
      return {
        ...newJob,
        stt: newStt,
      };
    });
    // const jobToursDisable = disableJobTours.reduce(
    //   (prev: string[], jobTour) => [
    //     ...prev,
    //     ...jobTour.jobs.map((job) => job.id),
    //   ],
    //   []
    // );
    const groupItemIdsDisable = groupItemsDisable.map(
      (groupItemDisable) => groupItemDisable.id
    );
    console.log({
      groupItemsConnects,
      groupItemIdsDisable,
    });
    dispatch(
      saveJobTours({
        groupItemsConnects,
        groupItemIdsDisable,
        tourId: tour.id,
      })
    );
  }, [selectJobToursGroupByDay, selectJobToursByTour]);

  return (
    <div className={`${styles.jobTourTab} ${styles.tabContainer}`}>
      <div className={`${styles.spaceBetween}`}>
        <Typography.Text
          strong
          className={styles.tabTitle}
        >{`Danh sách điểm đến của Loại Tour ${tour.name}`}</Typography.Text>
      </div>
      <Tabs
        tabPosition='left'
        style={{ height: '100%', maxHeight: 310 }}
        destroyInactiveTabPane
        items={jobToursDays.map((day) => ({
          ...day,
          children: (
            <Spin spinning={isFetchingJobTour}>
              <div className={`${styles.jobTourList} customScroll`}>
                {currentJobTours.length ? (
                  currentJobTours.map((job, index) => (
                    <JobTourCard
                      job={job}
                      key={job.id}
                      index={index}
                      moveJob={handleMoveJob}
                      deleteJob={handleDeleteJob}
                    />
                  ))
                ) : (
                  <EmptyHolder message='Trống' />
                )}
              </div>
            </Spin>
          ),
        }))}
        onChange={(activeKey) => handleChangeOrdinalDay(activeKey)}
        activeKey={selectedOrdinalDay.toString()}
      />
      <div className={styles.buttonsContainer}>
        <Button type='primary' onClick={handleSaveJobTours}>
          Lưu
        </Button>
      </div>
    </div>
  );
};

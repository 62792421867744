import { Form, FormInstance, Input, Radio, Select } from "antd";
import Utils from "common/Utils";
import { IJobItem, JobTicketType, JobTypeItem } from "common/define-types";
import { MoneyInput } from "components/Input/MoneyInput";
import { useEffect } from "react";

interface JobItemFormProps {
  form?: FormInstance<any>;
  editingJobItem?: IJobItem;
}

const types = [
  {
    label: "Nhân viên",
    value: JobTicketType.Driver,
    typeItems: [
      JobTypeItem.Food,
      JobTypeItem.SightseeingTickets,
      JobTypeItem.Room,
    ],
  },
  {
    label: "Khách",
    value: JobTicketType.Tickets,
    typeItems: [
      JobTypeItem.Food,
      JobTypeItem.SightseeingTickets,
      JobTypeItem.Room,
    ],
  },
  {
    label: "Vé xe",
    value: JobTicketType.Motor,
    typeItems: [JobTypeItem.DriverTicket],
  },
  {
    label: "Đổ xăng",
    value: JobTicketType.Oil,
    typeItems: [JobTypeItem.Petro],
  },
];

export const JobItemForm = ({ form, editingJobItem }: JobItemFormProps) => {
  const [defaultForm] = Form.useForm();
  const jobItemForm = form || defaultForm;
  useEffect(() => {
    if (editingJobItem) {
      jobItemForm.setFields(
        Utils.objectToAntFormData({
          ...editingJobItem,
        })
      );
    }
  }, [editingJobItem, jobItemForm]);

  const typeItem = Form.useWatch("typeItem", jobItemForm);

  const handleChange = (changedValues: any, values: any) => {
    if (changedValues["typeItem"]) {
      jobItemForm.setFieldValue("type", null);
    }
  };

  return (
    <Form
      form={jobItemForm}
      labelAlign="left"
      className="noMargin_form-item"
      labelCol={{ span: 24 }}
      onValuesChange={handleChange}
    >
      <Form.Item
        label="Loại"
        name={"typeItem"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Select
          options={[
            {
              label: "Xăng",
              value: JobTypeItem.Petro,
            },
            {
              label: "Ăn uống",
              value: JobTypeItem.Food,
            },
            {
              label: "Vé xe",
              value: JobTypeItem.DriverTicket,
            },
            {
              label: "Tham quan",
              value: JobTypeItem.SightseeingTickets,
            },
            {
              label: "Phòng ngủ",
              value: JobTypeItem.Room,
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Đối tượng"
        name={"type"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Select
          options={
            typeItem
              ? types
                  .filter((type) => type.typeItems.includes(typeItem))
                  .map((type) => ({
                    label: type.label,
                    value: type.value,
                  }))
              : []
          }
          notFoundContent={"Chọn loại trước"}
        />
      </Form.Item>
      <Form.Item
        label="Tên"
        name={"name"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Giá trả NCC" name={"rate"}>
        <MoneyInput min={0} />
      </Form.Item>
      <Form.Item label="Giá bán" name={"giaBan"}>
        <MoneyInput min={0} />
      </Form.Item>
      <Form.Item label="Giá thu đại lý" name={"giaDaiLy"}>
        <MoneyInput min={0} />
      </Form.Item>
      <Form.Item label="Công nợ" name={"typeCN"}>
        <Radio.Group options={[
          {
            label: 'Công nợ',
            value: 0,
          },
          {
            label: 'Thanh toán luôn',
            value: 1,
          },
        ]}></Radio.Group>
      </Form.Item>
    </Form>
  );
};

import { Modal, Tabs, TabsProps } from "antd";
import { DiscountTour } from "./DiscountTour";
import { DiscountBus } from "./DiscountBus";
// import styles from "../Agents.module.css";

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
}
const items: TabsProps["items"] = [
  {
    key: "1",
    label: "Chiết khấu Tour",
    children: <DiscountTour />,
  },
  {
    key: "2",
    label: "Chiếu khấu Bus",
    children: <DiscountBus />,
  },
];

export const AgentDiscountModal = ({ isOpen, handleClose }: IProps) => {
  return (
    <Modal
      onCancel={handleClose}
      open={isOpen}
      centered
      style={{ margin: 10 }}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Tabs items={items} />
    </Modal>
  );
};

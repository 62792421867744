import { PlusOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Typography, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { ChangeEvent, useEffect, useState } from "react";
import { addLocation, fetchLocations, setAddModalOpen, setLocationFilterValue } from "store/slice/LocationSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "./Location.module.css";
import { LocationForm } from "./components/LocationForm";
import { LocationTable } from "./components/LocationTable";
export const Location = () => {
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const selectAddModalOpen = useSelectorRoot(
    (state) => state.location.addModalOpen
  );
  const selectErrMsg = useSelectorRoot((state) => state.location.errMsg);
  const selectLocationFilterValue = useSelectorRoot(
    (state) => state.location.filterValue
  );
  const [addLocationForm] = useForm();
  const dispatch = useDispatchRoot();
  useEffect(() => {
    dispatch(fetchLocations({ pageSize: 25 }));
  }, [dispatch]);
  useEffect(() => console.log(selectAddModalOpen), [selectAddModalOpen]);
  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({
        message: selectErrMsg,
      });
    }
  }, [selectErrMsg]);
  const handleAddLocation = async () => {
    const locationValue = await addLocationForm
      .validateFields()
      .catch((err) => {
        console.log(err);
        notification.error({ message: "Vui lòng nhập đủ thông tin" });
      });
    if (!locationValue) return;
    dispatch(
      addLocation({
        ...locationValue,
      })
    );

  };
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(
        setLocationFilterValue({
          ...selectLocationFilterValue,
          search: e.target.value,
        })
      );
    }, 1000);
    setTimer(timeoutId);
  };
  return (
    <div className={styles.locationContainer}>
      <div className={styles.tableHeader}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            flexDirection: "row",
          }}
        >
          <Typography.Text strong style={{ fontSize: 20 }}>
            Danh sách vị trí
          </Typography.Text>
          <div>
            <Input.Search
              placeholder="Tìm kiếm vị trí"
              onChange={handleSearch}
              allowClear
            />
          </div>
        </div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            dispatch(setAddModalOpen(true));
            addLocationForm.resetFields();
          }}
        >
          Thêm mới vị trí
        </Button>
      </div>
      <LocationTable />
      {selectAddModalOpen ? (
        <Modal
          title={"Thêm mới vị trí"}
          open={selectAddModalOpen}
          onCancel={() => dispatch(setAddModalOpen(false))}
          onOk={handleAddLocation}
          cancelText="Hủy bỏ"
          okText="Thêm mới"
        >
          <LocationForm form={addLocationForm} />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

import { Select, Typography, notification } from "antd";
import { getAllComboTours } from "api/comboTour.api";
import Utils from "common/Utils";
import {
  ComboTourStatus,
  ComboTourType,
  IComboTour,
  IComboTourParams,
} from "common/define-types";
import moment from "moment";
import { useEffect, useState } from "react";

interface IProps {
  value?: string;
  onChange?: (arg: IComboTour | undefined) => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  allowClear?: boolean;
  testId: string;
  defaultOptions?: IComboTour[];
  filterValue?: IComboTourParams;
  acceptTypes?: ComboTourType[];
  acceptStatus?: ComboTourStatus[];
}
const ComboTourSelect = ({
  defaultOptions,
  value,
  onChange = () => {},
  style,
  readOnly = false,
  allowClear = false,
  testId,
  acceptTypes,
  acceptStatus,
  filterValue = {
    dateTime: moment.utc().toISOString(),
  },
}: IProps) => {
  const [comboTours, setComboTours] = useState<IComboTour[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (defaultOptions) return;
    setLoading(true);
    getAllComboTours({
      dateTime: filterValue.dateTime,
    }).subscribe(
      (res) => {
        if (res) {
          const comboTours: IComboTour[] = res;
          setComboTours(Utils.sortComboTour(comboTours));
        }
      },
      (err) => {
        notification.error({ message: "Không thể lấy danh sách tour" });
        setComboTours([]);
        setLoading(false);
      },
      () => {
        // finally
        setLoading(false);
      }
    );
  }, [defaultOptions, filterValue, acceptTypes]);

  const selectOptions = (defaultOptions || comboTours).filter((combo) => {
    if (acceptTypes || acceptStatus) {
      if (acceptTypes) {
        return acceptTypes.some((type) => type === combo.Type);
      }
      if (acceptStatus) {
        return acceptStatus.some((status) => status === combo.Status);
      }
    }
    return true;
  });
  return (
    <Select
      options={selectOptions.map((option) => ({
        label: (
          <Typography.Text
            title={`${option.Title} ${option.SubTitle} ${option.Description}`}
          >
            {`${option.Title} ${option.SubTitle} `}
            <Typography.Text style={{ color: "grey", fontSize: 14 }} italic>
              {option.Description}
            </Typography.Text>
          </Typography.Text>
        ),
        value: option.Id,
      }))}
      data-test-id={testId}
      loading={loading}
      value={value}
      style={style}
      onChange={(value) =>
        onChange(selectOptions.find((option) => option.Id === value))
      }
      optionLabelProp="label"
      allowClear={allowClear}
      disabled={readOnly}
    />
  );
};

export default ComboTourSelect;

import { Form, FormInstance, Input, InputNumber, Select } from "antd";
import { DiscountForm, DiscountType } from "components/Form/DiscountForm";
import { useCallback, useEffect } from "react";
import styles from "../Suppliers.module.css";
import { IDiscountDetail, JobDiscountType } from "common/define-types";
import Utils from "common/Utils";
interface JobDiscountFormProps {
  forms: {
    jobDiscountForm: FormInstance<any>;
    discountForm: FormInstance<any>;
  };
  discountValue?: IDiscountDetail | null;
}
export const JobDiscountForm = ({
  forms,
  discountValue,
}: JobDiscountFormProps) => {
  const { jobDiscountForm, discountForm } = forms;

  const discountType = Form.useWatch("type", jobDiscountForm);

  const renderFormByType = useCallback(() => {
    switch (discountType) {
      case JobDiscountType.ChietKhauNumberGroup:
        return (
          <>
            <Form.Item
              name={"valueFrom"}
              label={`Với mỗi số lượng Group`}
              rules={[
                {
                  required: true,
                  message: "Không được bỏ trống",
                },
              ]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
            <DiscountForm form={discountForm} />
          </>
        );
      case JobDiscountType.ChietKhauNumberPeople:
        return (
          <>
            <Form.Item label={"Số lượng vé"} labelCol={{ span: 24 }}>
              <div className={styles.formItemGroup}>
                <Form.Item
                  name={"valueFrom"}
                  label={"Từ"}
                  labelCol={{ span: 6 }}
                  rules={[
                    {
                      required: true,
                      message: "Không được bỏ trống",
                    },
                  ]}
                >
                  <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name={"valueTo"}
                  label={"Đến"}
                  labelCol={{ span: 6 }}
                  rules={[
                    {
                      required: true,
                      message: "Không được bỏ trống",
                    },
                  ]}
                >
                  <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </Form.Item>
            <DiscountForm form={discountForm} />
          </>
        );

      case JobDiscountType.ChietKhauBill:
        return (
          <>
            <Form.Item
              labelCol={{ span: 8 }}
              name={"valueFrom"}
              label={`Bill giá trị từ`}
              rules={[
                {
                  required: true,
                  message: "Không được bỏ trống",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 8 }}
              name={"valueTo"}
              label={`Bill giá trị đến`}
              rules={[
                {
                  required: true,
                  message: "Không được bỏ trống",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <DiscountForm form={discountForm} />
          </>
        );

      default:
        break;
    }
  }, [discountType, discountForm]);

  useEffect(() => {
    if (discountValue) {
      switch (discountValue.type) {
        case JobDiscountType.ChietKhauNumberGroup:
          const isDiscountBySoLuongChietKhau =
            discountValue.soLuongChietKhau &&
            discountValue.soLuongChietKhau > 0;
          jobDiscountForm.setFields(Utils.objectToAntFormData(discountValue));
          discountForm.setFields(
            Utils.objectToAntFormData({
              type: isDiscountBySoLuongChietKhau
                ? DiscountType.BY_VALUE
                : DiscountType.BY_PERCENT,
              amount: isDiscountBySoLuongChietKhau
                ? discountValue.soLuongChietKhau
                : discountValue.discountPercent || 0,
            })
          );
          break;
        case JobDiscountType.ChietKhauNumberPeople:
          const isDiscountBySoLuongChietKhauNumberPeople =
            discountValue.soLuongChietKhau &&
            discountValue.soLuongChietKhau > 0;
          jobDiscountForm.setFields(Utils.objectToAntFormData(discountValue));
          discountForm.setFields(
            Utils.objectToAntFormData({
              type: isDiscountBySoLuongChietKhauNumberPeople
                ? DiscountType.BY_VALUE
                : DiscountType.BY_PERCENT,
              amount: isDiscountBySoLuongChietKhauNumberPeople
                ? discountValue.soLuongChietKhau
                : discountValue.discountPercent || 0,
            })
          );
          break;

        case JobDiscountType.ChietKhauBill:
          const isDiscountByValue =
            discountValue.discountValue && discountValue.discountValue > 0;
          jobDiscountForm.setFields(Utils.objectToAntFormData(discountValue));
          discountForm.setFields(
            Utils.objectToAntFormData({
              type: isDiscountByValue
                ? DiscountType.BY_VALUE
                : DiscountType.BY_PERCENT,
              amount: isDiscountByValue
                ? discountValue.discountValue
                : discountValue.discountPercent || 0,
            })
          );
          break;

        default:
          break;
      }
    }
  }, [discountValue, jobDiscountForm, discountForm]);
  return (
    <Form
      form={jobDiscountForm}
      labelAlign="left"
      className="noMargin_form-item"
      initialValues={{
        type: JobDiscountType.ChietKhauNumberGroup,
      }}
    >
      <Form.Item
        label={"Loại chiết khấu"}
        name={"type"}
        style={{ paddingBottom: 8 }}
      >
        <Select
          options={[
            {
              label: "Theo số lượng Group",
              value: JobDiscountType.ChietKhauNumberGroup,
            },
            {
              label: "Theo số lượng vé",
              value: JobDiscountType.ChietKhauNumberPeople,
            },
            {
              label: "Theo giá trị bill",
              value: JobDiscountType.ChietKhauBill,
            },
          ]}
        />
      </Form.Item>
      {renderFormByType()}
    </Form>
  );
};

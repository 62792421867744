import {
  Button,
  Form,
  FormInstance,
  Input,
  Popconfirm,
  Radio,
  Space,
  Spin,
  Typography,
} from "antd";
import Utils from "common/Utils";
import { IExtraService } from "common/define-types";
import { useEffect, useRef } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { MoneyInput } from "components/Input/MoneyInput";
import styles from "../ExtraService.module.css";
import { useSelectorRoot } from "store/store";

interface ExtraServiceFormProps {
  form?: FormInstance<any>;
  editingExtraService?: IExtraService | null;
}

export enum ServiceType {
  NORMAL = 0,
  HANGHOA = 1,
}

const ServiceTypes = [
  {
    label: "Loại thường",
    value: ServiceType.NORMAL,
  },
  {
    label: "Loại hàng hóa",
    value: ServiceType.HANGHOA,
  },
];
export const ExtraServiceForm = ({
  form,
  editingExtraService,
}: ExtraServiceFormProps) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const [defaultForm] = Form.useForm();
  const serviceForm = form || defaultForm;
  const isFetchingHangHoas = useSelectorRoot(
    (state) => state.extraServiceCategory.isFetchingHangHoa
  );
  const extraService_hangHoas = useSelectorRoot(
    (state) => state.extraServiceCategory.editingExtrarService_hangHoas
  );
  const serviceTypeOption = Form.useWatch("itemType", serviceForm);

  // parse data into form
  useEffect(() => {
    if (editingExtraService) {
      serviceForm?.setFields(Utils.objectToAntFormData(editingExtraService));
      serviceForm?.setFieldValue("hangHoas", extraService_hangHoas);
      if (extraService_hangHoas?.length) {
        serviceForm?.setFieldValue("itemType", ServiceType.HANGHOA);
      }
    }
  }, [editingExtraService, serviceForm, extraService_hangHoas]);

  return (
    <Spin spinning={isFetchingHangHoas}>
      <Form
        form={serviceForm}
        labelCol={{ span: 24 }}
        className="noMargin_form-item"
      >
        <Form.Item
          name={"code"}
          label={"Mã dịch vụ"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Input disabled={!!editingExtraService} />
        </Form.Item>
        <Form.Item
          name={"name"}
          label={"Tên dịch vụ"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={"description"} label={"Ghi chú"}>
          <Input />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 8 }}
          name={"itemType"}
          label={"Loại"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Radio.Group options={ServiceTypes} />
        </Form.Item>
        {serviceTypeOption === 1 && (
          <Form.List name={"hangHoas"}>
            {(fields, { add, remove }) => (
              <div>
                <div
                  className={styles.spaceBetween}
                  style={{ marginBottom: 16 }}
                >
                  <Typography.Text strong>Danh sách hàng hóa</Typography.Text>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add({
                        price: 0,
                        giaThuDaiLy: 0,
                      });
                      setTimeout(() => {
                        listRef.current &&
                          listRef.current.scrollTo({
                            top: listRef.current.scrollHeight,
                            behavior: "smooth",
                          });
                      }, 200);
                    }}
                    icon={<PlusOutlined />}
                  >
                    Thêm hàng hóa
                  </Button>
                </div>
                <div
                  className={`${styles.hangHoasContainer} customScroll`}
                  ref={listRef}
                >
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key} className={styles.hangHoaFormItem}>
                      <div className={styles.hangHoaFormItem__wrapper}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 5,
                            alignItems: "flex-end",
                            width: "100%",
                          }}
                        >
                          <Typography.Text strong style={{ marginBottom: 5 }}>
                            {index + 1}.
                          </Typography.Text>
                          <Form.Item
                            {...restField}
                            name={[name, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Không được bỏ trống",
                              },
                            ]}
                            style={{ flex: 1 }}
                          >
                            <Input placeholder="Tên hàng hóa" />
                          </Form.Item>
                        </div>
                        <Space
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            label={"Giá bán"}
                            name={[name, "giaBan"]}
                            rules={[
                              {
                                required: true,
                                message: "Không được bỏ trống",
                              },
                            ]}
                          >
                            <MoneyInput />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label={"Giá thu đại lý"}
                            name={[name, "giaDaiLy"]}
                            rules={[
                              {
                                required: true,
                                message: "Không được bỏ trống",
                              },
                            ]}
                          >
                            <MoneyInput />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label={"Giá trả nhà cung cấp"}
                            name={[name, "giaTraNCC"]}
                          >
                            <MoneyInput />
                          </Form.Item>
                        </Space>
                      </div>
                      <Popconfirm
                        title="Xóa hàng hóa"
                        okText="Ok"
                        cancelText="Hủy"
                        onConfirm={() => remove(name)}
                        // disabled={hangHoasValue && !!hangHoasValue[name]?.id}
                      >
                        <MinusCircleOutlined
                          style={
                            // hangHoasValue && !!hangHoasValue[name]?.id
                            // ? {
                            //     color: "#000000e0",
                            //     cursor: "not-allowed",
                            //   }
                            // : {
                            {
                              color: "red",
                              cursor: "pointer",
                            }
                          }
                        />
                      </Popconfirm>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Form.List>
        )}
      </Form>
    </Spin>
  );
};

import { Form, FormInstance, notification } from "antd";
import styles from "./Billing.module.css";
import UsdIcon from "image/icon/usd-icon.svg";
import VndIcon from "image/icon/vnd-icon.svg";
import BankTransIcon from "image/icon/bank-transfer-icon.svg";
import MasterCardIcon from "image/icon/mastercard-icon.svg";
import AmericanExpIcon from "image/icon/american-express-icon.svg";
// import DinnerCardIcon from "image/icon/dinner-card-icon.svg";
import JcbIcon from "image/icon/jcb-icon.svg";
import OtherCardIcon from "image/icon/other-card-icon.svg";
import VisaIcon from "image/icon/visa-icon.svg";
import DebtIcon from "image/icon/debt-icon.svg";
import { useEffect, useState } from "react";
import { ICodePay } from "common/define-types";
import { getAllCodePay } from "api/codepay.api";
import { MethodItem } from "./MethodItem";

interface IProps {
  setPayAmount: (...args: any) => void;
  totalAmount: number;
  paymentForm: FormInstance<any>;
  hasDebtItem?: boolean;
  readOnly?: boolean;
  payAmount: number;
}
const methods = [
  {
    name: "Cash - USD",
    image: UsdIcon,
    code: 90002,
  },
  {
    name: "Cash - VND",
    image: VndIcon,
    code: 90001,
  },
  {
    name: "Bank trans",
    image: BankTransIcon,
    code: 90013,
  },
  {
    name: "Visa card",
    image: VisaIcon,
    code: 90003,
  },
  {
    name: "Master card",
    image: MasterCardIcon,
    code: 90004,
  },
  {
    name: "AE",
    image: AmericanExpIcon,
    code: 90005,
  },
  // {
  //   name: "Dinner card",
  //   image: DinnerCardIcon,
  //   code: 90006,
  // },
  {
    name: "JCB card",
    image: JcbIcon,
    code: 90007,
  },
  {
    name: "Other cards",
    image: OtherCardIcon,
    code: 90012,
  },
  {
    name: "Khách nợ",
    image: DebtIcon,
    code: 90008,
  },
  {
    name: "JASMINE thu hộ",
    image: DebtIcon,
    code: 90015,
  },
];
export const MethodForm = ({
  totalAmount,
  setPayAmount,
  paymentForm,
  hasDebtItem = true,
  readOnly = false,
  payAmount,
}: IProps) => {
  const HANDLER_KEY = "=";
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const [codePays, setCodePays] = useState<ICodePay[]>([]);

  useEffect(() => {
    getAllCodePay().subscribe(
      (res) => {
        if (res) {
          setCodePays(res);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const calculatePayAmount = async (changedCode: number) => {
    const allValues = await paymentForm.validateFields();
    let totalPayAmount = 0;
    for (const key in allValues) {
      if (allValues[key]) {
        const newPayAmount = totalPayAmount + allValues[key];

        if (newPayAmount > totalAmount) {
          notification.info({
            message: "Bạn đã nhập quá số tiền cần trả!",
          });
          paymentForm.setFieldValue(changedCode, null);
          calculatePayAmount(changedCode);
        } else {
          totalPayAmount += allValues[key];
        }
      }
    }
    setPayAmount(totalPayAmount);
  };

  const handleFormChange = (changedValue: any, allValues: any) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      const keys = Object.keys(changedValue);

      calculatePayAmount(keys.length > 0 ? parseInt(keys[0]) : 0);
    }, 400);
    setTimer(timeoutId);
  };

  const handleKeyDown = (key: string, methodCode: number) => {
    if (key && key === HANDLER_KEY) {
      // paymentForm.resetFields([methodCode]);
      setTimeout(() => {
        paymentForm.setFieldValue(methodCode, totalAmount - payAmount);
        calculatePayAmount(methodCode);
      }, 100);
    }
  };

  const getIconForCodePay = (code: number) => {
    const foundMethod = methods.find((method) => method.code === code);
    return foundMethod ? foundMethod.image : OtherCardIcon;
  };

  return (
    <Form
      onValuesChange={handleFormChange}
      form={paymentForm}
      className={`${styles.methodForm} customScroll`}
    >
      {/* [4-12-2023] [Phuc Thinh] [Update paymentMethodBooking interface] */}

      {[...codePays]
        .sort((a, b) => parseInt(a.ma) - parseInt(b.ma))
        .map((codePay) => ({
          ...codePay,
          code: parseInt(codePay.ma),
          image: getIconForCodePay(parseInt(codePay.ma)),
          note: codePay.ten,
        }))
        .filter((method) =>
          !hasDebtItem ? method.code !== 90008 && method.code !== 90015 : true
        )
        .map((method, index) => (
          <MethodItem
            key={index}
            readOnly={readOnly}
            handleKeyDown={handleKeyDown}
            code={method.code}
            label={method.note}
            image={method.image}
          />
        ))}
    </Form>
  );
};

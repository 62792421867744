import SYSTEM_CONSTANTS from "common/constants";
import {
  IExportOpTicket,
  IGroupTicketsParams,
  IJobTicket,
  IOperatorTicketFilterValue,
  IParams,
  ITicket,
  JobTicketStatusEnum,
} from "common/define-types";
import Utils from "common/Utils";
import { Observable, throwError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import HttpClient from "./http-client";

const api = `${SYSTEM_CONSTANTS.API_URL}/Ticket`;

export const getTicketById = (id: string): Observable<any> => {
  return HttpClient.get(`${api}/${id}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const getAllTickets = (params: IParams): Observable<any> => {
  if (params) {
    const { page, pageSize } = params;
    return HttpClient.get(
      `${api}?
        ${page ? `page=${page}&` : ""} 
        ${pageSize ? `size=${pageSize}&` : ""}`
    ).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(`${api}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};

export const updateTicket = (body: ITicket): Observable<any> => {
  return HttpClient.put(`${api}/${body.id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const getJobsByTicketId = (
  ticketId: string,
  currentPage: number,
  pageSize: number
): Observable<any> => {
  return HttpClient.get(
    `${api}/${ticketId}/jobticket?page=${currentPage}&size=${pageSize}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const getIssuesByTicketId = (
  ticketId: string,
  currentPage: number,
  pageSize: number
): Observable<any> => {
  return HttpClient.get(
    `${api}/${ticketId}/issues?page=${currentPage}&size=${pageSize}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const getTicketsByGroupId = (
  groupId: string | null,
  params?: IGroupTicketsParams
) => {
  if (params) {
    const { startDate, endDate, ...rest } = params;
    return HttpClient.get(
      `${api}/tickets?groupId=${
        groupId || ""
      }&pageSize=1000&${Utils.getParamsString(
        groupId === null ? params : { ...rest }
      )}`
    ).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(
      `${api}/tickets?groupId=${groupId || ""}&pageSize=1000`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};

export const getAllPriceReqs = (params: {
  dateTime: string | null;
}): Observable<any> => {
  return HttpClient.get(
    `${api}/tickets/priceRequest?${Utils.getParamsString(params)}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const updateJobTicket = (
  body: IJobTicket[],
  status?: JobTicketStatusEnum
) => {
  return HttpClient.put(
    `${api}/tickets/jobtickets?status=${status}`,
    JSON.stringify(body),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getQuanLyTickets = (params: IOperatorTicketFilterValue) => {
  return HttpClient.get(
    `${api}/quanlytickets?${Utils.getParamsString(params)}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
// [7-12-2023][Phuc Thinh] [feature/14836]
// add API for export quanlytickets
export const exportQuanLyTickets = (params: IExportOpTicket) => {
  return HttpClient.get(
    `${api}/quanlytickets/export?${Utils.getParamsString(params)}`,
    {
      headers: {
        "Content-Type": "application/pdf",
        "Content-Disposition": 'attachment; filename="filename.pdf"',
        "Cache-Control": "private",
      },
      responseType: "blob",
    }
  ).pipe(
    map(
      (res) => {
        return (res as any) || null;
      },
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
// [24-05-2024][cong_vt] [feature/17681]
// add API for export quanlytickets
export const exportExcelQuanLyTickets = (params: IExportOpTicket) => {
  return HttpClient.get(
    `${api}/quanlytickets/export_to_excel?${Utils.getParamsString(params)}`,
    {
      headers: {
        "Content-Type": "application/pdf",
        "Content-Disposition": 'attachment; filename="filename.pdf"',
        "Cache-Control": "private",
      },
      responseType: "blob",
    }
  ).pipe(
    map(
      (res) => {
        return (res as any) || null;
      },
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
// [5-12-2023][Phuc Thinh] [add checkbox for checkin ticket]
// add API for checkin ticket
export const checkIn = (ticketId: string, time: string) => {
  return HttpClient.put(
    `${api}/tickets/checkinTicket?id=${ticketId}&time=${time}`,
    {}
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
// [15-12-2023][Phuc Thinh] [add checkbox for checkin ticket]
// add API for uncheckin ticket
export const uncheckIn = (ticketId: string) => {
  return HttpClient.put(
    `${api}/tickets/unCheckinTicket?id=${ticketId}`,
    {}
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

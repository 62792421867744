import {
  Button,
  Checkbox,
  Form,
  Input,
  PaginationProps,
  Popover,
  Table,
  notification,
} from "antd";
import styles from "../MotorbikeOperator.module.css";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { ColumnsType } from "antd/es/table";
import { IOperatorMotorbike, MotorTourStatus } from "common/define-types";
import {
  EditOutlined,
  StopOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import {
  checkInJasmineMotorbike,
  fetchMotorbikes,
  saveMotorbike,
  unCheckInJasmineMotorbike,
} from "store/slice/MotorbikeOperatorSlice";
import { useForm } from "antd/es/form/Form";
import Utils from "common/Utils";
import { useEffect, useState } from "react";
import { BrokeMotorbikeCalendar } from "./BrokeMotorbikeCalendar";
import { editMotorbike } from "store/slice/MotorbikeOperatorSlice";
import { filterButtons } from "../MotorbikeOperator";
import moment from "moment";
// import useModal from "antd/es/modal/useModal";

export const MotorbikeTable = () => {
  const selectLoading = useSelectorRoot(
    (state) => state.operatorMotorbike.isLoading
  );
  const selectMotorbikes = useSelectorRoot(
    (state) => state.operatorMotorbike.motorbikes
  );
  const selectMotorbikesResult = useSelectorRoot(
    (state) => state.operatorMotorbike.motorbikesResult
  );
  const selectEditingMotorbike = useSelectorRoot(
    (state) => state.operatorMotorbike.editingMotorbike
  );
  const selectFilterValue = useSelectorRoot(
    (state) => state.operatorMotorbike.filterValue
  );
  const dispatch = useDispatchRoot();
  // const [modal, contextHolder] = useModal()
  const [editMotorbikeForm] = useForm();
  const [brokeMotorbikeIdOpen, setBrokeMotorbikeOpen] = useState<string | null>(
    null
  );
  // const [editingMotorbikeNoteId, setEditingMotorbikeNoteId] = useState<
  //   string | null
  // >(null);
  const isEditing = (record: IOperatorMotorbike) =>
    record.id === selectEditingMotorbike?.id;

  const handleEdit = (record: IOperatorMotorbike | null) => {
    dispatch(editMotorbike(record));
  };
  const handleClickCheckin = (
    record: IOperatorMotorbike,
    isChecked: boolean
  ) => {
    Utils.throttle(() => {
      isChecked ? handleUnCheckin(record) : handleCheckin(record);
      // modal.confirm({
      //   title: "Check-in",
      //   content: `Bạn có chắc chắn muốn ${
      //     isChecked ? "hủy check-in" : "check-in"
      //   }  cho xe ${record.name}`,
      //   onOk: () =>

      // });
    }, 500);
  };

  const handleCheckin = (record: IOperatorMotorbike) => {
    // const tzOffset = new Date().getTimezoneOffset(); // offset in minutes
    dispatch(checkInJasmineMotorbike(record.id));
  };
  const handleUnCheckin = (record: IOperatorMotorbike) => {
    dispatch(unCheckInJasmineMotorbike(record.id));
  };

  const columns: ColumnsType<IOperatorMotorbike> = [
    {
      title: "Đã nhập kho",
      key: "tourStatus",
      dataIndex: "tourStatus",
      width: 100,
      render: (value, record) => {
        const isChecked = [MotorTourStatus.None].some(
          (tourStatus) => tourStatus === value
        );

        return (
          <Checkbox
            checked={isChecked}
            onClick={(e) =>
              (!e.detail || e.detail === 1) &&
              handleClickCheckin(record, isChecked)
            }
            disabled={
              !selectFilterValue ||
              !moment
                .utc(selectFilterValue.dateTime)
                .startOf("day")
                .isSame(moment.utc().startOf("day"))
            }
          />
        );
      },
      align: "center",
    },
    {
      title: "Biển số xe",
      dataIndex: "motorNumber",
      sorter: {
        compare: (a, b) => {
          if (a.motorNumber < b.motorNumber) {
            return -1;
          }
          if (a.motorNumber > b.motorNumber) {
            return 1;
          }
          return 0;
        },
        multiple: 3,
      },
      width: 100,
    },
    {
      title: "Loại xe máy",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name, "vi"),
        multiple: 2,
      },
      width: 100,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      width: 100,
      align: "center",
      render: (value) => {
        return (
          <img
            src={filterButtons.find((button) => button.type === value)?.icon}
            alt="status"
          />
        );
      },
    },
    {
      title: "Tên tài xế",
      dataIndex: "driverName",
      width: 140,
      ellipsis: true,
    },
    {
      title: "Tên khách",
      dataIndex: "clientName",
      width: 140,
      ellipsis: true,
    },
    {
      title: "Ghi chú",
      width: 150,
      render: (_, record) =>
        isEditing(record) ? (
          <Form.Item name={"note"} style={{ marginBottom: 0 }}>
            <Input />
          </Form.Item>
        ) : (
          record.datA_Note_MotorBikes &&
          record.datA_Note_MotorBikes[0] && (
            <>{record.datA_Note_MotorBikes[0].note}</>
          )
        ),
    },
    {
      title: "",
      dataIndex: "action",
      width: 160,
      render: (_, record) =>
        isEditing(record) ? (
          <div className={styles.btnContainer}>
            <Button
              type="text"
              icon={
                <CloseOutlined style={{ fontSize: 20, color: "#000000" }} />
              }
              id="close_button"
              onClick={() => handleEdit(null)}
            />
            <Button
              type="text"
              icon={
                <CheckOutlined style={{ fontSize: 20, color: "#52C41A" }} />
              }
              id="save_button"
              onClick={() => handleSave(record)}
            />
          </div>
        ) : (
          <div className={styles.btnContainer}>
            <Popover
              overlayStyle={{ width: 280, height: 387 }}
              destroyTooltipOnHide={true}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
              }
              content={
                <BrokeMotorbikeCalendar
                  motorbikeId={record.id}
                  handleClose={() => setBrokeMotorbikeOpen(null)}
                />
              }
              title={`Báo hỏng xe ${record.name}_${record.motorNumber}`}
              trigger="click"
              placement="bottom"
              open={
                brokeMotorbikeIdOpen !== null &&
                brokeMotorbikeIdOpen === record.id
              }
              onOpenChange={(visible) =>
                visible
                  ? setBrokeMotorbikeOpen(record.id)
                  : setBrokeMotorbikeOpen(null)
              }
            >
              <Button
                type="text"
                icon={
                  <StopOutlined style={{ fontSize: 20, color: "#FAAD14" }} />
                }
                id="stop_button"
                onClick={() =>
                  setBrokeMotorbikeOpen(brokeMotorbikeIdOpen ? null : record.id)
                }
              />
            </Popover>
            <Button
              type="text"
              icon={
                <EditOutlined
                  style={{
                    fontSize: 20,
                    color: !!selectEditingMotorbike ? "grey" : "#1890FF",
                  }}
                />
              }
              onClick={() => handleEdit(record)}
              disabled={!!selectEditingMotorbike}
            />
          </div>
        ),
    },
  ];

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectMotorbikesResult) {
      dispatch(
        fetchMotorbikes({
          ...selectMotorbikesResult,
          page,
        })
      );
    }
  };

  const handleSave = async (record: IOperatorMotorbike) => {
    const formValue = await editMotorbikeForm.validateFields().catch((err) => {
      console.log(err);
      notification.error({ message: "Vui lòng nhập đủ thông tin" });
    });
    if (!formValue) return;
    const newNote = formValue.note;
    if (selectEditingMotorbike) {
      if (
        selectEditingMotorbike.datA_Note_MotorBikes &&
        selectEditingMotorbike.datA_Note_MotorBikes[0]
      ) {
        dispatch(
          saveMotorbike({
            note: newNote,
            id: selectEditingMotorbike.datA_Note_MotorBikes[0].id,
            motorBikeId: selectEditingMotorbike.id,
            date: selectFilterValue.dateTime ?? new Date().toISOString(),
          })
        );
      } else {
        dispatch(
          saveMotorbike({
            note: newNote,
            motorBikeId: selectEditingMotorbike.id,
            date: selectFilterValue.dateTime ?? new Date().toISOString(),
          })
        );
      }
    }
  };

  useEffect(() => {
    if (
      selectEditingMotorbike &&
      selectEditingMotorbike.datA_Note_MotorBikes &&
      selectEditingMotorbike.datA_Note_MotorBikes[0]
    ) {
      editMotorbikeForm.setFields(
        Utils.objectToAntFormData({
          note: selectEditingMotorbike.datA_Note_MotorBikes[0].note,
        })
      );
    }
  }, [selectEditingMotorbike, editMotorbikeForm]);

  return (
    <Form form={editMotorbikeForm} className={styles.table}>
      {/* {contextHolder} */}
      <Table
        dataSource={selectMotorbikes}
        columns={columns.filter((col) =>
          !selectFilterValue ||
          !moment
            .utc(selectFilterValue.dateTime)
            .startOf("day")
            .isSame(moment.utc().startOf("day"))
            ? col.key !== "tourStatus"
            : true
        )}
        rowKey={"id"}
        scroll={{ x: 550 }}
        //[1-12-2023] [Phuc Thinh]
        //Thay đổi thanh scroll
        className="customScrollTable"
        pagination={{
          pageSize: selectMotorbikesResult
            ? selectMotorbikesResult.pageSize
            : 10,
          current: selectMotorbikesResult ? selectMotorbikesResult.page : 1,
          total: selectMotorbikesResult ? selectMotorbikesResult.queryCount : 0,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
        loading={selectLoading}
      />
    </Form>
  );
};

import {
  Button,
  Popconfirm,
  Segmented,
  SegmentedProps,
  Space,
  Typography,
} from 'antd';

import styles from '../NightAuditor.module.css';
import { GroupDetailView } from '../../Operation/components/GroupDetailView';
// import { JobDateEnum } from "common/define-types";
import { useDispatchRoot, useSelectorRoot } from 'store/store';
import {
  auditGroup,
  confirmAllGroupRequest,
  setJobDateSelected,
} from 'store/slice/NightAuditorSlice';
import { useForm } from 'antd/es/form/Form';
import { IssuesContainer } from './IssuesContainer';
import { Journey } from './Journey';
import moment from 'moment';
import Utils from 'common/Utils';

// const jobDateOptions = [
//   {
//     value: JobDateEnum.DAY1,
//     label: `Ngày 1`,
//   },
//   {
//     value: JobDateEnum.DAY2,
//     label: `Ngày 2`,
//   },
//   {
//     value: JobDateEnum.DAY3,
//     label: `Ngày 3`,
//   },
//   {
//     value: JobDateEnum.DAY4,
//     label: `Ngày 4`,
//   },
// ];
export const GroupDetailContainer = () => {
  const selectGroupSelected = useSelectorRoot(
    (state) => state.nightAuditor.groupSelected
  );
  const selectIsNightAuditing = useSelectorRoot(
    (state) => state.nightAuditor.isSubmitting
  );
  const selectIsRunNight = useSelectorRoot(
    (state) => state.nightAuditor.groupSelectedIsRunNight
  );
  const selectJobTours = useSelectorRoot(
    (state) => state.nightAuditor.jobTours
  );
  const selectJobDateSelected = useSelectorRoot(
    (state) => state.nightAuditor.jobDateSelected
  );
  const selectGroupDriverTickets = useSelectorRoot(
    (state) => state.nightAuditor.groupSelectedDriverTickets
  );
  const selectGroupIssues = useSelectorRoot(
    (state) => state.nightAuditor.groupSelectedIssues
  );
  const selectGroupSelectedCount = useSelectorRoot(
    (state) => state.nightAuditor.groupSelectedCount
  );
  const hasUnconfirmedGroup = useSelectorRoot(
    (state) => state.nightAuditor.hasUnconfirmedGroup
  );
  const allowConfirmAll = useSelectorRoot(
    (state) => state.nightAuditor.allowConfirmAll
  );
  const isCheckingAllowConfirm = useSelectorRoot(
    (state) => state.nightAuditor.isCheckingAllowConfirm
  );
  const isActionSynchronized = useSelectorRoot(
    (state) => state.nightAuditor.isActionSynchronized
  );
  const dispatch = useDispatchRoot();
  const options: SegmentedProps['options'] = selectGroupSelected
    ? new Array(Utils.getGroupCountDay(selectGroupSelected) || 4)
        .fill(null)
        .map((_, index) => ({
          label: (
            <Typography.Text
            // style={{
            //   color:
            //     Utils.checkGroupEndDateIsBeforeCountDay(
            //       selectGroupSelected
            //     ) &&
            //     index + 1 === (Utils.getGroupCountDay(selectGroupSelected) || 4)
            //       ? 'red'
            //       : '#000000a6',
            // }}
            >
              {`Ngày ${index + 1}`}
            </Typography.Text>
          ),
          value: index + 1,
        }))
    : [];
  const [groupInfoForm] = useForm();

  const handleAudit = () => {
    dispatch(
      auditGroup({
        driver: selectGroupDriverTickets
          .filter((dTicket) =>
            moment(dTicket.date).isBetween(
              moment
                .utc(selectGroupSelected?.businessDate)
                .startOf('day')
                .subtract(1, 'second'),
              moment().endOf('day')
            )
          )
          .map((dTicket) => ({
            id: dTicket.id,
            driverId: dTicket.driverId,
            ticketId: dTicket.ticketId,
            day: dTicket.day,
            carSalary: dTicket.carSalary,
            date: dTicket.date,
          })),
      })
    );
  };

  const handleConfirmAllGroup = () => {
    const today = new Date();
    dispatch(
      confirmAllGroupRequest({
        dateTime: today.toISOString(),
      })
    );
  };

  return (
    <div
      className={styles.listContainer}
      style={{ width: 'clamp(26rem, 100%, 60rem)', marginBottom: '1rem' }}
    >
      <div className={`${styles.listContainer_header} customScroll`}>
        {!!selectGroupSelected && (
          <Segmented
            options={options}
            value={selectJobDateSelected}
            onChange={(value) => dispatch(setJobDateSelected(value))}
            style={{ marginRight: 4 }}
          />
        )}
        <Space>
          {selectJobTours &&
            selectJobTours.length > 0 &&
            Utils.isBusinessDateAvailable(
              selectGroupSelected,
              typeof selectJobDateSelected === 'number'
                ? selectJobDateSelected
                : parseInt(selectJobDateSelected)
            ) && (
              //[10-4-2024][Phuc Thinh]
              // [disabled audit button when group list has unconfirmed group and selected group is confirmed previous day]

              // (typeof selectJobDateSelected === "number"
              //   ? selectJobDateSelected
              //   : parseInt(selectJobDateSelected)) <=
              //   moment().diff(moment(selectGroupSelected?.startDate), "days") +
              //     1 && (
              <Popconfirm
                title='Kiểm toán group'
                description='Bạn có xác nhận kiểm toán'
                onConfirm={handleAudit}
              >
                <Button
                  type='primary'
                  disabled={
                    !selectGroupSelected ||
                    selectIsRunNight !== false ||
                    (hasUnconfirmedGroup &&
                      !selectGroupSelected?.isNotConfirmpPreviousDay)
                  }
                  loading={selectIsNightAuditing}
                >
                  Kiểm toán
                </Button>
              </Popconfirm>
            )}
          {!isActionSynchronized && (
            <Popconfirm
              title='Đồng bộ kiểm toán'
              description='Bạn có xác nhận đồng bộ'
              onConfirm={handleConfirmAllGroup}
            >
              <Button
                type='primary'
                disabled={!allowConfirmAll}
                loading={isCheckingAllowConfirm}
              >
                Đồng bộ
              </Button>
            </Popconfirm>
          )}
        </Space>
      </div>
      <div className={styles.detailContainer}>
        <div className={styles.detailWrapper}>
          <div className={styles.detailWrapper_column}>
            <div
              className={styles.detailWrapper_item}
              style={{ minHeight: 365 }}
            >
              <GroupDetailView
                form={groupInfoForm}
                readOnly={true}
                group={selectGroupSelected}
                // drivers={selectGroupDriverTickets.filter(
                //   (dTicket) =>
                //     moment(dTicket.date).diff(
                //       moment(selectGroupSelected?.startDate),
                //       "days"
                //     ) +
                //       1 ===
                //     selectJobDateSelected
                // )}
                countDriver={selectGroupSelectedCount?.countDriver || 0}
                countMotor={selectGroupSelectedCount?.countMotor || 0}
                countTicket={selectGroupSelectedCount?.countTicket || 0}
                issues={selectGroupIssues}
              />
            </div>
            <div className={styles.detailWrapper_item}>
              <div className={styles.detailView_wrapper}>
                <IssuesContainer />
              </div>
            </div>
          </div>
          <div
            className={styles.detailWrapper_column}
            style={{ minWidth: 300 }}
          >
            <div className={styles.detailWrapper_item}>
              <Journey />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { PlusOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Typography, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { ChangeEvent, useEffect, useState } from "react";
import { addBus, fetchBuses, setAddModalOpen, setBusFilterValue } from "store/slice/BusSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "./Bus.module.css";
import { BusForm } from "./components/BusForm";
import { BusTable } from "./components/BusTable";
export const Bus = () => {
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const selectAddModalOpen = useSelectorRoot(
    (state) => state.bus.addModalOpen
  );
  const selectErrMsg = useSelectorRoot((state) => state.bus.errMsg);
  const selectBusFilterValue = useSelectorRoot(
    (state) => state.bus.filterValue
  );
  const [addBusForm] = useForm();
  const dispatch = useDispatchRoot();
  useEffect(() => {
    dispatch(fetchBuses({ pageSize: 25 }));
  }, [dispatch]);
  useEffect(() => console.log(selectAddModalOpen), [selectAddModalOpen]);
  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({
        message: selectErrMsg,
      });
    }
  }, [selectErrMsg]);
  const handleAddBus = async () => {
    const BusValue = await addBusForm
      .validateFields()
      .catch((err) => {
        console.log(err);
        notification.error({ message: "Vui lòng nhập đủ thông tin" });
      });
    if (!BusValue) return;
    dispatch(
      addBus({
        ...BusValue,
      })
    );
  };
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(
        setBusFilterValue({
          ...selectBusFilterValue,
          search: e.target.value,
        })
      );
    }, 1000);
    setTimer(timeoutId);
  };
  return (
    <div className={styles.BusContainer}>
      <div className={styles.tableHeader}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            flexDirection: "row",
          }}
        >
          <Typography.Text strong style={{ fontSize: 20 }}>
            Danh sách phương tiện
          </Typography.Text>
          <div>
            <Input.Search
              placeholder="Tìm kiếm phương tiện"
              onChange={handleSearch}
              allowClear
            />
          </div>
        </div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            dispatch(setAddModalOpen(true));
            addBusForm.resetFields();
          }}
        >
          Thêm mới
        </Button>
      </div>
      <BusTable />
      {selectAddModalOpen ? (
        <Modal
          title={"Thêm mới phương tiện "}
          open={selectAddModalOpen}
          onCancel={() => dispatch(setAddModalOpen(false))}
          onOk={handleAddBus}
          okText="Thêm mới"
          cancelText="Hủy bỏ"
        >
          <BusForm form={addBusForm} />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import {
  ICreateExtraService,
  IParams,
  IUpdateExtraService,
} from "common/define-types";
import Utils from "common/Utils";

const api = `${SYSTEM_CONSTANTS.API_URL}/DMUC_CHHangHoaDichVuKhac`;

export const getAllExtraServices = (): Observable<any> => {
  return HttpClient.get(api).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getAllExtraServicesPagable = (
  params?: IParams
): Observable<any> => {
  return HttpClient.get(
    `${api}/allHangHoaDichVuKhac?${Utils.getParamsString(params || {})}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const createExtraService = (
  body: ICreateExtraService
): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updateExtraService = (
  body: IUpdateExtraService
): Observable<any> => {
  return HttpClient.put(`${api}?id=${body.id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const removeExtraService = (serviceId: string): Observable<any> => {
  return HttpClient.delete(`${api}/${serviceId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

import { Typography } from 'antd';
import { IGroup } from 'common/define-types';
import DriverIcon from 'image/icon/driver-icon.svg';
import styles from '../NightAuditor.module.css';
import { useDispatchRoot, useSelectorRoot } from 'store/store';
import { selectGroup } from 'store/slice/NightAuditorSlice';
import { DriverSelect } from 'components/Selects/DriverSelect';
import { TourType } from 'components/Text/TourType';
import Utils from 'common/Utils';
import React from 'react';

interface IProps {
  group: IGroup;
  isActive?: boolean;
  disabled?: boolean;
}
export const GroupItem = React.forwardRef<HTMLDivElement, IProps>(
  ({ group, isActive = false, disabled }, ref) => {
    const dispatch = useDispatchRoot();
    const selectGroupSelected = useSelectorRoot(
      (state) => state.nightAuditor.groupSelected
    );
    const selectDrivers = useSelectorRoot((state) => state.group.allDrivers);

    const handleGroupClick = () => {
      if (selectGroupSelected && group && selectGroupSelected.id === group.id) {
        return;
      }
      dispatch(selectGroup(group));
    };

    return (
      <div
        className={`${styles.groupItem} ${isActive ? styles.selected : ''} ${
          disabled ? styles.disabled : ''
        }`}
        onClick={handleGroupClick}
        ref={ref}
        aria-selected={isActive}
      >
        <div className={`${styles.groupItem_Wrapper}`}>
          <Typography.Title className={styles.groupName} level={4}>
            {group.name} <TourType countDay={Utils.getGroupCountDay(group)} />
          </Typography.Title>
          <div className={styles.groupItem_Info}>
            <img src={DriverIcon} alt='driver' />
            <DriverSelect
              value={group.leaderId}
              readOnly={true}
              style={{ width: 180 }}
              valuePropName='accountId'
              driversOptions={selectDrivers}
            />
          </div>
        </div>
        <Typography.Text
          italic
          style={{ color: '#8C8C8C', whiteSpace: 'nowrap' }}
        >{`Ngày ${Utils.getDayFromBusinessDate(group)}`}</Typography.Text>
      </div>
    );
  }
);

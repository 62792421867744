import { Form, Upload, Image, UploadProps, message, Typography } from "antd";
import SYSTEM_CONSTANTS from "common/constants";
import { FileImageOutlined, DeleteOutlined } from "@ant-design/icons";
import Utils from "common/Utils";
import { Rule } from "antd/es/form";
import imageCompression from "browser-image-compression";

interface IProps {
  setPathFile?: (value: string | null) => void;
  pathFile?: string | null;
  name?: string;
  label?: string;
  apiEndPoint?: string;
  maxCount?: number;
  rules?: Rule[];
  onDeleteImage?: () => void;
}
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList || [];
};
const { Dragger } = Upload;
const UploadImage = ({
  pathFile,
  setPathFile,
  name = "File",
  label = "Thêm ảnh",
  apiEndPoint = "/Issue/issues/uploadImage",
  maxCount = 1,
  rules,
  onDeleteImage,
}: IProps) => {
  const props: UploadProps = {
    name: "file",
    multiple: true,
    maxCount,
    accept: "image/*",
    showUploadList: true,
    action: `${SYSTEM_CONSTANTS.API_URL}${apiEndPoint}`,
    headers: {
      Authorization: Utils.token ? `Bearer ${Utils.token}` : "",
    },

    // [5-12-2023] [Phuc Thinh] [set CCCD and license for driver]
    // compress image before upload
    async beforeUpload(file, FileList) {
      const imageFile = file;
      const options = {
        maxSizeMB: 1,
      };

      try {
        return await imageCompression(imageFile, options);
      } catch (error) {
        console.log(error);
      }
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setPathFile && setPathFile(info.file.response);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setPathFile && setPathFile(null);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <div style={{ marginBlock: 6 }}>
      <div
        style={{
          marginBlock: 8,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Text strong>{label}</Typography.Text>
        {!!pathFile && (
          <DeleteOutlined
            style={{ color: "red" }}
            onClick={() =>
              onDeleteImage
                ? onDeleteImage()
                : !!setPathFile && setPathFile(null)
            }
          />
        )}
      </div>
      {!pathFile ? (
        <>
          <Form.Item
            valuePropName="fileList"
            name={name}
            getValueFromEvent={normFile}
            rules={rules}
          >
            <Dragger {...props}>
              <FileImageOutlined />
              <p>Drag and drop here</p>
            </Dragger>
          </Form.Item>
          {/* <Dragger {...props}>
            <FileImageOutlined />
            <p>Drag and drop here</p>
          </Dragger>
          <Form.Item name={name} rules={rules} style={{ display: "none" }}>
            <Input />
          </Form.Item> */}
        </>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <Image
              src={`${SYSTEM_CONSTANTS.API_URL}${pathFile}`}
              style={{
                backgroundColor: "#00000073",
              }}
            />
            {/* <Button
              danger
              icon={<DeleteOutlined />}
              style={{ position: "absolute", top: 5, right: 5 }}
              onClick={() => !!setPathFile && setPathFile(null)}
            /> */}
          </div>
        </>
      )}
    </div>
  );
};

export default UploadImage;

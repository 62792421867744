import { Skeleton } from "antd";
import styles from "../Group.module.css";
import GroupTicketItem from "./GroupTicketItem";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import { GroupTicketType, IGroupTicket, IsDriverNumberType, IsDriverType } from "common/define-types";
import Utils from "common/Utils";

interface IProps {
  tickets: IGroupTicket[];
  // isDriver?: IsDriverType | string;
  isLoading?: boolean;
}
export const TicketList = ({
  tickets,
  // isDriver = IsDriverType.NO,
  isLoading = false,
}: IProps) => {
  const groupTickets = tickets.filter(ticket => ticket.type === GroupTicketType.TICKET);
  const dummyTickets = tickets.filter(ticket => ticket.type === GroupTicketType.DUMMY);
  const groupTicketsByDockerId = Utils.groupBy(groupTickets, "dockerId");

  const getIsDriver = (ticket: IGroupTicket) => {
    if (ticket.isDriver === IsDriverNumberType.DRIVER_PRICE)  return ticket.driverPrice?.isDriver === IsDriverType.YES
    return ticket.isDriver === IsDriverNumberType.YES;
  };

  const renderGroupTicketByDockerId = () => {
    const bgColors = ["#E6F7FF", "#fff7e6fa"];
    let index = -1;
    let results: any[] = [];
    let key: keyof typeof groupTicketsByDockerId;

    for (key in groupTicketsByDockerId) {
      if (key !== "null") {
        index += 1;
        for (let i = 0; i < groupTicketsByDockerId[key].length; i++) {
          const ticket = groupTicketsByDockerId[key][i];
          results.push(
            <GroupTicketItem
              groupTicket={ticket}
              key={ticket.id}
              isDriver={getIsDriver(ticket)}
              bgColor={bgColors[index % 2]}
            />
          );
        }
      }
    }
    for (key in groupTicketsByDockerId) {
      if (key === "null") {
        for (let j = 0; j < groupTicketsByDockerId[key].length; j++) {
          index += 1;
          const ticket = groupTicketsByDockerId[key][j];
          results.push(
            <GroupTicketItem
              groupTicket={ticket}
              key={ticket.id}
              isDriver={getIsDriver(ticket)}
              bgColor={"#FFFFFF"}
              // bgColor={bgColors[index % 2]}
            />
          );
        }
      }
    }
    dummyTickets.forEach(dummyTicket => {
      results.push(
        <GroupTicketItem
          groupTicket={dummyTicket}
          key={dummyTicket.id}
          isDriver={getIsDriver(dummyTicket)}
          bgColor={"#e3effcb2"}
        />
      );
    })
    return results;
  };

  return (
    <>
      <div className={styles.listWrapper}>
        <div
          className={`${styles.listWrapper_List} customScroll`}
          style={{ padding: "8px 12px" }}
        >
          {isLoading ? (
            <>
              <Skeleton avatar={{ size: 100 }} paragraph={{ rows: 3 }} active />
              <Skeleton avatar={{ size: 100 }} paragraph={{ rows: 3 }} active />
              <Skeleton avatar={{ size: 100 }} paragraph={{ rows: 3 }} active />
            </>
          ) : tickets && tickets.length > 0 ? (
            // (
            //   tickets
            //     .filter(
            //       (ticket) =>
            //         getIsDriver(ticket) !== null &&
            //         getIsDriver(ticket) === isDriver
            //     )
            //     .map((ticket) => (
            //       <GroupTicketItem
            //         groupTicket={ticket}
            //         key={ticket.id}
            //         isDriver={getIsDriver(ticket)}
            //       />
            //     ))
            // )
            renderGroupTicketByDockerId()
          ) : (
            <EmptyHolder message="No tickets" />
          )}
        </div>
      </div>
    </>
  );
};

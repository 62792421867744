import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createLocation, deleteLocationById, getAllLocationsPageable, updateLocation } from "api/location.api";
import {
  ICreateLocation,
  ILocation,
  ILocationFilterValue,
  ILocationsResult,
  IParams,
  IUpdateLocation,
  RootEpic,
} from "common/define-types";
import { catchError, filter, mergeMap, switchMap } from "rxjs";
import { AjaxError } from "rxjs/ajax";

export interface LocationState {
  isLoading: boolean;
  isSubmitting: boolean;
  locations: ILocation[] | [];
  errMsg: string | null;
  locationsResult: ILocationsResult | null;
  addModalOpen: boolean;
  deletingLocationId: string | null;
  editingLocation: ILocation | null;
  filterValue: ILocationFilterValue | null;
}

const initialState: LocationState = {
  isLoading: false,
  isSubmitting: false,
  locations: [],
  errMsg: null,
  locationsResult: null,
  addModalOpen: false,
  deletingLocationId: null,
  editingLocation: null,
  filterValue: {
    search: "",
  },
};

export const locationSlice = createSlice({
  name: "Location",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchLocations: (state, action: PayloadAction<IParams | undefined>) => {
      state.isLoading = true;
      state.isSubmitting = false;
      state.addModalOpen = false;
      state.editingLocation = null;
      state.deletingLocationId = null;
      state.errMsg = null;
    },
    setLocations: (state, action: PayloadAction<ILocation[]>) => {
      state.locations = action.payload;
      state.isLoading = false;
    },
    setErrMsg: (state, action: PayloadAction<string>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
    setLocationsResult: (state, action: PayloadAction<ILocationsResult>) => {
      state.locationsResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    editLocation: (state, action: PayloadAction<ILocation | null>) => {
      state.editingLocation = action.payload;
    },
    addLocation: (state, action: PayloadAction<ICreateLocation>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    deleteLocation: (state, action: PayloadAction<string>) => {
      state.deletingLocationId = action.payload;
      state.errMsg = null;
    },
    setAddModalOpen: (state, action: PayloadAction<boolean>) => {
      state.addModalOpen = action.payload;
    },
    saveLocation: (state, action: PayloadAction<IUpdateLocation>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    setLocationFilterValue: (
      state,
      action: PayloadAction<ILocationFilterValue>
    ) => {
      state.filterValue = {
        ...state.filterValue,
        ...action.payload,
      };
      console.log("filterValue: ", state.filterValue);
    },
  },
});
const getLocations$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchLocations.match),
    switchMap((re) => {
      return getAllLocationsPageable({ ...re.payload }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const locations = res.results;
            return [
              locationSlice.actions.setLocations(locations),
              locationSlice.actions.setLocationsResult(res),
            ];
          } else {
            return [locationSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          locationSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách vị trí"
          ),
        ])
      );
    })
  );
const getLocationsWhenFilter$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setLocationFilterValue.match),
    switchMap((re) => {
      return getAllLocationsPageable({
        // ...state$.value.Location.LocationsResult,
        ...re.payload,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const Locations = res.results;
            return [
              locationSlice.actions.setLocations(Locations ?? []),
              locationSlice.actions.setLocationsResult(res),
            ];
          } else {
            return [locationSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          locationSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách vị trí"
          ),
        ])
      );
    })
  );
const addLocations$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addLocation.match),
    switchMap((re) => {
      return createLocation([re.payload]).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.location.locationsResult) {
              return [
                locationSlice.actions.fetchLocations(
                  state$.value.location.locationsResult
                ),
              ];
            }
            return [locationSlice.actions.fetchLocations()];
          } else {
            return [locationSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          locationSlice.actions.setErrMsg("Có lỗi xảy ra khi thêm vị trí"),
        ])
      );
    })
  );
const saveLocations$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveLocation.match),
    switchMap((re) => {
      return updateLocation(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.location.locationsResult) {
              return [
                locationSlice.actions.fetchLocations(
                  state$.value.location.locationsResult
                ),
              ];
            }
            return [locationSlice.actions.fetchLocations()];
          } else {
            return [locationSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          locationSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lưu thông tin vị trí"
          ),
        ])
      );
    })
  );
const deleteLocations$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteLocation.match),
    switchMap((re) => {
      return deleteLocationById(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.location.locationsResult) {
              return [
                locationSlice.actions.fetchLocations(
                  state$.value.location.locationsResult
                ),
              ];
            }
            return [locationSlice.actions.fetchLocations()];
          } else {
            return [locationSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          locationSlice.actions.setErrMsg("Có lỗi xảy ra khi xóa vị trí"),
        ])
      );
    })
  );

export const {
  fetchLocations,
  setLocations,
  setErrMsg,
  addLocation,
  setAddModalOpen,
  saveLocation,
  editLocation,
  deleteLocation,
  setLocationFilterValue,
} = locationSlice.actions;

export const locationEpics = [
  getLocations$,
  addLocations$,
  saveLocations$,
  deleteLocations$,
  getLocationsWhenFilter$,
];

export const locationReducer = locationSlice.reducer;

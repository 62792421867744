import {
  Typography,
  Button,
  Pagination,
  PaginationProps,
  Skeleton,
} from "antd";
import styles from "../Suppliers.module.css";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import {
  fetchSupplierJobs,
  setAddSupplierGroupJobOpen,
} from "store/slice/SuppliersCategorySlice";

import { EmptyHolder } from "components/Empty/EmptyHolder";
import { SupplierJobItem } from "./SupplierJobItem";

export const SupplierJobList = () => {
  // const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const selectSupplierJobs = useSelectorRoot(
    (state) => state.suppliersCategory.supplierSelectedJobs
  );
  const selectSupplierSelected = useSelectorRoot(
    (state) => state.suppliersCategory.supplierSelected
  );
  const selectSupplierJobsResult = useSelectorRoot(
    (state) => state.suppliersCategory.supplierJobsResults
  );
  const selectSupplierJobsLoading = useSelectorRoot(
    (state) => state.suppliersCategory.isJobsLoading
  );
  const dispatch = useDispatchRoot();
  // const [addAccModalOpen, setAddAccModalOpen] = useState(false);

  // const handleCreateJobs = async () => {
  //   const agentJobsValue = await addJobsForm
  //     .validateFields()
  //     .catch((err) => {
  //       console.log(err);
  //       notification.error({ message: "Vui lòng nhập đủ thông tin" });
  //     });
  //   if (!agentJobsValue) return;
  //   const employeeName = agentJobsValue.employeeName.trim();
  //   const indexOfFirstSpaceChar = employeeName.lastIndexOf(" ");
  //   const lastName = employeeName.slice(0, indexOfFirstSpaceChar);
  //   const firstName = employeeName.slice(
  //     indexOfFirstSpaceChar,
  //     employeeName.length
  //   );
  //   if (selectSupplierSelected) {
  //     dispatch(
  //       createSupplierJobs({
  //         account: {
  //           firstname: indexOfFirstSpaceChar > 0 ? firstName : employeeName,
  //           lastname: indexOfFirstSpaceChar > 0 ? lastName : "",
  //           email: agentJobsValue.email,
  //           phoneNumber: agentJobsValue.phoneNumber,
  //           password: agentJobsValue.password,
  //           userName: agentJobsValue.userName,
  //         },
  //         agent: selectSupplierSelected,
  //       })
  //     );
  //   }
  // };

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectSupplierJobsResult) {
      dispatch(
        fetchSupplierJobs({
          ...selectSupplierJobsResult,
          page,
        })
      );
    } else {
      dispatch(fetchSupplierJobs());
    }
  };

  const openAddJobModal = () => {
    dispatch(setAddSupplierGroupJobOpen({ open: true }));
  };
  // const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
  //   const query = e.target.value;
  //   clearTimeout(timer);
  //   const timeoutId = setTimeout(() => {
  //     if (selectSupplierJobssResult) {
  //       dispatch(
  //         fetchSupplierJobss({
  //           ...selectSupplierJobssResult,
  //           search: query,
  //         })
  //       );
  //       dispatch(
  //         setSupplierJobssResults({
  //           ...selectSupplierJobssResult,
  //           search: query,
  //         })
  //       );
  //     }
  //   }, 800);
  //   setTimer(timeoutId);
  // };

  return (
    <div className={styles.listContainer}>
      <div className={styles.listContainer_header}>
        <Typography.Text strong style={{ fontSize: 20 }}>
          Danh sách điểm đến
        </Typography.Text>
        <div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled={selectSupplierSelected === null}
            onClick={openAddJobModal}
          >
            Thêm điểm đến
          </Button>
        </div>
      </div>
      <div className={styles.searchContainer}>
        {selectSupplierJobsLoading ? (
          <Skeleton.Input block={true} />
        ) : (
          <>
            <Typography.Text>{`Số lượng điểm đến: ${
              selectSupplierJobs?.length || 0
            }`}</Typography.Text>
            {/* <Input.Search
              allowClear
              style={{ flex: 1 }}
              placeholder="Tìm kiếm tài khoản"
              onChange={handleSearch}
            /> */}
          </>
        )}
      </div>
      <div className={styles.listWrapper}>
        <div className={`${styles.listWrapper_List} customScroll`}>
          {selectSupplierJobsLoading ? (
            <>
              <Skeleton paragraph={{ rows: 3 }} />
              <Skeleton paragraph={{ rows: 3 }} />
              <Skeleton paragraph={{ rows: 3 }} />
            </>
          ) : selectSupplierJobs && selectSupplierJobs.length > 0 ? (
            [...selectSupplierJobs].map((job, index) => (
              <SupplierJobItem job={job} key={`${job.id}`} />
            ))
          ) : (
            <EmptyHolder message="Chưa có điểm đến" />
          )}
        </div>
      </div>
      <div className={styles.listContainer_footer}>
        <Pagination
          total={selectSupplierJobsResult?.queryCount || 0}
          pageSize={selectSupplierJobsResult?.pageSize || 10}
          current={selectSupplierJobsResult?.page || 1}
          onChange={handleChangePage}
          showTotal={(total, range) =>
            `Hiển thị ${
              range[1] === 0 && range[1] - range[0] === 0
                ? 0
                : range[1] - range[0] + 1
            }/${total} điểm đến`
          }
        />
      </div>
    </div>
  );
};

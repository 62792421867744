import { Spin, Typography, notification } from "antd";
import { getAllComboTours } from "api/comboTour.api";
import { IComboTour, IPriceDetail } from "common/define-types";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import moment from "moment";
import { useEffect, useState } from "react";
import styles from "../Agents.module.css";
import { useSelectorRoot } from "store/store";
import { DiscountTourModal } from "./DiscountTourModal";
import { updateTourDiscountForAgent } from "api/agent.api";

const ComboTourItem = ({
  comboTour,
  handleClick,
}: {
  comboTour: IComboTour;
  handleClick?: () => void;
}) => {
  return (
    <div onClick={handleClick} className={styles.comboTourItem}>
      <Typography.Text strong>{comboTour.Title}</Typography.Text>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography.Text>{comboTour.SubTitle}</Typography.Text>
        <Typography.Text>{comboTour.Description}</Typography.Text>
      </div>
    </div>
  );
};
export const DiscountTour = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [comboTours, setComboTours] = useState<IComboTour[]>([]);
  const [selectedDiscountComboTour, setSelectedDiscountComboTour] =
    useState<IComboTour | null>(null);
  const selectDiscountAgent = useSelectorRoot(
    (state) => state.agents.discountAgent
  );

  useEffect(() => {
    const today = moment().toISOString();
    setIsFetching(true);
    getAllComboTours({ dateTime: today }).subscribe(
      (res) => {
        res.length && setComboTours(res || []);
        setIsFetching(false);
      },
      (err) => {
        console.log(err);
        notification.error({
          message: err.response.Message
        })
        setIsFetching(false);
      }
    );
  }, []);

  useEffect(() => {
    if (selectedDiscountComboTour) {
      // TODO: get discounts for comboTour by agent
    }
  }, [selectedDiscountComboTour]);

  const handleOpenDiscountForm = (comboTour: IComboTour) => {
    setSelectedDiscountComboTour(comboTour);
  };

  const handleCloseDiscountForm = () => {
    setSelectedDiscountComboTour(null);
  };

  const onUpdateDiscountComboTour = ({ discountValue }:{ discountValue: IPriceDetail }) => {
    if (selectDiscountAgent && selectedDiscountComboTour) {
      // TODO: dispatch action to update discount of combotour
      updateTourDiscountForAgent(
        selectDiscountAgent.id, 
        { 
          itemId: selectedDiscountComboTour.Id,
          priceDetailRequestDto: {
            ...discountValue,
            itemId: selectedDiscountComboTour.Id,
          }
        }
      ).subscribe(
        res => {
          if (res) {
            setSelectedDiscountComboTour(null);
            notification.success({
              message: 'Lưu thông tin thành công'
            })
          }
        },
        err => {
          notification.error({
            message: err.response.Message || 'Lưu không thành công'
          })
        }
      )
    }
  };

  return (
    <Spin spinning={isFetching}>
      <div
        style={{
          minHeight: 600,
          maxHeight: 600,
          overflowY: "auto",
          display: "flex",
          gap: 10,
          flexDirection: "column",
        }}
        className="customScroll"
      >
        {comboTours.length
          ? comboTours.map((comboTour) => (
              <ComboTourItem
                comboTour={comboTour}
                handleClick={() => handleOpenDiscountForm(comboTour)}
              />
            ))
          : !isFetching && <EmptyHolder message="Trống" />}
      </div>
      {!!selectedDiscountComboTour ? (
        <DiscountTourModal open={!!selectedDiscountComboTour}
          onCancel={handleCloseDiscountForm}
          okText="Lưu"
          cancelText="Hủy"
          onOk={onUpdateDiscountComboTour}
          centered 
          selectedComboTour={selectedDiscountComboTour}/>
        ) : (
          <></>
        )}
    </Spin>
  );
};

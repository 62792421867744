import Utils from 'common/Utils';
import SYSTEM_CONSTANTS from 'common/constants';
import { ICreateBus, IParams, IUpdateBus } from 'common/define-types';
import { Observable, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import HttpClient from './http-client';

const api = `${SYSTEM_CONSTANTS.API_URL}/Bus`;

export const getBusById = (id: string): Observable<any> => {
    return HttpClient.get(`${api}/${id}`).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};
export const getAllBus = (params?: IParams): Observable<any> => {
    if (params) {
      return HttpClient.get(`${api}?${Utils.getParamsString(params)}`).pipe(
            map(
                res => (res as any) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    else {
        return HttpClient.get(`${api}`).pipe(
            map(
                res => (res as any) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
};
export const getAllBuses = (params?: IParams): Observable<any> => {
  if (params) {
    return HttpClient.get(`${api}/all?${Utils.getParamsString(params)}`).pipe(
          map(
              res => (res as any) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
  else {
      return HttpClient.get(`${api}`).pipe(
          map(
              res => (res as any) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
};
export const createBus = (body: ICreateBus[]): Observable<any> => {
    return HttpClient.post(`${api}`, JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
      },
    }).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  };
  
  export const updateBus = (body: IUpdateBus): Observable<any> => {
      return HttpClient.put(`${api}?id=${body.id}`, JSON.stringify(body), {
        headers: {
          "Content-Type": "application/json",
        },
      }).pipe(
        map(
          (res) => (res as any) || null,
          catchError((e: AjaxError) => throwError(e))
        )
      );
    };
  export const deleteBusById = (BusId: string): Observable<any> => {
      return HttpClient.delete(`${api}/${BusId}`).pipe(
        map(
          (res) => (res as any) || null,
          catchError((e: AjaxError) => throwError(e))
        )
      );
    };





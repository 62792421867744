import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  RootEpic,
  IExtraService,
  ICommonResult,
  ICreateExtraService,
  IUpdateExtraService,
  IParams,
  IHangHoaItem,
} from "common/define-types";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import {
  createExtraService,
  getAllExtraServicesPagable,
  removeExtraService,
  updateExtraService,
} from "api/extraService.api";
import { getAllHangHoa } from "api/hanghoa.api";

export interface ExtraServiceCategoryState {
  isLoading: boolean;
  isFetchingHangHoa: boolean;
  isSubmitting: boolean;
  extraServices: IExtraService[] | [];
  errMsg: string | null;
  extraServicesResult: ICommonResult | null;
  addModalOpen: boolean;
  deletingExtraServiceId: string | null;
  editingExtraService: IExtraService | null;
  editingExtrarService_hangHoas: IHangHoaItem[];
  // filterValue: IExtraServiceParams | null;
}

const initialState: ExtraServiceCategoryState = {
  isLoading: false,
  isFetchingHangHoa: false,
  isSubmitting: false,
  extraServices: [],
  errMsg: null,
  extraServicesResult: null,
  addModalOpen: false,
  deletingExtraServiceId: null,
  editingExtraService: null,
  editingExtrarService_hangHoas: [],
  // filterValue: {
  //   search: "",
  // },
};

export const extraServiceCategorySlice = createSlice({
  name: "ExtraServiceCategory",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchExtraServiceCategorys: (
      state,
      action: PayloadAction<IParams | undefined>
    ) => {
      state.isLoading = true;
      state.isSubmitting = false;
      state.addModalOpen = false;
      state.editingExtraService = null;
      state.deletingExtraServiceId = null;
      state.errMsg = null;
    },
    setExtraServices: (state, action: PayloadAction<IExtraService[]>) => {
      state.extraServices = action.payload;
      state.isLoading = false;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
    setExtraServicesResult: (
      state,
      action: PayloadAction<ICommonResult | null>
    ) => {
      state.extraServicesResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    editExtraService: (state, action: PayloadAction<IExtraService | null>) => {
      state.editingExtraService = action.payload;
      state.isFetchingHangHoa = action.payload ? true : false;
    },
    setEditingExtraService_hangHoas: (
      state,
      action: PayloadAction<IHangHoaItem[]>
    ) => {
      state.editingExtrarService_hangHoas = action.payload;
      state.isFetchingHangHoa = false;
    },
    addExtraService: (state, action: PayloadAction<ICreateExtraService>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    deleteExtraService: (state, action: PayloadAction<string>) => {
      state.deletingExtraServiceId = action.payload;
      state.errMsg = null;
    },
    setAddModalOpen: (state, action: PayloadAction<boolean>) => {
      state.addModalOpen = action.payload;
    },
    saveExtraService: (state, action: PayloadAction<IUpdateExtraService>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    // setExtraServiceCategoryFilterValue: (
    //   state,
    //   action: PayloadAction<IExtraServiceParams>
    // ) => {
    //   state.filterValue = {
    //     ...state.filterValue,
    //     ...action.payload,
    //   };
    // },
  },
});
const getExtraServiceCategorys$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchExtraServiceCategorys.match),
    switchMap((re) => {
      return getAllExtraServicesPagable({
        ...re.payload,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const { results: extraServices, ...rest } = res;
            return [
              extraServiceCategorySlice.actions.setExtraServices(
                extraServices ?? []
              ),
              extraServiceCategorySlice.actions.setExtraServicesResult(rest),
            ];
          } else {
            return [
              extraServiceCategorySlice.actions.setExtraServices([]),
              extraServiceCategorySlice.actions.setExtraServicesResult(null),
              extraServiceCategorySlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          extraServiceCategorySlice.actions.setExtraServices([]),
          extraServiceCategorySlice.actions.setExtraServicesResult(null),
          extraServiceCategorySlice.actions.setErrMsg(
            e.response?.Message ||
              "Có lỗi xảy ra khi lấy danh sách dịch vụ thêm"
          ),
        ])
      );
    })
  );
// const getExtraServicesWhenFilter$: RootEpic = (action$, state$) =>
//   action$.pipe(
//     filter(setExtraServiceCategoryFilterValue.match),
//     switchMap((re) => {
//       return getAllExtraServices(
//       //   {
//       //   // ...state$.value.ExtraServiceCategory.ExtraServiceCategorysResult,
//       //   ...re.payload,
//       // }
//       ).pipe(
//         mergeMap((res: any) => {
//           //   if (res && !res?.response?.error && res.results) {
//           //     const ExtraServices = res.results;
//           if (res && !res?.response?.error && res) {
//             const extraServices = res;
//             return [
//               extraServiceCategorySlice.actions.setExtraServices(extraServices ?? []),
//               //   ExtraServiceCategorySlice.actions.setExtraServicesResult(res),
//             ];
//           } else {
//             return [
//               extraServiceCategorySlice.actions.setErrMsg(res?.response.error),
//             ];
//           }
//         }),
//         catchError((e: AjaxError) => [
//           extraServiceCategorySlice.actions.setErrMsg(
//             "Có lỗi xảy ra khi lấy danh sách ngày extraService"
//           ),
//         ])
//       );
//     })
//   );
const addExtraService$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addExtraService.match),
    switchMap((re) => {
      return createExtraService(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.extraServiceCategory.extraServicesResult) {
              return [
                extraServiceCategorySlice.actions.fetchExtraServiceCategorys(),
                // state$.value.extraServiceCategory.ExtraServicesResult
              ];
            }
            return [
              extraServiceCategorySlice.actions.fetchExtraServiceCategorys(),
            ];
          } else {
            return [
              extraServiceCategorySlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          extraServiceCategorySlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi thêm dịch vụ thêm"
          ),
        ])
      );
    })
  );
const saveExtraService$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveExtraService.match),
    switchMap((re) => {
      return updateExtraService(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.extraServiceCategory.extraServicesResult) {
              return [
                extraServiceCategorySlice.actions.fetchExtraServiceCategorys(),
                // state$.value.extraServiceCategory.ExtraServicesResult
              ];
            }
            return [
              extraServiceCategorySlice.actions.fetchExtraServiceCategorys(),
            ];
          } else {
            return [
              extraServiceCategorySlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          extraServiceCategorySlice.actions.setErrMsg(
            e.response?.Message ||
              "Có lỗi xảy ra khi lưu thông tin dịch vụ thêm"
          ),
        ])
      );
    })
  );
const deleteExtraService$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteExtraService.match),
    switchMap((re) => {
      return removeExtraService(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.extraServiceCategory.extraServicesResult) {
              return [
                extraServiceCategorySlice.actions.fetchExtraServiceCategorys(),
                // state$.value.extraServiceCategory.ExtraServicesResult
              ];
            }
            return [
              extraServiceCategorySlice.actions.fetchExtraServiceCategorys(),
            ];
          } else {
            return [
              extraServiceCategorySlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          extraServiceCategorySlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi xóa dịch vụ thêm"
          ),
        ])
      );
    })
  );
const fetchHangHoas$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(editExtraService.match),
    switchMap((re) => {
      if (!re.payload) return [];
      return getAllHangHoa({
        typeHangHoa: -1,
        dichVuKhacId: re.payload.id,
        pageSize: 100,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            let hangHoas: IHangHoaItem[] = res.results;
            return [
              extraServiceCategorySlice.actions.setEditingExtraService_hangHoas(
                hangHoas || []
              ),
            ];
          } else {
            return [
              extraServiceCategorySlice.actions.setEditingExtraService_hangHoas(
                []
              ),
              extraServiceCategorySlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          extraServiceCategorySlice.actions.setEditingExtraService_hangHoas([]),
          extraServiceCategorySlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi lấy danh sách hàng hóa"
          ),
        ])
      );
    })
  );

export const {
  fetchExtraServiceCategorys,
  setExtraServices,
  setErrMsg,
  addExtraService,
  setAddModalOpen,
  saveExtraService,
  editExtraService,
  deleteExtraService,
  setEditingExtraService_hangHoas,
  setExtraServicesResult,
} = extraServiceCategorySlice.actions;

export const ExtraServiceCategoryEpics = [
  getExtraServiceCategorys$,
  addExtraService$,
  saveExtraService$,
  deleteExtraService$,
  fetchHangHoas$,
  // getExtraServicesWhenFilter$,
];

export const extraServiceCategoryReducer = extraServiceCategorySlice.reducer;

import { Spin, Typography } from "antd";
import { GroupItemType, IGroupJobItem, MoveJobArgs } from "common/define-types";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { useMemo, useCallback, useState, useEffect } from "react";
import { moveJob } from "store/slice/TourCategorySlice";
import styles from "../TourCategory.module.css";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import { JobItemCard } from "./JobItemCard";
import { getJobItemByDay } from "api/jobItem.api";

export const JobItemTab = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [jobItems, setJobItems] = useState<IGroupJobItem[]>([]);
  const dispatch = useDispatchRoot();
  const selectJobToursGroupByDay = useSelectorRoot(
    (state) => state.tourCategory.jobToursGroupByDay
  );
  // const selectJobItems = useSelectorRoot((state) => state.supplier.jobItems);

  const currentJobTours = useMemo(
    () =>
      Object.values(selectJobToursGroupByDay).reduce(
        (prev, groupJobs) => [...prev, ...groupJobs],
        []
      ) || [],
    [selectJobToursGroupByDay]
  );

  const handleMoveJob = useCallback((args: MoveJobArgs) => {
    dispatch(moveJob(args));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    setLoading(true);
    getJobItemByDay().subscribe(
      (res) => {
        if (res && res.results) {
          const jobItems = res.results || [];
          setJobItems(
            jobItems.map((job: IGroupJobItem) => ({
              ...job,
              id:
                job.id || job.type === GroupItemType.Parent
                  ? job.id
                  : job.jobItems[0]?.id || "",
            }))
          );
        } else {
          setJobItems([]);
        }
      },
      (err) => {
        console.log(err);
        setJobItems([]);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  const availableJobItems = jobItems.filter(
    (jobItem) =>
      !currentJobTours.some(
        (jobT) => {
          return jobT.groupItemId === jobItem.id || jobT.id === jobItem.id;
        }
        // jobT.type === GroupItemType.Parent
        //   ? jobT.groupItemId === jobItem.id || jobT.id === jobItem.id
        //   : !!jobItem.jobItems.find((job) => job.id === jobT.id)
      )
  );

  return (
    <div className={`${styles.jobItemTab} ${styles.tabContainer}`}>
      <div className={styles.spaceBetween}>
        <Typography.Text
          strong
          className={styles.tabTitle}
          title={`Danh mục Điểm đến`}
        >
          Danh mục Điểm đến
        </Typography.Text>
        {/* <Input.Search
          placeholder="Tìm kiếm"
          style={{ maxWidth: 160, minWidth: 120 }}
        /> */}
      </div>
      <Spin spinning={loading}>
        <div className={`${styles.jobItemList} customScroll`}>
          {availableJobItems.length ? (
            availableJobItems.map((jobItem, index) => (
              <JobItemCard
                jobItem={jobItem}
                index={index}
                key={jobItem.id}
                onClickAdd={() =>
                  handleMoveJob({ dragJobItem: jobItem, hoverIndex: 0 })
                }
              />
            ))
          ) : (
            <EmptyHolder message="Trống" />
          )}
        </div>
      </Spin>
    </div>
  );
};

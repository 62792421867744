import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { RootEpic, IDevice } from "common/define-types";
import { AjaxError } from "rxjs/ajax";
import { getAllDevices, registerDevice } from "api/devices.api";

export interface DevicesState {
  isLoading: boolean;
  isSubmitting: boolean;
  devices: IDevice[];
  fcmToken: string | null;
  errMsg: string | null;
}

const initialState: DevicesState = {
  isLoading: false,
  isSubmitting: false,
  errMsg: null,
  devices: [],
  fcmToken: null,
};

export const devicesSlice = createSlice({
  name: "devices",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchDevices: (state, action: PayloadAction) => {
      state.isLoading = true;
      state.errMsg = null;
    },
    setDevices: (state, action: PayloadAction<IDevice[]>) => {
      state.devices = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    checkAndRegisterDevice: (state, action: PayloadAction<IRegisterDevice>) => {
      state.isSubmitting = true;
    },
    registerUserDevice: (state, action: PayloadAction<IRegisterDevice>) => {
      state.isSubmitting = true;
    },
    setFCMToken: (state, action: PayloadAction<string | null>) => {
      state.fcmToken = action.payload;
      state.isSubmitting = false;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
  },
});

const fetchDevices$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchDevices.match),
    switchMap((re) => {
      return getAllDevices().pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const devices = res;
            return [devicesSlice.actions.setDevices(devices ?? [])];
          } else {
            return [devicesSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          devicesSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách thiết bị cho notification"
          ),
        ])
      );
    })
  );
const registerUserDevice$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(registerUserDevice.match),
    switchMap((re) => {
      return registerDevice(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [devicesSlice.actions.setFCMToken(re.payload.token ?? null)];
          } else {
            return [devicesSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          devicesSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi đăng kí token cho device"
          ),
        ])
      );
    })
  );

export const {
  fetchDevices,
  setDevices,
  registerUserDevice,
  setFCMToken,
  setErrMsg,
  checkAndRegisterDevice,
} = devicesSlice.actions;

export const DevicesEpics = [fetchDevices$, registerUserDevice$];

export const devicesReducer = devicesSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RootEpic,
  ITour,
  ICommonResult,
  ICreateTour,
  IUpdateTour,
  MoveJobArgs,
  IUpdateJobTours,
  IParams,
  IGroupJobItem,
  GroupItemType,
  JobTourExtraType,
} from 'common/define-types';
import { filter, switchMap, mergeMap, catchError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import {
  createTour,
  getAllToursPageable,
  removeTour,
  updateTour,
} from 'api/tour.api';
import { getJobTourByTourId, updateJobTours } from 'api/jobTour.api';
import Utils from 'common/Utils';

export interface TourCategoryState {
  loading: {
    isFetching: boolean;
    isSubmitting: boolean;
    isFetchingJobTour: boolean;
  };
  tours: ITour[] | [];
  errMsg: string | null;
  toursResult: ICommonResult | null;
  addModalOpen: boolean;
  deletingTourId: string | null;
  editingTour: ITour | null;
  editingJobTourByTour: ITour | null;
  // edit job tour modal
  selectedOrdinalDay: string;
  jobToursGroupByDay: { [key: string]: IGroupJobItem[] };
  jobToursByTour: IGroupJobItem[];
  filterValue: IParams | null;
}

const initialState: TourCategoryState = {
  loading: {
    isFetching: false,
    isSubmitting: false,
    isFetchingJobTour: false,
  },
  tours: [],
  errMsg: null,
  toursResult: null,
  addModalOpen: false,
  deletingTourId: null,
  editingTour: null,
  editingJobTourByTour: null,

  selectedOrdinalDay: '1',
  jobToursGroupByDay: {},
  jobToursByTour: [],
  filterValue: {
    search: '',
  },
};

export const tourCategorySlice = createSlice({
  name: 'TourCategory',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchTourCategorys: (state, action: PayloadAction<IParams | undefined>) => {
      state.loading.isFetching = true;
      state.loading.isSubmitting = false;
      state.addModalOpen = false;
      state.editingTour = null;
      state.deletingTourId = null;
      state.errMsg = null;
    },
    startLoading: (state, action: PayloadAction<{ key: string }>) => {
      const { key } = action.payload;
      state.loading = {
        ...state.loading,
        [key]: true,
      };
    },
    stopLoading: (state, action: PayloadAction<{ key: string }>) => {
      const { key } = action.payload;
      state.loading = {
        ...state.loading,
        [key]: false,
      };
    },
    setTours: (state, action: PayloadAction<ITour[]>) => {
      state.tours = action.payload;
      state.loading.isFetching = false;
    },
    setJobToursByTour: (state, action: PayloadAction<IGroupJobItem[]>) => {
      state.jobToursByTour = action.payload;
      state.loading.isFetchingJobTour = false;
    },
    setJobToursByDay: (
      state,
      action: PayloadAction<{ [key: number]: IGroupJobItem[] }>
    ) => {
      state.jobToursGroupByDay = action.payload;
      state.loading.isFetchingJobTour = false;
    },
    setSelectedOrdinalDay: (state, action: PayloadAction<string>) => {
      state.selectedOrdinalDay = action.payload;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.loading.isFetching = false;
      state.loading.isSubmitting = false;
    },
    setToursResult: (state, action: PayloadAction<ICommonResult | null>) => {
      state.toursResult = action.payload;
      state.loading.isFetching = false;
      state.errMsg = null;
    },
    editTour: (state, action: PayloadAction<ITour | null>) => {
      state.editingTour = action.payload;
    },
    editJobTourOfTour: (state, action: PayloadAction<ITour | null>) => {
      state.editingJobTourByTour = action.payload;
      state.loading.isFetchingJobTour = !!action.payload;
      if (!action.payload) {
        state.selectedOrdinalDay = '1';
      }
    },
    addTour: (state, action: PayloadAction<ICreateTour>) => {
      state.loading.isSubmitting = true;
      state.errMsg = null;
    },
    deleteTour: (state, action: PayloadAction<string>) => {
      state.deletingTourId = action.payload;
      state.errMsg = null;
    },
    setAddModalOpen: (state, action: PayloadAction<boolean>) => {
      state.addModalOpen = action.payload;
    },
    saveTour: (state, action: PayloadAction<IUpdateTour>) => {
      state.loading.isSubmitting = true;
      state.errMsg = null;
    },
    moveJob: (state, action: PayloadAction<MoveJobArgs>) => {
      const prevJobTours = state.jobToursGroupByDay;
      const selectedOrdinalDay = state.selectedOrdinalDay;
      const lastIndex = selectedOrdinalDay.lastIndexOf('-');
      const ordinalDay = selectedOrdinalDay.includes('extra')
        ? parseInt(selectedOrdinalDay.slice(lastIndex + 1))
        : parseInt(selectedOrdinalDay);
      const { dragJob, hoverIndex, dragJobItem } = action.payload;

      let jobToursByCurrentDay = prevJobTours[selectedOrdinalDay]
        ? [...prevJobTours[selectedOrdinalDay]]
        : [];
      if (dragJob) {
        const dragIndex = jobToursByCurrentDay.findIndex(
          (job) => job.id === dragJob.id
        );

        const newDragJob: IGroupJobItem = {
          ...dragJob,
          day: ordinalDay,
          // jobs: (dragJob.jobs || []).map((job) => ({
          //   ...job,
          //   day: selectedOrdinalDay.toString(),
          // })),
          // jobItems:
          //   dragJob.type === GroupItemType.Parent
          //     ? dragJob.jobItems
          //     : dragJob.jobItems
          //     ? dragJob.jobItems.map((jobItem) => ({
          //         ...jobItem,
          //         day: selectedOrdinalDay,
          //       }))
          //     : [],
          groupItemDTOs:
            dragJob.type === GroupItemType.Parent
              ? dragJob.groupItemDTOs?.map((groupItem) => ({
                  ...groupItem,
                  day: ordinalDay,
                }))
              : dragJob.groupItemDTOs,
        };
        if (dragIndex === -1) {
          // const newJob: IJob = {
          //   ...dragJobItem,
          // }
          jobToursByCurrentDay.splice(hoverIndex, 0, newDragJob);
        } else {
          jobToursByCurrentDay.splice(dragIndex, 1);
          jobToursByCurrentDay.splice(hoverIndex, 0, newDragJob);
        }
      } else if (dragJobItem) {
        const dragIndex = jobToursByCurrentDay.findIndex(
          (job) => job.id === dragJobItem.id
        );
        const newJob: IGroupJobItem = {
          ...dragJobItem,
          day: ordinalDay,
          // jobs: [],
          groupItemDTOs:
            dragJobItem.type === GroupItemType.Parent
              ? dragJobItem.groupItemDTOs?.map((groupItem) => ({
                  ...groupItem,
                  day: ordinalDay,
                }))
              : dragJobItem.groupItemDTOs,
        };
        if (dragIndex === -1) {
          jobToursByCurrentDay.splice(hoverIndex, 0, newJob);
        } else {
          jobToursByCurrentDay.splice(dragIndex, 1);
          jobToursByCurrentDay.splice(hoverIndex, 0, newJob);
        }
      }

      state.jobToursGroupByDay = {
        ...prevJobTours,
        [selectedOrdinalDay]: jobToursByCurrentDay,
      };
      state.errMsg = null;
    },
    removeJob: (state, action: PayloadAction<{ jobTourId: string }>) => {
      const prevJobTours = state.jobToursGroupByDay;
      const selectedOrdinalDay = state.selectedOrdinalDay;
      const { jobTourId: removeJobId } = action.payload;

      let jobToursByCurrentDay = [...prevJobTours[selectedOrdinalDay]];

      state.jobToursGroupByDay = {
        ...prevJobTours,
        [selectedOrdinalDay]: jobToursByCurrentDay.filter(
          (jobTour) => jobTour.id !== removeJobId
        ),
      };
      state.errMsg = null;
    },
    resetJobTourModalState: (state, action: PayloadAction) => {
      state.jobToursGroupByDay = {};
      state.selectedOrdinalDay = '1';
      state.loading.isFetchingJobTour = false;
      state.errMsg = null;
    },
    saveJobTours: (state, action: PayloadAction<IUpdateJobTours>) => {},
    setTourCategoryFilterValue: (state, action: PayloadAction<IParams>) => {
      state.filterValue = {
        ...state.filterValue,
        ...action.payload,
      };
    },
  },
});
const getTourCategorys$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchTourCategorys.match),
    switchMap((re) => {
      return getAllToursPageable(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const { results: tours, ...rest } = res;
            // if (res && !res?.response?.error && res) {
            //   const tours = res;
            return [
              tourCategorySlice.actions.setTours(tours ?? []),
              tourCategorySlice.actions.setToursResult(rest),
            ];
          } else {
            return [
              tourCategorySlice.actions.setTours([]),
              tourCategorySlice.actions.setToursResult(null),
              tourCategorySlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          tourCategorySlice.actions.setTours([]),
          tourCategorySlice.actions.setToursResult(null),
          tourCategorySlice.actions.setErrMsg(
            e.response?.Message || 'Có lỗi xảy ra khi lấy danh sách ngày tour'
          ),
        ])
      );
    })
  );
const getToursWhenFilter$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setTourCategoryFilterValue.match),
    switchMap((re) => {
      return getAllToursPageable({
        ...state$.value.tourCategory.toursResult,
        ...re.payload,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const { results: tours, ...rest } = res;
            // if (res && !res?.response?.error && res) {
            //   const tours = res;
            return [
              tourCategorySlice.actions.setTours(tours ?? []),
              tourCategorySlice.actions.setToursResult(rest),
            ];
          } else {
            return [
              tourCategorySlice.actions.setTours([]),
              tourCategorySlice.actions.setToursResult(null),
              tourCategorySlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          tourCategorySlice.actions.setTours([]),
          tourCategorySlice.actions.setToursResult(null),
          tourCategorySlice.actions.setErrMsg(
            e.response?.Message || 'Có lỗi xảy ra khi lấy danh sách ngày tour'
          ),
        ])
      );
    })
  );
const addTour$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addTour.match),
    switchMap((re) => {
      return createTour(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.tourCategory.toursResult) {
              return [
                tourCategorySlice.actions.fetchTourCategorys(),
                // state$.value.tourCategory.ToursResult
              ];
            }
            return [tourCategorySlice.actions.fetchTourCategorys()];
          } else {
            return [tourCategorySlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          tourCategorySlice.actions.setErrMsg(
            'Có lỗi xảy ra khi thêm ngày tour'
          ),
        ])
      );
    })
  );
const saveTour$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveTour.match),
    switchMap((re) => {
      return updateTour(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.tourCategory.toursResult) {
              return [
                tourCategorySlice.actions.fetchTourCategorys(),
                // state$.value.tourCategory.ToursResult
              ];
            }
            return [tourCategorySlice.actions.fetchTourCategorys()];
          } else {
            return [tourCategorySlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          tourCategorySlice.actions.setErrMsg(
            'Có lỗi xảy ra khi lưu thông tin ngày tour'
          ),
        ])
      );
    })
  );
const deleteTour$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteTour.match),
    switchMap((re) => {
      return removeTour(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.tourCategory.toursResult) {
              return [
                tourCategorySlice.actions.fetchTourCategorys(),
                // state$.value.tourCategory.ToursResult
              ];
            }
            return [tourCategorySlice.actions.fetchTourCategorys()];
          } else {
            return [tourCategorySlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          tourCategorySlice.actions.setErrMsg(
            'Có lỗi xảy ra khi xóa ngày tour'
          ),
        ])
      );
    })
  );

const editJobTourOfTour$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(editJobTourOfTour.match),
    switchMap((re) => {
      if (!re.payload) return [];
      return getJobTourByTourId(re.payload.id).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const jobTours: IGroupJobItem[] = res.results || [];

            const sortedJobs = [...jobTours]
              .sort((a, b) => {
                const sttA = parseInt(a.stt);
                const sttB = parseInt(b.stt);
                return sttA - sttB;
              })
              .map((job) => ({
                ...job,
                type:
                  job.groupItemDTOs && job.groupItemDTOs?.length
                    ? GroupItemType.Parent
                    : GroupItemType.Child,
              }));
            const jobToursGroupByExtraType = Utils.groupBy(
              sortedJobs,
              'extraType'
            );

            const normalJobToursGroupByDay = Utils.groupBy(
              jobToursGroupByExtraType[JobTourExtraType.Normal],
              'day'
            );

            const extraJobToursGroupByDay = Utils.groupBy(
              jobToursGroupByExtraType[JobTourExtraType.Extra],
              'day'
            );

            let convertExtraJobTours: any = {};
            for (const [key, value] of Object.entries(
              extraJobToursGroupByDay
            )) {
              const newKey = `extra-${key}`;
              convertExtraJobTours[newKey] = value;
            }

            return [
              tourCategorySlice.actions.setJobToursByDay({
                ...normalJobToursGroupByDay,
                ...convertExtraJobTours,
              }),
              tourCategorySlice.actions.setJobToursByTour(sortedJobs),
            ];
          } else {
            return [tourCategorySlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => {
          console.log(e);

          return [
            tourCategorySlice.actions.setJobToursByDay({}),
            tourCategorySlice.actions.setJobToursByTour([]),
            tourCategorySlice.actions.setErrMsg(
              e.response?.Message ||
                'Có lỗi xảy ra khi lấy danh sách điểm đến của tour'
            ),
          ];
        })
      );
    })
  );
const saveJobTours$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveJobTours.match),
    switchMap((re) => {
      if (!re.payload) return [];
      return updateJobTours(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [
              tourCategorySlice.actions.editJobTourOfTour(null),
              tourCategorySlice.actions.stopLoading({ key: 'isSubmitting' }),
            ];
          } else {
            return [tourCategorySlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          tourCategorySlice.actions.setErrMsg(
            e.response?.Message || 'Có lỗi xảy ra khi lưu danh sách'
          ),
        ])
      );
    })
  );

export const {
  fetchTourCategorys,
  setTours,
  setErrMsg,
  addTour,
  setAddModalOpen,
  saveTour,
  editTour,
  editJobTourOfTour,
  deleteTour,
  moveJob,
  resetJobTourModalState,
  setSelectedOrdinalDay,
  removeJob,
  setJobToursByTour,
  saveJobTours,
  startLoading,
  stopLoading,
  setTourCategoryFilterValue,
} = tourCategorySlice.actions;

export const TourCategoryEpics = [
  getTourCategorys$,
  addTour$,
  saveTour$,
  deleteTour$,
  editJobTourOfTour$,
  saveJobTours$,
  getToursWhenFilter$,
];

export const tourCategoryReducer = tourCategorySlice.reducer;

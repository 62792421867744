import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import { IParams, IUpdateDiscountAgent } from "common/define-types";
import { ICreateAgent, IUpdateAgent } from "./types/agent";
import Utils from "common/Utils";

const api = `${SYSTEM_CONSTANTS.API_URL}/Agent`;

export const getAgentById = (id: string): Observable<any> => {
  return HttpClient.get(`${api}/${id}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const getAllAgents = (params?: IParams): Observable<any> => {
  if (params) {
    return HttpClient.get(`${api}?${Utils.getParamsString(params)}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(`${api}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};
export const getCurrentAgent = (): Observable<any> => {
  return HttpClient.get(`${api}/current`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const createAgent = (body: ICreateAgent): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const createAgents = (body: ICreateAgent[]): Observable<any> => {
  return HttpClient.post(`${api}/listAgent`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const deleteAgentById = (agentId: string): Observable<any> => {
  return HttpClient.delete(`${api}/${agentId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const updateAgent = (body: IUpdateAgent): Observable<any> => {
  return HttpClient.put(`${api}/${body.agentId}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const assignAccount = (
  accountIds: string[],
  agentId: string
): Observable<any> => {
  return HttpClient.put(
    `${api}/agent/${agentId}/assignAccount`,
    JSON.stringify(accountIds),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const removeAccount = (
  accountIds: string[],
  agentId: string
): Observable<any> => {
  return HttpClient.put(
    `${api}/agent/${agentId}/removeAccount`,
    JSON.stringify(accountIds),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getAllAgentsByMonth = (params: IParams): Observable<any> => {
  return HttpClient.get(`${api}/agents?${Utils.getParamsString(params)}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getAllAgentsForDashboard = (params: IParams): Observable<any> => {
  return HttpClient.get(
    `${api}/agents/dashboard?${Utils.getParamsString(params)}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getAllAgentsOfTopDays = (params: {
  dateTime: string | null;
  topDay: number;
}): Observable<any> => {
  return HttpClient.get(
    `${api}/agents/top?${Utils.getParamsString(params)}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getPriceTourForAgent = (
  agentId: string,
  params: {
    comboId: string;
  }
): Observable<any> => {
  return HttpClient.get(
    `${api}/agent/${agentId}/priceTourForAgent?comboId=${params.comboId}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const getPriceBusForAgent = (
  agentId: string,
  params: {
    routerBusId: string;
  }
): Observable<any> => {
  return HttpClient.get(
    `${api}/agent/${agentId}/priceBusForAgent?routerBusId=${params.routerBusId}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const updateTourDiscountForAgent = (
  agentId: string,
  body: IUpdateDiscountAgent
): Observable<any> => {
  return HttpClient.put(`${api}/agent/${agentId}/tour`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const updateBusDiscountForAgent = (
  agentId: string,
  body: IUpdateDiscountAgent
): Observable<any> => {
  return HttpClient.put(`${api}/agent/${agentId}/bus`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createBus, deleteBusById, getAllBuses, updateBus } from "api/bus.api";
import {
  IBus,
  IBusesResult,
  IBusFilterValue,
  ICreateBus,
  IParams,
  IUpdateBus,
  RootEpic,
} from "common/define-types";
import { catchError, filter, mergeMap, switchMap } from "rxjs";
import { AjaxError } from "rxjs/ajax";

export interface BusState {
  isLoading: boolean;
  isSubmitting: boolean;
  Buses: IBus[] | [];
  errMsg: string | null;
  BusesResult: IBusesResult | null;
  addModalOpen: boolean;
  deletingBusId: string | null;
  editingBus: IBus | null;
  filterValue: IBusFilterValue | null;
}

const initialState: BusState = {
  isLoading: false,
  isSubmitting: false,
  Buses: [],
  errMsg: null,
  BusesResult: null,
  addModalOpen: false,
  deletingBusId: null,
  editingBus: null,
  filterValue: {
    search: "",
  },
};

export const BusSlice = createSlice({
  name: "Bus",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchBuses: (state, action: PayloadAction<IParams | undefined>) => {
      state.isLoading = true;
      state.isSubmitting = false;
      state.addModalOpen = false;
      state.editingBus = null;
      state.deletingBusId = null;
      state.errMsg = null;
    },
    setBuses: (state, action: PayloadAction<IBus[]>) => {
      state.Buses = action.payload;
      state.isLoading = false;
    },
    setErrMsg: (state, action: PayloadAction<string>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
    setBusesResult: (state, action: PayloadAction<IBusesResult>) => {
      state.BusesResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    editBus: (state, action: PayloadAction<IBus | null>) => {
      state.editingBus = action.payload;
    },
    addBus: (state, action: PayloadAction<ICreateBus>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    deleteBus: (state, action: PayloadAction<string>) => {
      state.deletingBusId = action.payload;
      state.errMsg = null;
    },
    setAddModalOpen: (state, action: PayloadAction<boolean>) => {
      state.addModalOpen = action.payload;
    },
    saveBus: (state, action: PayloadAction<IUpdateBus>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    setBusFilterValue: (
      state,
      action: PayloadAction<IBusFilterValue>
    ) => {
      state.filterValue = {
        ...state.filterValue,
        ...action.payload,
      };
      console.log('filterValue: ',state.filterValue);
    },
  },
});
const getBuses$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchBuses.match),
    switchMap((re) => {
      return getAllBuses({ ...re.payload }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error  && res.results) {
            const Buses = res.results;
            return [
              BusSlice.actions.setBuses(Buses),
              BusSlice.actions.setBusesResult(res),
            ];
          } else {
            return [BusSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          BusSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách vị trí"
          ),
        ])
      );
    })
  );
const getBusesWhenFilter$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setBusFilterValue.match),
    switchMap((re) => {
      return getAllBuses({
        // ...state$.value.Bus.BussResult,
        ...re.payload,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error  && res.results) {
            const Buses = res.results;
            return [
              BusSlice.actions.setBuses(Buses ?? []),
              BusSlice.actions.setBusesResult(res),
            ];
          } else {
            return [BusSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          BusSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách vị trí"
          ),
        ])
      );
    })
  );
const addBuses$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addBus.match),
    switchMap((re) => {
      return createBus([re.payload]).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.bus.BusesResult) {
              return [
                BusSlice.actions.fetchBuses(
                  state$.value.bus.BusesResult
                ),
              ];
            }
            return [BusSlice.actions.fetchBuses()];
          } else {
            return [BusSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          BusSlice.actions.setErrMsg("Có lỗi xảy ra khi thêm vị trí"),
        ])
      );
    })
  );
const saveBuses$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveBus.match),
    switchMap((re) => {
      return updateBus(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.bus.BusesResult) {
              return [
                BusSlice.actions.fetchBuses(
                  state$.value.bus.BusesResult
                ),
              ];
            }
            return [BusSlice.actions.fetchBuses()];
          } else {
            return [BusSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          BusSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lưu thông tin vị trí"
          ),
        ])
      );
    })
  );
const deleteBuses$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteBus.match),
    switchMap((re) => {
      return deleteBusById(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.bus.BusesResult) {
              return [
                BusSlice.actions.fetchBuses(
                  state$.value.bus.BusesResult
                ),
              ];
            }
            return [BusSlice.actions.fetchBuses()];
          } else {
            return [BusSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          BusSlice.actions.setErrMsg("Có lỗi xảy ra khi xóa vị trí"),
        ])
      );
    })
  );


export const {
  fetchBuses,
  setBuses,
  setErrMsg,
  addBus,
  setAddModalOpen,
  saveBus,
  editBus,
  deleteBus,
  setBusFilterValue,
} = BusSlice.actions;

export const BusEpics = [
  getBuses$,
  addBuses$,
  saveBuses$,
  deleteBuses$,
  getBusesWhenFilter$,
];

export const BusReducer = BusSlice.reducer;

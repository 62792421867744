import {
  Form,
  FormInstance,
  FormListFieldData,
  Input,
  InputNumber,
  Typography,
} from "antd";
import ExtraServiceSelect from "components/Selects/ExtraServiceSelect";
import ExtraServiceItem from "./ExtraServiceItem";
import styles from "./ExtraService.module.css";
import { RoomTemplateSelect } from "components/Selects/RoomTemplateSelect";
import {
  IExtraService,
  ExtraServiceCode,
  RoomTemplateType,
  IComboTourItem,
  ITicket_ExtraService,
  ComboTourItemType,
} from "common/define-types";
import HangHoaSelect from "components/Selects/HangHoaSelect";
import moment from "moment";
import Utils from "common/Utils";
import { ServiceType } from "pages/Admin/pages/ExtraService/components/ExtraServiceForm";
import { useEffect } from "react";
import { useSelectorRoot } from "store/store";

interface IProps {
  form: FormInstance<any>;
  className?: string;
  ticketStartDate: string;
  extraServices: IExtraService[];
  selectedExtraServices: { [key: string]: boolean };
  setSelectedService: (args: { [key: string]: boolean }) => void;
  foundDriverPrice?: IComboTourItem;
  extraService_combos?: IComboTourItem[];
  formStyle?: React.CSSProperties;
  titleClassName?: string;
}
const ExtraServiceTab = ({
  form,
  className,
  ticketStartDate,
  extraServices,
  selectedExtraServices,
  setSelectedService,
  foundDriverPrice,
  extraService_combos,
  formStyle,
  titleClassName,
}: IProps) => {
  // const selectExtraService = useSelectorRoot(
  //   (state) => state.booking.extraServices
  // );
  // const selectSelectedService = useSelectorRoot(
  //   (state) => state.booking.selectedExtraServices
  // );
  // const selectTicketSelected = useSelectorRoot(
  //   (state) => state.booking.selectedTicket
  // )
  const hangHoaMotors = useSelectorRoot((state) => state.hangHoa.motorbikes);

  const datA_TOUR_CHHangHoaDichVuKhacs_value = Form.useWatch(
    "datA_TOUR_CHHangHoaDichVuKhacs",
    form
  );
  const deleteService = (serviceId: string) => {
    handleSelectedService({
      ...selectedExtraServices,
      [serviceId]: false,
    });
  };
  useEffect(() => {
    console.log(datA_TOUR_CHHangHoaDichVuKhacs_value);
    // if (!datA_TOUR_CHHangHoaDichVuKhacs_value && ids?.length > 0) return;
    let selectedExtraServicesValue = {};
    if (datA_TOUR_CHHangHoaDichVuKhacs_value?.length) {
      datA_TOUR_CHHangHoaDichVuKhacs_value.forEach(
        (value: ITicket_ExtraService) => {
          selectedExtraServicesValue = {
            ...selectedExtraServicesValue,
            [value.dmuC_CHHangHoaDichVuKhacId]: true,
          };
        }
      );
      setSelectedService(selectedExtraServicesValue);
    } else {
      setSelectedService({});
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [datA_TOUR_CHHangHoaDichVuKhacs_value]);

  const handleSelectedService = (services: { [key: string]: boolean }) => {
    setSelectedService(services);
    const ids = Object.keys(services);
    // if (!datA_TOUR_CHHangHoaDichVuKhacs_value && ids?.length > 0) return;

    if (services) {
      let datA_TOUR_CHHangHoaDichVuKhacs: ITicket_ExtraService[] = [];
      ids.forEach((id) => {
        if (services[id] !== true) return;
        const foundExtraService = extraServices.find(
          (extraService) => extraService.id === id
        );
        if (!foundExtraService) return;
        const foundExistedService = (
          datA_TOUR_CHHangHoaDichVuKhacs_value || []
        ).find(
          (service: ITicket_ExtraService) =>
            service.dmuC_CHHangHoaDichVuKhacId === id
        );
        if (foundExistedService) {
          datA_TOUR_CHHangHoaDichVuKhacs.push({
            ...foundExistedService,
          });
        } else {
          datA_TOUR_CHHangHoaDichVuKhacs.push({
            dmuC_CHHangHoaDichVuKhacId: id,
            price: 0,
            soLuong:
              foundExtraService?.code === ExtraServiceCode.CHANGE_VEHICLE
                ? Utils.toNumber(foundDriverPrice?.CountDay, 1)
                : 1,
            extraService: foundExtraService,
          });
        }
      });
      form.setFieldValue(
        "datA_TOUR_CHHangHoaDichVuKhacs",
        datA_TOUR_CHHangHoaDichVuKhacs
      );
    }
  };

  // useEffect(() => {
  //   // console.log(datA_TOUR_CHHangHoaDichVuKhacs_value);
  //   const ids = Object.keys(selectedExtraServices);
  //   // if (!datA_TOUR_CHHangHoaDichVuKhacs_value && ids?.length > 0) return;

  //   if (selectedExtraServices) {
  //     let datA_TOUR_CHHangHoaDichVuKhacs: ITicket_ExtraService[] = [];
  //     ids.forEach((id) => {
  //       if (selectedExtraServices[id] !== true) return;
  //       const foundExtraService = extraServices.find(
  //         (extraService) => extraService.id === id
  //       );
  //       if (!foundExtraService) return;
  //       const foundExistedService = (
  //         datA_TOUR_CHHangHoaDichVuKhacs_value || []
  //       ).find(
  //         (service: ITicket_ExtraService) =>
  //           service.dmuC_CHHangHoaDichVuKhacId === id
  //       );
  //       if (foundExistedService) {
  //         datA_TOUR_CHHangHoaDichVuKhacs.push({
  //           ...foundExistedService,
  //         });
  //       } else {
  //         datA_TOUR_CHHangHoaDichVuKhacs.push({
  //           dmuC_CHHangHoaDichVuKhacId: id,
  //           price: 0,
  //           soLuong:
  //             foundExtraService?.code === ExtraServiceCode.CHANGE_VEHICLE
  //               ? Utils.toNumber(foundDriverPrice?.CountDay, 1)
  //               : 1,
  //           extraService: foundExtraService,
  //         });
  //       }
  //     });
  //     form.setFieldValue(
  //       "datA_TOUR_CHHangHoaDichVuKhacs",
  //       datA_TOUR_CHHangHoaDichVuKhacs
  //     );
  //   }
  //   /* eslint-disable react-hooks/exhaustive-deps */
  // }, [
  //   selectedExtraServices,
  //   // datA_TOUR_CHHangHoaDichVuKhacs_value,
  //   extraServices,
  //   form,
  //   foundDriverPrice?.CountDay,
  // ]);

  const hasCombo = (service: IExtraService) => {
    if (!extraService_combos?.length || !service) return false;
    return extraService_combos.some((combo) =>
      combo.ItemType === ComboTourItemType.RoomTemplate
        ? service.code === ExtraServiceCode.CHANGE_ROOM
        : combo.DMUC_CHHangHoaDichVuKhacs?.Id === service.id
    );
  };

  const renderFormItem = (service: IExtraService, field: FormListFieldData) => {
    if (!service) return <></>;
    const disabled = hasCombo(service);
    const { key, name, ...restField } = field;

    if (service.code === ExtraServiceCode.CHANGE_ROOM) {
      return (
        <>
          <Form.Item
            {...restField}
            label={service.name}
            className={styles.formItem}
            name={[name, "roomTemplateIds"]}
            rules={[
              {
                required: true,
                message: "Không được bỏ trống",
              },
            ]}
          >
            <RoomTemplateSelect
              multiple
              style={{ width: "100%", marginBottom: "10px", marginTop: 8 }}
              acceptType={[
                RoomTemplateType.CHANGE_ROOM,
                RoomTemplateType.EXTRA,
              ]}
              readOnly={!!disabled}
            />
          </Form.Item>
        </>
      );
    }
    if (service.type === ServiceType.NORMAL) {
      return (
        <>
          <div className={styles.formItem_group}>
            <Form.Item
              {...restField}
              name={[name, `price`]}
              label="Số tiền (đ)"
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: "Không được bỏ trống",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                stringMode={true}
                controls={false}
                formatter={(value) =>
                  `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                }
                parser={(value) =>
                  parseInt(value ? value?.replaceAll(".", "") : "0")
                }
                readOnly={!!disabled}
              />
            </Form.Item>
            <Form.Item
              {...restField}
              name={[name, `note`]}
              label="Ghi chú"
              className={styles.formItem}
            >
              <Input.TextArea rows={1} readOnly={!!disabled} />
            </Form.Item>
          </div>
        </>
      );
    } else if (service.type === ServiceType.HANGHOA) {
      return (
        <>
          <div
            className={styles.formItem_group}
            style={{ marginBottom: "10px" }}
          >
            <Form.Item
              {...restField}
              className={styles.formItem}
              name={[name, "soLuong"]}
              label="Số lượng"
              rules={[
                {
                  required: true,
                  message: "Không được bỏ trống",
                },
              ]}
            >
              <InputNumber
                min={1}
                style={{ width: "100%" }}
                readOnly={!!disabled}
              />
            </Form.Item>
            <Form.Item
              {...restField}
              className={styles.formItem}
              name={[name, "dmuC_HangHoaId"]}
              label="Hàng hóa"
              rules={[
                {
                  required: true,
                  message: "Không được bỏ trống",
                },
              ]}
            >
              <HangHoaSelect
                defaultOptions={hangHoaMotors}
                testId="DoiXe_Dropdown"
                filterValue={{
                  dateTime:
                    ticketStartDate ||
                    moment.utc().add(2, "days").toISOString(),
                  typeHangHoa: -1,
                  dichVuKhacId: service.id,
                  pageSize: 100,
                }}
                style={{ width: "100%" }}
                onChange={(motorbike) => {
                  form.setFieldValue(
                    ["datA_TOUR_CHHangHoaDichVuKhacs", name, "dmuC_HangHoaId"],
                    motorbike?.id
                  );
                  form.setFieldValue(
                    ["datA_TOUR_CHHangHoaDichVuKhacs", name, "price"],
                    motorbike?.price || 0
                    // Utils.toNumber(datA_TOUR_CHHangHoaDichVuKhacs_value[name].soLuong, 1)
                  );
                }}
                readOnly={!!disabled}
              />
            </Form.Item>
          </div>
          <Form.Item
            {...restField}
            style={{ display: "none" }}
            name={[name, "price"]}
          >
            <Input style={{ display: "none" }} />
          </Form.Item>
        </>
      );
    }
  };
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.header}>
        <Typography.Text className={titleClassName || styles.header_title}>
          Dịch vụ thêm
        </Typography.Text>
        <ExtraServiceSelect
          extraServices={extraServices.map((extraService) => ({
            ...extraService,
            disabled: hasCombo(extraService),
          }))}
          selectedExtraServices={selectedExtraServices}
          setSelectedService={handleSelectedService}
        />
      </div>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        className="ticket-card_form"
        style={{ ...formStyle }}
      >
        <Form.List name={"datA_TOUR_CHHangHoaDichVuKhacs"}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div key={field.key}>
                  {datA_TOUR_CHHangHoaDichVuKhacs_value && (
                    <ExtraServiceItem
                      key={field.key}
                      renderFormItem={() =>
                        renderFormItem(
                          datA_TOUR_CHHangHoaDichVuKhacs_value[field.name]
                            ?.extraService,
                          field
                        )
                      }
                      service={
                        datA_TOUR_CHHangHoaDichVuKhacs_value[field.name]
                          ?.extraService
                      }
                      handleDelete={
                        hasCombo(
                          datA_TOUR_CHHangHoaDichVuKhacs_value[field.name]
                            ?.extraService
                        )
                          ? undefined
                          : (id) => {
                              remove(field.name);
                              deleteService(id);
                            }
                      }
                    />
                  )}
                </div>
              ))}
            </>
          )}
        </Form.List>
        {/* {extraServices.map(
           (service) =>
             selectedExtraServices[service.code] && (
               <ExtraServiceItem
                 key={service.id}
                 service={service}
                 renderFormItem={() => renderFormItem(service)}
                 handleDelete={deleteService}
              />
             )
        )} */}
      </Form>
    </div>
  );
};

export default ExtraServiceTab;

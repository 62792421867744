import { ColumnsType } from "antd/es/table";
import { IExtraService, IHangHoaItem } from "common/define-types";
import { CategoryTableLayout } from "components/Layout/CategoryTableLayout";
import { useEffect } from "react";
import {
  addExtraService,
  // deleteExtraService,
  editExtraService,
  fetchExtraServiceCategorys,
  saveExtraService,
  setAddModalOpen,
} from "store/slice/ExtraServiceCategorySlice";
import { Button, Dropdown, Modal } from "antd";
import type { MenuProps, PaginationProps } from "antd";
import { ExtraServiceForm, ServiceType } from "./components/ExtraServiceForm";
import { useForm } from "antd/es/form/Form";
import { DashOutlined, EditOutlined } from "@ant-design/icons";
import { useSelectorRoot, useDispatchRoot } from "store/store";
import styles from "./ExtraService.module.css";
// import { PaginationProps } from "antd/es/pagination";

export const ExtraService = () => {
  // const [modal, contextHolder] = Modal.useModal();
  const selectIsLoginSuccess = useSelectorRoot(
    (state) => state.login.isSuccess
  );
  const selectIsRefreshing = useSelectorRoot(
    (state) => state.login.isRefreshing
  );
  const dispatch = useDispatchRoot();

  const [extraServiceForm] = useForm();
  const addModalOpen = useSelectorRoot(
    (state) => state.extraServiceCategory.addModalOpen
  );
  const selectEditingExtraService = useSelectorRoot(
    (state) => state.extraServiceCategory.editingExtraService
  );
  const selectExtraServices = useSelectorRoot(
    (state) => state.extraServiceCategory.extraServices
  );
  const selectEditingExtraService_hangHoas = useSelectorRoot(
    (state) => state.extraServiceCategory.editingExtrarService_hangHoas
  );
  const isLoading = useSelectorRoot(
    (state) => state.extraServiceCategory.isLoading
  );
  const isSubmitting = useSelectorRoot(
    (state) => state.extraServiceCategory.isSubmitting
  );
  const selectExtraServiceResult = useSelectorRoot(
    (state) => state.extraServiceCategory.extraServicesResult
  );
  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectExtraServiceResult) {
      dispatch(
        fetchExtraServiceCategorys({
          ...selectExtraServiceResult,
          page,
        })
      );
    }
  };

  useEffect(() => {
    if (selectIsLoginSuccess && !selectIsRefreshing) {
      dispatch(fetchExtraServiceCategorys({ pageSize: 25 }));
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [selectIsLoginSuccess, selectIsRefreshing]);

  const openAddModal = () => dispatch(setAddModalOpen(true));
  const closeAddModal = () => dispatch(setAddModalOpen(false));
  const handleAddService = async () => {
    const extraServiceValue = await extraServiceForm
      .validateFields()
      .catch((err) => console.log(err));
    if (!extraServiceValue) return;
    const { itemType, ...rest } = extraServiceValue;

    dispatch(
      addExtraService({
        ...rest,
        hangHoas: itemType === ServiceType.NORMAL ? [] : rest.hangHoas || [],
        type: itemType,
        price: rest.giaBan,
      })
    );
  };

  const openEditModal = (extraService: IExtraService) =>
    dispatch(editExtraService(extraService));
  const closeEditModal = () => dispatch(editExtraService(null));
  const handleSaveService = async () => {
    if (selectEditingExtraService) {
      const extraServiceValue = await extraServiceForm
        .validateFields()
        .catch((err) => console.log(err));
      if (!extraServiceValue) return;
      const { itemType, ...rest } = extraServiceValue;
      let deletedHangHoas: IHangHoaItem[] = [];
      if (selectEditingExtraService_hangHoas?.length) {
        deletedHangHoas = selectEditingExtraService_hangHoas.filter(
          (oldHangHoa) =>
            (rest.hangHoas || []).every(
              (newHangHoa: IHangHoaItem) => newHangHoa.id !== oldHangHoa.id
            )
        );
      }

      dispatch(
        saveExtraService({
          ...selectEditingExtraService,
          ...rest,
          hangHoas: itemType === ServiceType.NORMAL ? [] : rest.hangHoas || [],
          deletedHangHoas: deletedHangHoas.map((hangHoa) => hangHoa.id),
          type: itemType,
          price: rest.giaBan,
        })
      );
    }
  };

  // const handleDeleteService = (extraService: IExtraService) => {
  //   dispatch(deleteExtraService(extraService.id));
  // };

  const getActionsMenu = (record: IExtraService): MenuProps["items"] => {
    return [
      {
        key: "edit",
        label: "Chỉnh sửa",
        icon: <EditOutlined style={{ color: "#1890ff" }} />,
        onClick: () => openEditModal(record),
      },
      // {
      //   key: "delete",
      //   label: "Xóa",
      //   icon: <DeleteOutlined style={{ color: "red" }} />,
      //   onClick: () =>
      //     modal.confirm({
      //       title: "Xóa dịch vụ",
      //       content: <>{`Xác nhận xóa ${record.name} ?`}</>,
      //       onOk: () => handleDeleteService(record),
      //       centered: true,
      //     }),
      // },
    ];
  };

  const columns: ColumnsType<IExtraService> = [
    {
      title: "Stt",
      dataIndex: "stt",
      render: (text, _, index) => (
        <>{(selectExtraServiceResult?.firstRowIndex || 1) + index}</>
      ),
      width: 40,
      align: "center",
    },
    {
      title: "Tên",
      dataIndex: "name",
      width: 120,
      ellipsis: true,
    },
    {
      title: "Code",
      dataIndex: "code",
      width: 80,
    },
    //[2-4-2024][Phuc Thinh][Hide unnecessary columns]
    // {
    //   title: "Giá",
    //   dataIndex: "price",
    //   width: 80,
    //   render(value) {
    //     return <>{Utils.formatCurrency(value)}</>;
    //   },
    // },
    // {
    //   title: "Giá hiển thị",
    //   dataIndex: "giaHienThi",
    //   width: 80,
    //   render(value) {
    //     return <>{Utils.formatCurrency(value)}</>;
    //   },
    // },
    {
      dataIndex: "actions",
      width: 30,
      align: "center",
      fixed: "right",
      render(_, record) {
        return record ? (
          <Dropdown
            arrow
            menu={{ items: getActionsMenu(record) }}
            trigger={["click"]}
          >
            <Button type="text" icon={<DashOutlined />} />
          </Dropdown>
        ) : (
          <></>
        );
      },
    },
  ];

  return (
    <div className={styles.container}>
      {/* {contextHolder} */}
      <CategoryTableLayout
        title="dịch vụ thêm"
        dataSource={selectExtraServices}
        pagination={selectExtraServiceResult || undefined}
        columns={columns}
        loading={isLoading}
        onAddClick={openAddModal}
        onChangePagination={handleChangePage}
      />

      {/* add form */}
      <Modal
        open={addModalOpen}
        onCancel={closeAddModal}
        onOk={handleAddService}
        okText="Lưu"
        cancelText="Hủy"
        destroyOnClose
        centered
        title={"Thêm dịch vụ"}
        confirmLoading={isSubmitting}
        afterClose={() => extraServiceForm.resetFields()}
      >
        <ExtraServiceForm form={extraServiceForm} />
      </Modal>

      {/* edit form */}
      <Modal
        open={!!selectEditingExtraService}
        onCancel={closeEditModal}
        onOk={handleSaveService}
        okText="Lưu"
        cancelText="Hủy"
        destroyOnClose
        centered
        title={"Sửa dịch vụ"}
        confirmLoading={isSubmitting}
        afterClose={() => extraServiceForm.resetFields()}
      >
        <ExtraServiceForm
          form={extraServiceForm}
          editingExtraService={selectEditingExtraService}
        />
      </Modal>
    </div>
  );
};

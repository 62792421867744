import { IIssue } from "api/types/issue";
import { IssueType } from "common/define-types";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import { BrokeMotorbikeItem } from "pages/Admin/pages/NightAuditor/components/BrokeMotorbikeItem";
import { CancelTourItem } from "pages/Admin/pages/NightAuditor/components/CancelTourItem";
import { ChangeRoomItem } from "pages/Admin/pages/NightAuditor/components/ChangeRoomItem";
import ChangeTourItem from "pages/Admin/pages/NightAuditor/components/ChangeTourItem";
import ChangeTypeDriverItem from "pages/Admin/pages/NightAuditor/components/ChangeTypeDriverItem";
import ExtraMealItem from "pages/Admin/pages/NightAuditor/components/ExtraMealItem";
import ExtraSleptItem from "pages/Admin/pages/NightAuditor/components/ExtraSleptItem";
import { OtherIssue } from "pages/Admin/pages/NightAuditor/components/OtherIssue";
import OuterGasItem from "pages/Admin/pages/NightAuditor/components/OuterGasItem";
import { useSelectorRoot } from "store/store";

interface IProps {
  issues: IIssue[];
}

const IssueList = ({ issues }: IProps) => {
  const selectCollectIssueFeeTicket = useSelectorRoot(
    (state) => state.operatorTicket.collectIssueFeeTicket
  );
  console.log(issues)
  const renderIssusItemByType = (issue: IIssue) => {
    switch (issue.type) {
      case IssueType.BREAKDOWN_MOTORBIKE:
        return (
          <BrokeMotorbikeItem readOnly={true} key={issue.id} issue={issue} />
        );
      case IssueType.OtherIssue:
          return (
            <OtherIssue readOnly={true} key={issue.id} issue={issue} />
          );
      case IssueType.CANCELTOUR:
        return <CancelTourItem readOnly={true} key={issue.id} issue={issue} />;
      case IssueType.OUTER_FUEL:
        return <OuterGasItem readOnly={true} key={issue.id} issue={issue} />;
      case IssueType.EXTRA_MEAL:
        return (
          <ExtraMealItem
            groupTickets={
              selectCollectIssueFeeTicket ? [selectCollectIssueFeeTicket] : []
            }
            readOnly={true}
            key={issue.id}
            issue={issue}
          />
        );
      case IssueType.EXTRA_SLEPT:
      case IssueType.EXTRA_SLEPT_DVT:
        return (
          <ExtraSleptItem
            readOnly={true}
            groupTickets={
              selectCollectIssueFeeTicket ? [selectCollectIssueFeeTicket] : []
            }
            key={issue.id}
            issue={issue}
          />
        );
      case IssueType.CHANGEROOM:
      case IssueType.CHANGE_ROOM_DVT:
        return (
          <ChangeRoomItem
            readOnly={true}
            key={issue.id}
            groupTickets={
              selectCollectIssueFeeTicket ? [selectCollectIssueFeeTicket] : []
            }
            issue={issue}
          />
        );
      case IssueType.CHANGE_TOUR:
        return (
          <ChangeTourItem
            readOnly={true}
            key={issue.id}
            groupTickets={
              selectCollectIssueFeeTicket ? [selectCollectIssueFeeTicket] : []
            }
            issue={issue}
          />
        );
      case IssueType.CHANGE_TO_WITH_DRIVER:
      case IssueType.CHANGE_TO_WITHOUT_DRIVER:
        return (
          <ChangeTypeDriverItem
            readOnly={true}
            key={issue.id}
            groupTickets={
              selectCollectIssueFeeTicket ? [selectCollectIssueFeeTicket] : []
            }
            issue={issue}
          />
        );
      default:
        return;
    }
  };
  return (
    <div>
      {issues.length && issues.length > 0 ? (
        issues.map((issue) => renderIssusItemByType(issue))
      ) : (
        <EmptyHolder message="No issue" />
      )}
    </div>
  );
};

export default IssueList;

import { Typography, Button, Popconfirm } from "antd";
import styles from "../StationCategory.module.css";
import {
  EnvironmentOutlined,
  PhoneOutlined,
  EditOutlined,
  DeleteOutlined,
  // PercentageOutlined,
  // UnlockOutlined,
  // LockOutlined,
} from "@ant-design/icons";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { useEffect, useRef } from "react";
import {
  deleteStation,
  editStation,
  setStationSelected,
} from "store/slice/StationCategorySlice";
import { IStation } from "common/define-types";

interface IProps {
  station: IStation;
  isActive?: boolean;
}
export const StationItem = ({ station, isActive }: IProps) => {
  const selectDeletingStationId = useSelectorRoot(
    (state) => state.stationsCategory.deletingStationId
  );
  const dispatch = useDispatchRoot();

  const handleSelectStation = () => {
    if (!isActive) {
      dispatch(setStationSelected(station));
    }
  };

  const agentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isActive && agentRef.current)
      agentRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [isActive]);
  // const handleDeleteAgent = () => {
  //   if (agent) {
  //     dispatch(deleteAgent(agent.id));
  //   }
  // };

  const openEditStation = (e: any) => {
    e.stopPropagation();
    dispatch(editStation(station));
  };

  const handleDeleteStation = () => {
    dispatch(deleteStation(station.id));
  };

  return (
    <div
      className={`${styles.agentItem} ${isActive ? styles.agentSelected : ""}`}
      ref={agentRef}
      onClick={handleSelectStation}
    >
      <div className={styles.agentItem_wrapper}>
        <div className={styles.spaceBetween}>
          <Typography.Text
            strong
            style={{ fontSize: 20, fontWeight: 700 }}
          >{`${station.name}`}</Typography.Text>
          {station.code && (
            <Typography.Text
              style={{ textAlign: "right", fontSize: 20, fontWeight: 700 }}
              strong
              italic
            >
              {`${station.code}`}
            </Typography.Text>
          )}
        </div>
        {station.address && (
          <div className={styles.textWithIcon}>
            <EnvironmentOutlined style={{ fontSize: 20 }} />
            <Typography.Text strong>{`${station.address}`}</Typography.Text>
          </div>
        )}
        <div className={styles.spaceBetween}>
          {station.phone && (
            <div className={styles.textWithIcon} style={{ minWidth: "45%" }}>
              <PhoneOutlined style={{ fontSize: 20 }} rotate={90} />
              <Typography.Text strong>{` ${
                station.phone ?? ""
              }`}</Typography.Text>
            </div>
          )}
          {station.description && (
            <div
              className={styles.textWithIcon}
              style={{ minWidth: "45%", justifyContent: "flex-end" }}
            >
              <Typography.Text strong>{`${
                station.description ?? ""
              }`}</Typography.Text>
            </div>
          )}
        </div>
      </div>
      <div className={styles.btnContainer} style={{ flexBasis: 72 }}>
        <Button
          icon={<EditOutlined style={{ fontSize: 20, color: "#1890FF" }} />}
          type="text"
          onClick={openEditStation}
        />

        <Popconfirm
          onConfirm={(e) => {
            e?.stopPropagation();
            handleDeleteStation();
          }}
          onCancel={(e) => e?.stopPropagation()}
          title={"Xóa nhà xe"}
          description={"Bạn có chắc chắn muốn xóa nhà xe này ?"}
          okText="Có"
          cancelText="Không"
        >
          <Button
            onClick={(e) => e.stopPropagation()}
            icon={<DeleteOutlined style={{ fontSize: 20, color: "red" }} />}
            loading={selectDeletingStationId === station.id}
            type="text"
          />
        </Popconfirm>
      </div>
    </div>
  );
};

import {
  Typography,
  Input,
  Skeleton,
  Pagination,
  Button,
  PaginationProps,
} from "antd";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import { PlusOutlined } from "@ant-design/icons";
import styles from "../StationCategory.module.css";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { StationItem } from "./StationItem";
import { ChangeEvent, useState } from "react";
import { RoleEnum } from "common/define-types";
import {
  fetchStations,
  setAddStationOpen,
  setStationsResults,
} from "store/slice/StationCategorySlice";

const TITLE = "nhà xe";
export const StationList = () => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const selectIsAgentsLoading = useSelectorRoot(
    (state) => state.agents.isLoading
  );
  const userRole = useSelectorRoot((state) => state.login.role);
  const selectStations = useSelectorRoot(
    (state) => state.stationsCategory.stations
  );
  const selectStationSelected = useSelectorRoot(
    (state) => state.stationsCategory.stationSelected
  );
  const selectStationsResults = useSelectorRoot(
    (state) => state.stationsCategory.stationsResults
  );

  const dispatch = useDispatchRoot();

  const handleChangePagi: PaginationProps["onChange"] = (page) => {
    dispatch(
      fetchStations({
        ...selectStationsResults,
        page: page,
      })
    );
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      //[2-4-2024][Phuc Thinh][No need to check for old params]
      dispatch(
        fetchStations({
          ...selectStationsResults,
          search: query,
        })
      );
      dispatch(
        setStationsResults({
          ...selectStationsResults,
          search: query,
        })
      );
    }, 800);
    setTimer(timeoutId);
  };

  const openAddStation = () => {
    dispatch(setAddStationOpen(true));
  };

  return (
    <div className={styles.listContainer}>
      <div
        className={styles.listContainer_header}
        style={{ justifyContent: "space-between" }}
      >
        <Typography.Text strong style={{ fontSize: 20 }}>
          {`Danh sách ${TITLE}`}
        </Typography.Text>
        <div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled={userRole !== RoleEnum.ADMIN}
            onClick={openAddStation}
          >
            {`Thêm ${TITLE}`}
          </Button>
        </div>
      </div>
      <div className={styles.searchContainer}>
        <div></div>
        <Input.Search
          allowClear
          style={{ flex: 1 }}
          placeholder={`Tìm kiếm ${TITLE}`}
          onChange={handleSearch}
        />
      </div>
      <div className={styles.listWrapper}>
        <div className={`${styles.listWrapper_List} customScroll`}>
          {selectIsAgentsLoading ? (
            <>
              <Skeleton paragraph={{ rows: 4 }} />
              <Skeleton paragraph={{ rows: 4 }} />
              <Skeleton paragraph={{ rows: 4 }} />
            </>
          ) : selectStations && selectStations.length > 0 ? (
            [...selectStations].map((station) => (
              <StationItem
                station={station}
                key={`Station-${station.id}`}
                isActive={selectStationSelected?.id === station.id}
              />
            ))
          ) : (
            <EmptyHolder message={`Chưa có ${TITLE}`} />
          )}
        </div>
      </div>
      <div className={styles.listContainer_footer}>
        <Pagination
          total={selectStationsResults?.queryCount || selectStations.length}
          pageSize={selectStationsResults?.pageSize || 25}
          current={selectStationsResults?.page || 1}
          showTotal={(total, range) =>
            `Hiển thị ${
              range[1] === 0 && range[1] - range[0] === 0
                ? 0
                : range[1] - range[0] + 1
            }/${total} ${TITLE}`
          }
          onChange={handleChangePagi}
        />
      </div>
    </div>
  );
};

import { Select, Spin, notification } from "antd";
import { useEffect, useState } from "react";
import { BusOption } from "./components/RouterStationBusOption";
import dayjs, { Dayjs } from "dayjs";
// import { fetchRouterStationBusData } from "store/slice/RouterStationBus";
import { IRouterStationBus } from "common/define-types";
import { getAllRouterStationBuses } from "api/routerStationBus.api";
import { useSelectorRoot } from "store/store";
import moment from "moment";
interface IProps {
  value?: string | string[];
  onChange?: (arg: IRouterStationBus | undefined | any) => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  allowClear?: boolean;
  filterValue: {
    locationFromId: string;
    locationToId: string;
    date: Dayjs | null;
    dockerId?: string | null;
  };
  testId: string;
  busPriceProp?: keyof IRouterStationBus;
  valuePropName?: keyof IRouterStationBus;
  busOptions?: IRouterStationBus[];
  multiple?: boolean;
}
const { Option } = Select;
export const BusSelect = ({
  value,
  onChange = () => {},
  style,
  readOnly,
  filterValue,
  allowClear = true,
  testId,
  busPriceProp,
  valuePropName,
  busOptions,
  multiple,
}: IProps) => {
  const selectRouterStationBuses = useSelectorRoot(
    (state) => state.routerStationBus.routerStationBuses
  );
  const [buses, setBuses] = useState<IRouterStationBus[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const getTime = (bus: IRouterStationBus) => {
    const formatUnit = (value: number | string) => {
      const input = typeof value === "string" ? parseInt(value) : value;
      if (input < 10) {
        return `0${input}`;
      }
      return value;
    };
    if (bus.date) {
      const date = moment.utc(bus.date);
      return {
        hour: formatUnit(date.hours().toString()),
        minute: formatUnit(date.minutes().toString()),
      };
    }
    return {
      hour: formatUnit(0),
      minute: formatUnit(0),
    };
  };

  //[29-3-2024][Phuc Thinh][check if bus is still exists then return its value]
  const getBusValue = () => {
    if (Array.isArray(value)) {
      return value.filter((busValue) =>
        buses.find((bus) => bus.id === busValue)
      );
    } else {
      if (buses.find((bus) => bus.id === value)) {
        return value;
      } else {
        return null;
      }
    }
  };

  useEffect(() => {
    if (
      filterValue.locationFromId === "" ||
      filterValue.locationToId === "" ||
      !filterValue.locationFromId ||
      !filterValue.locationToId
    ) {
      setBuses([]);
      return;
    }
    if (busOptions) {
      setBuses(busOptions);
      return;
    }
    // if (filterValue.dockerId && filterValue.dockerId !== "nocombo") {
    //   const busesRes: IRouterStationBus[] = selectRouterStationBuses;
    //   const newBuses = busesRes.filter((routerStaBus) =>
    //     routerStaBus.dockerId
    //       ?.toLowerCase()
    //       .includes(filterValue.dockerId?.toLowerCase() || "")
    //   );
    //   setBuses(newBuses);
    //   return;
    // }
    if (!modalOpen) {
      setBuses(
        selectRouterStationBuses.filter(
          (routerStaBus) =>
            !filterValue.dockerId ||
            routerStaBus.dockerId
              ?.toLowerCase()
              .includes(filterValue.dockerId.toLowerCase() || "")
        )
      );
      return;
    }
    setLoading(true);
    getAllRouterStationBuses({
      dateTime: !!filterValue.date
        ? dayjs(filterValue.date).toISOString()
        : dayjs().toISOString(),
      dockerId: filterValue.dockerId,
    }).subscribe(
      (res) => {
        if (res) {
          const newBuses: IRouterStationBus[] = res;
          setBuses(newBuses);
        }
      },
      (err) => {
        notification.error({ message: "Không thể lấy danh sách bus" });
        setBuses([]);
        setLoading(false);
      },
      () => {
        // finally
        setLoading(false);
      }
    );
  }, [filterValue, selectRouterStationBuses, modalOpen, busOptions]);

  return (
    <Select
      // options={selectBuses.map((bus) => ({
      //   label: bus.name,
      //   value: bus.description,
      // }))}
      data-test-id={testId}
      loading={loading}
      value={getBusValue()}
      style={style}
      onChange={(value: any) => {
        if (multiple) {
          const foundBuses = buses.filter((bus) =>
            value.some((busId: string) => busId === bus.id)
          );
          onChange(
            valuePropName
              ? foundBuses && foundBuses.map((bus) => bus[valuePropName])
              : foundBuses
          );
          return;
        }

        const foundBus = buses.find((bus) => bus.id === value);
        onChange(
          valuePropName ? foundBus && foundBus[valuePropName] : foundBus
        );
      }}
      optionLabelProp="label"
      allowClear={allowClear}
      open={modalOpen}
      onDropdownVisibleChange={(open) => setModalOpen(open)}
      notFoundContent={
        filterValue.locationFromId === "" ||
        filterValue.locationToId === "" ||
        !filterValue.locationFromId ||
        !filterValue.locationToId
          ? "Vui lòng chọn địa điểm"
          : "Chưa có bus"
      }
      disabled={readOnly}
      mode={multiple ? "multiple" : undefined}
      maxTagCount={multiple ? "responsive" : undefined}
    >
      {loading ? (
        <div style={{ textAlign: "center", paddingBlock: 10 }}>
          <Spin />
        </div>
      ) : (
        buses
          .filter(
            (routerStaBus) =>
              routerStaBus.dmuC_Router_Station?.dmuC_Router.localtionStartId ===
                filterValue.locationFromId &&
              routerStaBus.dmuC_Router_Station?.dmuC_Router.localtionEndId ===
                filterValue.locationToId
          )
          .map((bus) => (
            <Option
              label={`${
                bus.dmuC_Bus.description ? `${bus.dmuC_Bus.description} - ` : ""
              }${bus.dmuC_Router_Station?.dmuC_Station?.name} - ${
                getTime(bus).hour
              }:${getTime(bus).minute}`}
              value={bus.id}
              key={bus.id}
            >
              <BusOption bus={bus} priceProp={busPriceProp} />
            </Option>
          ))
      )}
    </Select>
  );
};

import { Form, FormInstance, Input } from "antd";
import styles from "../Location.module.css";

interface IProps {
  form: FormInstance<any>;
}
export const LocationForm = ({ form }: IProps) => {
  return (
    <Form labelCol={{ span: 24 }} form={form}>
      <Form.Item
        label={"Tên vị trí"}
        name={"name"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <div className={styles.formItemGroup}>
        <Form.Item label={"Mô tả"} name={"description"}>
          <Input />
        </Form.Item>
      </div>
    </Form>
  );
};

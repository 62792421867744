import { Typography, Form, FormInstance } from "antd";
import Utils from "common/Utils";
import { LocationSelect } from "components/Selects/LocationSelect";
import { BusSelect } from "components/Selects/RouterStationBusSelect";
import { MinusCircleOutlined } from "@ant-design/icons";
import styles from "../ComboTourCategory.module.css";
import { IRouterStationBus } from "common/define-types";

interface ComboTourBusFormProps {
  parentFormIntance: FormInstance<any>;
  title: string;
  locationsName: string;
  busesName: string;
  locationIds?: string[];
  buses?: { locationId: string; routerStationBusIds?: string[] }[];
  direction: "fromHaGiang" | "toHaGiang";
  busOptions?: IRouterStationBus[];
}

export const ComboTourBusForm = ({
  parentFormIntance,
  title,
  locationsName,
  busesName,
  locationIds,
  buses,
  direction,
  busOptions,
}: ComboTourBusFormProps) => {
  return (
    <>
      <div className={styles.spaceBetween} style={{ marginBottom: 16 }}>
        <Typography.Text strong>{title}</Typography.Text>
        <Form.Item name={locationsName}>
          <LocationSelect
            testId="locationSelect"
            multiple
            style={{ width: 200 }}
          />
        </Form.Item>
      </div>
      <Form.List name={busesName}>
        {(fields, { remove }) => (
          <div>
            <div className={`${styles.comboTourBusesContainer} customScroll`}>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} className={styles.comboTourBusFormItem}>
                  <div className={styles.formItemGroup}>
                    <Form.Item
                      {...restField}
                      name={[name, "locationId"]}
                      rules={[
                        { required: true, message: "Không được bỏ trống" },
                      ]}
                      style={{ flex: 1 }}
                    >
                      <LocationSelect
                        testId="noTest"
                        readOnly
                        allowClear={false}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "routerStationBusIds"]}
                      rules={[
                        { required: true, message: "Không được bỏ trống" },
                      ]}
                      style={{ flex: 1 }}
                    >
                      <BusSelect
                        testId="busSelect"
                        filterValue={{
                          locationFromId:
                            direction === "fromHaGiang"
                              ? Utils.HaGiangLocationId
                              : buses
                              ? buses[name].locationId
                              : "",
                          locationToId:
                            direction === "toHaGiang"
                              ? Utils.HaGiangLocationId
                              : buses
                              ? buses[name].locationId
                              : "",
                          date: null,
                        }}
                        valuePropName="id"
                        busOptions={busOptions}
                        multiple
                        busPriceProp="price"
                      />
                    </Form.Item>
                  </div>
                  <MinusCircleOutlined
                    style={{ color: "red" }}
                    onClick={() => {
                      remove(name);
                      parentFormIntance.setFieldValue(
                        locationsName,
                        locationIds?.filter((_, index) => index !== name)
                      );
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </Form.List>
    </>
  );
};

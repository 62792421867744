import {
  GroupStatusEnum,
  GroupTicketType,
  IDriver,
  IDriverTicket,
  IGroupTicket,
  IMotorbike,
  IMotorbikeTicket,
  JobTicketStatusEnum,
  MotorTicketType,
  SelfMotor,
} from "common/define-types";
import styles from "../Group.module.css";
import { Button, Popconfirm, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
// import TwoArrowIcon from "image/icon/tabler_arrows-icon.svg";
import DriverIcon from "image/icon/driver-icon.svg";
import MotorbikeIcon from "image/icon/motorbike-icon.svg";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import {
  removeTicketFromGroup,
  saveDriverTicket,
  saveMotorbikeTicket,
  setDriverTicketByTicketId,
  setMotorbikeTicketByTicketId,
} from "store/slice/GroupSlice";
import { GroupDriverSelect } from "components/Selects/GroupDriverSelect";
import moment from "moment";
import { GroupVehicleSelect } from "components/Selects/GroupVehicleSelect";
import { TourType } from "components/Text/TourType";
import Utils from "common/Utils";
import React from "react";
import { GroupTicketCheckbox } from "./GroupTicketCheckbox";

interface IProps {
  groupTicket: IGroupTicket;
  isDriver: boolean;
  bgColor?: string;
}
const GroupTicketItem = ({
  groupTicket,
  isDriver,
  bgColor = "#FFFFFF",
}: IProps) => {
  // const selectSelectedDate = useSelectorRoot(
  //   (state) => state.group.selectedDate
  // );
  const selectGroupSelected = useSelectorRoot(
    (state) => state.group.groupSelected
  );
  const selectAdditionalMotorOptions = useSelectorRoot(
    (state) => state.motorbike.additionalMotor
  );
  const selectDrivers = useSelectorRoot((state) => state.group.allDrivers);
  const selectMotorbikes = useSelectorRoot(
    (state) => state.group.allMotorbikes
  );
  const dispatch = useDispatchRoot();

  const isGroupTicketTakingPlace = () => {
    return moment().isBetween(
      moment(groupTicket.startDate).set({ ...Utils.startDateOfGroup }),
      moment(groupTicket.startDate)
        .add(parseInt(groupTicket.driverPrice?.countDay ?? "3") - 1, "days")
        .endOf("day")
    );
  };
  const isGroupEnded = selectGroupSelected
    ? selectGroupSelected?.status === GroupStatusEnum.END
    : false;
  const isGroupStarted = selectGroupSelected
    ? selectGroupSelected?.status !== GroupStatusEnum.NOT_RUNNING
    : false;
  const isTicketCancelTour =
    groupTicket.status === JobTicketStatusEnum.BREAKUPTOUR;
  const isDummyTicket = groupTicket.type === GroupTicketType.DUMMY;

  const handleSaveDriverTicket = (driver: IDriver) => {
    if (!driver) return;
    if (
      groupTicket &&
      groupTicket.driver_Tickets &&
      groupTicket.driver_Tickets.length > 0 &&
      groupTicket.driver_Tickets.filter((dTicket) => dTicket.status === 3)
        .length > 0
    ) {
      dispatch(
        saveDriverTicket({
          driverId: driver.id,
          ticketId: groupTicket.id,
          driverTicketId: groupTicket.driver_Tickets.filter(
            (dTicket) => dTicket.status === 3
          )[0].id,
        })
      );
    } else {
      dispatch(
        saveDriverTicket({
          driverId: driver.id,
          ticketId: groupTicket.id,
        })
      );
    }
  };

  const handleDeleteDriverTicket = (driver_Ticket: IDriverTicket | null) => {
    if (driver_Ticket) {
      dispatch(
        saveDriverTicket({
          driverId: driver_Ticket.driverId,
          ticketId: undefined,
          driverTicketId: driver_Ticket.id,
        })
      );
      if (groupTicket.driver_Tickets && groupTicket.driver_Tickets.length)
        dispatch(
          setDriverTicketByTicketId({
            ticketId: driver_Ticket.ticketId,
            dTickets: groupTicket.driver_Tickets?.filter(
              (dTicket) => dTicket.status !== 3
            ),
          })
        );
    }
  };

  const handleSaveMotorbikeTicket = (
    motorbike: IMotorbike,
    typeMotor: MotorTicketType
  ) => {
    if (!motorbike) return;
    if (
      groupTicket &&
      groupTicket.motorbike_Ticket &&
      groupTicket.motorbike_Ticket.length > 0 &&
      groupTicket.motorbike_Ticket.filter((mTicket) => mTicket.status === 3)
        .length > 0
    ) {
      dispatch(
        saveMotorbikeTicket({
          motorbikeId:
            typeMotor === MotorTicketType.Jasmine ||
            typeMotor === MotorTicketType.Chung
              ? motorbike.id
              : null,
          ticketId: groupTicket.id,
          motorbikeTicketId: groupTicket.motorbike_Ticket.filter(
            (mTicket) => mTicket.status === 3
          )[0].id,
          typeMotor,
        })
      );
    } else {
      dispatch(
        saveMotorbikeTicket({
          motorbikeId:
            typeMotor === MotorTicketType.Jasmine ||
            typeMotor === MotorTicketType.Chung
              ? motorbike.id
              : null,
          ticketId: groupTicket.id,
          typeMotor,
        })
      );
    }
  };

  const handleDeleteMotorbikeTicket = (
    motorbike_Ticket: IMotorbikeTicket | null
  ) => {
    if (motorbike_Ticket) {
      dispatch(
        saveMotorbikeTicket({
          motorbikeId: motorbike_Ticket.motorbikeId,
          ticketId: undefined,
          motorbikeTicketId: motorbike_Ticket.id,
          typeMotor: motorbike_Ticket.typeMotor,
        })
      );
      if (groupTicket.motorbike_Ticket && groupTicket.motorbike_Ticket.length)
        dispatch(
          setMotorbikeTicketByTicketId({
            ticketId: motorbike_Ticket.ticketId,
            mTickets: groupTicket.motorbike_Ticket?.filter(
              (mTicket) => mTicket.status !== 3
            ),
          })
        );
    }
  };

  return (
    <div
      className={styles.groupTicketItem}
      style={{ backgroundColor: bgColor, position: "relative" }}
    >
      <div className={styles.groupTicketItem_checkBoxContainer}>
        <Typography.Text
          italic
          style={{ color: "#BFBFBF", whiteSpace: "nowrap" }}
        >
          {isDummyTicket ? "Nội bộ" : groupTicket.agentCode ?? ""}
        </Typography.Text>
        {!isDummyTicket && <GroupTicketCheckbox groupTicket={groupTicket} />}
      </div>
      <div className={styles.groupTicketWrapper}>
        <div className={styles.groupTicketInforItem}>
          <Typography.Text className={styles.groupTicketFullname}>
            {`${(groupTicket.fullName ?? "Trống").toLowerCase()}`}
          </Typography.Text>
          <div
            className={styles.inforWithIcon}
            style={{ justifyContent: "space-between" }}
          >
            <TourType
              countDay={
                groupTicket.driverPrice
                  ? parseInt(groupTicket.driverPrice.countDay)
                  : 0
              }
            />
            <div className={styles.actionsWrapper}>
              {/* <Tooltip title={"Bỏ ticket"}>
                <StopOutlined style={{ fontSize: 20, color: "#FAAD14" }} />
              </Tooltip>
              <Tooltip title={"Chi tiết ticket"}>
                <InfoCircleOutlined style={{ fontSize: 20 }} />
              </Tooltip>
              <Tooltip title={"Chuyển phòng"}>
                <FlagOutlined style={{ fontSize: 20, color: "red" }} />
              </Tooltip> */}
            </div>
          </div>
        </div>
        {!isDummyTicket && (
          <div className={styles.groupTicketInforItem}>
            <Typography.Text
              className={styles.groupTicketFullname}
              style={{ textTransform: "none", fontWeight: 400 }}
            >
              {`${groupTicket.email ?? "Email: trống"}`}
            </Typography.Text>
            <Typography.Text
              className={styles.groupTicketFullname}
              style={{ textTransform: "none", fontWeight: 400 }}
            >
              {`${groupTicket.phone ?? "Số điện thoại: trống"}`}
            </Typography.Text>
          </div>
        )}
        <div className={styles.groupTicketInforItem}>
          <div className={styles.inforWithIcon}>
            <img src={DriverIcon} alt="driver" />
            {isDriver !== null && isDriver ? (
              <GroupDriverSelect
                testId="Driver-Ticket"
                defaultOptions={selectDrivers}
                value={
                  (groupTicket.driver_Tickets || []).filter(
                    (dTicket) => dTicket.status === 3
                  ).length > 0
                    ? (groupTicket.driver_Tickets || []).filter(
                        (dTicket) => dTicket.status === 3
                      )[0].driverId
                    : null
                }
                style={{ flex: 1, maxWidth: 140 }}
                onChange={(value) => handleSaveDriverTicket(value)}
                onClear={() =>
                  handleDeleteDriverTicket(
                    groupTicket.driver_Tickets &&
                      groupTicket.driver_Tickets.length > 0
                      ? groupTicket.driver_Tickets.filter(
                          (dTicket) => dTicket.status === 3
                        )[0]
                      : null
                  )
                }
                readOnly={isGroupEnded || isTicketCancelTour}
                params={{
                  startDate: isGroupTicketTakingPlace()
                    ? moment().startOf("day").add(7, "hours").toISOString()
                    : moment(groupTicket.startDate)
                        .startOf("day")
                        .add(7, "hours")
                        .toISOString(),
                  endDate: moment(groupTicket.startDate)
                    .add(
                      parseInt(groupTicket.driverPrice?.countDay ?? "2"),
                      "days"
                    )
                    .endOf("day")
                    .add(7, "hours")
                    .toISOString(),
                  isAvailable: true,
                  groupId: selectGroupSelected
                    ? selectGroupSelected.id
                    : undefined,
                }}
              />
            ) : (
              <Typography.Text>Tự lái</Typography.Text>
            )}
          </div>
          <div className={styles.inforWithIcon}>
            <img src={MotorbikeIcon} alt="motorbike" />
            {groupTicket.selfMotor === SelfMotor.YES ? (
              <Typography.Text>Xe của khách</Typography.Text>
            ) : (
              <GroupVehicleSelect
                testId="Motorbike-Ticket"
                defaultOptions={selectMotorbikes}
                value={Utils.getCurrentValueMotorbikeTicket(
                  groupTicket.motorbike_Ticket
                )}
                onChange={(value, type) =>
                  handleSaveMotorbikeTicket(value, type)
                }
                style={{ width: "100%", maxWidth: 140 }}
                valuePropName="id"
                readOnly={isGroupEnded || isTicketCancelTour}
                onClear={() =>
                  handleDeleteMotorbikeTicket(
                    groupTicket.motorbike_Ticket &&
                      groupTicket.motorbike_Ticket.length > 0
                      ? groupTicket.motorbike_Ticket.filter(
                          (mTicket) => mTicket.status === 3
                        )[0]
                      : null
                  )
                }
                additionalOptions={selectAdditionalMotorOptions.filter(
                  (option) =>
                    isDriver ||
                    [
                      MotorTicketType.NhanVien,
                      MotorTicketType.NhanVienVe,
                    ].every((type) => option.type !== type)
                )}
                params={{
                  startDate: isGroupTicketTakingPlace()
                    ? moment().startOf("day").add(7, "hours").toISOString()
                    : moment(groupTicket.startDate)
                        .startOf("day")
                        .add(7, "hours")
                        .toISOString(),
                  endDate: moment(groupTicket.startDate)
                    .add(
                      parseInt(groupTicket.driverPrice?.countDay ?? "2"),
                      "days"
                    )
                    .endOf("day")
                    .add(7, "hours")
                    .toISOString(),
                  isAvailable: true,
                  groupId: selectGroupSelected
                    ? selectGroupSelected.id
                    : undefined,
                }}
              />
            )}
          </div>
        </div>
      </div>
      {/* [06-12-2023][Phuc Thinh] [Feature/14882]
          Allow unassign ticket in group */}
      {selectGroupSelected && (
        <DeleteTicketButton
          handleSubmit={() => {
            dispatch(
              removeTicketFromGroup({
                ticket: groupTicket,
                group: selectGroupSelected,
              })
            );
          }}
          disabled={
            isDummyTicket ? isGroupEnded : isGroupStarted || isTicketCancelTour
          }
        />
      )}
    </div>
  );
};
export default React.memo(GroupTicketItem);
interface IDeleteTicketProps {
  handleSubmit: () => void;
  disabled?: boolean;
}
const DeleteTicketButton = ({ handleSubmit, disabled }: IDeleteTicketProps) => {
  return (
    <Popconfirm
      title="Xóa vé khỏi group"
      description="Bạn chắc chắn muốn xóa vé này"
      onCancel={(e: any) => e?.stopPropagation()}
      onConfirm={(e: any) => {
        e?.stopPropagation();
        handleSubmit();
      }}
      disabled={disabled}
    >
      <Button
        type="text"
        icon={<DeleteOutlined style={{ color: disabled ? "black" : "red" }} />}
        style={{ position: "absolute", top: 0, right: 0 }}
        disabled={disabled}
      />
    </Popconfirm>
  );
};

import { format, parseISO } from 'date-fns';
import moment from 'moment';
import {
  ComboTourItemType,
  ExtraServiceCode,
  GroupStatusEnum,
  IBooking,
  IBookingTicket,
  IComboTour,
  IDriver,
  IDriverTicket,
  IExtraService,
  IGroup,
  IGroupJob,
  IGroupTicket,
  IHangHoaItem,
  IJobItem,
  ILocation,
  IMotorbikeTicket,
  IParams,
  IPaymentMethodBooking,
  IPriceReq,
  ISelectedComboTour,
  ISelectedTicket,
  ITicket,
  ITicketOperatorItem,
  ITicketWithPrice,
  ITicket_ExtraService,
  // IsDriverType,
  IssueStatus,
  JobDateEnum,
  MotorTicketType,
  PaymentMethodBookingStatus,
  Profile,
  RoleEnum,
} from './define-types';
import { IIssue } from 'api/types/issue';
import { SegmentedValue } from 'antd/es/segmented';
import { IAgent } from 'api/types/agent';
import { v4 as uuidv4 } from 'uuid';
import { IRoomTemplate } from 'api/types/roomTemplate';
import { ServiceType } from 'pages/Admin/pages/ExtraService/components/ExtraServiceForm';
class Utils {
  static token: any;
  static profile: Profile | null;
  static refresh: string;
  static role: RoleEnum;
  static constant = {
    token: 'token',
    username: 'username',
    role: 'role',
    email: 'email',
    user: 'user',
  };
  static throttleTimer: boolean;
  static startDateOfGroup = { hour: 8, minute: 0, second: 0, millisecond: 0 };
  static dateFormat = 'DD-MM-YYYY';
  static refundPercent = 20;
  static RANGE_PAGESIZE = {
    MAX_INTERGER: 5000,
    MIN_INTERGER: 25,
  };
  static HaGiangLocationId = '83c545ce-462d-466d-b0cc-9edd09eeef91';
  // [8-12-2023][Phuc Thinh]
  // add throttle function
  static throttle = (callback: () => void, time: number) => {
    if (Utils.throttleTimer) {
      return;
    }

    Utils.throttleTimer = true;

    setTimeout(() => {
      callback();
      Utils.throttleTimer = false;
    }, time);
  };
  static async setLocalStorage(key: string, value: unknown): Promise<void> {
    localStorage.setItem(key, JSON.stringify(value));
  }
  static async getValueLocalStorage(key: string): Promise<any> {
    const value = await localStorage.getItem(key);
    let re = null;
    value && (re = Utils.parseJson(value));
    return re;
  }
  static async removeItemLocalStorage(key: string): Promise<void> {
    await localStorage.removeItem(key);
  }
  static async clear(): Promise<void> {
    await localStorage.clear();
  }

  static convertJson2XForm(details: any): string {
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }

  static parseJson(str: string): any | null {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  }
  static pathNameMatchContent(path: string): string | undefined {
    if (path === '/main') {
      return 'main';
    }
    const regex = /\/main\/(\w+)$/g;
    const matchResult = regex.exec(path);
    if (matchResult && matchResult.length === 2) {
      return matchResult[1];
    }
    return;
  }
  static formatDate(date: Date): any | null {
    const tyoeFormat = 'MM/dd/yyyy';
    return format(date, tyoeFormat);
  }
  static formatDateVN(date: Date): any | null {
    const tyoeFormat = 'dd/MM/yyyy';
    return format(date, tyoeFormat);
  }
  static formatDateCallApi(date: Date): any | null {
    const tyoeFormat = 'yyyy-MM-dd';
    return format(date, tyoeFormat);
  }
  static formatDateTimeCallApi(date: Date): any | null {
    const tyoeFormat = 'yyyy-MM-dd HH:mm:ss';
    return format(date, tyoeFormat);
  }
  static isNullOrEmpty(value: string | null): boolean {
    return value === null || value === '' || value === undefined;
  }
  static formatDateString(date: string): any | null {
    const tyoeFormat = 'MM/dd/yyyy';
    const newDate = parseISO(date);
    return format(newDate, tyoeFormat);
  }
  static typeFormatDate(): any | null {
    return 'MM/dd/yyyy';
  }
  static typeFormatYear(): any | null {
    return 'yyyy';
  }
  static formatDateToTable(dateArr: string, dateDep: string): string | null {
    const tyoeFormat = 'MM/dd';
    const newDateArr = parseISO(dateArr);
    const newDateDep = parseISO(dateDep);
    return (
      format(newDateArr, tyoeFormat) + ' - ' + format(newDateDep, tyoeFormat)
    );
  }
  static formatDateToStringRevenue(dateArr: string): string | null {
    const tyoeFormat = 'dd/MM';
    const newDateArr = parseISO(dateArr);
    return format(newDateArr, tyoeFormat);
  }
  static convertToAsiaVNZone(date: Date): string {
    const tzString = 'Asia/Jakarta';
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        { timeZone: tzString }
      )
    ).toISOString();
  }

  static convertToVNTimeZone(date: Date): string {
    const hour = 7;
    date.setTime(date.getTime() + hour * 60 * 60 * 1000);
    return date.toISOString();
  }
  static convertToVNTimeZoneMbyMoment(
    date: string | null | Date,
    format: string = 'HH:mm:ss DD/MM/YYYY'
  ): string {
    const testDateUtc = moment.utc(date);
    const localDate = moment(testDateUtc).local();
    return localDate.format(format);
  }
  static convertToUTC(date: Date): string {
    return moment(date).utc().format();
  }
  static differenceInDays(
    dateTo: Date | string,
    dateForm: Date | string
  ): number {
    return moment(dateTo).diff(moment(dateForm), 'days');
  }
  static convertBirthDateFormat(oldDate: string | null): Date | undefined {
    if (oldDate === null || oldDate === undefined) {
      return undefined;
    }
    if (oldDate.length > 9) {
      return new Date(oldDate);
    }
    return undefined;
  }
  static convertStartDate(date: Date | string): any {
    const tmp = new Date(date);
    tmp.setHours(0, 0, 0, 0);
    return tmp;
  }

  static convertMiddleDate(date: Date | string): any {
    const tmp = new Date(date);
    tmp.setHours(12, 0, 0, 0);
    return tmp;
  }

  static convertEndDate(date: Date | string): any {
    const tmp = new Date(date);
    tmp.setHours(23, 59, 59, 59);
    return tmp;
  }
  static async formatNumber(value: number) {
    const stringFormat = await this.getValueLocalStorage('FMNUMBER');
    return Intl.NumberFormat(stringFormat ?? 'en-US').format(value ?? 0);
  }

  static unsetFormatNumber(value: string): number {
    return parseInt(value.split(',').join(''), 10);
  }

  static dateDiffInDays = (startDate: Date, endDate: Date): number => {
    // Discard the time and time-zone information.
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    const utc2 = Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  };

  static transformData = (obj: any, destination: any) => {
    Object?.keys(destination)?.map((key) => {
      if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
        destination[key] = obj[key];
      }
      return null;
    });
  };
  static querySearchToString(filters: any): string {
    const _filters = { ...filters };
    const searchParams = Object.keys(_filters)
      .map((filterKey) => {
        if (_filters[filterKey] === undefined || _filters[filterKey] === null) {
          return '';
        }
        return `${filterKey}=${_filters[filterKey]}`;
      })
      .join('&');
    return searchParams;
  }
  static querySearchToJson(queryString: string): any {
    const pairs = queryString.substring(1).split('&');
    const array = pairs.map((item) => {
      const parts = item.split('=');
      return parts;
    });

    return Object.fromEntries(array);
  }
  static getPageSize(windowSize: string): number {
    switch (windowSize) {
      case '2xl':
        return 15;
      case 'xl':
        return 12;
      case 'lg':
        return 12;
      case 'md':
        return 12;
      case 'sm':
        return 12;
      default:
        break;
    }
    return 12;
  }
  static getPageSizeAssign(windowSize: string): number {
    switch (windowSize) {
      case '2xl':
        return 8;
      case 'xl':
        return 6;
      case 'lg':
        return 6;
      case 'md':
        return 6;
      case 'sm':
        return 6;
      default:
        break;
    }
    return 6;
  }

  static compareString = (a: string, b: string): number => {
    return a.localeCompare(b);
  };

  static spliceSlice(str: string, index: number, count: number, add: string) {
    // We cannot pass negative indexes directly to the 2nd slicing operation.
    if (index < 0) {
      index = str.length + index;
      if (index < 0) {
        index = 0;
      }
    }

    return str.slice(0, index) + (add || '') + str.slice(index + count);
  }

  static compareWithoutTime = (date1: Date, date2: Date): boolean => {
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);
    return date1.toDateString() === date2.toDateString();
  };

  static middayTime(date: Date): Date {
    date.setHours(12, 0, 0, 0);
    return date;
  }

  static formatCreditCard = (value: string) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(' ');
    } else {
      return value;
    }
  };
  /**
   * Parse a localized number to a float.
   * @param {string} stringNumber - the localized number
   * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
   */
  static async parseLocaleNumber(stringNumber: string) {
    const stringFormat = await this.getValueLocalStorage('FMNUMBER');
    const thousandSeparator = Intl.NumberFormat(stringFormat ?? 'en-US')
      .format(11111)
      .replace(/\p{Number}/gu, '');
    const decimalSeparator = Intl.NumberFormat(stringFormat ?? 'en-US')
      .format(1.1)
      .replace(/\p{Number}/gu, '');

    return parseFloat(
      stringNumber
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.')
    );
  }
  static parseUrl(obj: { [key: string]: string }): URLSearchParams {
    const params = new URLSearchParams();
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (key && value) {
        params.set(key, value);
      }
    });
    return params;
  }
  static handleErrosMessaeg(error: any): boolean {
    if (error) {
      if (error.status === 401 || error.status === 502) {
        return false;
      }
    }
    return true;
  }
  static formatDateByUTC(date: Date): Date {
    const tmp = new Date(date);
    return new Date(
      Date.UTC(tmp.getFullYear(), tmp.getMonth(), tmp.getDate(), 0, 0, 0)
    );
  }
  static getDatesBetween = (
    startDate: Date,
    endDate: Date,
    includeEndDate?: boolean
  ) => {
    const dates = [];
    const currentDate = startDate;
    while (currentDate < endDate) {
      dates.push(format(new Date(currentDate), 'MM/dd/yyyy'));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    if (includeEndDate) {
      dates.push(endDate);
    }
    return dates;
  };
  static getTimeZoneLocal(): number {
    return new Date().getTimezoneOffset() / 60;
  }

  static storageFile = async (data: any, name: string) => {
    try {
      let url = window.URL.createObjectURL(data);
      let a = document.createElement('a');
      a.href = url;
      a.download = 'employees.json';
      a.click();
      // SimpleToast.show('Tải thành công vào thư mục Downloads ' + name);
    } catch (ex) {
      console.log(ex);
      // SimpleToast.show('Tải không thành công');
    }
  };
  static formatCurrency = (number: number | string | any, currency = 'VND') => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: currency,
    }).format(Utils.toNumber(number, 0));
  };
  static formatCurrencyWithSub = (
    number: number | string | any,
    currency = 'VND'
  ) => {
    var result = typeof number === 'string' ? parseInt(number) : number;
    result =
      `${result < 0 ? 'giảm ' : 'tăng '}` +
      new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: currency,
      }).format(result < 0 ? -result : result);
    return result;
  };
  static getDiffFromNewToCurrent = (
    newTicket: ISelectedTicket,
    currentTicket: ITicket
  ) => {
    let busC1Price = 0,
      busC2Price = 0,
      ticketPrice = 0,
      extraServicePrice = 0;

    if (newTicket.busTicketC1 || currentTicket.busTicketC1) {
      busC1Price =
        (newTicket.busTicketC1?.price || 0) -
        (currentTicket.busTicketC1?.price || 0);
    }

    if (newTicket.busTicketC2 || currentTicket.busTicketC2) {
      busC2Price =
        (newTicket.busTicketC2?.price || 0) -
        (currentTicket.busTicketC2?.price || 0);
    }

    ticketPrice = (newTicket.price || 0) - (currentTicket.price || 0);

    const newExtraServicePrice =
      newTicket.datA_TOUR_CHHangHoaDichVuKhacs?.reduce(
        (prev, current) => prev + current.price,
        0
      );
    const currentExtraServicePrice =
      currentTicket.datA_TOUR_CHHangHoaDichVuKhacs?.reduce(
        (prev, current) => prev + current.price,
        0
      );
    extraServicePrice =
      Utils.toNumber(newExtraServicePrice, 0) -
      Utils.toNumber(currentExtraServicePrice, 0);
    console.log(
      extraServicePrice,
      newExtraServicePrice,
      currentExtraServicePrice
    );

    return {
      busC1Price,
      busC2Price,
      ticketPrice,
      extraServicePrice,
    };
  };
  static objectToAntFormData = (object: any) => {
    const antFormData = [];
    for (const key in object) {
      antFormData.push({
        name: [key],
        value: object[key],
      });
    }
    return antFormData;
  };
  static getBrowser = () => {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Edg') !== -1) {
      return 'Edge';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') !== -1) {
      //IF IE > 10
      return 'IE';
    } else {
      return 'unknown';
    }
  };
  static isValid = (input: string | undefined | null | Date) => {
    if (input instanceof Date) return input !== null;
    if (input === '' || !input) return false;
    return true;
  };
  static groupBy = (xs: any[], key: string) => {
    if (!xs?.length) return {};
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  static getDefaultTicket = ({
    agent,
    index = 0,
    comboTour,
    extraServices,
    hangHoas,
  }: {
    agent: IAgent | null;
    index: number;
    comboTour: ISelectedComboTour;
    extraServices: IExtraService[];
    hangHoas: IHangHoaItem[];
  }) => {
    const ticketId = uuidv4();
    const foundTour = comboTour.ListItem?.find(
      (item) => item.ItemType === ComboTourItemType.Tour
    );
    const driverPrice = comboTour.ListItem?.find(
      (item) => item.ItemType === ComboTourItemType.DriverPrice
    );
    //[18-12-2023][Phuc Thinh][Feature/14999]
    const foundMotorPrice = comboTour.ListItem?.find(
      (item) => item.ItemType === ComboTourItemType.MotorPrice
    );
    const busComboItems = comboTour.ListItem
      ? comboTour.ListItem.filter(
          (item) => item.ItemType === ComboTourItemType.Bus
        )
      : [];
    const hangHoaComboItems = comboTour.ListItem
      ? comboTour.ListItem.filter(
          (item) => item.ItemType === ComboTourItemType.HangHoa
        )
      : [];
    const roomComboItems = comboTour.ListItem
      ? comboTour.ListItem.filter(
          (item) => item.ItemType === ComboTourItemType.RoomTemplate
        )
      : [];
    const hasBusCombo = busComboItems && busComboItems.length > 0;
    const busC1 = busComboItems.find(
      (item) =>
        item.DMUC_Router_Station &&
        item.DMUC_Router_Station.DMUC_Router?.LocaltionEndId ===
          Utils.HaGiangLocationId
    );
    const busC2 = busComboItems.find(
      (item) =>
        item.DMUC_Router_Station &&
        item.DMUC_Router_Station.DMUC_Router?.LocaltionStartId ===
          Utils.HaGiangLocationId
    );
    // change date to UTC date
    const today = Utils.formatDateByUTC(new Date());
    const startDate = moment(today).add(2, 'days');
    const tomorrow = moment(today).add(1, 'day').toISOString();

    // extraService value
    let datA_TOUR_CHHangHoaDichVuKhacs: ITicket_ExtraService[] = [];
    if (hangHoaComboItems?.length) {
      hangHoaComboItems.forEach((hangHoaComboItem) => {
        const DMUC_CHHangHoaDichVuKhacId =
          hangHoaComboItem.DMUC_CHHangHoaDichVuKhacs?.Id;
        if (DMUC_CHHangHoaDichVuKhacId) {
          const foundExtraService = extraServices.find(
            (extraService) => extraService.id === DMUC_CHHangHoaDichVuKhacId
          );
          const foundHangHoa = hangHoas.find(
            (hangHoa) => hangHoa.id === hangHoaComboItem.ItemId
          );
          if (foundExtraService && foundHangHoa) {
            datA_TOUR_CHHangHoaDichVuKhacs.push({
              dmuC_HangHoaId: hangHoaComboItem.ItemId,
              soLuong: 1,
              extraService: foundExtraService,
              price: foundHangHoa.price,
              dmuC_CHHangHoaDichVuKhacId: DMUC_CHHangHoaDichVuKhacId,
            });
          }
        }
      });
    }

    if (roomComboItems?.length) {
      roomComboItems.forEach((roomComboItem) => {
        // const DMUC_CHHangHoaDichVuKhacId =
        //   roomComboItem.DMUC_CHHangHoaDichVuKhacId;
        // if (DMUC_CHHangHoaDichVuKhacId) {
        const foundExtraService = extraServices.find(
          (extraService) => extraService.code === ExtraServiceCode.CHANGE_ROOM
        );
        if (foundExtraService) {
          datA_TOUR_CHHangHoaDichVuKhacs.push({
            roomTemplateId: roomComboItem.ItemId,
            soLuong: 1,
            extraService: foundExtraService,
            price: roomComboItem.MoneyToPay || 0,
            dmuC_CHHangHoaDichVuKhacId: foundExtraService.id,
          });
        }
        // }
      });
    }

    const defaultTicket: ITicket = {
      id: ticketId,
      name: `${agent ? agent.code : ''}_JM${moment()
        .add(index + 1, 'second')
        .format('DDMMYYYYHHmmss')}`,
      fullName: null,
      phone: null,
      passportId: null,
      startDate: startDate.toISOString() || '',
      arrivalDate: tomorrow || '',
      //[1-12-2023] [Phuc Thinh]
      //Đổi ngày về default thành endDate của Tour
      departDate:
        moment(startDate)
          .add(Utils.toNumber(driverPrice?.CountDay, 4) - 1, 'days')
          .toISOString() || '',
      driverPriceId: driverPrice?.Id,
      tourId: foundTour?.Id,
      comboTourId: comboTour.Id,
      selfMotor: foundMotorPrice?.Value,
      dmuC_MotorPriceId: foundMotorPrice?.Id,
      busTicketC1:
        hasBusCombo && busC1
          ? {
              ticketId,
              dateTime: tomorrow || '',
              routerStationBusId: busC1?.Id,
              price: busC1.Price,
            }
          : undefined,
      busTicketC2:
        hasBusCombo && busC2
          ? {
              ticketId,
              //[1-12-2023] [Phuc Thinh]
              //Đổi ngày về default thành endDate của Tour
              dateTime:
                moment(startDate)
                  .add(Utils.toNumber(driverPrice?.CountDay, 4) - 1, 'days')
                  .toISOString() || '',
              routerStationBusId: busC2?.Id,
              price: busC2.Price,
            }
          : undefined,
      datA_TOUR_CHHangHoaDichVuKhacs,
    };
    return defaultTicket;
  };
  static toNumber = (value: any, fallBackNumIfNaN: number) => {
    const typeOfValue = typeof value;
    if (typeOfValue === 'string') return parseInt(value);
    if (typeOfValue === 'number') return value;
    return fallBackNumIfNaN;
  };
  static mapArray = (
    mainArr: any[],
    subArray: any[],
    compareMainKey: string,
    compareSubKey: string,
    mapKey: string
  ) => {
    let results: any[] = [];
    if (mainArr.length && subArray.length) {
      mainArr.forEach((mainItem) => {
        if (
          subArray.some(
            (subItem) =>
              parseInt(subItem[compareSubKey]) ===
              parseInt(mainItem[compareMainKey])
          )
        ) {
          subArray.forEach((subItem) => {
            if (
              parseInt(subItem[compareSubKey]) ===
              parseInt(mainItem[compareMainKey])
            ) {
              let newMainItem = {
                ...mainItem,
              };
              newMainItem[mapKey] = subItem;
              results.push(newMainItem);
            }
          });
        }
      });
    }
    return results;
  };

  static calculateTotal = (toursSelected: ISelectedComboTour[]) => {
    let tickets: ITicketWithPrice[] = [];
    toursSelected.forEach((tour) => {
      if (tour.tickets && tour.tickets.length > 0) {
        tickets = [
          ...tickets,
          ...tour.tickets.map((ticket) => ({
            ...ticket,
            price: ticket.price ?? 0,
          })),
        ];
      }
    });

    let total = 0;
    tickets.forEach((ticket) => {
      if (ticket && ticket.price) {
        total += ticket.price;
      }
    });
    return total;
  };
  static addDaysToDate = (date = new Date(), days: number) => {
    const dateCopy = new Date(date);

    dateCopy.setDate(dateCopy.getDate() + days);

    return dateCopy;
  };
  static getBookingTicketPrice = (
    ticket: IBookingTicket,
    priceReqs: IPriceReq[]
  ) => {
    let price = 0;
    const priceReqInfo = priceReqs.find(
      (price) => price.id === ticket.driverPriceId
    );
    if (priceReqInfo) {
      price += parseInt(priceReqInfo.price);
    }
    return price;
  };
  static getBusPrice = (
    ticket: ITicket | IBookingTicket | ITicketOperatorItem
  ) => {
    let busPrice = 0;
    if (ticket.busTicketC1) {
      busPrice += ticket.busTicketC1.price || 0;
    }
    if (ticket.busTicketC2) {
      busPrice += ticket.busTicketC2.price || 0;
    }
    return busPrice;
  };
  static getExtraServicePrice = (
    ticket: ITicket | IBookingTicket | ITicketOperatorItem
  ) => {
    let extraServicePrice = 0;
    if (
      ticket.datA_TOUR_CHHangHoaDichVuKhacs &&
      ticket.datA_TOUR_CHHangHoaDichVuKhacs.length > 0
    ) {
      ticket.datA_TOUR_CHHangHoaDichVuKhacs.forEach((service) => {
        extraServicePrice += service.price;
      });
    }
    return extraServicePrice;
  };
  static calculateBusTotal = (toursSelected: ISelectedComboTour[]) => {
    let total = 0;
    let tickets: ITicket[] = [];
    toursSelected.forEach((tour) => {
      if (tour.tickets) {
        tickets = [...tickets, ...tour.tickets];
      }
    });
    tickets.forEach((ticket) => {
      if (!ticket.price || ticket.price <= 0) return;
      if (ticket.busTicketC1) {
        total += ticket.busTicketC1.price;
      }
      if (ticket.busTicketC2) {
        total += ticket.busTicketC2.price;
      }
    });
    return total;
  };
  static calculateExtraServiceTotal = (toursSelected: ISelectedComboTour[]) => {
    let total = 0;
    let tickets: ITicket[] = [];
    toursSelected.forEach((tour) => {
      if (tour.tickets) {
        tickets = [...tickets, ...tour.tickets];
      }
    });
    tickets.forEach((ticket) => {
      if (!ticket.price || ticket.price <= 0) return;
      if (
        ticket.datA_TOUR_CHHangHoaDichVuKhacs &&
        ticket.datA_TOUR_CHHangHoaDichVuKhacs.length > 0
      ) {
        ticket.datA_TOUR_CHHangHoaDichVuKhacs.forEach((service) => {
          total += service.price;
        });
      }
    });
    return total;
  };
  static isGroupEnded = (group: IGroup) => {
    // if (group === null) return false;
    if (group === undefined) return false;
    // const endDate = moment(group.startDate)
    //   .add(group.dmuC_Tour.countDay - 1, "day")
    //   .endOf("day");

    // return endDate.isBefore(moment());

    return group.status === GroupStatusEnum.END;
  };
  static isGroupTakingPlace = (group: IGroup) => {
    // if (group === null) return false;
    if (group === undefined) return false;
    // const today = moment();
    // const startDate = moment(group.startDate).set({
    //   hour: 8,
    //   minute: 0,
    //   second: 0,
    //   millisecond: 0,
    // });
    // const endDate = moment(group.startDate)
    //   .add(group.dmuC_Tour.countDay - 1, "day")
    //   .endOf("day");

    return group.status === GroupStatusEnum.RUNNING;
  };
  static isBusinessDateAvailable = (
    group: IGroup | null,
    jobDate: JobDateEnum | SegmentedValue
  ) => {
    if (group === null || jobDate === JobDateEnum.ALL) return false;
    const businessDate = moment.utc(group.businessDate).endOf('day');
    const tempBusinessDate = businessDate.isBefore(moment.utc(group.startDate))
      ? moment.utc(group.startDate).subtract(1, 'days').endOf('day')
      : businessDate;

    const groupDate = moment
      .utc(group.startDate)
      .add(jobDate, 'days')
      .subtract(1, 'day')
      .startOf('day')
      .add(1, 'minute');

    return groupDate.isBetween(
      tempBusinessDate,
      tempBusinessDate.clone().add(1, 'days')
    );
    // const businessDate = moment.utc(group.businessDate).endOf("day");
    // const groupDate = moment
    //   .utc(group.startDate)
    //   .add(jobDate, "days")
    //   .subtract(1, "day")
    //   .startOf("day")
    //   .add(1, "minute");

    // return groupDate.isBetween(businessDate, moment().endOf("day"));
  };
  static getParamsString = (params: IParams) => {
    let key: keyof IParams;
    let paramsString = '';
    for (key in params) {
      if (
        params[key] !== null &&
        params[key] !== undefined &&
        key !== 'results'
      ) {
        paramsString += `${key}=${params[key]}&`;
      }
    }
    return paramsString;
  };
  static getName = (value: string) => {
    const fullName = value.trim();
    const indexOfLastSpace = fullName.lastIndexOf(' ');
    if (indexOfLastSpace < 0)
      return {
        firstName: fullName,
        lastName: '',
      };
    const lastName = fullName.slice(0, indexOfLastSpace);
    const firstName = fullName.slice(indexOfLastSpace, fullName.length);
    return {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
    };
  };
  static getMotorbikeIdOfListInItem = (item: IGroupTicket | null) => {
    if (!item) return null;
    const list = item.motorbike_Ticket;
    if (!list || list.length === 0) return null;
    const foundItem = list.find(
      (listItem: IMotorbikeTicket) => listItem.status === 3
    );
    // const foundItem = list.find((listItem: IMotorbikeTicket) => moment().startOf('day').isSame(moment(listItem.date).startOf('day')))
    return foundItem
      ? foundItem.typeMotor === MotorTicketType.Jasmine ||
        foundItem.typeMotor === MotorTicketType.Chung
        ? foundItem.motorbikeId
        : foundItem.typeMotor.toString()
      : null;
  };
  static getDriverIdOfListInItem = (item: IGroupTicket | null) => {
    if (!item) return null;
    const list = item.driver_Tickets;
    if (!list || list.length === 0) return null;
    const foundItem = list.find(
      (listItem: IDriverTicket) => listItem.status === 3
    );
    // const foundItem = list.find((listItem: IDriverTicket) => moment().startOf('day').isSame(moment(listItem.date).startOf('day')))
    return foundItem ? foundItem.driverId : null;
  };
  static getDriverByAccountId = (accountId: string, drivers: IDriver[]) => {
    if (drivers.length === 0) return null;
    const foundItem = drivers.find((driver) => driver.accountId === accountId);
    // const foundItem = list.find((listItem: IDriverTicket) => moment().startOf('day').isSame(moment(listItem.date).startOf('day')))
    return foundItem;
  };
  static getMaxIndex = (array: number[]) => {
    if (!array || array.length === 0) return 0;
    const sortedArray = [...array];
    sortedArray.sort((a, b) => b - a);

    return sortedArray[0];
  };
  static getIndexInGroupName = (groupName: string) => {
    if ((groupName.match(/_/g) || []).length < 2) return 0;

    const indexOfLastUnderscore = groupName.lastIndexOf('_');
    if (indexOfLastUnderscore < 0) return 0;
    const index = groupName.slice(indexOfLastUnderscore + 1, groupName.length);

    return parseInt(index);
  };
  static calculateTotalBookingPrice = (
    booking: IBooking,
    checkTicketOnRefund?: boolean
  ) => {
    let tickets = booking.tickets
      .filter((ticket) => ticket.isErase !== true)
      .map((ticket) => ({
        ...ticket,
        price: ticket.price ?? 0,
      }));

    let total = 0;
    tickets.forEach((ticket) => {
      if (ticket && ticket.price) {
        let ticketPrice = 0;
        ticketPrice += ticket.price;

        const chargePercent =
          checkTicketOnRefund && this.checkOncomingTicket(ticket)
            ? 100 - this.refundPercent
            : 100;
        total += Math.round((ticketPrice * chargePercent) / 100);
      }
    });
    return total;
  };

  static calculateBookingBusTotal = (
    booking: IBooking,
    checkTicketOnRefund?: boolean
  ) => {
    let total = 0;
    let tickets: IBookingTicket[] = booking.tickets.filter(
      (ticket) => ticket.isErase !== true
    );
    tickets.forEach((ticket) => {
      let busPrice = 0;
      if (ticket.busTicketC1) {
        busPrice += ticket.busTicketC1.price;
      }
      if (ticket.busTicketC2) {
        busPrice += ticket.busTicketC2.price;
      }
      const chargePercent =
        checkTicketOnRefund && this.checkOncomingTicket(ticket)
          ? 100 - this.refundPercent
          : 100;
      total += Math.round((busPrice * chargePercent) / 100);
    });
    return total;
  };
  static calculateBookingExtraServiceTotal = (
    booking: IBooking,
    checkTicketOnRefund?: boolean
  ) => {
    let total = 0;
    let tickets: IBookingTicket[] = booking.tickets.filter(
      (ticket) => ticket.isErase !== true
    );
    tickets.forEach((ticket) => {
      let extraServiceTotal = 0;
      if (
        ticket.datA_TOUR_CHHangHoaDichVuKhacs &&
        ticket.datA_TOUR_CHHangHoaDichVuKhacs.length > 0
      ) {
        ticket.datA_TOUR_CHHangHoaDichVuKhacs.forEach((service) => {
          extraServiceTotal += service.price;
        });
      }

      const chargePercent =
        checkTicketOnRefund && this.checkOncomingTicket(ticket)
          ? 100 - this.refundPercent
          : 100;
      total += Math.round((extraServiceTotal * chargePercent) / 100);
    });
    return total;
  };

  static getBookingPayment = (
    paymentMethodBookings: IPaymentMethodBooking[]
  ) => {
    let paid = 0;
    let jasmineFee = 0;
    const JasmineFeeCode = '90015';
    paymentMethodBookings.forEach((method) => {
      const methodPaymentAmount = method.amount;
      if (method.status === PaymentMethodBookingStatus.Pay) {
        // [4-12-2023] [Phuc Thinh] [Update paymentMethodBooking interface]
        if (method.paymentCode === JasmineFeeCode) {
          jasmineFee += methodPaymentAmount;
        } else {
          paid += methodPaymentAmount;
        }
      } else if (method.status === PaymentMethodBookingStatus.Refund) {
        if (method.paymentCode === JasmineFeeCode) {
          jasmineFee -= methodPaymentAmount;
        } else {
          paid -= methodPaymentAmount;
        }
      }
    });
    return {
      paid,
      jasmineFee,
    };
  };
  static addIndexToBooking = (bookings: IBooking[]) => {
    if (bookings.length > 0) {
      let currentIndex = 0;
      let currentBookingDate = moment(bookings[0].createdTime).startOf('day');
      const newBookings = bookings.map((booking) => {
        let index;
        if (
          moment(booking.createdTime).startOf('day').isSame(currentBookingDate)
        ) {
          index = currentIndex + 1;
          currentIndex += 1;
        } else {
          index = 1;
          currentIndex = 1;
          currentBookingDate = moment(booking.createdTime).startOf('day');
        }
        return {
          ...booking,
          index: index,
        };
      });
      return newBookings;
    }
    return [];
  };
  static getMaxCountDay = (groupTickets: IGroupTicket[]) => {
    if (!groupTickets || groupTickets.length === 0) return 4;
    let countDays: number[] = [];
    groupTickets.forEach((gTicket) =>
      countDays.push(parseInt(gTicket.driverPrice?.countDay ?? '4'))
    );
    return countDays.length > 0 ? countDays.sort((a, b) => b - a)[0] : 4;
  };
  static getMaxGroupCountDay = (groups: IGroup[]) => {
    if (!groups || groups.length === 0) return 4;
    let countDays: number[] = [];
    groups.forEach((group) => countDays.push(this.getGroupCountDay(group)));
    return countDays.length > 0 ? countDays.sort((a, b) => b - a)[0] : 4;
  };
  static getIssueTime = (issue: IIssue, format = 'DD/MM/YYYY') => {
    const createdTime = moment.utc(issue.createdTime).local();
    const modifiedTime = moment.utc(issue.modifiedTime).local();
    switch (issue.status) {
      case IssueStatus.Approve:
        return {
          color: '#52C41A',
          text: `Đã duyệt lúc ${modifiedTime.format(format)}`,
          time: modifiedTime,
        };
      case IssueStatus.Reject:
        return {
          color: '#ff4d4f',
          text: `Đã từ chối lúc ${modifiedTime.format(format)}`,
          time: modifiedTime,
        };
      case IssueStatus.ComfirmByAdmin:
        return {
          color: '#52C41A',
          text: `Đã kiểm toán lúc ${modifiedTime.format(format)}`,
          time: modifiedTime,
        };
      case IssueStatus.Init:
        return {
          color: '#1677ff',
          text: `Đã tạo lúc ${createdTime.format(format)}`,
          time: createdTime,
        };
      default:
        return {
          color: '#1677ff',
          text: `Đã tạo lúc ${createdTime.format(format)}`,
          time: createdTime,
        };
    }
  };
  static checkIsDay4MissingLead = (
    group: IGroup | null,
    drivers: IDriver[],
    groupTickets: IGroupTicket[]
  ) => {
    if (!group) return false;
    const leader = this.getDriverByAccountId(group?.leaderId || '', drivers);
    if (leader) {
      return groupTickets.some((gTicket) => {
        const runningDriverId = this.getDriverIdOfListInItem(gTicket);
        return (
          runningDriverId === leader.id && gTicket.driverPrice?.countDay !== '4'
        );
      });
    } else {
      return false;
    }
  };
  static checkGroupEndDateIsBeforeCountDay = (group: IGroup | null) => {
    if (!group) return false;
    if (group?.dmuC_Tour?.countDay === 3) return false;
    return moment(group.endDate)
      .subtract(1, 'minute')
      .endOf('day')
      .isBefore(
        moment(group.startDate)
          .add(group.dmuC_Tour.countDay - 1, 'days')
          .endOf('day')
      );
  };
  static getDayFromBusinessDate = (group: IGroup | null) => {
    if (!group) return false;
    return moment(group.businessDate)
      .endOf('day')
      .isBefore(moment(group.startDate).startOf('day'))
      ? 1
      : moment(group.businessDate).diff(moment(group.startDate), 'days') + 2 <=
        group.dmuC_Tour?.countDay
      ? moment(group.businessDate).diff(group.startDate, 'days') + 2
      : group.dmuC_Tour?.countDay;
  };
  static moneyFormatter = (ammount: number) => {
    let results = ammount.toString();
    if (ammount <= 0) return results;
    const FORMATTERS = [
      {
        unitPosition: 1,
        suffix: '',
      },
      {
        unitPosition: 1000,
        suffix: 'K',
      },
      {
        unitPosition: 1000000,
        suffix: 'M',
      },
      {
        unitPosition: 1000000000,
        suffix: 'B',
      },
    ];
    for (let i = 0; i < FORMATTERS.length; i++) {
      if (ammount >= FORMATTERS[i].unitPosition) {
        if (i === FORMATTERS.length - 1) {
          results =
            Math.floor(
              Math.sign(ammount) *
                (Math.abs(ammount) / FORMATTERS[i].unitPosition) *
                10
            ) /
              10 +
            FORMATTERS[i].suffix;
          return results;
        } else {
          if (ammount < FORMATTERS[i + 1].unitPosition) {
            results =
              Math.floor(
                Math.sign(ammount) *
                  (Math.abs(ammount) / FORMATTERS[i].unitPosition) *
                  10
              ) /
                10 +
              FORMATTERS[i].suffix;
            return results;
          }
        }
      }
    }
  };
  static checkOncomingTicket = (ticket: ITicket | IBookingTicket) => {
    return moment
      .utc(ticket.startDate)
      .local()
      .subtract(1, 'day')
      .startOf('day')
      .isBefore(moment().endOf('day'));
  };
  static checkDepartedTicket = (ticket: ITicket | IBookingTicket) => {
    return moment
      .utc(ticket.startDate)
      .endOf('day')
      .isBefore(moment().startOf('day'));
  };
  static getCurrentValueMotorbikeTicket = (
    mTickets: IMotorbikeTicket[] | undefined
  ) => {
    if (!mTickets || mTickets.length === 0) return null;
    const foundMTicket = mTickets.find((mTicket) => mTicket.status === 3);
    if (!foundMTicket) return null;
    return foundMTicket.typeMotor === MotorTicketType.Jasmine ||
      foundMTicket.typeMotor === MotorTicketType.Chung
      ? foundMTicket.motorbikeId
      : foundMTicket.typeMotor.toString();
  };
  static toUniqueArr = (array: any[]) => {
    if (!Array.isArray(array)) return [];
    const onlyUnique = (value: any, index: number, array: any[]) => {
      return array.indexOf(value) === index;
    };
    return array.filter(onlyUnique);
  };
  static countKeysWithValues = (obj: any, values: any[]) => {
    const keys: (keyof typeof obj)[] = Object.keys(obj);
    return keys.filter((key) => {
      return values.some((value) => value === obj[key]);
    }).length;
  };
  static isObjectEmpty = (objectName: any) => {
    for (let prop in objectName) {
      if (
        objectName.hasOwnProperty(prop) &&
        objectName[prop] !== '' &&
        objectName[prop]
      ) {
        return false;
      }
    }
    return true;
  };

  //[16-12-2023][Phuc Thinh][Feature/14984]
  static mapValueToPayload_extraService = (
    value: ITicket_ExtraService[],
    roomTemplates: IRoomTemplate[],
    hangHoas?: IHangHoaItem[]
  ) => {
    let datA_TOUR_CHHangHoaDichVuKhacs: ITicket_ExtraService[] = value;
    let datA_TOUR_CHHangHoaDichVuChangeRoom: ITicket_ExtraService[] = [];
    if (!datA_TOUR_CHHangHoaDichVuKhacs?.length) return [];
    datA_TOUR_CHHangHoaDichVuKhacs.forEach((extraServiceValue) => {
      if (
        extraServiceValue.extraService?.code === ExtraServiceCode.CHANGE_ROOM &&
        extraServiceValue.roomTemplateIds?.length
      ) {
        const foundRooms = roomTemplates.filter((room) =>
          extraServiceValue.roomTemplateIds?.some((id) => id === room.id)
        );
        if (foundRooms?.length) {
          foundRooms.forEach((foundRoom) => {
            datA_TOUR_CHHangHoaDichVuChangeRoom.push({
              roomTemplateId: foundRoom.id,
              price: foundRoom.price,
              soLuong: 1,
              dmuC_CHHangHoaDichVuKhacId:
                extraServiceValue.dmuC_CHHangHoaDichVuKhacId,
              extraService: extraServiceValue.extraService,
            });
          });
        }
      }
    });

    return datA_TOUR_CHHangHoaDichVuKhacs
      .filter(
        (extraServiceValue) =>
          extraServiceValue.extraService?.code !== ExtraServiceCode.CHANGE_ROOM
      )
      .concat(datA_TOUR_CHHangHoaDichVuChangeRoom)
      .map((extraServiceValue) => {
        const extraService = extraServiceValue.extraService;
        if (extraService && extraService.type === ServiceType.HANGHOA) {
          const foundHangHoa = hangHoas?.find(
            (hangHoa) => hangHoa.id === extraServiceValue.dmuC_HangHoaId
          );
          return {
            ...extraServiceValue,
            price:
              Utils.toNumber(foundHangHoa?.price, 0) *
              Utils.toNumber(extraServiceValue.soLuong, 1),
          };
        } else {
          return {
            ...extraServiceValue,
            price:
              Utils.toNumber(extraServiceValue.price, 0) *
              Utils.toNumber(extraServiceValue.soLuong, 1),
          };
        }
      });
  };
  static sortComboTour = (comboTours: IComboTour[] | ISelectedComboTour[]) => {
    const temp = [...comboTours];
    return temp.sort((a, b) => {
      // const foundADriverPrice = a.ListItem?.find(
      //   (item) => item.ItemType === ComboTourItemType.DriverPrice
      // );
      // const foundBDriverPrice = b.ListItem?.find(
      //   (item) => item.ItemType === ComboTourItemType.DriverPrice
      // );
      // if (!foundADriverPrice || !foundBDriverPrice) return -1;
      // const { CountDay: aCountDay, IsDriver: aIsDriver } = foundADriverPrice;
      // const { CountDay: bCountDay, IsDriver: bIsDriver } = foundBDriverPrice;
      // if (aCountDay > bCountDay) return 1;
      // if (bCountDay > aCountDay) return -1;
      // if (aIsDriver === IsDriverType.YES) return a.GiaHienThi - b.GiaHienThi;
      // if (bIsDriver === IsDriverType.YES) return a.GiaHienThi - b.GiaHienThi;
      // if (a.GiaHienThi > b.GiaHienThi) return 1;
      // if (b.GiaHienThi > a.GiaHienThi) return -1;
      // if (aCountDay === bCountDay) return 0;
      // return -1;
      return 0;
    });
  };
  static sortSelectedComboToursByComboTours = (
    selectedComboTours: ISelectedComboTour[],
    comboTours: IComboTour[]
  ) => {
    const temp: ISelectedComboTour[] = [];
    comboTours.forEach((combo) => {
      const foundSelectedComboTour = selectedComboTours.find(
        (selectedCombo) => selectedCombo.Id === combo.Id
      );
      if (foundSelectedComboTour) {
        temp.push(foundSelectedComboTour);
      }
    });
    return temp;
  };
  static convertJobItemToJob = (
    jobItem: IJobItem,
    groupJob: IGroupJob,
    index: number
  ) => {
    return {
      ...jobItem,
      id: uuidv4(),
      price: 0,
      stt: (index + 1).toString(),
      name: jobItem.name,
      jobItemId: jobItem.id,
      // jobItem,
      day: groupJob.day.toString(),
      status: 0,
      supplierId: jobItem.supplierId,
      nameSupplier: '',
      jobTickets: [],
      sumarizedMoney: 0,
      datA_TOUR_Issues: [],
    };
  };
  static getBusLocation = (
    allLocations: ILocation[],
    fromLocationId: string,
    endLocationId: string
  ) => {
    return {
      fromLocation: allLocations.find(
        (location) => location.id === fromLocationId
      ),
      endLocation: allLocations.find(
        (location) => location.id === endLocationId
      ),
    };
  };
  static getGroupCountDay = (group: IGroup) => {
    const startDate = moment.utc(group.startDate);
    const endDate = moment.utc(group.endDate);
    const countDay = endDate.diff(startDate, 'days') + 1;
    console.log({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      countDay,
    });
    return countDay;

    // return {
    //   fromLocation: allLocations.find(
    //     (location) => location.id === fromLocationId
    //   ),
    //   endLocation: allLocations.find(
    //     (location) => location.id === endLocationId
    //   ),
    // };
  };
}

export default Utils;

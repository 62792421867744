import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAgentAccountsResult,
  IAgentsResult,
  RoleEnum,
  RootEpic,
} from "common/define-types";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import {
  assignAccount,
  createAgent,
  createAgents,
  deleteAgentById,
  getAllAgents,
  removeAccount,
  updateAgent,
} from "api/agent.api";
import {
  IAgent,
  ICreateAgent,
  ICreateAgentAccount,
  IDeleteAgentAccount,
  IUpdateAgent,
  IUpdateAgentAccount,
} from "api/types/agent";
import {
  assignRoleForAccount,
  blockUsers,
  createAccount,
  deleteAccount,
  getAccountsByIds,
  unblockUsers,
  updateAccount,
} from "api/account.api";

export interface AgentsState {
  isLoading: boolean;
  isAgentAccountLoading: boolean;
  isAgentSaving: boolean;
  isAgentAccountSaving: boolean;
  isAgentAccountCreating: boolean;
  addAgentOpen: boolean;
  discountAgent: IAgent | null;
  agents: IAgent[] | [];
  agentsResults: IAgentsResult | null;
  agentSelected: IAgent | null;
  agentSelectedAccounts: IAccount[];
  agentAccountsResult: IAgentAccountsResult | null;
  deletingAgentId: string | null;
  deletingAgentAccountId: string | null;
  editingAgent: IAgent | null;
  editingAgentAccount: IAccount | null;
  errMsg: string | null;
}

const initialState: AgentsState = {
  isLoading: false,
  isAgentAccountLoading: false,
  isAgentSaving: false,
  isAgentAccountSaving: false,
  isAgentAccountCreating: false,
  addAgentOpen: false,
  discountAgent: null,
  agents: [],
  agentsResults: null,
  agentSelected: null,
  agentSelectedAccounts: [],
  agentAccountsResult: null,
  deletingAgentId: null,
  deletingAgentAccountId: null,
  editingAgent: null,
  editingAgentAccount: null,
  errMsg: null,
};

export const agentsSlice = createSlice({
  name: "agents",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchAgents: (state, action: PayloadAction<IAgentsResult | undefined>) => {
      state.isLoading = true;
    },
    fetchAgentAccounts: (
      state,
      action: PayloadAction<IAgentAccountsResult | undefined>
    ) => {
      state.isAgentAccountLoading = true;
    },
    setAgents: (state, action: PayloadAction<IAgent[]>) => {
      state.agents = action.payload;
      state.isLoading = false;
      state.isAgentSaving = false;
      state.isAgentAccountCreating = false;
    },
    setAgentsResults: (state, action: PayloadAction<IAgentsResult>) => {
      state.agentsResults = action.payload;
      state.isLoading = false;
      state.isAgentSaving = false;
      state.isAgentAccountCreating = false;
    },
    setAgentAccounts: (state, action: PayloadAction<IAccount[]>) => {
      state.agentSelectedAccounts = action.payload;
      state.isLoading = false;
      state.isAgentAccountLoading = false;
    },
    setAgentAccountsResults: (
      state,
      action: PayloadAction<IAgentAccountsResult>
    ) => {
      state.agentAccountsResult = action.payload;
      state.isLoading = false;
      state.isAgentAccountLoading = false;
    },
    setAgentSelected: (state, action: PayloadAction<IAgent | null>) => {
      state.agentSelected = action.payload;
      state.isAgentAccountLoading = true;
      state.errMsg = null;
    },
    addAgent: (state, action: PayloadAction<ICreateAgent>) => {
      state.isAgentSaving = true;
    },
    addAgentFullfiled: (state, action: PayloadAction<IAgent>) => {
      // state.agents = [
      //   ...action.payload,
      //   ...state.agents
      // ];
      state.isAgentSaving = false;
      state.addAgentOpen = false;
    },
    addAgents: (state, action: PayloadAction<ICreateAgent[]>) => {
      state.isAgentSaving = true;
    },
    addAgentsFullfiled: (state, action: PayloadAction<IAgent[]>) => {
      // state.agents = [
      //   ...action.payload,
      //   ...state.agents
      // ];
      state.isAgentSaving = false;
      state.addAgentOpen = false;
    },
    deleteAgent: (state, action: PayloadAction<string>) => {
      state.deletingAgentId = action.payload;
    },
    editAgent: (state, action: PayloadAction<any | null>) => {
      state.editingAgent = action.payload;
    },
    editAgentAccount: (state, action: PayloadAction<any | null>) => {
      state.editingAgentAccount = action.payload;
    },
    deleteAgentFullfilled: (state, action: PayloadAction<number>) => {
      if (action.payload === 1) {
        const newAgents = state.agents.map((agent) =>
          agent.id === state.deletingAgentId
            ? {
                ...agent,
                isErase: true,
              }
            : agent
        );
        state.agents = newAgents;
        if (!newAgents.some((agent) => agent.id === state.agentSelected?.id)) {
          state.agentSelected = newAgents[0];
        }
      } else {
        state.errMsg = "Có lỗi xảy ra";
      }
      state.deletingAgentId = null;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.deletingAgentId = null;
      state.isAgentAccountLoading = false;
    },
    saveAgent: (state, action: PayloadAction<IUpdateAgent>) => {
      state.isAgentSaving = true;
      state.errMsg = null;
    },
    blockOrUnblockAgent: (state, action: PayloadAction<IUpdateAgent>) => {
      state.isAgentSaving = true;
      state.errMsg = null;
    },
    createAgentAccount: (state, action: PayloadAction<ICreateAgentAccount>) => {
      state.isAgentAccountCreating = true;
      state.errMsg = null;
    },
    saveAgentAccount: (state, action: PayloadAction<IUpdateAgentAccount>) => {
      state.isAgentAccountSaving = true;
      state.errMsg = null;
    },
    deleteAgentAccount: (state, action: PayloadAction<IDeleteAgentAccount>) => {
      state.deletingAgentAccountId = action.payload.accountId;
      state.errMsg = action.payload.showMessage ? null : state.errMsg;
    },
    setAddAgentOpen: (state, action: PayloadAction<boolean>) => {
      state.addAgentOpen = action.payload;
    },
    setDiscountAgent: (state, action: PayloadAction<IAgent | null>) => {
      state.discountAgent = action.payload;
    },
  },
});
const getAgents$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchAgents.match),
    switchMap((re) => {
      return getAllAgents(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const agents = res.results;
            if (agents.length > 0) {
              return [
                agentsSlice.actions.setAgents(agents),
                agentsSlice.actions.setAgentSelected(agents[0]),
                agentsSlice.actions.setAgentsResults({
                  ...re.payload,
                  ...res,
                }),
              ];
            }
            return [
              agentsSlice.actions.setAgents([]),
              agentsSlice.actions.setAgentSelected(null),
              agentsSlice.actions.setAgentsResults({
                ...re.payload,
                ...res,
              }),
            ];
          } else {
            return [agentsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          agentsSlice.actions.setErrMsg("Có lỗi xảy ra"),
        ])
      );
    })
  );

const addAgent$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addAgent.match),
    switchMap((re) => {
      return createAgent(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const agentsResults = state$.value.agents.agentsResults;
            return [
              agentsSlice.actions.addAgentsFullfiled(res),
              agentsSlice.actions.fetchAgents(
                agentsResults
                  ? {
                      ...agentsResults,
                    }
                  : undefined
              ),
            ];
          } else {
            return [agentsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          agentsSlice.actions.setErrMsg("Có lỗi xảy ra khi thêm đại lý"),
        ])
      );
    })
  );
const addAgents$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addAgents.match),
    switchMap((re) => {
      return createAgents(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const agentsResults = state$.value.agents.agentsResults;
            return [
              agentsSlice.actions.addAgentsFullfiled(res),
              agentsSlice.actions.fetchAgents(
                agentsResults
                  ? {
                      ...agentsResults,
                    }
                  : undefined
              ),
            ];
          } else {
            return [agentsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          agentsSlice.actions.setErrMsg("Có lỗi xảy ra khi thêm đại lý"),
        ])
      );
    })
  );
const deleteAgent$: RootEpic = (action$) =>
  action$.pipe(
    filter(deleteAgent.match),
    switchMap((re) => {
      return deleteAgentById(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res === 1) {
            return [agentsSlice.actions.deleteAgentFullfilled(res)];
          } else {
            return [agentsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          agentsSlice.actions.setErrMsg("Có lỗi xảy ra khi xóa đại lý"),
        ])
      );
    })
  );
const getAgentAccounts$: RootEpic = (action$) =>
  action$.pipe(
    filter(setAgentSelected.match),
    switchMap((re) => {
      if (re.payload) {
        return getAccountsByIds(re.payload.accountIds).pipe(
          mergeMap((res: any) => {
            if (res && !res?.response?.error && res.results) {
              const agents = res.results;
              return [
                agentsSlice.actions.setAgentAccounts(agents ?? []),
                agentsSlice.actions.setAgentAccountsResults(res),
              ];
            } else {
              return [agentsSlice.actions.setErrMsg(res?.response.error)];
            }
          }),
          catchError((e: AjaxError) => [
            agentsSlice.actions.setErrMsg(
              "Có lỗi xảy ra khi lấy thông tin đại lý"
            ),
          ])
        );
      }
      return [agentsSlice.actions.setAgentAccounts([])];
    })
  );
const fetchAgentAccounts$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchAgentAccounts.match),
    switchMap((re) => {
      return getAccountsByIds(
        state$.value.agents.agentSelected
          ? state$.value.agents.agentSelected.accountIds
          : [],
        re.payload
      ).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const agents = res.results;
            return [
              agentsSlice.actions.setAgentAccounts(agents ?? []),
              agentsSlice.actions.setAgentAccountsResults({
                ...re.payload,
                ...res,
              }),
            ];
          } else {
            return [agentsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          agentsSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy thông tin đại lý"
          ),
        ])
      );
    })
  );
const saveAgents$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveAgent.match),
    switchMap((re) => {
      return updateAgent(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.id) {
            return [
              agentsSlice.actions.setAgents(
                state$.value.agents.agents.map((agent) =>
                  agent.id === res.id ? res : agent
                )
              ),
            ];
          } else {
            return [agentsSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          agentsSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lưu thông tin đại lý"
          ),
        ])
      );
    })
  );
const blockOrUnblockAgents$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(blockOrUnblockAgent.match),
    switchMap((re) => {
      if (re.payload.isBlock) {
        return blockUsers(re.payload.accountIds ?? []).pipe(
          mergeMap((res: any) => {
            return [agentsSlice.actions.saveAgent(re.payload)];
          }),
          catchError((e: AjaxError) => [
            agentsSlice.actions.setErrMsg(
              "Có lỗi xảy ra khi chặn tài khoản đại lý"
            ),
          ])
        );
      } else {
        return unblockUsers(re.payload.accountIds ?? []).pipe(
          mergeMap((res: any) => {
            return [agentsSlice.actions.saveAgent(re.payload)];
          }),
          catchError((e: AjaxError) => [
            agentsSlice.actions.setErrMsg(
              "Có lỗi xảy ra khi gỡ chặn tài khoản đại lý"
            ),
          ])
        );
      }
    })
  );

const createAgentAccount$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(createAgentAccount.match),
    switchMap((re) => {
      return createAccount(re.payload.account).pipe(
        mergeMap((accRes: any) => {
          if (accRes && !accRes?.response?.error && accRes.id) {
            return assignAccount([accRes.id], re.payload.agent.id).pipe(
              mergeMap((res: any) => {
                if (res && !res?.response?.error) {
                  return assignRoleForAccount({
                    userId: accRes.id,
                    roleName: RoleEnum.AGENT,
                  }).pipe(
                    mergeMap((assignRoleRes: any) => {
                      if (assignRoleRes && !assignRoleRes?.response?.error) {
                        const newAgents = state$.value.agents.agents.map(
                          (agent) =>
                            agent.id === re.payload.agent.id
                              ? {
                                  ...agent,
                                  accountIds: [...agent.accountIds, accRes.id],
                                }
                              : agent
                        );
                        return [
                          agentsSlice.actions.setAgents(newAgents),
                          agentsSlice.actions.setAgentSelected(
                            newAgents.filter(
                              (agent) => agent.id === re.payload.agent.id
                            )[0]
                          ),
                        ];
                      } else {
                        return [
                          agentsSlice.actions.setErrMsg(
                            assignRoleRes?.response.error
                          ),
                        ];
                      }
                    }),
                    catchError((e: AjaxError) => {
                      if (e.status === 401) {
                        return [
                          agentsSlice.actions.setErrMsg(
                            "Không được cấp quyền khi gắn role"
                          ),
                          agentsSlice.actions.deleteAgentAccount({
                            agentId: re.payload.agent.id,
                            accountId: accRes.id,
                            showMessage: false,
                          }),
                        ];
                      }
                      return [
                        agentsSlice.actions.setErrMsg(
                          e.response?.Message ?? "Có lỗi xảy ra"
                        ),
                        agentsSlice.actions.deleteAgentAccount({
                          agentId: re.payload.agent.id,
                          accountId: accRes.id,
                          showMessage: false,
                        }),
                      ];
                    })
                  );
                } else {
                  return [agentsSlice.actions.setErrMsg(res?.response.error)];
                }
              }),
              catchError((e: AjaxError) => [
                agentsSlice.actions.setErrMsg(
                  e.response?.Message ||
                    "Có lỗi xảy ra khi gắn tài khoản cho đại lý"
                ),
                agentsSlice.actions.deleteAgentAccount({
                  agentId: re.payload.agent.id,
                  accountId: accRes.id,
                  showMessage: false,
                }),
              ])
            );
          } else {
            return [agentsSlice.actions.setErrMsg(accRes?.response.error)];
          }
        }),
        catchError((e: AjaxError) => {
          if (e.status === 400) {
            return [agentsSlice.actions.setErrMsg(e.response?.Message)];
          }
          if (e.status === 401) {
            return [
              agentsSlice.actions.setErrMsg(
                "Không được cấp quyền tạo tài khoản"
              ),
            ];
          }
          return [
            agentsSlice.actions.setErrMsg(
              e.response?.Message ?? "Có lỗi xảy ra khi tạo tài khoản"
            ),
          ];
        })
      );
    })
  );
const saveAgentAccount$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveAgentAccount.match),
    switchMap((re) => {
      return updateAccount(re.payload.account).pipe(
        mergeMap((accRes: any) => {
          if (accRes && !accRes?.response?.error && accRes.id) {
            return [
              agentsSlice.actions.setAgentAccounts(
                state$.value.agents.agentSelectedAccounts.map((account) =>
                  account.id === accRes.id ? accRes : account
                )
              ),
            ];
          } else {
            return [agentsSlice.actions.setErrMsg(accRes?.response.error)];
          }
        }),
        catchError((e: AjaxError) => {
          if (e.status === 401) {
            return [agentsSlice.actions.setErrMsg("Không được cấp quyền")];
          }
          return [
            agentsSlice.actions.setErrMsg(
              "Có lỗi xảy ra khi lưu thông tin tài khoản"
            ),
          ];
        })
      );
    })
  );
const deleteAgentAccount$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteAgentAccount.match),
    switchMap((re) => {
      return deleteAccount(re.payload.accountId).pipe(
        mergeMap((accRes: any) => {
          if (accRes && !accRes?.response?.error && accRes.id) {
            return removeAccount([accRes.id], re.payload.agentId).pipe(
              mergeMap((res: any) => {
                if (res && !res?.response?.error) {
                  return [
                    agentsSlice.actions.setAgentAccounts(
                      state$.value.agents.agentSelectedAccounts.filter(
                        (account) => account.id !== re.payload.accountId
                      )
                    ),
                    agentsSlice.actions.setAgents(
                      state$.value.agents.agents.map((agent) =>
                        agent.id === re.payload.agentId
                          ? {
                              ...agent,
                              accountIds: agent.accountIds.filter(
                                (id) => id !== accRes.id
                              ),
                            }
                          : agent
                      )
                    ),
                  ];
                } else {
                  return [agentsSlice.actions.setErrMsg(res?.response.error)];
                }
              }),
              catchError((e: AjaxError) =>
                re.payload.showMessage
                  ? [
                      agentsSlice.actions.setErrMsg(
                        "Có lỗi xảy ra khi xóa tài khoản"
                      ),
                    ]
                  : []
              )
            );
          } else {
            return [agentsSlice.actions.setErrMsg(accRes?.response.error)];
          }
        }),
        catchError((e: AjaxError) => {
          if (e.status === 401) {
            return [agentsSlice.actions.setErrMsg("Không được cấp quyền")];
          }
          return re.payload.showMessage
            ? [agentsSlice.actions.setErrMsg("Có lỗi xảy ra khi xóa tài khoản")]
            : [];
        })
      );
    })
  );

export const {
  fetchAgents,
  fetchAgentAccounts,
  setAgentsResults,
  setAgentAccountsResults,
  setAgentSelected,
  setAgents,
  deleteAgent,
  deleteAgentFullfilled,
  editAgent,
  editAgentAccount,
  setErrMsg,
  setAgentAccounts,
  saveAgent,
  createAgentAccount,
  saveAgentAccount,
  deleteAgentAccount,
  blockOrUnblockAgent,
  setAddAgentOpen,
  setDiscountAgent,
  addAgent,
  addAgents,
} = agentsSlice.actions;

export const AgentsEpics = [
  getAgents$,
  deleteAgent$,
  getAgentAccounts$,
  saveAgents$,
  createAgentAccount$,
  saveAgentAccount$,
  deleteAgentAccount$,
  fetchAgentAccounts$,
  blockOrUnblockAgents$,
  addAgent$,
  addAgents$,
];

export const agentsReducer = agentsSlice.reducer;

import { Typography, Form, InputNumber } from "antd";
import styles from "./Billing.module.css";

interface IProps {
  image?: string;
  label?: string;
  code: number;
  readOnly?: boolean;
  handleKeyDown?: (key: string, methodCode: number) => void;
}
export const MethodItem = ({
  image,
  label,
  code,
  readOnly,
  handleKeyDown,
}: IProps) => {
  return (
    <div className={styles.methodContainer}>
      <div className={styles.methodWrapper}>
        {image && (
          <div className={styles.methodImg}>
            <img src={image} alt="payment method" />
          </div>
        )}
        <Typography.Text
          strong
          italic
          style={{ width: "100%", textAlign: "left", maxWidth: 100 }}
        >
          {label || ""}
        </Typography.Text>
      </div>
      <Form.Item name={code} className={styles.methodFormItem}>
        <InputNumber
          style={{ width: 160 }}
          controls={false}
          formatter={(value) =>
            `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
          }
          parser={(value) => parseInt(value ? value?.replaceAll(".", "") : "0")}
          readOnly={readOnly}
          onKeyDown={(e) => handleKeyDown && handleKeyDown(e.key, code)}
        />
      </Form.Item>
    </div>
  );
};

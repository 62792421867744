import Utils from "common/Utils";
import SYSTEM_CONSTANTS from "common/constants";
import { ICreateRouter, IParams, IUpdateRouter } from "common/define-types";
import { Observable, throwError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import HttpClient from "./http-client";

const api = `${SYSTEM_CONSTANTS.API_URL}/Router`;

export const getRouterById = (id: string): Observable<any> => {
  return HttpClient.get(`${api}/${id}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const getAllRouters = (params?: IParams): Observable<any> => {
  if (params) {
    return HttpClient.get(`${api}?${Utils.getParamsString(params)}`).pipe(
          map(
              res => (res as any) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
  else {
      return HttpClient.get(`${api}`).pipe(
          map(
              res => (res as any) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
};
export const getAllRoutersPageable = (params?: IParams): Observable<any> => {
  if (params) {
    return HttpClient.get(`${api}/all?${Utils.getParamsString(params)}`).pipe(
          map(
              res => (res as any) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
  else {
      return HttpClient.get(`${api}`).pipe(
          map(
              res => (res as any) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
};
export const createRouter = (body: ICreateRouter[]): Observable<any> => {
  return HttpClient.post(`${api}/Routers`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const updateRouter = (body: IUpdateRouter): Observable<any> => {
    return HttpClient.put(`${api}?id=${body.id}`, JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
      },
    }).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  };
export const deleteRouterById = (locationId: string): Observable<any> => {
    return HttpClient.delete(`${api}/${locationId}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  };
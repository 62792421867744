import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createRouter, deleteRouterById, getAllRoutersPageable, updateRouter } from "api/router.api";
import {
  ICreateRouter,
  IParams,
  IRouter,
  IRouterFilterValue,
  IRoutersResult,
  IUpdateRouter,
  RootEpic,
} from "common/define-types";
import { catchError, filter, mergeMap, switchMap } from "rxjs";
import { AjaxError } from "rxjs/ajax";

export interface RouterState {
  isLoading: boolean;
  isSubmitting: boolean;
  Routers: IRouter[] | [];
  RouterNames: string[];
  errMsg: string | null;
  RoutersResult: IRoutersResult | null;
  addModalOpen: boolean;
  deletingRouterId: string | null;
  editingRouter: IRouter | null;
  filterValue: IRouterFilterValue | null;
}

const initialState: RouterState = {
  isLoading: false,
  isSubmitting: false,
  Routers: [],
  RouterNames: [],
  errMsg: null,
  RoutersResult: null,
  addModalOpen: false,
  deletingRouterId: null,
  editingRouter: null,
  filterValue: {
    search: "",
  },
};

export const RouterSlice = createSlice({
  name: "Router",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchRouters: (state, action: PayloadAction<IParams | undefined>) => {
      state.isLoading = true;
      state.isSubmitting = false;
      state.addModalOpen = false;
      state.editingRouter = null;
      state.deletingRouterId = null;
      state.errMsg = null;
    },
    setRouters: (state, action: PayloadAction<IRouter[]>) => {
      state.Routers = action.payload;
      state.isLoading = false;
    },
    setErrMsg: (state, action: PayloadAction<string>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
    setRoutersResult: (state, action: PayloadAction<IRoutersResult>) => {
      state.RoutersResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    editRouter: (state, action: PayloadAction<IRouter | null>) => {
      state.editingRouter = action.payload;
    },
    addRouter: (state, action: PayloadAction<ICreateRouter>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    deleteRouter: (state, action: PayloadAction<string>) => {
      state.deletingRouterId = action.payload;
      state.errMsg = null;
    },
    setAddModalOpen: (state, action: PayloadAction<boolean>) => {
      state.addModalOpen = action.payload;
    },
    saveRouter: (state, action: PayloadAction<IUpdateRouter>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    setRouterFilterValue: (
      state,
      action: PayloadAction<IRouterFilterValue>
    ) => {
      state.filterValue = {
        ...state.filterValue,
        ...action.payload,
      };
      console.log('filterValue: ',state.filterValue);
    },
  },
});
const getRouters$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchRouters.match),
    switchMap((re) => {
      return getAllRoutersPageable({ ...re.payload }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const routers = res.results;
            return [
              RouterSlice.actions.setRouters(routers),
              RouterSlice.actions.setRoutersResult(res),
            ];
          } else {
            return [RouterSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          RouterSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách vị trí"
          ),
        ])
      );
    })
  );
const getRoutersWhenFilter$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setRouterFilterValue.match),
    switchMap((re) => {
      return getAllRoutersPageable({
        // ...state$.value.Router.RoutersResult,
        ...re.payload,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const routers = res.results;
            return [
              RouterSlice.actions.setRouters(routers ?? []),
              RouterSlice.actions.setRoutersResult(res),
            ];
          } else {
            return [RouterSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          RouterSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách vị trí"
          ),
        ])
      );
    })
  );
const addRouters$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addRouter.match),
    switchMap((re) => {
      console.log("API Input:", re.payload);
      return createRouter([re.payload]).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.router.RoutersResult) {
              return [
                RouterSlice.actions.fetchRouters(
                  state$.value.router.RoutersResult
                ),
              ];
            }
            return [RouterSlice.actions.fetchRouters()];
          } else {
            return [RouterSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          RouterSlice.actions.setErrMsg("Có lỗi xảy ra khi thêm vị trí"),
        ])
      );
    })
  );
const saveRouters$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveRouter.match),
    switchMap((re) => {
      return updateRouter(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.router.RoutersResult) {
              return [
                RouterSlice.actions.fetchRouters(
                  state$.value.router.RoutersResult
                ),
              ];
            }
            return [RouterSlice.actions.fetchRouters()];
          } else {
            return [RouterSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          RouterSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lưu thông tin vị trí"
          ),
        ])
      );
    })
  );
const deleteRouters$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteRouter.match),
    switchMap((re) => {
      return deleteRouterById(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.router.RoutersResult) {
              return [
                RouterSlice.actions.fetchRouters(
                  state$.value.router.RoutersResult
                ),
              ];
            }
            return [RouterSlice.actions.fetchRouters()];
          } else {
            return [RouterSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          RouterSlice.actions.setErrMsg("Có lỗi xảy ra khi xóa vị trí"),
        ])
      );
    })
  );


export const {
  fetchRouters,
  setRouters,
  setErrMsg,
  addRouter,
  setAddModalOpen,
  saveRouter,
  editRouter,
  deleteRouter,
  setRouterFilterValue,
} = RouterSlice.actions;

export const RouterEpics = [
  getRouters$,
  addRouters$,
  saveRouters$,
  deleteRouters$,
  getRoutersWhenFilter$,
];

export const routerReducer = RouterSlice.reducer;

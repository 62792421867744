import { Form, FormInstance, Input } from "antd";
import styles from "../Suppliers.module.css";
import { ISupplier } from "api/types/supplier";
import Utils from "common/Utils";
import { useEffect } from "react";

interface IProps {
  form?: FormInstance<any>;
  editingSupplier?: ISupplier;
}
export const SupplierForm = ({ form, editingSupplier }: IProps) => {
  const [defaultForm] = Form.useForm();
  const suppilerForm = form || defaultForm;

  useEffect(() => {
    if (editingSupplier) {
      suppilerForm.setFields(Utils.objectToAntFormData(editingSupplier));
    } else {
      suppilerForm.resetFields();
    }
  }, [editingSupplier, suppilerForm]);

  return (
    <Form
      name="supplier"
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      className="noMargin_form-item"
    >
      <Form.Item
        label={"Mã nhà cung cấp"}
        name={"code"}
        rules={[
          {
            required: !editingSupplier,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input disabled={!!editingSupplier} />
      </Form.Item>
      <Form.Item
        label={"Tên nhà cung cấp"}
        name={"name"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={"Địa chỉ"} name={"address"} style={{ flex: 1 }}>
        <Input />
      </Form.Item>
      <div className={styles.spaceBetween} style={{ gap: 8 }}>
        <Form.Item label={"Số điện thoại"} name={"phone"} style={{ flex: 1 }}>
          <Input />
        </Form.Item>
        <Form.Item
          label={"Email"}
          name={"email"}
          style={{ flex: 1 }}
          rules={[
            {
              type: "email",
              message: "Phải là email hợp lệ",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
    </Form>
  );
};

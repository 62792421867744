import { PlusOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Typography, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { ChangeEvent, useEffect, useState } from "react";
import {
  addRoomTemplate,
  fetchRoomTemplates,
  setAddModalOpen,
  setRoomTemplateFilterValue,
} from "store/slice/RoomTemplateSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "./Room.module.css";
import { RoomForm } from "./components/RoomForm";
import { RoomTable } from "./components/RoomTable";

export const Room = () => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const selectAddModalOpen = useSelectorRoot(
    (state) => state.roomTemplate.addModalOpen
  );
  const selectErrMsg = useSelectorRoot((state) => state.roomTemplate.errMsg);
  const selectRoomFilterValue = useSelectorRoot(
    (state) => state.roomTemplate.filterValue
  );
  const [addRoomForm] = useForm();
  const dispatch = useDispatchRoot();

  useEffect(() => {
    dispatch(fetchRoomTemplates({ pageSize: 25 }));
  }, [dispatch]);

  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({
        message: selectErrMsg,
      });
    }
  }, [selectErrMsg]);

  const handleAddRoom = async () => {
    const RoomValue = await addRoomForm
      .validateFields()
      .catch((err) => {
        console.log(err);
        notification.error({ message: "Vui lòng nhập đủ thông tin" });
      });
    if (!RoomValue) return;
    dispatch(
      addRoomTemplate({
        ...RoomValue,
        registrationDate: dayjs(RoomValue.registrationDate),
      })
    );
  };
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(
        setRoomTemplateFilterValue({
          ...selectRoomFilterValue,
          search: e.target.value,
        })
      );
    }, 1000);
    setTimer(timeoutId);
  };
  return (
    <div className={styles.RoomContainer}>
      <div className={styles.tableHeader}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            flexDirection: "row",
          }}
        >
          <Typography.Text strong style={{ fontSize: 20 }}>
            Danh sách phòng
          </Typography.Text>
          <div>
            <Input.Search
              placeholder="Tìm kiếm Phòng"
              onChange={handleSearch}
              allowClear
            />
          </div>
        </div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            dispatch(setAddModalOpen(true));
            addRoomForm.resetFields();
          }}
        >
          Thêm mới phòng
        </Button>
      </div>
      <RoomTable />
      {selectAddModalOpen ? (
        // [2-4-2024][Duc Ngoc][update logic on RoomForm ]
        <Modal
          title={"Thêm mới phòng"}
          open={selectAddModalOpen}
          onCancel={() => dispatch(setAddModalOpen(false))}
          onOk={handleAddRoom}
          okText="Thêm"
          cancelText="Hủy bỏ"
        >
          <RoomForm form={addRoomForm} editRoomTemplateForm = {false} />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

import {
  Typography,
  Input,
  Skeleton,
  Pagination,
  Button,
  PaginationProps,
  Tooltip,
} from "antd";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import styles from "../Agents.module.css";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { AgentItem } from "./AgentItem";
import { ChangeEvent, useEffect, useState } from "react";
import {
  fetchAgents,
  setAddAgentOpen,
  setAgentSelected,
  setAgentsResults,
} from "store/slice/AgentsSlice";
import { RoleEnum } from "common/define-types";

export const AgentList = () => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const selectIsAgentsLoading = useSelectorRoot(
    (state) => state.agents.isLoading
  );
  const userRole = useSelectorRoot((state) => state.login.role);
  const selectAgents = useSelectorRoot((state) => state.agents.agents);
  const selectAgentSelected = useSelectorRoot(
    (state) => state.agents.agentSelected
  );
  const selectAgentsResults = useSelectorRoot(
    (state) => state.agents.agentsResults
  );
  const dispatch = useDispatchRoot();
  const [isAscendingSort, setIsAscendingSort] = useState(false);

  useEffect(() => {
    if (selectAgents.length > 0 && !selectAgentSelected) {
      dispatch(
        setAgentSelected(
          [...selectAgents].sort((a, b) =>
            isAscendingSort
              ? a.name.localeCompare(b.name, "vn")
              : b.name.localeCompare(a.name, "vn")
          )[0]
        )
      );
    }
  }, [isAscendingSort, dispatch, selectAgents, selectAgentSelected]);

  const handleChangePagi: PaginationProps["onChange"] = (page) => {
    if (selectAgentsResults) {
      dispatch(
        fetchAgents({
          ...selectAgentsResults,
          page: page,
        })
      );
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      if (selectAgentsResults) {
        dispatch(
          fetchAgents({
            ...selectAgentsResults,
            search: query,
          })
        );
        dispatch(
          setAgentsResults({
            ...selectAgentsResults,
            search: query,
          })
        );
      }
    }, 800);
    setTimer(timeoutId);
  };

  const handleOpenAddAgent = () => {
    dispatch(setAddAgentOpen(true));
  };

  return (
    <div className={styles.listContainer}>
      <div
        className={styles.listContainer_header}
        style={{ justifyContent: "space-between" }}
      >
        <Typography.Text strong style={{ fontSize: 20 }}>
          Danh sách đại lý
        </Typography.Text>
        <div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled={userRole !== RoleEnum.ADMIN}
            onClick={handleOpenAddAgent}
          >
            Thêm đại lý
          </Button>
          {userRole !== RoleEnum.ADMIN && (
            <Tooltip title={"Bạn không được cấp quyền tạo đại lý"}>
              <InfoCircleOutlined
                style={{
                  fontSize: 14,
                  marginLeft: 8,
                  color: "#7dccfc",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          )}
        </div>
      </div>
      <div className={styles.searchContainer}>
        <Button
          icon={
            isAscendingSort ? (
              <SortAscendingOutlined style={{ fontSize: 18 }} />
            ) : (
              <SortDescendingOutlined style={{ fontSize: 18 }} />
            )
          }
          onClick={() => setIsAscendingSort((prev) => !prev)}
        >
          Sắp xếp
        </Button>
        <Input.Search
          allowClear
          style={{ flex: 1 }}
          placeholder="Tìm kiếm đại lý"
          onChange={handleSearch}
        />
      </div>
      <div className={styles.listWrapper}>
        <div className={`${styles.listWrapper_List} customScroll`}>
          {selectIsAgentsLoading ? (
            <>
              <Skeleton paragraph={{ rows: 4 }} />
              <Skeleton paragraph={{ rows: 4 }} />
              <Skeleton paragraph={{ rows: 4 }} />
            </>
          ) : selectAgents && selectAgents.length > 0 ? (
            [...selectAgents]
              .sort((a, b) =>
                isAscendingSort
                  ? a.name.localeCompare(b.name, "vn")
                  : b.name.localeCompare(a.name, "vn")
              )
              .map((agent) => (
                <AgentItem
                  agent={agent}
                  key={`Agent-${agent.id}`}
                  isActive={selectAgentSelected?.id === agent.id}
                />
              ))
          ) : (
            <EmptyHolder message="Chưa có đại lý" />
          )}
        </div>
      </div>
      <div className={styles.listContainer_footer}>
        <Pagination
          total={selectAgentsResults ? selectAgentsResults.queryCount : 0}
          pageSize={selectAgentsResults ? selectAgentsResults.pageSize : 10}
          current={selectAgentsResults ? selectAgentsResults.page : 1}
          showTotal={(total, range) =>
            `Hiển thị ${
              range[1] === 0 && range[1] - range[0] === 0
                ? 0
                : range[1] - range[0] + 1
            }/${total} đại lý`
          }
          onChange={handleChangePagi}
        />
      </div>
    </div>
  );
};

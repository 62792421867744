import { Form, FormInstance, Input, InputNumber } from "antd";
import { ITour } from "common/define-types";
import Utils from "common/Utils";
import { useEffect } from "react";
import UploadImage from "components/Input/UploadImage";

interface TourFormProps {
  form?: FormInstance<any>;
  pathFile?: string | null;
  setPathFile?: (path: string | null) => void;
  editingTour?: ITour | null;
}

export const TourForm = ({
  form,
  editingTour,
  pathFile,
  setPathFile,
}: TourFormProps) => {
  const [defaultForm] = Form.useForm();
  const tourForm = form || defaultForm;

  // parse data into form
  useEffect(() => {
    if (editingTour) {
      tourForm?.setFields(Utils.objectToAntFormData(editingTour));
      if (editingTour.pathFile) {
        setPathFile && setPathFile(editingTour.pathFile || "");
      }
    }
  }, [editingTour, tourForm, setPathFile]);

  const handleRemoveImage = () => {
    setPathFile && setPathFile("");
    tourForm.setFieldValue("File", null);
  };

  return (
    <Form
      form={tourForm}
      labelCol={{ span: 24 }}
      className="noMargin_form-item"
    >
      <Form.Item
        name={"code"}
        label={"Mã tour"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input disabled={!!editingTour} />
      </Form.Item>
      <Form.Item
        name={"name"}
        label={"Tên tour"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input placeholder="E.g: 3N2D" />
      </Form.Item>
      <Form.Item
        name={"description"}
        label={"Mô tả tour"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input placeholder="E.g: 3 Ngày 2 Đêm" />
      </Form.Item>
      <Form.Item
        name={"countDay"}
        label={"Số ngày"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <InputNumber min={0} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name={"note"} label={"Mô tả"}>
        <Input.TextArea />
      </Form.Item>
      <UploadImage
        label="Ảnh"
        name="File"
        // rules={[
        //   {
        //     required: true,
        //     message: "Không được bỏ trống",
        //   },
        // ]}
        pathFile={pathFile}
        setPathFile={(path) => setPathFile && setPathFile(path || "")}
        onDeleteImage={handleRemoveImage}
      />
      {/* {pathFile ? (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography.Text strong style={{ paddingBlock: 8 }}>
              Ảnh:
            </Typography.Text>
            <DeleteOutlined
              style={{ color: "red" }}
              onClick={handleRemoveImage}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={`${SYSTEM_CONSTANTS.API_URL}${pathFile}`}
              width={"100%"}
            />
          </div>
        </>
      ) : (
        <Form.Item
          label={"Thêm ảnh"}
          valuePropName="fileList"
          name={"File"}
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Dragger {...props}>
            <FileImageOutlined />
            <p>Drag and drop here</p>
          </Dragger>
        </Form.Item>
      )} */}
    </Form>
  );
};

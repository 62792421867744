import {
  Button,
  Collapse,
  Modal,
  Segmented,
  SegmentedProps,
  Typography,
} from 'antd';
import {
  IssueType,
  JobDateEnum,
  ThanhToanIssueStatus,
} from 'common/define-types';
import {
  approveIssue,
  rejectIssue,
  resetIssueState,
  saveIssue,
  setDetailViewingIssue,
  setJobDateSelected,
} from 'store/slice/GroupSlice';
import { useSelectorRoot, useDispatchRoot } from 'store/store';
import { PlusOutlined } from '@ant-design/icons';
import styles from './Modal.module.css';
import { useState } from 'react';
import { AddIssueModal } from './AddIssueModal';
import { BrokeMotorbikeItem } from 'pages/Admin/pages/NightAuditor/components/BrokeMotorbikeItem';
import { ChangeRoomItem } from 'pages/Admin/pages/NightAuditor/components/ChangeRoomItem';
import { CancelTourItem } from 'pages/Admin/pages/NightAuditor/components/CancelTourItem';
import { EmptyHolder } from 'components/Empty/EmptyHolder';
import Utils from 'common/Utils';
import { IIssue } from 'api/types/issue';
import { IssueDetailModal } from './IssueDetailModal';
import moment from 'moment';
import OuterGasItem from 'pages/Admin/pages/NightAuditor/components/OuterGasItem';
import ExtraSleptItem from 'pages/Admin/pages/NightAuditor/components/ExtraSleptItem';
import ExtraMealItem from 'pages/Admin/pages/NightAuditor/components/ExtraMealItem';
import ChangeTourItem from 'pages/Admin/pages/NightAuditor/components/ChangeTourItem';
import ChangeTypeDriverItem from 'pages/Admin/pages/NightAuditor/components/ChangeTypeDriverItem';
import { SegmentedValue } from 'antd/es/segmented';
import { OtherIssue } from 'pages/Admin/pages/NightAuditor/components/OtherIssue';

interface IProps {
  open: boolean;
  handleClose: () => void;
}
const jobDateOptions = [
  {
    value: JobDateEnum.ALL,
    label: `All`,
  },
];

const issueTypes = [
  {
    name: 'Khách bỏ tour',
    types: [IssueType.CANCELTOUR],
  },
  {
    name: 'Hỏng xe',
    types: [IssueType.BREAKDOWN_MOTORBIKE],
  },
  {
    name: 'Đổi phòng',
    types: [IssueType.CHANGEROOM, IssueType.CHANGE_ROOM_DVT],
  },
  {
    name: 'Đổ xăng lẻ',
    types: [IssueType.OUTER_FUEL],
  },
  {
    name: 'Khách ăn thêm',
    types: [IssueType.EXTRA_MEAL],
  },
  {
    name: 'Ngủ thêm',
    types: [IssueType.EXTRA_SLEPT, IssueType.EXTRA_SLEPT_DVT],
  },
  {
    name: 'Thêm ngày đi tour',
    types: [IssueType.CHANGE_TOUR],
  },
  {
    name: 'Tự lái sang có lái',
    types: [IssueType.CHANGE_TO_WITH_DRIVER],
  },
  {
    name: 'Có lái sang tự lái',
    types: [IssueType.CHANGE_TO_WITHOUT_DRIVER],
  },
  {
    name: 'Sự cố khác',
    types: [IssueType.OtherIssue],
  },
];

const { Panel } = Collapse;
export const IssueModal = ({ open, handleClose }: IProps) => {
  const selectJobDateSelected = useSelectorRoot(
    (state) => state.group.jobDateSelected
  );
  const selectGroupSelected = useSelectorRoot(
    (state) => state.group.groupSelected
  );
  const selectGroupIssues = useSelectorRoot((state) => state.group.groupIssue);
  const selectGroupTickets = useSelectorRoot(
    (state) => state.group.groupTickets
  );
  const selectDetailViewingIssue = useSelectorRoot(
    (state) => state.group.detailViewingIssue
  );
  const dispatch = useDispatchRoot();
  const options: SegmentedProps['options'] = selectGroupSelected
    ? new Array(Utils.getGroupCountDay(selectGroupSelected) || 4)
        .fill(null)
        .map((_, index) => ({
          label: (
            <Typography.Text
            // style={{
            //   color:
            //     Utils.checkGroupEndDateIsBeforeCountDay(
            //       selectGroupSelected
            //     ) &&
            //     index + 1 === (Utils.getGroupCountDay(selectGroupSelected) || 4)
            //       ? 'red'
            //       : '#000000a6',
            // }}
            >
              {`Ngày ${index + 1}`}
            </Typography.Text>
          ),
          value: index + 1,
        }))
    : [];
  const issues = selectGroupIssues.filter((issue) => {
    if (selectGroupSelected) {
      const startDate = moment
        .utc(selectGroupSelected.startDate)
        .startOf('day')
        .subtract(1, 'second');
      const endDate = moment.utc(selectGroupSelected.endDate);
      const issueDate = moment.utc(issue.createdTime);
      // const issueDate = [IssueType.CHANGEROOM, IssueType.EXTRA_SLEPT].some(
      //   (type) => type === issue.type
      // )
      //   ? moment.utc(issue.date)
      //   : moment.utc(issue.createdTime);

      if (issueDate.isBetween(startDate, endDate)) {
        if (selectJobDateSelected === JobDateEnum.ALL) return true;
        else {
          return (
            issueDate.diff(startDate, 'days') + 1 === selectJobDateSelected
          );
        }
      }
    }
    return false;
  });
  const [addIssueModalOpen, setAddIssueModalOpen] = useState(false);

  const handleConfirmIssue = (issue: IIssue) => {
    dispatch(approveIssue(issue.id));
  };

  const handleRejectIssue = (issue: IIssue) => {
    dispatch(rejectIssue(issue.id));
  };

  const handleViewDetailIssue = (issue: IIssue) => {
    dispatch(setDetailViewingIssue(issue));
  };
  const handleCheckIssuePaymentStatus = (issue: IIssue, checked: boolean) => {
    dispatch(
      saveIssue({
        ...issue,
        issueId: issue.id,
        thanhToanIssueStatus: checked
          ? ThanhToanIssueStatus.NOT_YET
          : ThanhToanIssueStatus.PAID_BY_LEADER,
      })
    );
  };

  const renderIssusItemByType = (issue: IIssue) => {
    switch (issue.type) {
      case IssueType.OtherIssue:
        return (<OtherIssue
            key={issue.id}
            handleConfirm={handleConfirmIssue}
            handleReject={handleRejectIssue}
            handleViewDetail={handleViewDetailIssue}
            issue={issue}
        ></OtherIssue>)
      case IssueType.BREAKDOWN_MOTORBIKE:
        return (
          <BrokeMotorbikeItem
            key={issue.id}
            handleConfirm={handleConfirmIssue}
            handleReject={handleRejectIssue}
            handleViewDetail={handleViewDetailIssue}
            issue={issue}
          />
        );
      case IssueType.CANCELTOUR:
        return (
          <CancelTourItem
            key={issue.id}
            issue={issue}
            handleConfirm={handleConfirmIssue}
            handleViewDetail={handleViewDetailIssue}
          />
        );
      case IssueType.OUTER_FUEL:
        return (
          <OuterGasItem
            key={issue.id}
            issue={issue}
            handleConfirm={handleConfirmIssue}
            handleReject={handleRejectIssue}
            handleViewDetail={handleViewDetailIssue}
          />
        );
      case IssueType.EXTRA_MEAL:
        return (
          <ExtraMealItem
            groupTickets={selectGroupTickets}
            key={issue.id}
            issue={issue}
            handleConfirm={handleConfirmIssue}
            handleReject={handleRejectIssue}
            handleViewDetail={handleViewDetailIssue}
          />
        );
      case IssueType.EXTRA_SLEPT:
      case IssueType.EXTRA_SLEPT_DVT:
        return (
          <ExtraSleptItem
            groupTickets={selectGroupTickets}
            key={issue.id}
            issue={issue}
            handleConfirm={handleConfirmIssue}
            handleReject={handleRejectIssue}
            handleViewDetail={handleViewDetailIssue}
            handleCheckPaymentStatus={handleCheckIssuePaymentStatus}
          />
        );
      case IssueType.CHANGE_ROOM_DVT:
        return (
          <ChangeRoomItem
            key={issue.id}
            groupTickets={selectGroupTickets}
            handleConfirm={handleConfirmIssue}
            handleReject={handleRejectIssue}
            handleViewDetail={handleViewDetailIssue}
            handleCheckPaymentStatus={handleCheckIssuePaymentStatus}
            issue={issue}
          />
        );
      case IssueType.CHANGE_TOUR:
        return (
          <ChangeTourItem
            key={issue.id}
            groupTickets={selectGroupTickets}
            handleConfirm={handleConfirmIssue}
            handleReject={handleRejectIssue}
            handleViewDetail={handleViewDetailIssue}
            handleCheckPaymentStatus={handleCheckIssuePaymentStatus}
            issue={issue}
          />
        );
      case IssueType.CHANGE_TO_WITH_DRIVER:
      case IssueType.CHANGE_TO_WITHOUT_DRIVER:
        return (
          <ChangeTypeDriverItem
            key={issue.id}
            groupTickets={selectGroupTickets}
            handleConfirm={handleConfirmIssue}
            handleReject={handleRejectIssue}
            handleViewDetail={handleViewDetailIssue}
            handleCheckPaymentStatus={handleCheckIssuePaymentStatus}
            issue={issue}
          />
        );
      default:
        return (
          <ChangeRoomItem
            key={issue.id}
            groupTickets={selectGroupTickets}
            handleConfirm={handleConfirmIssue}
            handleReject={handleRejectIssue}
            handleViewDetail={handleViewDetailIssue}
            handleCheckPaymentStatus={handleCheckIssuePaymentStatus}
            issue={issue}
          />
        );
    }
  };

  return (
    <Modal open={open} footer={null} onCancel={handleClose} centered>
      {addIssueModalOpen && (
        <AddIssueModal
          open={addIssueModalOpen}
          handleClose={() => setAddIssueModalOpen(false)}
        />
      )}
      {selectDetailViewingIssue !== null ? (
        <IssueDetailModal
          open={selectDetailViewingIssue !== null}
          issue={selectDetailViewingIssue}
          handleClose={() => dispatch(setDetailViewingIssue(null))}
        />
      ) : (
        <></>
      )}
      <Segmented
        options={[...jobDateOptions, ...options]}
        value={selectJobDateSelected}
        onChange={(value: SegmentedValue) =>
          dispatch(setJobDateSelected(value))
        }
      />
      <div className={`${styles.issueModal} customScroll`}>
        <div className={styles.issueModal_header}>
          <Typography.Text strong style={{ fontSize: 20 }}>
            Danh sách phát sinh
          </Typography.Text>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            disabled={
              selectGroupSelected !== null &&
              !Utils.isGroupTakingPlace(selectGroupSelected)
            }
            onClick={() => {
              dispatch(resetIssueState());
              setAddIssueModalOpen(true);
            }}
          >
            Tạo phát sinh
          </Button>
        </div>
        <Collapse bordered={false} style={{ backgroundColor: 'white' }}>
          {issueTypes.map((type) => (
            <Panel
              key={type.name}
              header={`${type.name} (${
                issues.filter((issue) =>
                  type.types.some((issueType) => issueType === issue.type)
                ).length
              })`}
              style={{
                marginBottom: 12,
                border: 'none',
                backgroundColor: '#f5f5f5',
              }}
            >
              {issues.filter((issue) =>
                type.types.some((issueType) => issueType === issue.type)
              ).length > 0 ? (
                issues
                  .filter((issue) =>
                    type.types.some((issueType) => issueType === issue.type)
                  )
                  .map((issue) => renderIssusItemByType(issue))
              ) : (
                <EmptyHolder message={`Chưa có phát sinh`} />
              )}
            </Panel>
          ))}
        </Collapse>
      </div>
    </Modal>
  );
};

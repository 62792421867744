import { Form, FormInstance, Select } from "antd";
import { getAllLocationsPageable } from "api/location.api";
import { ILocation } from "common/define-types";
import { useEffect, useState } from "react";

interface IProps {
  form: FormInstance<any>;
}
export const RouterForm = ({ form }: IProps) => {
  const [locationList, setLocationList] = useState<ILocation[]>([]);
  useEffect(() => {
    // Thêm hàm để fetch danh sách địa điểm
    const fetchLocations = async () => {
      try {
        const response = await getAllLocationsPageable().toPromise(); // Convert Observable to Promise
        // Lưu danh sách địa điểm vào state
        setLocationList(response || []);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);
  return (
    <Form labelCol={{ span: 24 }} form={form}>
      <Form.Item
        label={"Tên điểm xuất phát"}
        name={"localtionStartId"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Select>
          {locationList.map((location) => (
            <Select.Option key={location.id} value={location.id}>
              {location.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={"Tên điểm đến"}
        name={"localtionEndId"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Select>
          {locationList.map((location) => (
            <Select.Option key={location.id} value={location.id}>
              {location.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

import { Typography } from "antd";

interface IProps {
  countDay: number;
}
export const TourType = ({ countDay }: IProps) => {
  const renderTourDay = () => {
    // switch (countDay) {
    //   case 2:
    //     return "2D1N";
    //   case 3:
    //     return "3D2N";
    //   case 4:
    //     return "4D3N";
    //   default:
    //     return "";
    // }
    return `${countDay}D${countDay - 1}N`;
  };
  return (
    <Typography.Text
      italic
      strong
      style={{ color: "#8C8C8C", whiteSpace: "nowrap" }}
    >
      {renderTourDay()}
    </Typography.Text>
  );
};

import { Form, Input, Modal, Tag, Typography, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Utils from 'common/Utils';
import { DriverType } from 'common/define-types';
import { GroupDriverSelect } from 'components/Selects/GroupDriverSelect';
import moment from 'moment';
import { useEffect } from 'react';
import { useSelectorRoot } from 'store/store';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (args: {
    groupName: string;
    tourId: string;
    leaderId?: string;
  }) => void;
}
export const AddGroupModal = ({
  isOpen,
  handleClose,
  handleSubmit,
}: IProps) => {
  // const selectTours = useSelectorRoot((state) => state.booking.tours);
  const [addGroupForm] = useForm();
  const handleAdd = async () => {
    const groupValue = await addGroupForm.validateFields().catch((err) => {
      console.log(err);
      notification.error({ message: 'Vui lòng nhập đủ thông tin' });
    });
    if (groupValue) {
      handleSubmit(groupValue);
      handleClose();
    }
  };
  const selectSelectedDate = useSelectorRoot(
    (state) => state.group.selectedDate
  );
  const selectTicketsSelected = useSelectorRoot(
    (state) => state.group.groupTicketsSelected
  );
  const selectTours = useSelectorRoot((state) => state.booking.tours);
  const selectPriceReqs = useSelectorRoot((state) => state.booking.priceReqs);
  const selectDrivers = useSelectorRoot((state) => state.group.allDrivers);
  useEffect(() => {
    addGroupForm.resetFields();
  }, [isOpen, addGroupForm]);

  const renderHelperText = () => {
    const groupByTourTickets = Utils.groupBy(selectTicketsSelected, 'tourId');
    let groupedTicket = [];
    for (const key in groupByTourTickets) {
      const groupByPriceTickets = Utils.groupBy(
        groupByTourTickets[key],
        'driverPriceId'
      );
      const foundTour = selectTours.find((tour) => tour.id === key);
      for (const priceKey in groupByPriceTickets) {
        const foundPrice = selectPriceReqs.find(
          (price) => price.id === priceKey
        );
        if (foundTour && foundPrice && selectTicketsSelected.length > 0) {
          groupedTicket.push({
            label: `${foundTour.countDay === 3 ? '3D2N' : '4D3N'} ${
              foundPrice.description
            }`,
            count: groupByPriceTickets[priceKey].length,
          });
        }
      }
    }

    return (
      <>
        {`Group có `}
        {groupedTicket.map((item) => (
          <Tag
            color='blue'
            key={`${item.label}`}
          >{`${item.count} KH ${item.label}`}</Tag>
        ))}
        {` bạn có chắc chắn tạo Group ?`}
      </>
    );
  };

  return (
    <Modal
      open={isOpen}
      onOk={handleAdd}
      onCancel={() => {
        handleClose();
        addGroupForm.resetFields();
      }}
      title={'Tạo group'}
      centered
    >
      <Form
        form={addGroupForm}
        labelAlign='left'
        labelCol={{ span: 5 }}
        style={{ paddingBlock: 8 }}
        initialValues={{
          groupName: `Empty_${moment().format('DDMMYYYY')}`,
        }}
      >
        <Form.Item name={'groupName'} label={'Tên group'}>
          <Input readOnly />
        </Form.Item>
        {/* <Form.Item
          name={"tourId"}
          label={"Loại tour "}
          rules={[
            {
              required: true,
              message: "Loại tour không được để trống",
            },
          ]}
        >
          <Select
            options={selectTours.map((tour) => ({
              label: tour.description,
              value: tour.id,
            }))}
          />
        </Form.Item> */}
        <Form.Item name={'leaderId'} label={'Leader'} id='Leader-Dropdown'>
          <GroupDriverSelect
            testId='Leader-Dropdown'
            defaultOptions={selectDrivers}
            onClear={() => addGroupForm.setFieldValue('leaderId', null)}
            onChange={(value) => {
              addGroupForm.setFieldValue('leaderId', value.accountId);
              addGroupForm.setFieldValue(
                'groupName',
                `${Utils.getName(
                  value.name
                ).firstName.trim()}_${moment().format('DDMMYYYY')}`
              );
            }}
            valuePropName='accountId'
            // [4-4-2024][Phuc Thinh][Only get contract driver]
            acceptTypes={[DriverType.CONTRACT]}
            params={{
              startDate: moment(selectSelectedDate)
                .startOf('day')
                .add(7, 'hours')
                .toISOString(),
              endDate: moment(selectSelectedDate)
                .endOf('day')
                .add(7, 'hours')
                .toISOString(),
              isAvailable: true,
            }}
          />
        </Form.Item>
      </Form>
      {
        <Typography.Text style={{ fontSize: 13 }}>
          {renderHelperText()}
        </Typography.Text>
      }
    </Modal>
  );
};

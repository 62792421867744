import Utils from 'common/Utils';
import SYSTEM_CONSTANTS from 'common/constants';
import { IParams } from 'common/define-types';
import { Observable, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import HttpClient from './http-client';
import { ICreateRoomTemplate, IUpdateRoomTemplate } from './types/roomTemplate';

const api = `${SYSTEM_CONSTANTS.API_URL}/RoomTemplate`;

export const getRoomTemplateById = (id: string): Observable<any> => {
    return HttpClient.get(`${api}/${id}`).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};
export const getAllRoomTemplates = (params?: IParams): Observable<any> => {
  return HttpClient.get(`${api}?${Utils.getParamsString(params || {})}`).pipe(
      map(
          res => (res as any) || null,
          catchError((e: AjaxError) => throwError(e)),
      ),
  );
};

export const getAllRoomTemplatesPageable = (params?: IParams): Observable<any> => {
    return HttpClient.get(`${api}/all?${Utils.getParamsString(params || {})}`).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};

export const createRoomTemplate = (body: ICreateRoomTemplate[]): Observable<any> => {
    return HttpClient.post(`${api}`, JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
      },
    }).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  };
  
  export const updateRoomTemplate = (body: IUpdateRoomTemplate): Observable<any> => {
    return HttpClient.put(`${api}?id=${body.id}`, JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
      },
    }).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  };
  
  export const deleteRoomTemplateById = (RoomTemplateId: string): Observable<any> => {
    return HttpClient.delete(`${api}/${RoomTemplateId}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  };

import Utils from "common/Utils";
import SYSTEM_CONSTANTS from "common/constants";
import {
  GetJobItemsParams,
  ICreateJobItem,
  ICreateDiscount,
  IParams,
  IUpdateJobItem,
} from "common/define-types";
import { Observable, throwError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import HttpClient from "./http-client";

const api = `${SYSTEM_CONSTANTS.API_URL}/JobItem`;

export const getJobItemByDay = (
  params?: GetJobItemsParams
): Observable<any> => {
  return HttpClient.get(`${api}?${Utils.getParamsString(params || {})}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getAllJobRooms = (): Observable<any> => {
  return HttpClient.get(`${api}/jobRoom`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getJobItemBySupplierId = (
  supplierId: string,
  params?: IParams | undefined
): Observable<any> => {
  return HttpClient.get(
    `${api}/${supplierId}?${Utils.getParamsString(params || {})}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const createJobItemForSupplier = (
  body: ICreateJobItem
): Observable<any> => {
  return HttpClient.post(
    `${api}?supplierId=${body.supplierId}`,
    JSON.stringify(body),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updateJobItem = (body: IUpdateJobItem): Observable<any> => {
  return HttpClient.put(`${api}/${body.id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const deleteJobItem = (jobItemId: string): Observable<any> => {
  return HttpClient.delete(`${api}/${jobItemId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const createDiscountForJobItem = (
  jobItemId: string,
  body: ICreateDiscount
): Observable<any> => {
  return HttpClient.post(
    `${api}/${jobItemId}/chietkhau`,
    JSON.stringify(body),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

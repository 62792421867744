import { Button, Input, PaginationProps, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { ICommonResult } from "common/define-types";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import styles from "./CategoryTableLayout.module.css";
import { AnyObject } from "antd/es/_util/type";
import { PlusOutlined } from "@ant-design/icons";
import { GetRowKey } from "antd/es/table/interface";

interface CategoryTableLayoutProps {
  title: string;
  loading?: boolean;
  dataSource?: AnyObject[];
  columns?: ColumnsType<any>;
  onSearch?: (query: string) => void;
  onAddClick?: () => void;
  pagination?: ICommonResult;
  onChangePagination?: PaginationProps["onChange"];
  rowKey?: string | number | symbol | GetRowKey<any>;
}
export const CategoryTableLayout = ({
  title,
  pagination,
  loading,
  dataSource = [],
  columns = [],
  onSearch,
  onAddClick,
  onChangePagination,
  rowKey = "id",
}: CategoryTableLayoutProps) => {
  const { height } = useWindowDimensions();
  return (
    <>
      <div className={styles.tableHeader}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            flexDirection: "row",
          }}
        >
          <Typography.Text strong style={{ fontSize: 20 }}>
            {`Danh sách ${title}`}
          </Typography.Text>
          {onSearch && (
            <div>
              <Input.Search
                placeholder={`Tìm kiếm ${title}`}
                onChange={(e) => onSearch(e.target.value)}
                allowClear
              />
            </div>
          )}
        </div>
        {onAddClick && (
          <div>
            <Button type="primary" icon={<PlusOutlined />} onClick={onAddClick}>
              {`Thêm mới ${title}`}
            </Button>
          </div>
        )}
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        className={`${styles.table} ticketTable horizontalDragScroll customScrollTable`}
        scroll={{ x: 1050, y: height - 320 }}
        pagination={{
          current: pagination ? pagination.page : 1,
          pageSize: pagination ? pagination.pageSize : 25,
          total: pagination ? pagination.queryCount : 0,
          showSizeChanger: false,
          onChange: onChangePagination,
        }}
        rowKey={rowKey}
        loading={loading}
      />
    </>
  );
};

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  PaginationProps,
  Popconfirm,
  Table,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnsType } from "antd/es/table";
import Utils from "common/Utils";
import { IBus } from "common/define-types";
import { useEffect } from "react";
import { deleteBus, editBus, fetchBuses, saveBus } from "store/slice/BusSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "../Bus.module.css";
import { BusForm } from "./BusForm";
// import { BrokeMotorbikeCalendar } from "./BrokeMotorbikeCalendar";

export const BusTable = () => {
  const selectBus = useSelectorRoot(
    (state) => state.bus.Buses
  );
  const selectBusesResult = useSelectorRoot(
    (state) => state.bus.BusesResult
  );
  const selectEditingBus = useSelectorRoot(
    (state) => state.bus.editingBus
  );
  const selectDeletingBusId = useSelectorRoot(
    (state) => state.bus.deletingBusId
  );
  const selectIsSubmitting = useSelectorRoot(
    (state) => state.bus.isSubmitting
  );
  const dispatch = useDispatchRoot();
  const [editBusForm] = useForm();
  // const [brokeMotorbikeIdOpen, setBrokeMotorbikeOpen] = useState<string | null>(
  //   null
  // );
  const columns: ColumnsType<IBus> = [
    {
      title: "Stt",
      dataIndex: "stt",
      render: (text, _, index) => (
        <>
          {index +
            (selectBusesResult ? selectBusesResult.firstRowIndex : 1)}
        </>
      ),
      width: '8%',
      align: "center",
    },
    {
      title: "Tên phương tiện",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name, "vi"),
        multiple: 3,
      },
      width: '30%',
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      width: '30%',
    },
    {
      title: "",
      dataIndex: "action",
      width: '17%',
      render: (_, record) => (
        <div className={styles.btnContainer}>
          <Button
            type="text"
            icon={<EditOutlined style={{ fontSize: 20, color: "#1890FF" }} />}
            onClick={() => handleEditBus(record)}
          />
          <Popconfirm
            onConfirm={() => handleDeleteBus(record.id)}
            title={"Xóa phương tiện"}
            description={"Bạn chắc chắn muốn xóa phương tiện này ?"}
          >
            <Button
              type="text"
              icon={
                <DeleteOutlined style={{ fontSize: 20, color: "#F5222D" }} />
              }
              loading={
                selectDeletingBusId !== null &&
                selectDeletingBusId === record.id
              }
              disabled={
                selectDeletingBusId !== null &&
                selectDeletingBusId !== record.id
              }
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectBusesResult) {
      dispatch(
        fetchBuses({
          ...selectBusesResult,
          page,
        })
      );
    }
  };

  const handleEditBus = (Bus: IBus) => {
    dispatch(editBus(Bus));
  };

  const handleSaveBus = async () => {
    if (selectEditingBus) {
      const BusValue = await editBusForm
        .validateFields()
        .catch((err) => {
          console.log(err);
          notification.error({ message: "Vui lòng nhập đủ thông tin" });
        });
      if (!BusValue) return;
      dispatch(
        saveBus({
          ...selectEditingBus,
          ...BusValue,
        })
      );
    }
  };

  const handleDeleteBus = async (BusId: string) => {
    await dispatch(deleteBus(BusId));
    dispatch(fetchBuses({ page: 1 }));
  };

  useEffect(() => {
    if (selectEditingBus) {
      editBusForm.setFields(
        Utils.objectToAntFormData({
          ...selectEditingBus,
        })
      );
    }
  }, [selectEditingBus, editBusForm]);

  return (
    <>
      <Table
        dataSource={selectBus}
        columns={columns}
        className={styles.table}
        rowKey={"id"}
        scroll={{ x: 550 }}
        pagination={{
          pageSize: selectBusesResult
            ? selectBusesResult.pageSize
            : 10,
          current: selectBusesResult ? selectBusesResult.page : 1,
          total: selectBusesResult ? selectBusesResult.queryCount : 0,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
      />
      {selectEditingBus !== null ? (
        <Modal
          title={
            <Typography.Text strong style={{ fontSize: 20 }}>
              Sửa thông tin phương tiện
            </Typography.Text>
          }
          okText={"Cập nhật"}
          centered
          onOk={handleSaveBus}
          open={selectEditingBus !== null}
          onCancel={() => dispatch(editBus(null))}
          cancelText="Hủy bỏ"
          destroyOnClose={true}
          okButtonProps={{
            loading: selectIsSubmitting,
          }}
        >
          <BusForm form={editBusForm} />
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

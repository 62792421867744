import { LayoutProps, Modal } from "antd";
import { FC } from "react";

interface IProps {
  title?: string;
  isOpen: boolean;
  handleClose: () => void;
  handleOk?: (...args: any) => void;
  okText?: string;
  cancelText?: string;
}
export const FormModal: FC<LayoutProps & IProps> = ({
  title,
  isOpen,
  handleClose,
  children,
  handleOk,
  okText = "Cập nhật",
  cancelText = "Hủy",
}) => {
  return (
    <Modal
      open={isOpen}
      onCancel={handleClose}
      onOk={() => {
        handleOk && handleOk();
      }}
      okText={okText}
      cancelText={cancelText}
      centered
      title={title}
      destroyOnClose={true}
    >
      {children}
    </Modal>
  );
};

import { Divider, Form, FormInstance, InputNumber, Typography } from 'antd';
import style from './EditTicket.module.css';
import Utils from 'common/Utils';
import {
  ISelectedTicket,
  ITicket,
  ITicketOperatorItem,
} from 'common/define-types';

interface IProps {
  currentTicket: ITicket | ITicketOperatorItem;
  newTicket: ISelectedTicket;
  form: FormInstance<any>;
}

export const RefundSection = ({ currentTicket, newTicket, form }: IProps) => {
  const { busC1Price, busC2Price, ticketPrice } = Utils.getDiffFromNewToCurrent(
    newTicket,
    currentTicket
  );
  // each price < 0 means refund money

  // [10-5-2024][Phuc Thinh][Show up more specific information about refund value of ticket]
  return (
    <Form
      form={form}
      style={{ minWidth: 300, minHeight: 600 }}
      className={style.ticketCard}
      labelCol={{ span: 24 }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography.Text
            strong
            style={{ fontSize: 16 }}
          >{`Vé ${currentTicket.name}`}</Typography.Text>
          <Typography.Text>{`Tổng đã thu của vé: ${Utils.formatCurrency(
            currentTicket.totalRevenue
          )}`}</Typography.Text>
          <Typography.Text>{`Tổng đã hoàn: ${Utils.formatCurrency(
            currentTicket.totalRefund
          )}`}</Typography.Text>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography.Text strong style={{ fontSize: 16 }}>{`Bus chiều đến ${
            currentTicket?.bookingCode || ''
          }`}</Typography.Text>
          <Typography.Text>{`Tổng đã thu của booking: ${Utils.formatCurrency(
            currentTicket.totalRevenueBus1
          )}`}</Typography.Text>
          <Typography.Text>{`Tổng đã hoàn: ${Utils.formatCurrency(
            currentTicket.totalRefundBus1
          )}`}</Typography.Text>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography.Text strong style={{ fontSize: 16 }}>{`Bus chiều về ${
            currentTicket?.bookingCode || ''
          }`}</Typography.Text>
          <Typography.Text>{`Tổng đã thu của booking: ${Utils.formatCurrency(
            currentTicket.totalRevenueBus2
          )}`}</Typography.Text>
          <Typography.Text>{`Tổng đã hoàn: ${Utils.formatCurrency(
            currentTicket.totalRefundBus2
          )}`}</Typography.Text>
        </div>
      </div>
      <Divider style={{ marginBlock: 10 }} />
      <div>
        {ticketPrice !== 0 && (
          <>
            <Typography.Text strong>{`Tiền vé chênh lệch: `}</Typography.Text>
            <Typography>{`${Utils.formatCurrency(
              currentTicket.price
            )} => ${Utils.formatCurrency(
              newTicket.price
            )} (${Utils.formatCurrencyWithSub(ticketPrice)})`}</Typography>
          </>
        )}
        <Form.Item label='Tiền hoàn vé' name={'RefundTicket'}>
          <InputNumber
            style={{ width: '100%' }}
            stringMode={true}
            controls={false}
            formatter={(value) =>
              `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
            }
            parser={(value) =>
              parseInt(value ? value?.replaceAll('.', '') : '0')
            }
          />
        </Form.Item>
      </div>
      <div>
        {busC1Price !== 0 && (
          <>
            <Typography.Text
              strong
            >{`Tiền Bus chiều đến chênh lệch: `}</Typography.Text>
            <Typography>{`${Utils.formatCurrency(
              currentTicket.busTicketC1?.price || 0
            )} => ${Utils.formatCurrency(
              newTicket.busTicketC1?.price || 0
            )} (${Utils.formatCurrencyWithSub(busC1Price)})`}</Typography>
          </>
        )}
        <Form.Item label='Tiền hoàn Bus chiều đến' name={'RefundBusC1'}>
          <InputNumber
            style={{ width: '100%' }}
            stringMode={true}
            controls={false}
            formatter={(value) =>
              `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
            }
            parser={(value) =>
              parseInt(value ? value?.replaceAll('.', '') : '0')
            }
          />
        </Form.Item>
      </div>
      <div>
        {busC2Price !== 0 && (
          <>
            <Typography.Text
              strong
            >{`Tiền Bus chiều về chênh lệch: `}</Typography.Text>
            <Typography>{`${Utils.formatCurrency(
              currentTicket.busTicketC2?.price || 0
            )} => ${Utils.formatCurrency(
              newTicket.busTicketC2?.price || 0
            )} (${Utils.formatCurrencyWithSub(busC2Price)})`}</Typography>
          </>
        )}
        <Form.Item label='Tiền hoàn Bus chiều về' name={'RefundBusC2'}>
          <InputNumber
            style={{ width: '100%' }}
            stringMode={true}
            controls={false}
            formatter={(value) =>
              `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
            }
            parser={(value) =>
              parseInt(value ? value?.replaceAll('.', '') : '0')
            }
          />
        </Form.Item>
      </div>
    </Form>
  );
};

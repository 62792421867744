import { Skeleton, Steps, Typography } from "antd";
import styles from "../NightAuditor.module.css";
import { useSelectorRoot } from "store/store";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import Utils from "common/Utils";
import { JobTicketType } from "common/define-types";
import moment from "moment";

export const Journey = () => {
  const selectJobTours = useSelectorRoot(
    (state) => state.nightAuditor.jobTours
  );
  const selectIsJobLoading = useSelectorRoot(
    (state) => state.nightAuditor.isJobLoading
  );
  // const selectJobDateSelected = useSelectorRoot(
  //   (state) => state.nightAuditor.jobDateSelected
  // );
  const selectGroupSelected = useSelectorRoot(
    (state) => state.nightAuditor.groupSelected
  );
  // const selectGroupRunningDateSelected =
  //   moment().diff(moment(selectGroupSelected?.startDate), "days") + 1;
  const renderJobItemType = (type: JobTicketType) => {
    switch (type) {
      case JobTicketType.Driver:
        return "Nhân viên";
      case JobTicketType.Motor:
        return "Xe máy";
      case JobTicketType.Tickets || JobTicketType.Oil:
        return "Khách";

      default:
        return "Khách";
    }
  };
  const filteredAndSortedJobTours = [...selectJobTours]
    .filter((job, index) => {
      const jobDateInGroup = moment.utc(selectGroupSelected?.startDate).add(
        parseInt(job.day) - 1,
        "days"
      );

      return jobDateInGroup.isBetween(
        moment
          .utc(selectGroupSelected?.businessDate)
          .endOf("day"),
        moment.utc().endOf("day")
      );
    })
    .sort((a, b) => {
      if (a.stt === "" || a.stt === null) return 1;
      if (b.stt === "" || b.stt === null) return -1;
      if (a.stt === b.stt) return 0;
      return parseInt(a.stt) < parseInt(b.stt) ? -1 : 1;
    });

  return (
    <div className={styles.detailView}>
      <div className={styles.detailView_title}>
        <Typography.Title level={4} style={{ marginBlock: 0 }}>
          Hành trình
        </Typography.Title>
      </div>
      <div className={styles.detailView_wrapper}>
        <div
          className={`${styles.scrollContainer} customScroll`}
          style={{ paddingRight: 15 }}
        >
          {selectIsJobLoading ? (
            new Array(7)
              .fill(null)
              .map((_, index) => (
                <Skeleton
                  avatar={{ size: "small" }}
                  paragraph={{ rows: 1 }}
                  key={`skeleton-${index}`}
                  active
                />
              ))
          ) : filteredAndSortedJobTours &&
            filteredAndSortedJobTours.length > 0 ? (
            <Steps
              direction="vertical"
              className="nightAudit-journey-step"
              items={[...filteredAndSortedJobTours].map((job) => ({
                title: (
                  <div className={styles.journeyItem}>
                    <div className={styles.journeyItem_info}>
                      <span className={styles.journeyItem_info_item}>
                        {job.name}
                      </span>
                      <span className={styles.journeyItem_info_item}>
                        {Utils.formatCurrency(job.price)}
                      </span>
                    </div>
                    <div className={styles.journeyItem_info}>
                      <span
                        className={styles.journeyItem_info_item}
                        style={{
                          lineHeight: "24px",
                          color: "#9a9a9a",
                          fontWeight: "500",
                          fontStyle: "italic",
                        }}
                      >
                        {`${job.jobItem ? renderJobItemType(job.jobItem.type) : 'Khách'}`}
                      </span>
                    </div>
                    <div className={styles.journeyItem_info}>
                      <span className={styles.journeyItem_info_item}>
                        {`Số lượng: ${
                          job.jobTickets.filter((jTicket) => jTicket.isCheck)
                            .length
                        }`}
                      </span>
                      {job.jobItem && job.jobItem.type !== JobTicketType.Oil && (
                        <span className={styles.journeyItem_info_item}>
                          {Utils.formatCurrency(job.jobItem.rate)}
                        </span>
                      )}
                    </div>
                  </div>
                ),
                status: "process",
                icon: (
                  <div
                    style={{
                      width: 32,
                      height: 32,
                      backgroundColor: "#BAE7FF",
                      color: "#1890FF",
                      fontSize: "14px",
                      borderRadius: "50%",
                    }}
                  >
                    {job.stt}
                  </div>
                ),
              }))}
            />
          ) : (
            <EmptyHolder message="No job yet" />
          )}
        </div>
      </div>
    </div>
  );
};

import { Pagination, PaginationProps } from "antd";
import styles from "../Group.module.css";
import { fetchGroupTickets } from "store/slice/GroupSlice";
import { useSelectorRoot, useDispatchRoot } from "store/store";
import { TicketList } from "./TicketList";
import moment from "moment";
import { TicketListHeader } from "./TicketListHeader";
import { useEffect, useState } from "react";
import { IGroupTicketsParams } from "common/define-types";
import PreviewTicketsContainer from "./PreviewTicketsContainer";

interface IProps {
  isResponsive?: boolean;
}
export const TicketListContainer = ({ isResponsive }: IProps) => {
  const selectGroupSelected = useSelectorRoot(
    (state) => state.group.groupSelected
  );
  const selectGroupsTickets = useSelectorRoot(
    (state) => state.group.groupTickets
  );

  const selectGroupTicketsResults = useSelectorRoot(
    (state) => state.group.groupTicketsResult
  );
  const selectSelectedDate = useSelectorRoot(
    (state) => state.group.selectedDate
  );
  const dispatch = useDispatchRoot();
  const isTicketLoading = useSelectorRoot(
    (state) => state.group.isTicketLoading
  );
  const [filterValue, setFilterValue] = useState<IGroupTicketsParams>({});

  const handleChangePagi: PaginationProps["onChange"] = (page) => {
    if (selectGroupTicketsResults) {
      dispatch(
        fetchGroupTickets({
          ...selectGroupTicketsResults,
          ...filterValue,
          page: page,
          startDate: moment(selectSelectedDate)
            .startOf("day")
            .add(7, "hours")
            .toISOString(),
          endDate: moment(selectSelectedDate)
            .endOf("day")
            .add(7, "hours")
            .toISOString(),
        })
      );
    }
  };

  const handleChangeFilterValue = (filterValue: IGroupTicketsParams) => {
    setFilterValue(filterValue);
    if (filterValue) {
      dispatch(
        fetchGroupTickets({
          // ...selectGroupTicketsResults,
          ...filterValue,
          startDate: moment(selectSelectedDate)
            .startOf("day")
            .add(7, "hours")
            .toISOString(),
          endDate: moment(selectSelectedDate)
            .endOf("day")
            .add(7, "hours")
            .toISOString(),
        })
      );
    }
  };

  useEffect(() => {
    setFilterValue({});
  }, [selectGroupSelected]);

  // [5-12-2023][Phuc Thinh] [show preview ticket selected]
  // add preview tab
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "clamp(20rem, 60vw, 60rem)",
        flex: 1,
        maxWidth: "65rem",
      }}
      className={`${isResponsive ? styles.ticketListContainer : ""}`}
    >
      <div
        className={`${styles.listContainer} `}
        style={{ flex: 2, minWidth: 360 }}
      >
        <TicketListHeader
          filterValue={filterValue}
          onChange={(value) => handleChangeFilterValue(value)}
        />
        {/* [26-1-2024][Phuc Thinh][Only filter isTicketInGroup when one group is selected] */}
        <TicketList tickets={selectGroupsTickets.filter(gTicket => !selectGroupSelected || gTicket.isTicketInGroup === true)} isLoading={isTicketLoading} />
        <div className={styles.listContainer_footer}>
          <Pagination
            total={selectGroupTicketsResults?.queryCount ?? 0}
            pageSize={selectGroupTicketsResults?.pageSize ?? 0}
            current={selectGroupTicketsResults?.page ?? 0}
            showSizeChanger={false}
            showTotal={(total, range) =>
              `Hiển thị ${
                range[1] === 1 || range[1] - range[0] !== 0
                  ? range[1] - range[0] + 1
                  : 0
              }/${total} khách`
            }
            onChange={handleChangePagi}
          />
        </div>
      </div>
      <PreviewTicketsContainer />
    </div>
  );
};

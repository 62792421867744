import { GroupItemType, IGroupJobItem, IJobItem } from "common/define-types";
import styles from "../Suppliers.module.css";
import { Tree } from "antd";
import { GroupJobItem } from "./GroupJobItem";
import { JobItem } from "./JobItem";
import { DataNode } from "antd/es/tree";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { setExpandedJobKeys } from "store/slice/SuppliersCategorySlice";
interface SupplierJobItemProps {
  job: IGroupJobItem;
}
export const SupplierJobItem = ({ job }: SupplierJobItemProps) => {
  const dispatch = useDispatchRoot();
  const expandedKeys = useSelectorRoot(
    (state) => state.suppliersCategory.expandedJobKeys
  );
  const getTreeData = (record: IGroupJobItem): DataNode => {
    let treeData = {
      title: (
        <GroupJobItem
          job={record}
          allowAddGroup={job.type === GroupItemType.Parent}
        />
      ),
      key: record?.id,
      children:
        job.type === GroupItemType.Parent
          ? (job.groupItemDTOs || [])
              .map((groupItem, index) => ({
                title: (
                  <GroupJobItem
                    job={groupItem}
                    allowAddGroup={false}
                    parentJob={job}
                    index={index + 1}
                  />
                ),
                key: groupItem.id,
                children: groupItem.jobItems.map(
                  (jobItem: IJobItem, jobItemIndex: number) => ({
                    title: (
                      <JobItem
                        jobItem={jobItem}
                        index={jobItemIndex}
                        parentJob={groupItem}
                      />
                    ),
                    key: jobItem.id,
                  })
                ),
              }))
              .concat(
                (job.jobItems || []).map((jobItem, jobItemIndex) => ({
                  title: (
                    <JobItem
                      jobItem={jobItem}
                      index={(job.groupItemDTOs || []).length + jobItemIndex}
                      parentJob={job}
                    />
                  ),
                  key: jobItem.id,
                  children: [],
                }))
              )
          : job.jobItems?.length
          ? job.jobItems.map((jobItem: IJobItem, jobItemIndex: number) => ({
              title: (
                <JobItem
                  jobItem={jobItem}
                  index={jobItemIndex}
                  parentJob={job}
                />
              ),
              key: jobItem.id,
            }))
          : undefined,
    };
    return treeData;
  };

  return (
    <div className={styles.jobCardContainer}>
      <div className={styles.jobTourWrapper}>
        <Tree
          showLine
          treeData={[getTreeData(job)]}
          expandedKeys={expandedKeys}
          selectable={false}
          onExpand={(keys) => {
            dispatch(setExpandedJobKeys(keys));
          }}
        />
      </div>
    </div>
  );
};

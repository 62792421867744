import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  PaginationProps,
  Popconfirm,
  Table,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnsType } from "antd/es/table";
import Utils from "common/Utils";
import { ILocation } from "common/define-types";
import { useEffect } from "react";
import { deleteLocation, editLocation, fetchLocations, saveLocation } from "store/slice/LocationSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "../Location.module.css";
import { LocationForm } from "./LocationForm";
// import { BrokeMotorbikeCalendar } from "./BrokeMotorbikeCalendar";

export const LocationTable = () => {
  const selectLocation = useSelectorRoot(
    (state) => state.location.locations
  );
  const selectLocationsResult = useSelectorRoot(
    (state) => state.location.locationsResult
  );
  const selectEditingLocation = useSelectorRoot(
    (state) => state.location.editingLocation
  );
  const selectDeletingLocationId = useSelectorRoot(
    (state) => state.location.deletingLocationId
  );
  const selectIsSubmitting = useSelectorRoot(
    (state) => state.location.isSubmitting
  );
  const dispatch = useDispatchRoot();
  const [editLocationForm] = useForm();
  // const [brokeMotorbikeIdOpen, setBrokeMotorbikeOpen] = useState<string | null>(
  //   null
  // );
  const columns: ColumnsType<ILocation> = [
    {
      title: "Stt",
      dataIndex: "stt",
      render: (text, _, index) => (
        <>
          {index +
            (selectLocationsResult ? selectLocationsResult.firstRowIndex : 1)}
        </>
      ),
      width: '8%',
      align: "center",
    },
    {
      title: "Tên vị trí",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name, "vi"),
        multiple: 3,
      },
      width: '30%',
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      width: '30%',
    },
    {
      title: "",
      dataIndex: "action",
      width: '25%',
      render: (_, record) => (
        <div className={styles.btnContainer}>
          <Button
            type="text"
            icon={<EditOutlined style={{ fontSize: 20, color: "#1890FF" }} />}
            onClick={() => handleEditLocation(record)}
          />
          <Popconfirm
            onConfirm={() => handleDeleteLocation(record.id)}
            title={"Xóa vị trí"}
            description={"Bạn chắc chắn muốn xóa vị trí này ?"}
          >
            <Button
              type="text"
              icon={
                <DeleteOutlined style={{ fontSize: 20, color: "#F5222D" }} />
              }
              loading={
                selectDeletingLocationId !== null &&
                selectDeletingLocationId === record.id
              }
              disabled={
                selectDeletingLocationId !== null &&
                selectDeletingLocationId !== record.id
              }
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectLocationsResult) {
      dispatch(
        fetchLocations({
          ...selectLocationsResult,
          page,
        })
      );
    }
  };

  const handleEditLocation = (location: ILocation) => {
    dispatch(editLocation(location));
  };

  const handleSaveLocation = async () => {
    if (selectEditingLocation) {
      const locationValue = await editLocationForm
        .validateFields()
        .catch((err) => {
          console.log(err);
          notification.error({ message: "Vui lòng nhập đủ thông tin" });
        });
      if (!locationValue) return;
      dispatch(
        saveLocation({
          ...selectEditingLocation,
          ...locationValue,
        })
      );
    }
  };

  const handleDeleteLocation = async (locationId: string) => {
    await dispatch(deleteLocation(locationId));
    // Fetch locations after successful deletion to return to page 1
    dispatch(fetchLocations({ page: 1 }));
  };

  useEffect(() => {
    if (selectEditingLocation) {
      editLocationForm.setFields(
        Utils.objectToAntFormData({
          ...selectEditingLocation,
        })
      );
    }
  }, [selectEditingLocation, editLocationForm]);

  return (
    <>
      <Table
        dataSource={selectLocation}
        columns={columns}
        className={styles.table}
        rowKey={"id"}
        scroll={{ x: 550 }}
        pagination={{
          pageSize: selectLocationsResult
            ? selectLocationsResult.pageSize
            : 10,
          current: selectLocationsResult ? selectLocationsResult.page : 1,
          total: selectLocationsResult ? selectLocationsResult.queryCount : 0,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
      />
      {selectEditingLocation !== null ? (
        <Modal
          title={
            <Typography.Text strong style={{ fontSize: 20 }}>
              Sửa thông tin vị trí
            </Typography.Text>
          }
          okText={"Cập nhật"}
          centered
          onOk={handleSaveLocation}
          open={selectEditingLocation !== null}
          onCancel={() => dispatch(editLocation(null))}
          cancelText="Hủy bỏ"
          destroyOnClose={true}
          okButtonProps={{
            loading: selectIsSubmitting,
          }}
        >
          <LocationForm form={editLocationForm} />
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

import { Form, Input, Modal, ModalProps, Spin, Typography } from 'antd';
import { getPriceTourForAgent } from 'api/agent.api';
import Utils from 'common/Utils';
import { IComboTour, IPriceDetail } from 'common/define-types';
import { DiscountForm, DiscountType } from 'components/Form/DiscountForm';
import { useState, useEffect } from 'react';
import { useSelectorRoot } from 'store/store';

interface DiscountTourModalProps extends Omit<ModalProps, 'onOk'> {
  selectedComboTour: IComboTour;
  onOk: ({ discountValue }: { discountValue: IPriceDetail }) => void;
}
export const DiscountTourModal = ({
  selectedComboTour,
  onOk,
  ...rest
}: DiscountTourModalProps) => {
  const [discountDetail, setDiscountDetail] = useState<IPriceDetail | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const selectDiscountAgent = useSelectorRoot(
    (state) => state.agents.discountAgent
  );
  const [priceForm] = Form.useForm();
  const [discountForm] = Form.useForm();

  const handleSubmit = async () => {
    if (discountDetail) {
      const priceValue = await priceForm
        .validateFields()
        .catch((err) => console.log(err));
      const discountFormValue = await discountForm
        .validateFields()
        .catch((err) => console.log(err));
      onOk({
        discountValue: {
          ...discountDetail,
          discountValue:
            discountFormValue.type === DiscountType.BY_VALUE
              ? discountFormValue.amount
              : 0,
          discountPercent:
            discountFormValue.type === DiscountType.BY_PERCENT
              ? discountFormValue.amount
              : 0,
          giaDaiLy: priceValue.giaDaiLy,
        },
      });
    }
  };

  useEffect(() => {
    if (selectedComboTour && selectDiscountAgent) {
      setLoading(true);
      getPriceTourForAgent(selectDiscountAgent.id, {
        comboId: selectedComboTour.Id,
      }).subscribe(
        (res) => {
          if (res) {
            setDiscountDetail(res);
          } else {
            setDiscountDetail(null);
          }
        },
        (err) => {
          console.log(err);
          setLoading(false);
        },
        () => setLoading(false)
      );
    }
  }, [selectedComboTour, selectDiscountAgent, discountForm]);

  useEffect(() => {
    if (discountDetail) {
      const isDiscountValue = discountDetail.discountValue !== 0;
      const discountType = isDiscountValue
        ? DiscountType.BY_VALUE
        : DiscountType.BY_PERCENT;
      if (discountType === DiscountType.BY_PERCENT) {
        discountForm.setFields(
          Utils.objectToAntFormData({
            type: discountType,
            amount: discountDetail.discountPercent,
          })
        );
      } else if (discountType === DiscountType.BY_VALUE) {
        discountForm.setFields(
          Utils.objectToAntFormData({
            type: discountType,
            amount: discountDetail.discountValue,
          })
        );
      }
      priceForm.setFieldValue('giaDaiLy', discountDetail.giaDaiLy);
    } else {
      discountForm.resetFields();
      priceForm.resetFields();
    }
  }, [discountDetail, discountForm, priceForm]);

  return (
    <Modal {...rest} onOk={handleSubmit}>
      <Spin spinning={loading}>
        <div>
          <Typography.Text strong>{selectedComboTour.Title}</Typography.Text>
          <Typography.Text>{` - ${selectedComboTour.SubTitle}`}</Typography.Text>
          <Typography.Text>{` - ${selectedComboTour.Description}`}</Typography.Text>
        </div>
        <Form form={priceForm}>
          <Form.Item
            label='Giá thu đại lý'
            name={'giaDaiLy'}
            rules={[
              {
                required: true,
                message: 'Không được bỏ trống',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
        <DiscountForm form={discountForm} />
      </Spin>
    </Modal>
  );
};

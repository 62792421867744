import { CategoryTableLayout } from "components/Layout/CategoryTableLayout";
import styles from "./TourCategory.module.css";
import { useEffect, useState } from "react";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import {
  addTour,
  editJobTourOfTour,
  editTour,
  fetchTourCategorys,
  saveTour,
  setAddModalOpen,
} from "store/slice/TourCategorySlice";
import { ColumnsType } from "antd/es/table";
import { ITour } from "common/define-types";
import {
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Image,
  PaginationProps,
} from "antd";
import { useForm } from "antd/es/form/Form";
import {
  DashOutlined,
  EditOutlined,
  // DeleteOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { TourForm } from "./components/TourForm";
import { EditJobTourModal } from "./components/EditJobTourModal";
import SYSTEM_CONSTANTS from "common/constants";

export const TourCategory = () => {
  // const [modal, contextHolder] = Modal.useModal();
  const selectIsLoginSuccess = useSelectorRoot(
    (state) => state.login.isSuccess
  );
  const selectIsRefreshing = useSelectorRoot(
    (state) => state.login.isRefreshing
  );
  const dispatch = useDispatchRoot();
  const [tourForm] = useForm();

  const [pathFile, setPathFile] = useState<string | null>(null);
  const addModalOpen = useSelectorRoot(
    (state) => state.tourCategory.addModalOpen
  );
  const selectEditingTour = useSelectorRoot(
    (state) => state.tourCategory.editingTour
  );
  const selectEditingJobTourByTour = useSelectorRoot(
    (state) => state.tourCategory.editingJobTourByTour
  );
  const selectTours = useSelectorRoot((state) => state.tourCategory.tours);
  const { isFetching, isSubmitting } = useSelectorRoot(
    (state) => state.tourCategory.loading
  );
  const selectToursResult = useSelectorRoot(
    (state) => state.tourCategory.toursResult
  );
  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectToursResult) {
      dispatch(
        fetchTourCategorys({
          ...selectToursResult,
          page,
        })
      );
    }
  };

  useEffect(() => {
    if (selectIsLoginSuccess && !selectIsRefreshing) {
      dispatch(fetchTourCategorys({ pageSize: 25 }));
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [selectIsLoginSuccess, selectIsRefreshing]);

  useEffect(() => {
    if (!selectEditingTour && !addModalOpen) {
      setPathFile("");
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [selectEditingTour, addModalOpen]);

  const openAddModal = () => dispatch(setAddModalOpen(true));
  const closeAddModal = () => dispatch(setAddModalOpen(false));
  const handleAddTour = async () => {
    const tourValue = await tourForm
      .validateFields()
      .catch((err) => console.log(err));
    if (!tourValue) return;
    dispatch(
      addTour({
        ...tourValue,
        pathFile,
      })
    );
  };

  const openEditModal = (tour: ITour) => dispatch(editTour(tour));
  const closeEditModal = () => dispatch(editTour(null));
  const handleSaveTour = async () => {
    if (selectEditingTour) {
      const tourValue = await tourForm
        .validateFields()
        .catch((err) => console.log(err));
      if (!tourValue) return;

      dispatch(
        saveTour({
          ...selectEditingTour,
          ...tourValue,
          pathFile,
        })
      );
    }
  };

  // const handleDeleteTour = () => {
  //   notification.success({
  //     message: "Delete",
  //   });
  // };

  const openEditJobTourModal = (tour: ITour) =>
    dispatch(editJobTourOfTour(tour));
  const closeEditJobTourModal = () => dispatch(editJobTourOfTour(null));

  const getActionsMenu = (record: ITour): MenuProps["items"] => {
    return [
      {
        key: "job",
        label: "Danh sách điểm đến",
        icon: <UnorderedListOutlined style={{ color: "#1890ff" }} />,
        onClick: () => openEditJobTourModal(record),
      },
      {
        key: "edit",
        label: "Chỉnh sửa",
        icon: <EditOutlined style={{ color: "#1890ff" }} />,
        onClick: () => openEditModal(record),
      },
      // {
      //   key: "delete",
      //   label: "Xóa",
      //   icon: <DeleteOutlined style={{ color: "red" }} />,
      //   onClick: () =>
      //     modal.confirm({
      //       title: "Xóa tour",
      //       content: <>{`Xác nhận xóa ${record.name} ?`}</>,
      //       onOk: handleDeleteTour,
      //       centered: true,
      //     }),
      // },
    ];
  };

  const columns: ColumnsType<ITour> = [
    {
      title: "Stt",
      dataIndex: "stt",
      render: (text, _, index) => (
        <>{(selectToursResult?.firstRowIndex || 1) + index}</>
      ),
      width: 20,
      align: "center",
    },
    {
      title: "Mã tour",
      dataIndex: "code",
      width: 50,
    },
    {
      title: "Tên tour",
      dataIndex: "name",
      width: 50,
      ellipsis: true,
    },
    {
      title: "Mô tả tour",
      dataIndex: "description",
      width: 60,
    },
    {
      title: "Ảnh",
      dataIndex: "pathFile",
      width: 50,
      render(value) {
        return (
          value && (
            <Image
              src={`${SYSTEM_CONSTANTS.API_URL}${value}`}
              width={"100%"}
              style={{ aspectRatio: 16 / 9, objectFit: "cover" }}
            />
          )
        );
      },
    },
    {
      dataIndex: "actions",
      width: 30,
      align: "center",
      fixed: "right",
      render(_, record) {
        return record ? (
          <Dropdown
            menu={{ items: getActionsMenu(record) }}
            trigger={["click"]}
            arrow
          >
            <Button type="text" icon={<DashOutlined />} />
          </Dropdown>
        ) : (
          <></>
        );
      },
    },
  ];

  return (
    <div className={styles.container}>
      {/* {contextHolder} */}
      <CategoryTableLayout
        title="loại tour"
        dataSource={selectTours}
        pagination={selectToursResult || undefined}
        columns={columns}
        loading={isFetching}
        onAddClick={openAddModal}
        onChangePagination={handleChangePage}
      />

      {/* add form */}
      <Modal
        open={addModalOpen}
        onCancel={closeAddModal}
        onOk={handleAddTour}
        okText="Lưu"
        cancelText="Hủy"
        destroyOnClose
        centered
        title={"Thêm loại Tour"}
        afterClose={() => tourForm.resetFields()}
      >
        <TourForm
          form={tourForm}
          pathFile={pathFile}
          setPathFile={setPathFile}
        />
      </Modal>

      {/* edit form */}
      <Modal
        open={!!selectEditingTour}
        onCancel={closeEditModal}
        onOk={handleSaveTour}
        okText="Lưu"
        cancelText="Hủy"
        destroyOnClose
        centered
        title={"Sửa loại Tour"}
        afterClose={() => tourForm.resetFields()}
      >
        <TourForm
          form={tourForm}
          editingTour={selectEditingTour}
          pathFile={pathFile}
          setPathFile={setPathFile}
        />
      </Modal>

      {/* edit job tour */}
      {selectEditingJobTourByTour && (
        <EditJobTourModal
          open={!!selectEditingJobTourByTour}
          handleClose={closeEditJobTourModal}
          tour={selectEditingJobTourByTour}
          loading={isSubmitting}
        />
      )}
    </div>
  );
};

import { Form, FormInstance, Input, InputNumber, Spin, Typography } from "antd";
import { useWatch } from "antd/es/form/Form";
import { getIssuePriceByType } from "api/issuePrice.api";
import { IIssue } from "api/types/issue";
import Utils from "common/Utils";
import SYSTEM_CONSTANTS from "common/constants";
import {
  GetIssuePriceByTypeParams,
  GroupTicketType,
  IsDriverNumberType,
  IsDriverType,
  IssueType,
  JobTicketStatusEnum,
} from "common/define-types";
import { TicketSelect } from "components/Selects/TicketSelect";
import { useEffect, useState } from "react";
import { useSelectorRoot } from "store/store";

interface IProps {
  form: FormInstance<any>;
  detailIssue?: IIssue;
}
export const ChangeTourForm = ({ form, detailIssue }: IProps) => {
  const AMOUNT_MONEY = {
    MAX: 8000000,
    MIN: 0,
  };
  const selectGroupTickets = useSelectorRoot(
    (state) => state.group.groupTickets
  );
  const [isLoading, setIsLoading] = useState(false);

  const ticketId = useWatch("ticketId", form);

  useEffect(() => {
    if (!!detailIssue) {
      form.setFields(Utils.objectToAntFormData(detailIssue));
    }

    return () => {
      form.resetFields();
    };
  }, [form, detailIssue]);

  useEffect(() => {
    if (!detailIssue && ticketId) {
      const foundTicket = selectGroupTickets.find(
        (gTicket) => gTicket.id === ticketId
      );
      if (!foundTicket) return;
      const params: GetIssuePriceByTypeParams = {
        type: IssueType.CHANGE_TOUR,
        day: 0,
        isDriver:
          foundTicket.isDriver !== IsDriverNumberType.DRIVER_PRICE
            ? foundTicket.isDriver
            : foundTicket.driverPrice?.isDriver === IsDriverType.YES
            ? 2
            : 1,
        tourId: foundTicket.tourId,
      };
      setIsLoading(true);
      getIssuePriceByType(params).subscribe(
        (res) => {
          if (res && !isNaN(res)) {
            form.setFieldValue("amount_Money", res);
          }
        },
        (err) => {
          console.log(err);
          setIsLoading(false);
        },
        () => setIsLoading(false)
      );
    }
  }, [detailIssue, selectGroupTickets, ticketId, form]);

  return (
    <Spin spinning={isLoading}>
      <Form form={form} labelCol={{ span: 24 }} className="noMargin_form-item">
        <Form.Item
          label={"Khách"}
          name={"ticketId"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <TicketSelect
            options={selectGroupTickets.filter(
              (gTicket) => gTicket.isTicketInGroup === true
            )}
            onChange={(value) => form.setFieldValue("ticketId", value)}
            readOnly={!!detailIssue}
            acceptStatus={[
              JobTicketStatusEnum.INIT,
              JobTicketStatusEnum.CONFIRM,
              JobTicketStatusEnum.PASS,
            ]}
            acceptType={[GroupTicketType.TICKET]}
          />
        </Form.Item>
        <Form.Item
          label={"Số tiền"}
          name={"amount_Money"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            stringMode={true}
            readOnly={!!detailIssue}
            controls={false}
            max={AMOUNT_MONEY.MAX}
            min={AMOUNT_MONEY.MIN}
            formatter={(value) =>
              `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
            }
            parser={(value) =>
              parseInt(value ? value?.replaceAll(".", "") : "0")
            }
          />
        </Form.Item>
        <Form.Item label={"Ghi chú"} name={"note"}>
          <Input.TextArea readOnly={!!detailIssue} />
        </Form.Item>
        {!!detailIssue && detailIssue.pathFile && (
          <>
            <Typography.Text strong style={{ paddingBlock: 16 }}>
              Ảnh:
            </Typography.Text>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={`${SYSTEM_CONSTANTS.API_URL}${detailIssue.pathFile}`}
                alt="issue"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </div>
          </>
        )}
      </Form>
    </Spin>
  );
};

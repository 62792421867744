import { Dropdown, MenuProps, Typography } from "antd";
import { IGroupJobItem } from "common/define-types";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { useDispatchRoot } from "store/store";
import {
  editSupplierGroupJobItem,
  removeSupplierGroupJobItem,
  setAddSupplierGroupJobOpen,
  setAddSupplierJobItemOpen,
} from "store/slice/SuppliersCategorySlice";
import useModal from "antd/es/modal/useModal";
interface GroupJobItemProps {
  job: IGroupJobItem;
  allowAddGroup?: boolean;
  parentJob?: IGroupJobItem;
  index?: number;
}
export const GroupJobItem = ({
  job,
  allowAddGroup,
  parentJob,
  index,
}: GroupJobItemProps) => {
  const [modal, contextHolder] = useModal();
  const dispatch = useDispatchRoot();

  const openAddGroupJob = () => {
    dispatch(
      setAddSupplierGroupJobOpen({
        open: true,
        parentJob: job,
      })
    );
  };

  const openAddJobItem = () => {
    dispatch(
      setAddSupplierJobItemOpen({
        open: true,
        parentJob: job,
      })
    );
  };

  const editGroupJob = () => {
    dispatch(
      editSupplierGroupJobItem({
        groupJobEditing: job,
        parentJob: parentJob,
      })
    );
  };

  const deleteGroupJob = () => {
    dispatch(removeSupplierGroupJobItem(job.id));
  };

  const getActionsMenu = (): MenuProps["items"] => {
    return [
      {
        key: "edit",
        label: "Chỉnh sửa",
        icon: <EditOutlined style={{ color: "#1890ff" }} />,
        onClick: editGroupJob,
      },
      {
        key: "addGroupJob",
        label: "Thêm nhóm công việc",
        icon: <PlusSquareOutlined style={{ color: "#333A73" }} />,
        onClick: openAddGroupJob,
      },
      {
        key: "addJobItem",
        label: "Thêm công việc",
        icon: <PlusOutlined style={{ color: "#50C4ED" }} />,
        onClick: openAddJobItem,
      },
      {
        key: "delete",
        label: "Xóa",
        icon: <DeleteOutlined style={{ color: "red" }} />,
        onClick: () => {
          modal.confirm({
            title: `Xóa ${!parentJob ? "điểm đến" : "nhóm công việc"}`,
            content: <>{`Xác nhận xóa ${job.name} ?`}</>,
            onOk: deleteGroupJob,
            centered: true,
          });
        },
      },
    ].filter((item) => !!allowAddGroup || item.key !== "addGroupJob");
  };
  return (
    <>
      {contextHolder}
      <Dropdown trigger={["contextMenu"]} menu={{ items: getActionsMenu() }}>
        <Typography.Text strong>{`${index ? index + ". " : ""}${
          job.name
        }`}</Typography.Text>
      </Dropdown>
    </>
  );
};

import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import Utils from "common/Utils";
import {
  ICreateRouterStationBus,
  IRouterStationBusParams,
  IUpdateRouterStationBus,
} from "common/define-types";

const api = `${SYSTEM_CONSTANTS.API_URL}/RouterStationBus`;

export const getRouterStationBusById = (id: string): Observable<any> => {
  return HttpClient.get(`${api}/${id}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getAllRouterStationBuses = (
  props: IRouterStationBusParams
): Observable<any> => {
  return HttpClient.get(`${api}?${Utils.getParamsString(props)}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getAllRouterStationBusesPagable = (
  props?: IRouterStationBusParams
): Observable<any> => {
  return HttpClient.get(
    `${api}/allRouterStationBus?${Utils.getParamsString(props || {})}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getRouterStationBusesByStationId = (
  stationId: string,
  props?: IRouterStationBusParams
): Observable<any> => {
  return HttpClient.get(
    `${api}/stationBus/${stationId}?${Utils.getParamsString(props || {})}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const createRouterStationBus = (
  body: ICreateRouterStationBus
): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updateRouterStationBus = (
  body: IUpdateRouterStationBus
): Observable<any> => {
  return HttpClient.put(`${api}?id=${body.id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const removeRouterStationBus = (
  routerStationBusId: string
): Observable<any> => {
  return HttpClient.delete(`${api}/${routerStationBusId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

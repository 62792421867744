import { Divider, Form, FormInstance } from "antd";
import { IComboTour } from "common/define-types";
import { useEffect } from "react";
import { ComboTourBusForm } from "./ComboTourBusForm";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import Utils from "common/Utils";
import { fetchRouterStationBusData } from "store/slice/RouterStationBus";

interface ComboTourBusesFormProps {
  form: FormInstance<any>;
  editingComboTourBuses?: IComboTour | null;
}

const FORM_NAMES = {
  FROM_BUSES: "fromBuses",
  END_BUSES: "endBuses",
  FROM_LOCATIONS: "fromLocations",
  END_LOCATIONS: "endLocations",
};

export const ComboTourBusesForm = ({
  form,
  editingComboTourBuses,
}: ComboTourBusesFormProps) => {
  const dispatch = useDispatchRoot();
  const selectRouterStationBuses = useSelectorRoot(
    (state) => state.routerStationBus.routerStationBuses
  );
  const fromBuses:
    | { locationId: string; routerStationBusIds?: string[] }[]
    | undefined = Form.useWatch(FORM_NAMES.FROM_BUSES, form);
  const fromLocations: string[] | undefined = Form.useWatch(
    FORM_NAMES.FROM_LOCATIONS,
    form
  );

  const endBuses:
    | { locationId: string; routerStationBusIds?: string[] }[]
    | undefined = Form.useWatch(FORM_NAMES.END_BUSES, form);
  const endLocations: string[] | undefined = Form.useWatch(
    FORM_NAMES.END_LOCATIONS,
    form
  );

  useEffect(() => {
    if (fromLocations && fromLocations.length) {
      if (!fromBuses || !fromBuses.length) {
        form.setFieldValue(
          FORM_NAMES.FROM_BUSES,
          fromLocations.map((locationId: string) => ({
            locationId: locationId,
          }))
        );
      } else {
        let newBuses = fromBuses.filter((bus) => {
          return fromLocations.includes(bus.locationId);
        });
        fromLocations.forEach((locationId: string) => {
          if (newBuses.every((newBus) => newBus.locationId !== locationId)) {
            newBuses.push({ locationId });
          }
        });
        form.setFieldValue(FORM_NAMES.FROM_BUSES, newBuses);
      }
    } else {
      form.setFieldValue(FORM_NAMES.FROM_BUSES, []);
    }
  }, [fromLocations, fromBuses, form]);

  useEffect(() => {
    if (endLocations && endLocations.length) {
      if (!endBuses || !endBuses.length) {
        form.setFieldValue(
          FORM_NAMES.END_BUSES,
          endLocations.map((locationId: string) => ({
            locationId: locationId,
          }))
        );
      } else {
        let newBuses = endBuses.filter((bus) => {
          return endLocations.includes(bus.locationId);
        });
        endLocations.forEach((locationId: string) => {
          if (newBuses.every((newBus) => newBus.locationId !== locationId)) {
            newBuses.push({ locationId });
          }
        });
        form.setFieldValue(FORM_NAMES.END_BUSES, newBuses);
      }
    } else {
      form.setFieldValue(FORM_NAMES.END_BUSES, []);
    }
  }, [endLocations, endBuses, form]);

  useEffect(() => {
    if (editingComboTourBuses) {
      // const hasBusCombo =
      //   !!editingComboTourBuses &&
      //   editingComboTourBuses.ListItem &&
      //   editingComboTourBuses.ListItem.filter(
      //     (item) => item.ItemType === ComboTourItemType.Bus
      //   ).length > 0;

      //[29-3-2024][Phuc Thinh][remove dockerId = 'nocombo']
      const comboTourBuses = selectRouterStationBuses.filter((bus) =>
        bus.dockerId?.toLowerCase().includes(editingComboTourBuses.Id)
      );

      if (comboTourBuses && comboTourBuses.length) {
        const c1Buses = comboTourBuses.filter(
          (bus) =>
            bus.dmuC_Router_Station?.dmuC_Router.localtionEndId ===
            Utils.HaGiangLocationId
        );
        const c2Buses = comboTourBuses.filter(
          (bus) =>
            bus.dmuC_Router_Station?.dmuC_Router.localtionStartId ===
            Utils.HaGiangLocationId
        );

        if (c1Buses && c1Buses.length) {
          const locationsSet = new Set(
            c1Buses.map(
              (bus) => bus.dmuC_Router_Station?.dmuC_Router.localtionStartId
            )
          );

          form.setFieldValue(
            FORM_NAMES.FROM_LOCATIONS,
            Array.from(locationsSet)
          );
          form.setFieldValue(
            FORM_NAMES.FROM_BUSES,
            Array.from(locationsSet).map((locationId) => ({
              locationId,
              routerStationBusIds: c1Buses
                .filter(
                  (bus) =>
                    locationId ===
                    bus.dmuC_Router_Station?.dmuC_Router.localtionStartId
                )
                .map((bus) => bus.id),
            }))
          );
        }

        if (c2Buses && c1Buses.length) {
          const locationsSet = new Set(
            c2Buses.map(
              (bus) => bus.dmuC_Router_Station?.dmuC_Router.localtionEndId
            )
          );
          form.setFieldValue(
            FORM_NAMES.END_LOCATIONS,
            Array.from(locationsSet)
          );
          form.setFieldValue(
            FORM_NAMES.END_BUSES,
            Array.from(locationsSet).map((locationId) => ({
              locationId,
              routerStationBusIds: c2Buses
                .filter(
                  (bus) =>
                    locationId ===
                    bus.dmuC_Router_Station?.dmuC_Router.localtionEndId
                )
                .map((bus) => bus.id),
            }))
          );
        }
      }
    }
  }, [editingComboTourBuses, form, selectRouterStationBuses]);

  useEffect(() => {
    const today = new Date();
    dispatch(fetchRouterStationBusData({ dateTime: today.toISOString() }));

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      labelAlign="left"
      className="noMargin_form-item"
    >
      <ComboTourBusForm
        title="Điểm đi"
        parentFormIntance={form}
        busesName={FORM_NAMES.FROM_BUSES}
        locationsName={FORM_NAMES.FROM_LOCATIONS}
        buses={fromBuses}
        locationIds={fromLocations}
        direction="toHaGiang"
        busOptions={selectRouterStationBuses}
      />
      <Divider />
      <ComboTourBusForm
        title="Điểm đến"
        parentFormIntance={form}
        busesName={FORM_NAMES.END_BUSES}
        locationsName={FORM_NAMES.END_LOCATIONS}
        buses={endBuses}
        locationIds={endLocations}
        direction="fromHaGiang"
        busOptions={selectRouterStationBuses}
      />
      {/* <div className={styles.spaceBetween} style={{ marginBottom: 16 }}>
        <Typography.Text strong>Điểm đi</Typography.Text>
        <Form.Item name={"fromLocations"}>
          <LocationSelect
            testId="locationSelect"
            multiple
            style={{ width: 200 }}
          />
        </Form.Item>
      </div>
      <Form.List name={"fromBuses"}>
        {(fields, { remove }) => (
          <div>
            <div className={`${styles.comboTourBusesContainer} customScroll`}>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} className={styles.comboTourBusFormItem}>
                  <Form.Item
                    {...restField}
                    name={[name, "locationId"]}
                    rules={[{ required: true, message: "Không được bỏ trống" }]}
                    style={{ flex: 1 }}
                  >
                    <LocationSelect testId="noTest" readOnly />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "routerStationBusId"]}
                    rules={[{ required: true, message: "Không được bỏ trống" }]}
                    style={{ flex: 1 }}
                  >
                    <BusSelect
                      testId="busSelect"
                      filterValue={{
                        locationFromId: fromBuses
                          ? fromBuses[name].locationId
                          : "",
                        locationToId: Utils.HaGiangLocationId,
                        date: null,
                        dockerId: editingComboTourBuses?.Id,
                      }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(name);
                      form.setFieldValue(
                        "fromLocations",
                        fromLocations?.filter((_, index) => index !== name)
                      );
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </Form.List> */}
    </Form>
  );
};

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  PaginationProps,
  Popconfirm,
  Table,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnsType } from "antd/es/table";
import { getAllJobRooms } from "api/jobItem.api";
import { IRoomTemplate } from "api/types/roomTemplate";
import Utils from "common/Utils";
import { IJobItem } from "common/define-types";
import { useEffect, useState } from "react";
import { deleteRoomTemplate, editRoomTemplate, fetchRoomTemplates, saveRoomTemplate } from "store/slice/RoomTemplateSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "../Room.module.css";
import { RoomForm } from "./RoomForm";
// import { BrokeMotorbikeCalendar } from "./BrokeMotorbikeCalendar";

export const RoomTable = () => {
  const [modalContent, setModalContent] = useState(<></>);
  const selectRooms = useSelectorRoot(
    (state) => state.roomTemplate.roomTemplates
  );
  const selectRoomTemplatesResult = useSelectorRoot(
    (state) => state.roomTemplate.roomTemplatesResult
  );
  const selectEditingRoomTemplate = useSelectorRoot(
    (state) => state.roomTemplate.editingRoomTemplate
  );
  const selectDeletingRoomTemplateId = useSelectorRoot(
    (state) => state.roomTemplate.deletingRoomTemplateId
  );
  const selectIsSubmitting = useSelectorRoot(
    (state) => state.roomTemplate.isSubmitting
  );
  const dispatch = useDispatchRoot();
  const [editRoomTemplateForm] = useForm();
  // const [brokeMotorbikeIdOpen, setBrokeMotorbikeOpen] = useState<string | null>(
  //   null
  // );
  const [sleepList, setSleepList] = useState<IJobItem[]>([]);
  useEffect(() => {
    // Thêm hàm để fetch danh sách địa điểm
    const fetchLocations = async () => {
      try {
        const response = await getAllJobRooms().toPromise(); // Convert Observable to Promise
        // Lưu danh sách địa điểm vào state
        setSleepList(response.results || []);
      } catch (error) {
        console.error("Error fetching locations:", error);
        // Xử lý lỗi nếu cần thiết
      }
    };
  
    // Gọi hàm để fetch danh sách địa điểm khi component được mount
    fetchLocations();
  }, []);
  const columns: ColumnsType<IRoomTemplate> = [
    {
      title: "Stt",
      dataIndex: "stt",
      render: (text, _, index) => (
        <>
          {index +
            (selectRoomTemplatesResult ? selectRoomTemplatesResult.firstRowIndex : 1)}
        </>
      ),
      width: '6%',
      align: "center",
    },
    {
      title: "Tên phòng",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name, "vi"),
        multiple: 3,
      },
      width: '15%',
    },
    {
      title: "Loại phòng",
      dataIndex: "type",
      sorter: {
        compare: (a, b) => a.type - b.type,
        multiple: 1,
      },
      width: '14%',
      render: (type) => {
        switch (type) {
          case 1:
            return 'Phòng đổi ngủ';
          case 0:
            return 'Phòng ngủ thêm đêm cuối';
          default:
            return 'Unknown Type';
        }
      },
    },
    {
      title: "Đêm áp dụng",
      dataIndex: "date",
      sorter: (a, b) => {
        const dateA = typeof a.date === "number" ? a.date : 0; // Nếu là số, sử dụng giá trị số, ngược lại sử dụng 0
        const dateB = typeof b.date === "number" ? b.date : 0; // Nếu là số, sử dụng giá trị số, ngược lại sử dụng 0
        
        // Sắp xếp theo số ngày
        return dateA - dateB;
      },  
      width: '12%',
      render: (_, record) => {
        if(record.date === -1) return <div>Đêm cuối</div>;
        return <div>Đêm {record.date}</div>;
      },
    },
    {
      title: "Điểm ngủ",
      dataIndex: "jobItemId",
      sorter: {
        compare: (a, b) => a.supplierId.localeCompare(b.jobItemId, "vi"),
        multiple: 3,
      },
      width: '13%',
      render: (jobItemId) => {
        const sleep = sleepList.find(sl => sl.id === jobItemId);
        return sleep ? sleep.name : "Jasmine";
      }
    },
    {
      title: "Giá bán",
      dataIndex: "price",
      sorter: (a, b) => (
        a.price - b.price
      ),
      width: '10%',
      ellipsis: true,
      render: (_, record) => {
        return (
          <>
            {record.price
              ? Utils.formatCurrency(record.price)
              : undefined}
          </>
        );
            }
    },
    {
      title: "Giá trả ncc",
      dataIndex: "moneyToPay",
      sorter: (a, b) => (
        a.moneyToPay - b.moneyToPay
      ),
      width: '10%',
      ellipsis: true,
      render: (_, record) => {
        return (
          <>
            {record.moneyToPay
              ? Utils.formatCurrency(record.moneyToPay)
              : undefined}
          </>
        );
            }
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      sorter: {
        compare: (a, b) =>
          (a.description || "").localeCompare(b.description || "", "vi"),
        multiple: 1,
      },
      width: '15%',
    },
    {
      title: "",
      dataIndex: "action",
      width: '10%',
      render: (_, record) => (
        <div className={styles.btnContainer}>
          <Button
            type="text"
            icon={<EditOutlined style={{ fontSize: 20, color: "#1890FF" }} />}
            onClick={() => handleEditRoomTemplate(record)}
          />
          <Popconfirm
            onConfirm={() => handleDeleteRoomTemplate(record.id)}
            title={"Xóa phòng"}
            description={"Bạn chắc chắn muốn xóa?"}
          >
            <Button
              type="text"
              icon={
                <DeleteOutlined style={{ fontSize: 20, color: "#F5222D" }} />
              }
              loading={
                selectDeletingRoomTemplateId !== null &&
                selectDeletingRoomTemplateId === record.id
              }
              disabled={
                selectDeletingRoomTemplateId !== null &&
                selectDeletingRoomTemplateId !== record.id
              }
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectRoomTemplatesResult) {
      dispatch(
        fetchRoomTemplates({
          ...selectRoomTemplatesResult,
          page,
        })
      );
    }
  };

  const handleEditRoomTemplate = (roomTemplate: IRoomTemplate) => {
    dispatch(editRoomTemplate(roomTemplate));
  };

  const handleSaveRoomTemplate = async () => {
    if (selectEditingRoomTemplate) {
      const roomValue = await editRoomTemplateForm
        .validateFields()
        .catch((err) => {
          console.log(err);
          notification.error({ message: "Vui lòng nhập đủ thông tin" });
        });
      if (!roomValue) return;
      dispatch(
        saveRoomTemplate({
          ...selectEditingRoomTemplate,
          ...roomValue,
        })
      );
    }
  };

  const handleDeleteRoomTemplate = async (motorbikeId: string) => {
    await dispatch(deleteRoomTemplate(motorbikeId));
    dispatch(fetchRoomTemplates({ page: 1 }));
  };

  useEffect(() => {
    if (selectEditingRoomTemplate) {
      editRoomTemplateForm.setFields(
        Utils.objectToAntFormData({
          ...selectEditingRoomTemplate,
        })
      );
    }
  }, [selectEditingRoomTemplate, editRoomTemplateForm]);
  // [2-4-2024][Duc Ngoc][update logic on RoomForm, disable jobItemId and type when editRoomTemplate ]
  useEffect(()=>{
    const f = async ()=>{
    console.log("room form::::: ",await  editRoomTemplateForm.getFieldsValue());

    }
    setModalContent(<RoomForm form={editRoomTemplateForm} editRoomTemplateForm={true} />);
    f()
  },[editRoomTemplateForm]);
  return (
    <>
      <Table
        dataSource={selectRooms}
        columns={columns}
        className={styles.table}
        rowKey={"id"}
        scroll={{ x: 550 }}
        pagination={{
          pageSize: selectRoomTemplatesResult
            ? selectRoomTemplatesResult.pageSize
            : 20,
          current: selectRoomTemplatesResult ? selectRoomTemplatesResult.page : 1,
          total: selectRoomTemplatesResult ? selectRoomTemplatesResult.queryCount : 0,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
      />
      {selectEditingRoomTemplate !== null ? (
        <Modal
          title={
            <Typography.Text strong style={{ fontSize: 20 }}>
              Sửa thông tin phòng
            </Typography.Text>
          }
          okText={"Cập nhật"}
          cancelText="Hủy bỏ"
          centered
          onOk={handleSaveRoomTemplate}
          open={selectEditingRoomTemplate !== null}
          onCancel={() => dispatch(editRoomTemplate(null))}
          destroyOnClose={true}
          okButtonProps={{
            loading: selectIsSubmitting,
          }}
        >
          {modalContent}
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

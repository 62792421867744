import { CategoryTableLayout } from 'components/Layout/CategoryTableLayout';
import styles from './ComboTourCategory.module.css';
import {
  IComboTour,
  ITicket_ExtraService,
  IsDriverType,
  SelfMotor,
} from 'common/define-types';
// import { PaginationProps } from "antd";
import { ColumnsType } from 'antd/es/table';
import Utils from 'common/Utils';
import { useDispatchRoot, useSelectorRoot } from 'store/store';
import { useEffect } from 'react';
import {
  addComboTour,
  deleteComboTour,
  editComboTour,
  editComboTourBuses,
  fetchComboTourCategorys,
  saveComboTour,
  saveComboTourBuses,
  setAddModalOpen,
  setErrMsg,
} from 'store/slice/ComboTourCategorySlice';
import { useForm } from 'antd/es/form/Form';
import {
  Button,
  Dropdown,
  MenuProps,
  Modal,
  PaginationProps,
  Typography,
  notification,
} from 'antd';
import {
  DashOutlined,
  EditOutlined,
  DeleteOutlined,
  CarOutlined,
} from '@ant-design/icons';
import { ComboTourForm } from './components/ComboTourForm';
import { fetchExtraService, fetchTours } from 'store/slice/BookingSlice';
import { fetchRoomTemplates } from 'store/slice/RoomTemplateSlice';
import { fetchHangHoaMotorbikes } from 'store/slice/HangHoaSlice';
import { ComboTourBusesForm } from './components/ComboTourBusesForm';
import { fetchRouterStationBusData } from 'store/slice/RouterStationBus';

export const ComboTourCategory = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [comboTourForm] = useForm();
  const [comboTourBusesForm] = useForm();
  const [extraServiceForm] = useForm();
  const selectIsLoginSuccess = useSelectorRoot(
    (state) => state.login.isSuccess
  );
  const selectIsRefreshing = useSelectorRoot(
    (state) => state.login.isRefreshing
  );
  const addModalOpen = useSelectorRoot(
    (state) => state.comboTourCategory.addModalOpen
  );
  const selectRoomTemplates = useSelectorRoot(
    (state) => state.roomTemplate.roomTemplates
  );
  const selectRouterStationBuses = useSelectorRoot(
    (state) => state.routerStationBus.routerStationBuses
  );
  const selectEditingComboTour = useSelectorRoot(
    (state) => state.comboTourCategory.editingComboTour
  );
  const selectEditingComboTourBuses = useSelectorRoot(
    (state) => state.comboTourCategory.editingComboTourBuses
  );
  const selectErrMsg = useSelectorRoot(
    (state) => state.comboTourCategory.errMsg
  );
  const dispatch = useDispatchRoot();

  const openAddModal = () => dispatch(setAddModalOpen(true));
  const closeAddModal = () => dispatch(setAddModalOpen(false));
  const handleAddComboTour = async () => {
    const comboTourValue = await comboTourForm
      .validateFields()
      .catch((err) => console.log(err));
    const extraServiceFormValue = await extraServiceForm
      .validateFields()
      .catch((err) => console.log(err));
    if (!comboTourValue) return;
    let hangHoaIds: string[] = [];
    let comboPrice = 0;
    if (
      extraServiceFormValue &&
      extraServiceFormValue.datA_TOUR_CHHangHoaDichVuKhacs?.length
    ) {
      extraServiceFormValue.datA_TOUR_CHHangHoaDichVuKhacs.forEach(
        (extraServiceValue: ITicket_ExtraService) => {
          if (extraServiceValue.dmuC_HangHoaId) {
            hangHoaIds.push(extraServiceValue.dmuC_HangHoaId);
            comboPrice += extraServiceValue.price;
          }
          if (extraServiceValue.roomTemplateIds?.length) {
            hangHoaIds = [...hangHoaIds, ...extraServiceValue.roomTemplateIds];
            selectRoomTemplates.forEach((room) => {
              if (extraServiceValue.roomTemplateIds?.includes(room.id)) {
                comboPrice += room.price;
              }
            });
          }
        }
      );
    }

    if (comboTourValue.fromBusId) {
      const foundFromBus = selectRouterStationBuses.find(
        (bus) => bus.id === comboTourValue.fromBusId
      );
      if (foundFromBus) {
        comboPrice += foundFromBus.price;
      }
    }

    if (comboTourValue.endBusId) {
      const foundEndBus = selectRouterStationBuses.find(
        (bus) => bus.id === comboTourValue.endBusId
      );
      if (foundEndBus) {
        comboPrice += foundEndBus.price;
      }
    }

    const payload = {
      comboTourDto: {
        code: comboTourValue.Code,
        name: comboTourValue.Name,
        title: comboTourValue.Title,
        giaHienThi: comboTourValue.GiaHienThi,
        price: parseInt(comboTourValue.GiaHienThi) - comboPrice,
      },
      tourId: comboTourValue.TourId,
      isDriver: comboTourValue.IsDriver === IsDriverType.YES,
      isMotor: comboTourValue.SelfMotor === SelfMotor.NO,
      priceTour: parseInt(comboTourValue.GiaHienThi) - comboPrice,
      routerBusId1: comboTourValue.fromBusId,
      routerBusId2: comboTourValue.endBusId,
      isBus2Way: !!comboTourValue.fromBusId && !!comboTourValue.endBusId,
      hangHoaIds,
    };
    dispatch(addComboTour(payload));
  };

  const openEditModal = (comboTour: IComboTour) =>
    dispatch(editComboTour(comboTour));
  const closeEditModal = () => dispatch(editComboTour(null));
  const handleSaveComboTour = async () => {
    if (!selectEditingComboTour) return;
    const comboTourValue = await comboTourForm
      .validateFields()
      .catch((err) => console.log(err));
    const extraServiceFormValue = await extraServiceForm
      .validateFields()
      .catch((err) => console.log(err));

    if (!comboTourValue) return;
    let hangHoaIds: string[] = [];
    let comboPrice = 0;
    if (
      extraServiceFormValue &&
      extraServiceFormValue.datA_TOUR_CHHangHoaDichVuKhacs?.length
    ) {
      extraServiceFormValue.datA_TOUR_CHHangHoaDichVuKhacs.forEach(
        (extraServiceValue: ITicket_ExtraService) => {
          if (extraServiceValue.dmuC_HangHoaId) {
            hangHoaIds.push(extraServiceValue.dmuC_HangHoaId);
            comboPrice += extraServiceValue.price;
          }
          if (extraServiceValue.roomTemplateIds?.length) {
            hangHoaIds = [...hangHoaIds, ...extraServiceValue.roomTemplateIds];
            selectRoomTemplates.forEach((room) => {
              if (extraServiceValue.roomTemplateIds?.includes(room.id)) {
                comboPrice += room.price;
              }
            });
          }
        }
      );
    }

    if (comboTourValue.fromBusId) {
      const foundFromBus = selectRouterStationBuses.find(
        (bus) => bus.id === comboTourValue.fromBusId
      );
      if (foundFromBus) {
        comboPrice += foundFromBus.price;
      }
    }

    if (comboTourValue.endBusId) {
      const foundEndBus = selectRouterStationBuses.find(
        (bus) => bus.id === comboTourValue.endBusId
      );
      if (foundEndBus) {
        comboPrice += foundEndBus.price;
      }
    }

    const payload = {
      comboTourDto: {
        code: comboTourValue.Code,
        name: comboTourValue.Name,
        title: comboTourValue.Title,
        giaHienThi: comboTourValue.GiaHienThi,
        price: parseInt(comboTourValue.GiaHienThi) - comboPrice,
      },
      tourId: comboTourValue.TourId,
      isDriver: comboTourValue.IsDriver === IsDriverType.YES,
      isMotor: comboTourValue.SelfMotor === SelfMotor.NO,
      priceTour: parseInt(comboTourValue.GiaHienThi) - comboPrice,
      routerBusId1: comboTourValue.fromBusId,
      routerBusId2: comboTourValue.endBusId,
      isBus2Way: !!comboTourValue.fromBusId && !!comboTourValue.endBusId,
      hangHoaIds,
      comboTourId: selectEditingComboTour.Id,
    };

    dispatch(saveComboTour(payload));
  };

  const openEditBusesModal = (comboTour: IComboTour) =>
    dispatch(editComboTourBuses(comboTour));
  const closeEditBusesModal = () => dispatch(editComboTourBuses(null));
  const handleSaveComboTourBuses = async () => {
    const comboTourBusesValue = await comboTourBusesForm
      .validateFields()
      .catch((err) => console.log(err));
    if (!comboTourBusesValue || !selectEditingComboTourBuses) return;

    let busIds: string[] = [];
    if (comboTourBusesValue.fromBuses?.length) {
      const fromBusesIds = comboTourBusesValue.fromBuses.reduce(
        (
          prev: string[],
          bus: { locationId: string; routerStationBusIds: string[] }
        ) => [...prev, ...bus.routerStationBusIds],
        []
      );
      busIds = [...busIds, ...fromBusesIds];
    }
    if (comboTourBusesValue.endBuses?.length) {
      const fromBusesIds = comboTourBusesValue.endBuses.reduce(
        (
          prev: string[],
          bus: { locationId: string; routerStationBusIds: string[] }
        ) => [...prev, ...bus.routerStationBusIds],
        []
      );
      busIds = [...busIds, ...fromBusesIds];
    }

    dispatch(
      saveComboTourBuses({
        comboTourId: selectEditingComboTourBuses.Id,
        busIds,
      })
    );
  };

  const handleDeleteComboTour = (record: IComboTour) => {
    dispatch(deleteComboTour(record.Id));
  };

  const getActionsMenu = (record: IComboTour): MenuProps['items'] => {
    return [
      {
        key: 'addBus',
        label: 'Tuyến xe bus',
        icon: <CarOutlined style={{ color: '#182aff' }} />,
        onClick: () => openEditBusesModal(record),
      },
      {
        key: 'edit',
        label: 'Chỉnh sửa',
        icon: <EditOutlined style={{ color: '#1890ff' }} />,
        onClick: () => openEditModal(record),
      },
      {
        key: 'delete',
        label: 'Xóa',
        icon: <DeleteOutlined style={{ color: 'red' }} />,
        onClick: () =>
          modal.confirm({
            title: 'Xóa tour',
            content: <>{`Xác nhận xóa ${record.Title} ?`}</>,
            onOk: () => handleDeleteComboTour(record),
            centered: true,
          }),
      },
    ];
  };

  const columns: ColumnsType<IComboTour> = [
    {
      title: 'Stt',
      dataIndex: 'stt',
      render: (text, _, index) => (
        <>{(selectComboToursResult?.firstRowIndex || 1) + index}</>
      ),
      width: 40,
      align: 'center',
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'Title',
      width: 120,
      ellipsis: true,
    },
    {
      title: 'Loại lái xe',
      dataIndex: 'SubTitle',
      width: 80,
    },
    {
      title: 'Loại xe',
      dataIndex: 'Description',
      width: 80,
    },
    {
      title: 'Giá hiển thị',
      dataIndex: 'GiaHienThi',
      width: 80,
      render(value) {
        return <>{Utils.formatCurrency(value)}</>;
      },
    },
    {
      dataIndex: 'actions',
      width: 30,
      align: 'center',
      fixed: 'right',
      render(_, record) {
        return record ? (
          <Dropdown
            menu={{ items: getActionsMenu(record) }}
            trigger={['click']}
            arrow
          >
            <Button type='text' icon={<DashOutlined />} />
          </Dropdown>
        ) : (
          <></>
        );
      },
    },
  ];

  const selectComboTours = useSelectorRoot(
    (state) => state.comboTourCategory.comboTours
  );
  const isLoading = useSelectorRoot(
    (state) => state.comboTourCategory.isLoading
  );
  const isSubmitting = useSelectorRoot(
    (state) => state.comboTourCategory.isSubmitting
  );
  const selectComboToursResult = useSelectorRoot(
    (state) => state.comboTourCategory.comboToursResult
  );
  const handleChangePage: PaginationProps['onChange'] = (page) => {
    if (selectComboToursResult) {
      dispatch(
        fetchComboTourCategorys({
          ...selectComboToursResult,
          page,
        })
      );
    }
  };

  useEffect(() => {
    if (selectIsLoginSuccess && !selectIsRefreshing) {
      const today = new Date();
      dispatch(fetchComboTourCategorys({ pageSize: 25 }));
      dispatch(fetchTours());
      dispatch(fetchRoomTemplates());
      dispatch(fetchHangHoaMotorbikes({}));
      dispatch(fetchExtraService());
      dispatch(fetchRouterStationBusData({ dateTime: today.toISOString() }));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [selectIsLoginSuccess, selectIsRefreshing]);

  useEffect(() => {
    if (selectErrMsg) {
      notification.error({
        message: selectErrMsg,
      });
      dispatch(setErrMsg(null));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [selectErrMsg]);

  return (
    <div className={styles.container}>
      {contextHolder}
      <CategoryTableLayout
        title='tour'
        dataSource={selectComboTours}
        pagination={selectComboToursResult || undefined}
        columns={columns}
        loading={isLoading}
        rowKey={'Id'}
        onAddClick={openAddModal}
        onChangePagination={handleChangePage}
      />

      {/* add form */}
      <Modal
        open={addModalOpen}
        onCancel={closeAddModal}
        onOk={handleAddComboTour}
        okText='Lưu'
        cancelText='Hủy'
        destroyOnClose
        centered
        confirmLoading={isSubmitting}
        title={
          // [2-4-2024][Phuc Thinh][Increase modal title font size]
          <Typography.Text strong style={{ fontSize: 20 }}>
            {'Thêm tour'}
          </Typography.Text>
        }
        afterClose={() => {
          comboTourForm.resetFields();
          extraServiceForm.resetFields();
        }}
      >
        <div
          className={`${styles.modalWrapper} customScroll`}
          style={{ paddingBlock: 10 }}
        >
          <ComboTourForm forms={{ comboTourForm, extraServiceForm }} />
        </div>
      </Modal>

      {/* edit form */}
      <Modal
        open={!!selectEditingComboTour}
        onCancel={closeEditModal}
        onOk={handleSaveComboTour}
        okText='Lưu'
        cancelText='Hủy'
        destroyOnClose
        centered
        confirmLoading={isSubmitting}
        title={
          // [2-4-2024][Phuc Thinh][Increase modal title font size]
          <Typography.Text strong style={{ fontSize: 20 }}>
            {'Sửa tour'}
          </Typography.Text>
        }
        afterClose={() => {
          comboTourForm.resetFields();
          extraServiceForm.resetFields();
        }}
      >
        <div className={`${styles.modalWrapper} customScroll`}>
          <ComboTourForm
            forms={{ comboTourForm, extraServiceForm }}
            editingComboTour={selectEditingComboTour}
          />
        </div>
      </Modal>

      {/* edit buses form */}
      <Modal
        open={!!selectEditingComboTourBuses}
        onCancel={closeEditBusesModal}
        onOk={handleSaveComboTourBuses}
        okText='Lưu'
        cancelText='Hủy'
        destroyOnClose
        centered
        confirmLoading={isSubmitting}
        title={`Tuyến xe của tour ${selectEditingComboTourBuses?.Title}`}
        afterClose={() => {
          comboTourBusesForm.resetFields();
        }}
        width={'clamp(300px, 95%, 650px)'}
      >
        <div className={`${styles.modalWrapper} customScroll`}>
          <ComboTourBusesForm
            form={comboTourBusesForm}
            editingComboTourBuses={selectEditingComboTourBuses}
          />
        </div>
      </Modal>
    </div>
  );
};

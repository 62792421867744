import { Form, FormInstance, Input } from "antd";
import styles from "../StationCategory.module.css";
import Utils from "common/Utils";
import { useEffect } from "react";
import { IStation } from "common/define-types";

interface IProps {
  form?: FormInstance<any>;
  editingStation?: IStation;
}
export const StationForm = ({ form, editingStation }: IProps) => {
  const [defaultForm] = Form.useForm();
  const stationForm = form || defaultForm;

  useEffect(() => {
    if (editingStation) {
      stationForm.setFields(Utils.objectToAntFormData(editingStation));
    } else {
      stationForm.resetFields();
    }
  }, [editingStation, stationForm]);

  return (
    <Form
      name="station"
      form={stationForm}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      className="noMargin_form-item"
    >
      <Form.Item
        label={"Mã nhà xe"}
        name={"code"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input disabled={!!editingStation} />
      </Form.Item>
      <Form.Item
        label={"Tên nhà xe"}
        name={"name"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <div className={styles.spaceBetween} style={{ gap: 8 }}>
        <Form.Item label={"Số điện thoại"} name={"phone"} style={{ flex: 1 }}>
          <Input />
        </Form.Item>
        <Form.Item label={"Địa chỉ"} name={"address"} style={{ flex: 1 }}>
          <Input />
        </Form.Item>
      </div>

      <Form.Item label={"Mô tả"} name={"description"}>
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
};

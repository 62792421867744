import { IBooking, PaymentMethodBookingStatus } from "common/define-types";
import styles from "../BookingRevenue.module.css";
import { EditOutlined, DeleteOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Typography, notification } from "antd";
import moment from "moment";
import TicketIcon from "image/icon/ticket-icon.svg";
import Utils from "common/Utils";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import {
  deleteBooking,
  setBookingSelected,
} from "store/slice/BookingRevenueSlice";
import { editBooking } from "store/slice/BookingSlice";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { IUpdatePaymentMethodBooking } from "api/types/booking";
import { PaymentDetailModal } from "pages/Booking/components/Billing/PaymentDetailModal";
import React from "react";

interface IProps {
  booking: IBooking;
  isActive?: boolean;
  index?: number;
  controls?: boolean;
}
export const BookingItem = React.forwardRef<HTMLDivElement, IProps>(
  ({ booking, isActive = false, index = 1, controls = true }, ref) => {
    const [refundModalOpen, setRefundModalOpen] = useState(false);
    const dispatch = useDispatchRoot();
    const selectIsBookingIdDeleting = useSelectorRoot(
      (state) => state.bookingRevenue.deletingBookingId
    );
    const selectBookingResult = useSelectorRoot(
      (state) => state.bookingRevenue.bookingsResult
    );
    const hasOncomingTicket = booking.tickets
      .filter((ticket) => ticket.isErase !== true)
      .some((ticket) => Utils.checkOncomingTicket(ticket));
    const hasDepartedTicket = booking.tickets
      .filter((ticket) => ticket.isErase !== true)
      .some((ticket) => Utils.checkDepartedTicket(ticket));
    const hasMoneyJasmineKeep =
      booking.moneyJasmineKeep > 0 ||
      booking.paymentMethodBookings
        .filter(
          (paymethod) => paymethod.status === PaymentMethodBookingStatus.Pay
        )
        .some(
          (paymethod) =>
            // [4-12-2023] [Phuc Thinh] [Update paymentMethodBooking interface]
            paymethod.paymentCode === "90015"
        );
    const isAllTicketDeparted = booking.tickets
      .filter((ticket) => ticket.isErase !== true)
      .every((ticket) => Utils.checkDepartedTicket(ticket));
    const history = useHistory();

    const handleSelectBooking = () => {
      dispatch(setBookingSelected(booking));
    };

    const handleDeleteBooking = (
      paymentMethodBookingsConnect?: IUpdatePaymentMethodBooking[],
      paymentMethodBookingsDisable?: IUpdatePaymentMethodBooking[]
    ) => {
      dispatch(
        deleteBooking({
          bookingId: booking.id,
          paymentMethodBookings: paymentMethodBookingsDisable,
        })
      );
      setRefundModalOpen(false);
    };

    const handleEditBooking = (e: any) => {
      e.stopPropagation();
      dispatch(editBooking(booking));
      history.push("/tours");
    };

    const renderHighlightText = (
      input: string,
      search: string | undefined,
      highlightColor = "#bd4840"
    ) => {
      if (search && input) {
        const index = input.toLowerCase().indexOf(search.toLowerCase());
        if (index >= 0) {
          return (
            <Typography.Text strong>
              {`${input.substring(0, index)}`}
              <Typography.Text strong style={{ color: highlightColor }}>
                {input.substring(index, index + search.length)}
              </Typography.Text>
              {input.substring(index + search.length)}
            </Typography.Text>
          );
        }
      }
      return <Typography.Text strong>{input}</Typography.Text>;
    };

    return (
      <div
        className={`${styles.bookingItem} ${
          isActive ? styles.bookingSelected : ""
        }`}
        onClick={handleSelectBooking}
        ref={ref}
        aria-selected={isActive}
      >
        {refundModalOpen ? (
          <PaymentDetailModal
            open={refundModalOpen}
            handleClose={() => setRefundModalOpen(false)}
            isRefundModal={true}
            total={
              Utils.calculateTotalBookingPrice(booking, true) +
              Utils.calculateBookingBusTotal(booking, true) +
              Utils.calculateBookingExtraServiceTotal(booking, true) -
              Utils.getBookingPayment(booking.paymentMethodBookings).jasmineFee
            }
            handleSubmit={handleDeleteBooking}
          />
        ) : (
          <></>
        )}
        <div className={styles.bookingItem_wrapper}>
          <Typography.Text strong style={{ fontSize: 20, color: "#1890FF" }}>
            {booking.code
              ? `${booking.code}`
              : `Booking_${moment
                  .utc(booking.createdTime)
                  .local()
                  .format("DDMMYYYY")}_${index}`}
          </Typography.Text>
          <div className={styles.textWithIcon}>
            <img src={TicketIcon} alt="ticket" />
            <Typography.Text strong>
              {`  Số lượng khách: ${
                booking.tickets.filter((ticket) => ticket.isErase !== true)
                  .length || 0
              }`}
            </Typography.Text>
          </div>
          <div className={styles.spaceBetween}>
            {renderHighlightText(
              booking.userPayName,
              selectBookingResult?.search
            )}
            {/* <Typography.Text strong>{booking.userPayName}</Typography.Text> */}
            <div className={styles.textWithIcon}>
              <PhoneOutlined />
              {renderHighlightText(
                booking.userPayPhone,
                selectBookingResult?.search
              )}
            </div>
          </div>
          {renderHighlightText(
            booking.userPayEmail,
            selectBookingResult?.search
          )}
          <div className={styles.spaceBetween}>
            <Typography.Text strong>{`Tổng tiền: ${Utils.formatCurrency(
              Utils.calculateTotalBookingPrice(booking) +
                Utils.calculateBookingBusTotal(booking) +
                Utils.calculateBookingExtraServiceTotal(booking)
            )}`}</Typography.Text>
            {/* <Typography.Text
            strong
            style={{ textAlign: "right", color: "red" }}
          >{`Khách nợ: ${Utils.formatCurrency(
            booking.paymentMethodBookings.find(
              (method) => method.paymethod?.code === "90008"
            )
              ? booking.paymentMethodBookings.find(
                  (method) => method.paymethod?.code === "90008"
                )?.amount
              : 0
          )}`}</Typography.Text> */}
          </div>
        </div>
        {controls && (
          <div className={styles.btnContainer}>
            <Button
              icon={
                <EditOutlined
                  style={{
                    fontSize: 20,
                    color:
                      booking.isErase || isAllTicketDeparted
                        ? "grey"
                        : "#1890FF",
                  }}
                />
              }
              type="text"
              onClick={handleEditBooking}
              disabled={booking.isErase || isAllTicketDeparted}
            />
            {hasOncomingTicket && hasMoneyJasmineKeep ? (
              <Button
                icon={
                  <DeleteOutlined
                    style={{
                      fontSize: 20,
                      color:
                        booking.isErase || hasDepartedTicket ? "grey" : "red",
                    }}
                  />
                }
                type="text"
                loading={selectIsBookingIdDeleting === booking.id}
                onClick={() =>
                  notification.info({
                    message:
                      "Booking chứa vé khởi hành sát giờ và còn tiền Jasmine thu hộ nên không thể xóa",
                  })
                }
              />
            ) : (
              <Popconfirm
                title="Xóa booking"
                description={
                  hasOncomingTicket
                    ? "Booking chứa vé khởi hành sát giờ, nếu xóa sẽ chịu phí phạt 20% tiền vé, bus, dịch vụ thêm"
                    : "Bạn chắc chắc muốn xóa booking này ?"
                }
                onConfirm={() => setRefundModalOpen(true)}
                disabled={
                  (selectIsBookingIdDeleting !== null &&
                    selectIsBookingIdDeleting !== booking.id) ||
                  booking.isErase ||
                  hasDepartedTicket
                }
              >
                <Button
                  icon={
                    <DeleteOutlined
                      style={{
                        fontSize: 20,
                        color:
                          booking.isErase || hasDepartedTicket ? "grey" : "red",
                      }}
                    />
                  }
                  type="text"
                  loading={selectIsBookingIdDeleting === booking.id}
                  disabled={
                    (selectIsBookingIdDeleting !== null &&
                      selectIsBookingIdDeleting !== booking.id) ||
                    booking.isErase ||
                    hasDepartedTicket
                  }
                />
              </Popconfirm>
            )}
          </div>
        )}
      </div>
    );
  }
);

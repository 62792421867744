import { PrinterOutlined } from "@ant-design/icons";
import { Button, Typography, notification } from "antd";
import { useEffect, useState } from "react";
import {
    clearFilterValue,
    fetchOperatorTickets,
    setErrMsg,
} from "store/slice/TIcketOperatorSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import PrintTicketModal from "./components/PrintTicketModal";
import TicketFilterBar from "./components/TicketFilterBar";
import TicketOperatorTable from "./components/TicketOperatorTable";
import styles from "./TicketOperator.module.css";

const TicketOperator = () => {
  const [printModalOpen, setPrintModalOpen] = useState(false);

  const dispatch = useDispatchRoot();
  const selectErrMsg = useSelectorRoot((state) => state.operatorTicket.errMsg);

  useEffect(() => {
    dispatch(clearFilterValue());
    dispatch(fetchOperatorTickets());
  }, [dispatch]);

  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({ message: selectErrMsg });
      dispatch(setErrMsg(null));
    }
  }, [selectErrMsg, dispatch]);

  return (
    <div className={styles.ticketOperatorContainer}>
      {/* [7-12-2023][Phuc Thinh][Feature/14836]
      create Modal for Export QuanLyTickets */}
      {printModalOpen && (
        <PrintTicketModal
          open={printModalOpen}
          handleClose={() => setPrintModalOpen(false)}
        />
      )}
      <div className={styles.ticketOperatorContainer_wrapper}>
        <Typography.Text className={styles.title}>Quản lý vé</Typography.Text>
        <Button
          type="primary"
          icon={<PrinterOutlined />}
          onClick={() => setPrintModalOpen(true)}
        >
          Tải danh sách vé
        </Button>
      </div>
      {/* [06-12-2023][Phuc Thinh]
            Refactor filter bar into component */}
      <TicketFilterBar />
      <TicketOperatorTable />
    </div>
  );
};

export default TicketOperator;

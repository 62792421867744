import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";
import { firebaseConfig } from "./firebaseConfig";

const fireBaseApp = initializeApp(firebaseConfig);

let messaging = getMessaging(fireBaseApp);

export const getMessagingToken = async (callback?: (token: string) => void) => {
  let token = "";
  const isMessagingSupported = isSupported();
  if (!messaging || !isMessagingSupported) return;
  getToken(messaging)
    .then((currentToken) => {
      if (currentToken) {
        token = currentToken;
        console.log("FCM registration token", currentToken);
        callback && callback(currentToken);
        //   updateUIForPushEnabled(currentToken);
      } else {
        // Show permission request.
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // Show permission UI.
        //   updateUIForPushPermissionRequired();
        //   setTokenSentToServer(false);
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // showToken('Error retrieving registration token.');
      // setTokenSentToServer(false);
    });
  return token;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createRoomTemplate,
  deleteRoomTemplateById,
  getAllRoomTemplatesPageable,
  updateRoomTemplate
} from "api/roomTemplate.api";
import {
  ICreateRoomTemplate,
  IRoomTemplate,
  IRoomTemplateFilterValue,
  IRoomTemplatesResult,
  IUpdateRoomTemplate,
} from "api/types/roomTemplate";
import { IParams, RootEpic } from "common/define-types";
import { catchError, filter, mergeMap, switchMap } from "rxjs";
import { AjaxError } from "rxjs/ajax";

export interface RoomTemplateState {
  isLoading: boolean;
  roomTemplates: IRoomTemplate[] | [];
  errMsg: string | null;
  isSubmitting: boolean;
  roomTemplatesResult: IRoomTemplatesResult | null;
  addModalOpen: boolean;
  deletingRoomTemplateId: string | null;
  editingRoomTemplate: IRoomTemplate | null;
  filterValue: IRoomTemplateFilterValue | null;
}

const initialState: RoomTemplateState = {
  isLoading: false,
  roomTemplates: [],
  isSubmitting: false,
  errMsg: null,
  roomTemplatesResult: null,
  addModalOpen: false,
  deletingRoomTemplateId: null,
  editingRoomTemplate: null,
  filterValue: {
    search: "",
  },
};

export const roomTemplateSlice = createSlice({
  name: "roomTemplate",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchRoomTemplates: (state, action: PayloadAction<IParams | undefined>) => {
      state.isLoading = true;
      state.isSubmitting = false;
      state.addModalOpen = false;
      state.editingRoomTemplate = null;
      state.deletingRoomTemplateId = null;
      state.errMsg = null;
    },
    setRoomTemplates: (state, action: PayloadAction<IRoomTemplate[]>) => {
      state.roomTemplates = action.payload;
      state.isLoading = false;
    },
    setErrMsg: (state, action: PayloadAction<string>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
    setroomTemplateResult: (
      state,
      action: PayloadAction<IRoomTemplatesResult>
    ) => {
      state.roomTemplatesResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    editRoomTemplate: (state, action: PayloadAction<IRoomTemplate | null>) => {
      state.editingRoomTemplate = action.payload;
    },
    addRoomTemplate: (state, action: PayloadAction<ICreateRoomTemplate>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    deleteRoomTemplate: (state, action: PayloadAction<string>) => {
      state.deletingRoomTemplateId = action.payload;
      state.errMsg = null;
    },
    setAddModalOpen: (state, action: PayloadAction<boolean>) => {
      state.addModalOpen = action.payload;
    },
    saveRoomTemplate: (state, action: PayloadAction<IUpdateRoomTemplate>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    setRoomTemplateFilterValue: (
      state,
      action: PayloadAction<IRoomTemplateFilterValue>
    ) => {
      state.filterValue = {
        ...state.filterValue,
        ...action.payload,
      };
    },
  },
});
const getRoomTemplates$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchRoomTemplates.match),
    switchMap((re) => {
      return getAllRoomTemplatesPageable().pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const roomTemplates = res.results;
            return [
              roomTemplateSlice.actions.setRoomTemplates(roomTemplates ?? []),
            ];
          } else {
            return [roomTemplateSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          roomTemplateSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách phòng ngủ"
          ),
        ])
      );
    })
  );
const getRoomTemplateWhenFilter$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setRoomTemplateFilterValue.match),
    switchMap((re) => {
      return getAllRoomTemplatesPageable({
        // ...state$.value.RoomTemplate.roomTemplateResult,
        ...re.payload,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const RoomTemplate = res.results;
            return [
              roomTemplateSlice.actions.setRoomTemplates(RoomTemplate ?? []),
              roomTemplateSlice.actions.setroomTemplateResult(res),
            ];
          } else {
            return [roomTemplateSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          roomTemplateSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách phòng ngủ"
          ),
        ])
      );
    })
  );
const addRoomTemplate$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addRoomTemplate.match),
    switchMap((re) => {
      const payloadWithDefaultDate = {
        ...re.payload,
        date: re.payload.date || -1,
      };
      return createRoomTemplate([payloadWithDefaultDate]).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.roomTemplate.roomTemplatesResult) {
              return [
                roomTemplateSlice.actions.fetchRoomTemplates(
                  state$.value.roomTemplate.roomTemplatesResult
                ),
              ];
            }
            return [roomTemplateSlice.actions.fetchRoomTemplates()];
          } else {
            return [roomTemplateSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          roomTemplateSlice.actions.setErrMsg("Có lỗi xảy ra khi thêm phòng"),
        ])
      );
    })
  );
const saveRoomTemplate$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveRoomTemplate.match),
    switchMap((re) => {
      const payloadWithDefaultDate = {
        ...re.payload,
        date: re.payload.date || -1,
      };
      return updateRoomTemplate(payloadWithDefaultDate).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.roomTemplate.roomTemplatesResult) {
              return [
                roomTemplateSlice.actions.fetchRoomTemplates(
                  state$.value.roomTemplate.roomTemplatesResult
                ),
              ];
            }
            return [roomTemplateSlice.actions.fetchRoomTemplates()];
          } else {
            return [roomTemplateSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          roomTemplateSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lưu thông tin phòng"
          ),
        ])
      );
    })
  );
const deleteRoomTemplate$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteRoomTemplate.match),
    switchMap((re) => {
      return deleteRoomTemplateById(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.roomTemplate.roomTemplatesResult) {
              return [
                roomTemplateSlice.actions.fetchRoomTemplates(
                  state$.value.roomTemplate.roomTemplatesResult
                ),
              ];
            }
            return [roomTemplateSlice.actions.fetchRoomTemplates()];
          } else {
            return [roomTemplateSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          roomTemplateSlice.actions.setErrMsg("Có lỗi xảy ra khi xóa phòng"),
        ])
      );
    })
  );

export const {
  fetchRoomTemplates,
  setRoomTemplates,
  setErrMsg,
  setRoomTemplateFilterValue,
  deleteRoomTemplate,
  saveRoomTemplate,
  addRoomTemplate,
  editRoomTemplate,
  setAddModalOpen
} = roomTemplateSlice.actions;

export const RoomTemplateEpics = [
  getRoomTemplates$,
  getRoomTemplateWhenFilter$,
  addRoomTemplate$,
  saveRoomTemplate$,
  deleteRoomTemplate$,
];

export const roomTemplateReducer = roomTemplateSlice.reducer;

import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import { ICreateStation, IParams, IUpdateStation } from "common/define-types";
import Utils from "common/Utils";

const api = `${SYSTEM_CONSTANTS.API_URL}/Station`;

export const getAllStations = (params?: IParams): Observable<any> => {
  return HttpClient.get(`${api}?${Utils.getParamsString(params || {})}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getAllStationsPageable = (params?: IParams): Observable<any> => {
  return HttpClient.get(
    `${api}/allStation?${Utils.getParamsString(params || {})}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const createStation = (body: ICreateStation[]): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updateStation = (body: IUpdateStation): Observable<any> => {
  return HttpClient.put(`${api}?id=${body.id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const removeStation = (stationId: string): Observable<any> => {
  return HttpClient.delete(`${api}/${stationId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

import { Form, FormInstance, Input, Select } from "antd";
import { getAllJobRooms } from "api/jobItem.api";
import { IJobItem } from "common/define-types";
import { useEffect, useState } from "react";
import styles from "../Room.module.css";
  // [2-4-2024][Duc Ngoc][update logic on RoomForm, hide type when edit RoomTemplate ]
interface IProps {
  form: FormInstance<any>;
  editRoomTemplateForm?: boolean;
}
export const RoomForm = ({ form, editRoomTemplateForm = false }: IProps) => {
  const [sleepList, setSleepList] = useState<IJobItem[]>([]);
  const typeList = [
    { id: 1, name: "Phòng đổi ngủ" },
    { id: 0, name: "Phòng ngủ thêm đêm cuối" },
  ];

  useEffect(() => {
    // Thêm hàm để fetch danh sách địa điểm
    const fetchLocations = async () => {
      try {
        const response = await getAllJobRooms().toPromise(); // Convert Observable to Promise
        // Lưu danh sách địa điểm vào state
        setSleepList(response.results || []);
      } catch (error) {
        console.error("Error fetching locations:", error);
        // Xử lý lỗi nếu cần thiết
      }
    };

    // Gọi hàm để fetch danh sách địa điểm khi component được mount
    fetchLocations();
  }, []);

  const roomType = Form.useWatch("type", form);

  const handleRoomTypeChange = (value: number) => {
    form.setFieldValue("date", value === 0 ? -1 : null);
  };

  return (
    <Form labelCol={{ span: 24 }} form={form}>
      <Form.Item
        label={"Loại phòng"}
        name={"type"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Select onChange={handleRoomTypeChange} disabled={editRoomTemplateForm}>
          {typeList.map((type) => (
            <Select.Option key={type.id} value={type.id} initialValue={roomType === 0 ? 'Jasmine' : null}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={"Tên phòng"}
        name={"name"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={"Mô tả"} name={"description"}>
        <Input />
      </Form.Item>
      {  // [2-4-2024][Duc Ngoc][update logic on RoomForm, hide jobItemId when roomType !== 1 ]
      roomType === 1 && (
      <Form.Item
        label={"Điểm ngủ"}
        name={"jobItemId"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Select>
          {sleepList
            .filter((sleep) => sleep.type === "tickets")
            .map((sleep) => (
              <Select.Option key={sleep.id} value={sleep.id}>
                {sleep.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      )}
      <div className={styles.formItemGroup}>
        <Form.Item
          label={"Giá bán"}
          name={"price"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Giá trả ncc"}
          name={"moneyToPay"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>

      <div className={styles.formItemGroup}>

        {  // [4-4-2024][Duc Ngoc][update logic on RoomForm, enable editing date when roomType = 1 ]
          roomType !== 0 && (
          <Form.Item
            label={"Đêm áp dụng"}
            name={"date"}
            rules={[
              {
                required: true,
                message: "Không được bỏ trống",
              },
            ]}
            initialValue={roomType === 0 ? -1 : null}
          >
            <Input disabled={roomType === 0} />
          </Form.Item>
        )}
      </div>
    </Form>
  );
};

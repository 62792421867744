import { IGroupJobItem } from "common/define-types";
import styles from "../TourCategory.module.css";
import { Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
interface JobItemCardProps {
  jobItem: IGroupJobItem;
  index: number;
  onClickAdd?: () => void;
}
export const JobItemCard = ({
  jobItem,
  index,
  onClickAdd,
}: JobItemCardProps) => {
  return (
    <div className={styles.jobCardContainer}>
      <div className={styles.jobTourWrapper}>
        <Typography.Text
          strong
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >{`${index + 1}. ${jobItem.name}`}</Typography.Text>
        <div>
          {onClickAdd && (
            <PlusOutlined onClick={onClickAdd} style={{ color: "green" }} />
          )}
        </div>
      </div>
    </div>
  );
};

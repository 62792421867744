import { Dropdown, MenuProps, Typography } from "antd";
import {
  IGroupJobItem,
  IJobItem,
  JobTicketType,
} from "common/define-types";
import {
  EditOutlined,
  DeleteOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import { useDispatchRoot } from "store/store";
import {
  editSupplierJobItem,
  removeSupplierJobItem,
  setDiscountJob,
} from "store/slice/SuppliersCategorySlice";
import useModal from "antd/es/modal/useModal";
interface JobItemProps {
  jobItem: IJobItem;
  index: number;
  parentJob: IGroupJobItem;
}
export const JobItem = ({ jobItem, index, parentJob }: JobItemProps) => {
  const [modal, contextHolder] = useModal();
  const dispatch = useDispatchRoot();

  const openDiscountJob = () => {
    dispatch(setDiscountJob(jobItem));
  };

  const editJobItem = () => {
    dispatch(editSupplierJobItem({ jobEditing: jobItem, parentJob }));
  };

  const deleteJobItem = () => {
    dispatch(removeSupplierJobItem(jobItem.id));
  };

  const getActionsMenu = (): MenuProps["items"] => {
    return [
      {
        key: "edit",
        label: "Chỉnh sửa",
        icon: <EditOutlined style={{ color: "#1890ff" }} />,
        onClick: editJobItem,
      },
      {
        key: "jobDiscount",
        label: "Chiết khấu",
        icon: <PercentageOutlined style={{ color: "#1890ff" }} />,
        onClick: openDiscountJob,
      },
      {
        key: "delete",
        label: "Xóa",
        icon: <DeleteOutlined style={{ color: "red" }} />,
        onClick: () => {
          modal.confirm({
            title: `Xóa công việc`,
            content: <>{`Xác nhận xóa ${jobItem.name} ?`}</>,
            onOk: deleteJobItem,
            centered: true,
          });
        },
      },
    ];
  };
  const getTypeItemOfJobItem = (jobItem: IJobItem) => {
    switch (jobItem.type) {
      case JobTicketType.Driver:
        return "Nhân viên";
      case JobTicketType.Motor:
        return "Xe";
      case JobTicketType.Oil:
        return "Đổ xăng";
      case JobTicketType.Tickets:
        // if (jobItem.typeItem === JobTypeItem.TICKETS) return "Khách";
        // if (jobItem.typeItem === JobTypeItem.VISITOR) return "Tham quan";
        return "Khách";

      default:
        return "";
    }
  };
  return (
    <>
      {contextHolder}
      <Dropdown trigger={["contextMenu"]} menu={{ items: getActionsMenu() }}>
        <Typography.Text>{`${index + 1}. ${
          jobItem.name
        } - ${getTypeItemOfJobItem(jobItem)}`}</Typography.Text>
      </Dropdown>
    </>
  );
};

import {
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
} from "antd";
import styles from "../Driver.module.css";
import Utils from "common/Utils";
import UploadImage from "components/Input/UploadImage";
import { useEffect, useState } from "react";
import { DriverType, IDriver } from "common/define-types";
import { useDispatchRoot } from "store/store";
import { addDriver, saveDriver } from "store/slice/DriverSlice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

interface IProps {
  form: FormInstance<any>;
  editingDriver?: IDriver | null;
}
const uploader: {
  name: keyof IDriver;
  label: string;
}[] = [
  {
    name: "pathImageCCCD",
    label: "Ảnh CCCD",
  },
  {
    name: "pathImageLicense",
    label: "Ảnh bằng lái",
  },
];
export const DriverForm = ({ form, editingDriver }: IProps) => {
  const RANGE_SALARY = {
    MAX: 5000000,
    MIN: 0,
  };

  const driverType = Form.useWatch("type", form);
  const dispatch = useDispatchRoot();
  const isEditForm = !!editingDriver;
  const [pathFiles, setPathFiles] = useState<any>(
    uploader.reduce(
      (prev, current) => ({
        ...prev,
        [current.name]: "",
      }),
      {}
    )
  );
  const handleChangePath = (
    key: keyof typeof pathFiles,
    value: string | null
  ) => {
    setPathFiles((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  // [5-12-2023] [Phuc Thinh] [set CCCD and license for driver]
  useEffect(() => {
    if (editingDriver) {
      const uploaderKeys: any[] = uploader.map((upload) => upload.name);

      let newDriverPathFiles = uploaderKeys.reduce(
        (prev, currentKey: keyof IDriver) => ({
          ...prev,
          [currentKey]:
            !!editingDriver[currentKey] && editingDriver[currentKey] !== ""
              ? editingDriver[currentKey]
              : null,
        }),
        {}
      );
      setPathFiles(newDriverPathFiles);
    }
  }, [editingDriver]);

  // useEffect(() => {
  //   if (pathFiles) {
  //     form.setFields(Utils.objectToAntFormData(pathFiles));
  //   }
  // }, [pathFiles, form]);

  const handleSaveDriver = (values: any) => {
    const driverValue = values;

    if (!driverValue) return;
    const getName = Utils.getName(driverValue.name);
    if (editingDriver) {
      dispatch(
        saveDriver({
          ...editingDriver,
          ...driverValue,
          ...getName,
          ...pathFiles,
          dateStartWork: dayjs(driverValue.dateStartWork).toISOString(),
        })
      );
    } else {
      dispatch(
        addDriver({
          ...driverValue,
          firstName: getName.firstName.trim(),
          lastName: getName.lastName,
          ...pathFiles,
          dateStartWork: dayjs(driverValue.dateStartWork).toISOString(),
        })
      );
    }
  };

  return (
    <Form
      labelCol={{ span: 24 }}
      form={form}
      initialValues={{
        type: DriverType.CONTRACT,
      }}
      onFinish={handleSaveDriver}
    >
      <div className={styles.formItemGroup}>
        <Form.Item
          label={"Tên tài xế"}
          name={"name"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* [16-12-2023][Phuc Thinh][Feature/14938] */}

        {/* <Form.Item
          label={"Mã nhân viên"}
          name={"accCode"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Input />
        </Form.Item> */}
      </div>
      <Form.Item
        label={"Loại tài xế"}
        name={"type"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Select
          options={[
            {
              label: "Theo hợp đồng",
              value: DriverType.CONTRACT,
            },
            {
              label: "Thuê ngoài",
              value: DriverType.FREELANCE,
            },
          ]}
        />
      </Form.Item>
      <div className={styles.formItemGroup}>
        <Form.Item
          label={"Giới tính"}
          name={"sex"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Select
            options={[
              {
                label: "Nam",
                value: "Male",
              },
              {
                label: "Nữ",
                value: "Female",
              },
              {
                label: "Khác",
                value: "Other",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label={"Tuổi"}
          name={"age"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || (value >= 18 && value <= 65)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Độ tuổi không hợp lệ (18 - 65)")
                );
              },
            }),
          ]}
        >
          <InputNumber style={{ width: "100%" }} controls={false} />
        </Form.Item>
      </div>
      <div className={styles.formItemGroup}>
        <Form.Item
          label={"Số điện thoại"}
          name={"phone"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
            {
              pattern: new RegExp(/(84|0[3|5|7|8|9])+([0-9]{8})\b/g),
              message: "Phải là số điện thoại VN",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {driverType === DriverType.CONTRACT && (
          <Form.Item
            label={"Email"}
            name={"email"}
            rules={[
              {
                type: "email",
                message: "Email không hợp lệ",
              },
            ]}
          >
            <Input readOnly={isEditForm} />
          </Form.Item>
        )}
      </div>
      {driverType === DriverType.CONTRACT && (
        <div className={styles.formItemGroup}>
          <Form.Item
            label={"CCCD"}
            name={"cccD1"}
            rules={[
              {
                required: true,
                message: "Không được bỏ trống",
              },
              {
                pattern: new RegExp(/(0([0-9]{11}))\b/g),
                message: "CCCD không hợp lệ",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={"Bằng lái xe"}
            name={"driveLisence1"}
            rules={[
              {
                required: true,
                message: "Không được bỏ trống",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
      )}
      <Form.Item
        label={"Địa chỉ"}
        name={"address"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input />
      </Form.Item>
      {driverType === DriverType.CONTRACT && (
        <div className={styles.formItemGroup}>
          <Form.Item
            label={"Tài khoản"}
            name={"userName"}
            rules={[
              {
                required: true,
                message: "Không được bỏ trống",
              },
              {
                pattern: new RegExp(
                  /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/
                ),
                message: "Tài khoản không hợp lệ.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={"Mật khẩu"}
            name={"password"}
            rules={[
              {
                required: !isEditForm,
                message: "Không được bỏ trống",
              },
              {
                pattern: new RegExp(
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
                ),
                message:
                  "Mật khẩu có tối thiểu 8 ký tự, phải bao gồm ít nhất 1 ký tự hoa, 1 ký tự thường, 1 chữ số và 1 ký tự đặc biệt (@$!%*?&)",
              },
            ]}
          >
            <Input.Password autoComplete="off" />
          </Form.Item>
        </div>
      )}

      <div className={styles.formItemGroup}>
        {driverType === DriverType.CONTRACT && (
          <Form.Item label={"Ngày bắt đầu làm việc"} name={"dateStartWork"}>
            <DatePicker
              allowClear={false}
              format={Utils.dateFormat}
              style={{ width: "100%" }}
            />
          </Form.Item>
        )}

        <Form.Item
          label={"Tiền công 1 ngày"}
          name={"salaryDate"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            controls={false}
            max={RANGE_SALARY.MAX}
            min={RANGE_SALARY.MIN}
            formatter={(value) =>
              `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
            }
            parser={(value) =>
              parseInt(value ? value?.replaceAll(".", "") : "0")
            }
          />
        </Form.Item>
      </div>
      {/* [4-12-2023] [Phuc Thinh] [add upload CCCD and license for driver] */}
      {uploader.map((upload) => (
        <UploadImage
          key={`driverUploader-${upload.name}`}
          {...upload}
          pathFile={pathFiles[upload.name]}
          setPathFile={(value) => handleChangePath(upload.name, value || null)}
        />
      ))}
      <Form.Item label={"Ghi chú"} name={"note"}>
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
};

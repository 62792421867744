import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ICommonResult,
  ICreateDiscount,
  ICreateGroupJobItem,
  ICreateJobItem,
  IDiscountDetail,
  IGroupJobItem,
  IJobItem,
  IParams,
  IUpdateDiscount,
  IUpdateGroupJobItem,
  IUpdateJobItem,
  RootEpic,
} from "common/define-types";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import {
  ICreateSupplier,
  ISupplier,
  IUpdateSupplier,
} from "api/types/supplier";
import {
  createSupplier,
  getAllSuppliersPageable,
  removeSupplier,
  updateSupplier,
} from "api/supplier.api";
import {
  createDiscountForJobItem,
  createJobItemForSupplier,
  deleteJobItem,
  getJobItemBySupplierId,
  updateJobItem,
} from "api/jobItem.api";
import {
  createGroupJobItem,
  deleteGroupJobItem,
  updateGroupJobItem,
} from "api/groupItem.api";
import { Key } from "react";
import {
  getDiscountByJobItemId,
  updateDiscount,
} from "api/chietKhauDetail.api";

export interface SuppliersState {
  isLoading: boolean;
  isJobsLoading: boolean;
  isSupplierSaving: boolean;
  isSupplierJobSaving: boolean;
  isSupplierJobCreating: boolean;
  addSupplierOpen: boolean;
  addGroupJobItem: {
    open: boolean;
    parentJob?: IGroupJobItem | null;
  };
  addJobItem: {
    open: boolean;
    parentJob: IGroupJobItem | null;
  };
  isAscendingSort: boolean;
  jobDiscountOpen: IJobItem | null;
  discountValue: IDiscountDetail | null;
  suppliers: ISupplier[] | [];
  suppliersResults: ICommonResult | null;
  supplierSelected: ISupplier | null;
  supplierSelectedJobs: IGroupJobItem[];
  expandedJobKeys: Key[];
  supplierJobsResults: ICommonResult | null;
  deletingSupplierId: string | null;
  deletingSupplierJobId: string | null;
  editingSupplier: ISupplier | null;
  editingGroupJob: {
    groupJobEditing: IGroupJobItem | null;
    parentJob?: IGroupJobItem | null;
  };
  editingJobItem: {
    jobEditing: IJobItem | null;
    parentJob: IGroupJobItem | null;
  };
  errMsg: string | null;
}

const initialState: SuppliersState = {
  isLoading: false,
  isJobsLoading: false,
  isSupplierSaving: false,
  isSupplierJobSaving: false,
  isSupplierJobCreating: false,
  addSupplierOpen: false,
  addGroupJobItem: {
    open: false,
  },
  addJobItem: {
    open: false,
    parentJob: null,
  },
  isAscendingSort: false,
  jobDiscountOpen: null,
  discountValue: null,
  suppliers: [],
  suppliersResults: null,
  supplierSelected: null,
  supplierJobsResults: null,
  supplierSelectedJobs: [],
  expandedJobKeys: [],
  deletingSupplierId: null,
  deletingSupplierJobId: null,
  editingSupplier: null,
  editingGroupJob: {
    groupJobEditing: null,
  },
  editingJobItem: {
    jobEditing: null,
    parentJob: null,
  },
  errMsg: null,
};

export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchSuppliers: (state, action: PayloadAction<IParams | undefined>) => {
      state.isLoading = true;
    },
    fetchSupplierJobs: (state, action: PayloadAction<IParams | undefined>) => {
      state.isJobsLoading = true;
    },
    setSuppliers: (state, action: PayloadAction<ISupplier[]>) => {
      state.suppliers = action.payload;
      state.isLoading = false;
      state.isSupplierSaving = false;
      state.isSupplierJobCreating = false;
      state.deletingSupplierId = null;
    },
    setSuppliersResults: (state, action: PayloadAction<any>) => {
      state.suppliersResults = action.payload;
      state.isLoading = false;
      state.isSupplierSaving = false;
      state.isSupplierJobCreating = false;
    },
    setSupplierJobs: (state, action: PayloadAction<IGroupJobItem[]>) => {
      state.supplierSelectedJobs = action.payload;
      state.isLoading = false;
      state.isJobsLoading = false;
    },
    setExpandedJobKeys: (state, action: PayloadAction<Key[]>) => {
      state.expandedJobKeys = action.payload;
    },
    setSupplierJobsResults: (
      state,
      action: PayloadAction<ICommonResult | null>
    ) => {
      state.supplierJobsResults = action.payload;
    },
    setSupplierSelected: (state, action: PayloadAction<ISupplier | null>) => {
      state.supplierSelected = action.payload;
      state.expandedJobKeys = [];
      //[2-4-2024][Phuc Thinh][Fix only loading when had supplier selected]
      state.isJobsLoading = !!action.payload;
      state.errMsg = null;
    },
    setAscendingSort: (state, action: PayloadAction<boolean>) => {
      state.isAscendingSort = action.payload;
    },
    deleteSupplier: (state, action: PayloadAction<string>) => {
      state.deletingSupplierId = action.payload;
    },
    editSupplier: (state, action: PayloadAction<ISupplier | null>) => {
      state.editingSupplier = action.payload;
    },
    editSupplierJobItem: (
      state,
      action: PayloadAction<{
        jobEditing: IJobItem | null;
        parentJob: IGroupJobItem | null;
      }>
    ) => {
      state.editingJobItem = action.payload;
      state.isSupplierJobSaving = false;
    },
    editSupplierGroupJobItem: (
      state,
      action: PayloadAction<{
        groupJobEditing: IGroupJobItem | null;
        parentJob?: IGroupJobItem | null;
      }>
    ) => {
      state.editingGroupJob = action.payload;
      state.isSupplierJobSaving = false;
    },
    deleteSupplierFullfilled: (state, action: PayloadAction<number>) => {
      if (action.payload === 1) {
        const newSuppliers = state.suppliers.map((supplier) =>
          supplier.id === state.deletingSupplierId
            ? {
                ...supplier,
                isErase: true,
              }
            : supplier
        );
        state.suppliers = newSuppliers;
        if (
          !newSuppliers.some(
            (supplier) => supplier.id === state.supplierSelected?.id
          )
        ) {
          state.supplierSelected = newSuppliers[0];
        }
      } else {
        state.errMsg = "Có lỗi xảy ra";
      }
      state.deletingSupplierId = null;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.deletingSupplierId = null;
      state.isJobsLoading = false;
    },
    addSupplier: (state, action: PayloadAction<ICreateSupplier>) => {
      state.isSupplierSaving = true;
      state.errMsg = null;
    },
    saveSupplier: (state, action: PayloadAction<IUpdateSupplier>) => {
      state.isSupplierSaving = true;
      state.errMsg = null;
    },
    saveSupplierJob: (state, action: PayloadAction<IGroupJobItem>) => {
      state.isSupplierJobSaving = true;
      state.errMsg = null;
    },
    setAddSupplierOpen: (state, action: PayloadAction<boolean>) => {
      state.addSupplierOpen = action.payload;
      state.isSupplierSaving = false;
    },
    setAddSupplierJobItemOpen: (
      state,
      action: PayloadAction<{ open: boolean; parentJob: IGroupJobItem | null }>
    ) => {
      state.addJobItem = action.payload;
      state.isSupplierJobCreating = false;
    },
    setAddSupplierGroupJobOpen: (
      state,
      action: PayloadAction<{ open: boolean; parentJob?: IGroupJobItem | null }>
    ) => {
      state.addGroupJobItem = action.payload;
      state.isSupplierJobCreating = false;
    },
    setDiscountJob: (state, action: PayloadAction<IJobItem | null>) => {
      state.jobDiscountOpen = action.payload;
      if (!action.payload) {
        state.discountValue = null;
      }
    },
    setJobItemDiscountValue: (
      state,
      action: PayloadAction<IDiscountDetail | null>
    ) => {
      state.discountValue = action.payload;
    },
    addSupplierJobItem: (state, action: PayloadAction<ICreateJobItem>) => {
      state.isSupplierJobCreating = true;
    },
    saveSupplierJobItem: (state, action: PayloadAction<IUpdateJobItem>) => {
      state.isSupplierJobSaving = true;
    },
    removeSupplierJobItem: (state, action: PayloadAction<string>) => {},
    addSupplierGroupJobItem: (
      state,
      action: PayloadAction<ICreateGroupJobItem>
    ) => {
      state.isSupplierJobCreating = true;
    },
    saveSupplierGroupJobItem: (
      state,
      action: PayloadAction<IUpdateGroupJobItem>
    ) => {
      state.isSupplierJobSaving = true;
    },
    removeSupplierGroupJobItem: (state, action: PayloadAction<string>) => {},
    addJobItemDiscount: (
      state,
      action: PayloadAction<{ jobItemId: string; body: ICreateDiscount }>
    ) => {},
    saveJobItemDiscount: (state, action: PayloadAction<IUpdateDiscount>) => {},
  },
});
const getSuppliers$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchSuppliers.match),
    switchMap((re) => {
      return getAllSuppliersPageable({
        ...re.payload,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const { results, ...rest } = res;
            const suppliers = [...results].sort((a, b) =>
              state$.value.suppliersCategory.isAscendingSort
                ? a.name.localeCompare(b.name, "vn")
                : b.name.localeCompare(a.name, "vn")
            );
            if (suppliers.length > 0) {
              return [
                suppliersSlice.actions.setSuppliers(suppliers),
                suppliersSlice.actions.setSupplierSelected(suppliers[0]),
                suppliersSlice.actions.setSuppliersResults(rest),
              ];
            }
            return [
              suppliersSlice.actions.setSuppliers([]),
              //[2-4-2024][Phuc Thinh][Reset jobs state when return no supplier]
              suppliersSlice.actions.setSupplierJobs([]),
              suppliersSlice.actions.setSupplierSelected(null),
              suppliersSlice.actions.setSuppliersResults(null),
              suppliersSlice.actions.setSupplierJobsResults(null),
            ];
          } else {
            return [suppliersSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setSuppliers([]),
          suppliersSlice.actions.setSupplierSelected(null),
          suppliersSlice.actions.setSuppliersResults(null),
          suppliersSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra"
          ),
        ])
      );
    })
  );

const getSupplierJobs$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setSupplierSelected.match),
    switchMap((re) => {
      if (!re.payload) return [];
      return getJobItemBySupplierId(re.payload.id).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.errors && res.results) {
            const { results: jobs, ...rest } = res;
            return [
              suppliersSlice.actions.setSupplierJobs(jobs ?? []),
              suppliersSlice.actions.setSupplierJobsResults(rest),
            ];
          } else {
            return [
              suppliersSlice.actions.setSupplierJobs([]),
              suppliersSlice.actions.setSupplierJobsResults(null),
              suppliersSlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setSupplierJobs([]),
          suppliersSlice.actions.setSupplierJobsResults(null),
          suppliersSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi lấy danh sách điểm đến"
          ),
        ])
      );
    })
  );
const fetchSupplierJobs$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchSupplierJobs.match),
    switchMap((re) => {
      if (!state$.value.suppliersCategory.supplierSelected) return [];
      return getJobItemBySupplierId(
        state$.value.suppliersCategory.supplierSelected.id,
        re.payload
      ).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.errors && res.results) {
            const { results: jobs, ...rest } = res;
            return [
              suppliersSlice.actions.setSupplierJobs(jobs ?? []),
              suppliersSlice.actions.setSupplierJobsResults(rest),
            ];
          } else {
            return [
              suppliersSlice.actions.setSupplierJobs([]),
              suppliersSlice.actions.setSupplierJobsResults(null),
              suppliersSlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setSupplierJobs([]),
          suppliersSlice.actions.setSupplierJobsResults(null),
          suppliersSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi lấy danh sách điểm đến"
          ),
        ])
      );
    })
  );
const addSupplier$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addSupplier.match),
    switchMap((re) => {
      return createSupplier([re.payload]).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const supplierResults =
              state$.value.suppliersCategory.suppliersResults;
            if (supplierResults) {
              return [
                suppliersSlice.actions.setAddSupplierOpen(false),
                suppliersSlice.actions.fetchSuppliers({
                  ...supplierResults,
                }),
              ];
            } else {
              return [
                suppliersSlice.actions.setAddSupplierOpen(false),
                suppliersSlice.actions.fetchSuppliers(),
              ];
            }
          } else {
            return [suppliersSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi tạo nhà cung cấp"
          ),
        ])
      );
    })
  );
const saveSupplier$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveSupplier.match),
    switchMap((re) => {
      return updateSupplier(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const supplierResults =
              state$.value.suppliersCategory.suppliersResults;
            if (supplierResults) {
              return [
                suppliersSlice.actions.editSupplier(null),
                suppliersSlice.actions.fetchSuppliers({
                  ...supplierResults,
                }),
              ];
            } else {
              return [
                suppliersSlice.actions.editSupplier(null),
                suppliersSlice.actions.fetchSuppliers(),
              ];
            }
          } else {
            return [suppliersSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi lưu thông tin"
          ),
        ])
      );
    })
  );
const deleteSupplier$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteSupplier.match),
    switchMap((re) => {
      return removeSupplier(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const supplierResults =
              state$.value.suppliersCategory.suppliersResults;
            if (supplierResults) {
              return [
                suppliersSlice.actions.fetchSuppliers({
                  ...supplierResults,
                  page: 1,
                }),
              ];
            } else {
              return [suppliersSlice.actions.fetchSuppliers()];
            }
          } else {
            return [suppliersSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi xóa"
          ),
        ])
      );
    })
  );

const addGroupJobItem$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addSupplierGroupJobItem.match),
    switchMap((re) => {
      return createGroupJobItem(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const supplierJobResults =
              state$.value.suppliersCategory.supplierJobsResults;
            if (supplierJobResults) {
              return [
                suppliersSlice.actions.setAddSupplierGroupJobOpen({
                  open: false,
                  parentJob: null,
                }),
                suppliersSlice.actions.fetchSupplierJobs({
                  ...supplierJobResults,
                }),
              ];
            } else {
              return [
                suppliersSlice.actions.setAddSupplierGroupJobOpen({
                  open: false,
                  parentJob: null,
                }),
                suppliersSlice.actions.fetchSupplierJobs(),
              ];
            }
          } else {
            return [suppliersSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra"
          ),
        ])
      );
    })
  );
const saveGroupJobItem$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveSupplierGroupJobItem.match),
    switchMap((re) => {
      return updateGroupJobItem(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const supplierJobResults =
              state$.value.suppliersCategory.supplierJobsResults;
            if (supplierJobResults) {
              return [
                suppliersSlice.actions.editSupplierGroupJobItem({
                  groupJobEditing: null,
                  parentJob: undefined,
                }),
                suppliersSlice.actions.fetchSupplierJobs({
                  ...supplierJobResults,
                }),
              ];
            } else {
              return [
                suppliersSlice.actions.editSupplierGroupJobItem({
                  groupJobEditing: null,
                  parentJob: undefined,
                }),
                suppliersSlice.actions.fetchSupplierJobs(),
              ];
            }
          } else {
            return [suppliersSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi lưu thông tin"
          ),
        ])
      );
    })
  );
const removeGroupJobItem$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(removeSupplierGroupJobItem.match),
    switchMap((re) => {
      return deleteGroupJobItem(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const supplierJobResults =
              state$.value.suppliersCategory.supplierJobsResults;
            if (supplierJobResults) {
              return [
                suppliersSlice.actions.fetchSupplierJobs({
                  ...supplierJobResults,
                }),
              ];
            } else {
              return [suppliersSlice.actions.fetchSupplierJobs()];
            }
          } else {
            return [suppliersSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi xóa"
          ),
        ])
      );
    })
  );

const addJobItem$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addSupplierJobItem.match),
    switchMap((re) => {
      return createJobItemForSupplier(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const supplierJobResults =
              state$.value.suppliersCategory.supplierJobsResults;
            if (supplierJobResults) {
              return [
                suppliersSlice.actions.setAddSupplierJobItemOpen({
                  open: false,
                  parentJob: null,
                }),
                suppliersSlice.actions.fetchSupplierJobs({
                  ...supplierJobResults,
                }),
              ];
            } else {
              return [
                suppliersSlice.actions.setAddSupplierJobItemOpen({
                  open: false,
                  parentJob: null,
                }),
                suppliersSlice.actions.fetchSupplierJobs(),
              ];
            }
          } else {
            return [suppliersSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi tạo nhà công việc"
          ),
        ])
      );
    })
  );
const saveJobitem$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveSupplierJobItem.match),
    switchMap((re) => {
      return updateJobItem(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const supplierJobResults =
              state$.value.suppliersCategory.supplierJobsResults;
            if (supplierJobResults) {
              return [
                suppliersSlice.actions.editSupplierJobItem({
                  jobEditing: null,
                  parentJob: null,
                }),
                suppliersSlice.actions.fetchSupplierJobs({
                  ...supplierJobResults,
                }),
              ];
            } else {
              return [
                suppliersSlice.actions.editSupplierJobItem({
                  jobEditing: null,
                  parentJob: null,
                }),
                suppliersSlice.actions.fetchSupplierJobs(),
              ];
            }
          } else {
            return [suppliersSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi lưu thông tin"
          ),
        ])
      );
    })
  );
const removeJobItem$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(removeSupplierJobItem.match),
    switchMap((re) => {
      return deleteJobItem(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const supplierJobResults =
              state$.value.suppliersCategory.supplierJobsResults;
            if (supplierJobResults) {
              return [
                suppliersSlice.actions.fetchSupplierJobs({
                  ...supplierJobResults,
                }),
              ];
            } else {
              return [suppliersSlice.actions.fetchSupplierJobs()];
            }
          } else {
            return [suppliersSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi xóa điểm đến"
          ),
        ])
      );
    })
  );
const addJobItemDiscount$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addJobItemDiscount.match),
    switchMap((re) => {
      return createDiscountForJobItem(
        re.payload.jobItemId,
        re.payload.body
      ).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [suppliersSlice.actions.setDiscountJob(null)];
          } else {
            return [suppliersSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi tạo chiết khấu"
          ),
        ])
      );
    })
  );
const saveJobItemDiscount$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveJobItemDiscount.match),
    switchMap((re) => {
      return updateDiscount(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (res && !res?.response?.error) {
              return [suppliersSlice.actions.setDiscountJob(null)];
            } else {
              return [suppliersSlice.actions.setErrMsg(res?.response.error)];
            }
          } else {
            return [suppliersSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi xóa điểm đến"
          ),
        ])
      );
    })
  );
const getDiscountValue$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setDiscountJob.match),
    switchMap((re) => {
      if (!re.payload) return [];
      return getDiscountByJobItemId(re.payload.id).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [suppliersSlice.actions.setJobItemDiscountValue(res)];
          } else {
            return [suppliersSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          suppliersSlice.actions.setErrMsg(e.response?.Message || ""),
          suppliersSlice.actions.setJobItemDiscountValue(null),
        ])
      );
    })
  );
export const {
  fetchSuppliers,
  fetchSupplierJobs,
  setSuppliersResults,
  setSupplierJobs,
  setSupplierSelected,
  setSuppliers,
  deleteSupplier,
  deleteSupplierFullfilled,
  editSupplier,
  editSupplierJobItem,
  editSupplierGroupJobItem,
  setErrMsg,
  saveSupplier,
  setAddSupplierOpen,
  setDiscountJob,
  setAscendingSort,
  setSupplierJobsResults,
  saveSupplierJob,
  setAddSupplierGroupJobOpen,
  setAddSupplierJobItemOpen,
  addSupplier,
  addSupplierGroupJobItem,
  addSupplierJobItem,
  saveSupplierGroupJobItem,
  saveSupplierJobItem,
  setExpandedJobKeys,
  removeSupplierJobItem,
  removeSupplierGroupJobItem,
  addJobItemDiscount,
  saveJobItemDiscount,
} = suppliersSlice.actions;

export const SuppliersEpics = [
  getSuppliers$,
  getSupplierJobs$,
  fetchSupplierJobs$,
  addSupplier$,
  saveSupplier$,
  deleteSupplier$,
  addGroupJobItem$,
  saveGroupJobItem$,
  addJobItem$,
  saveJobitem$,
  removeGroupJobItem$,
  removeJobItem$,
  addJobItemDiscount$,
  saveJobItemDiscount$,
  getDiscountValue$,
];

export const suppliersReducer = suppliersSlice.reducer;

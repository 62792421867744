import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "./Suppliers.module.css";
import { SupplierJobList } from "./components/SupplierJobList";
import { SupplierList } from "./components/SupplierList";
import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";
import { SupplierForm } from "./components/SupplierForm";
import { Modal, notification } from "antd";
import {
  addJobItemDiscount,
  addSupplier,
  addSupplierGroupJobItem,
  addSupplierJobItem,
  editSupplier,
  editSupplierGroupJobItem,
  editSupplierJobItem,
  fetchSuppliers,
  saveJobItemDiscount,
  saveSupplier,
  saveSupplierGroupJobItem,
  saveSupplierJobItem,
  setAddSupplierGroupJobOpen,
  setAddSupplierJobItemOpen,
  setAddSupplierOpen,
  setDiscountJob,
  setErrMsg,
} from "store/slice/SuppliersCategorySlice";
import { GroupJobForm } from "./components/GroupJobForm";
import { JobDiscountForm } from "./components/JobDiscountForm";
import { JobItemForm } from "./components/JobItemForm";
import {
  GroupItemType,
  ICreateDiscount,
  IGroupJobItem,
  JobDiscountType,
} from "common/define-types";
import { DiscountType } from "components/Form/DiscountForm";

export const Suppliers = () => {
  const selectAddSupplierOpen = useSelectorRoot(
    (state) => state.suppliersCategory.addSupplierOpen
  );
  const selectSupplierEditing = useSelectorRoot(
    (state) => state.suppliersCategory.editingSupplier
  );
  const selectSupplierSelected = useSelectorRoot(
    (state) => state.suppliersCategory.supplierSelected
  );

  const selectAddSupplierJobItem = useSelectorRoot(
    (state) => state.suppliersCategory.addJobItem
  );
  const selectAddSupplierGroupJobItem = useSelectorRoot(
    (state) => state.suppliersCategory.addGroupJobItem
  );
  const selectSupplierJobEditing = useSelectorRoot(
    (state) => state.suppliersCategory.editingJobItem
  );
  const selectSupplierGroupJobEditing = useSelectorRoot(
    (state) => state.suppliersCategory.editingGroupJob
  );
  const selectDiscountJobOpen = useSelectorRoot(
    (state) => state.suppliersCategory.jobDiscountOpen
  );
  const selectJobItemDiscountValue = useSelectorRoot(
    (state) => state.suppliersCategory.discountValue
  );
  const selectIsRefreshing = useSelectorRoot(
    (state) => state.login.isRefreshing
  );
  const selectErrMsg = useSelectorRoot(
    (state) => state.suppliersCategory.errMsg
  );
  const [supplierForm] = useForm();
  const [jobDiscountForm] = useForm();
  const [discountForm] = useForm();
  const [groupJobForm] = useForm();
  const [jobItemForm] = useForm();
  const dispatch = useDispatchRoot();

  useEffect(() => {
    !selectIsRefreshing && dispatch(fetchSuppliers({ pageSize: 25 }));
  }, [dispatch, selectIsRefreshing]);

  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({
        message: selectErrMsg,
      });
      dispatch(setErrMsg(null));
    }
  }, [selectErrMsg, dispatch]);

  const closeAddSupplier = () => {
    dispatch(setAddSupplierOpen(false));
  };
  const handleCreateSupplier = async () => {
    const supplierValue = await supplierForm
      .validateFields()
      .catch((err) => console.log(err));
    if (!supplierValue) return;
    dispatch(addSupplier(supplierValue));
  };

  const closeEditSupplier = () => {
    dispatch(editSupplier(null));
  };
  const handleSaveSupplier = async () => {
    const supplierValue = await supplierForm
      .validateFields()
      .catch((err) => console.log(err));
    if (!supplierValue || !selectSupplierEditing) return;
    dispatch(
      saveSupplier({
        ...selectSupplierEditing,
        ...supplierValue,
      })
    );
  };

  const closeAddSupplierJobItem = () => {
    dispatch(
      setAddSupplierJobItemOpen({
        open: false,
        parentJob: null,
      })
    );
  };
  const handleCreateSupplierJobItem = async () => {
    const jobItemValue = await jobItemForm
      .validateFields()
      .catch((err) => console.log(err));
    if (
      !jobItemValue ||
      !selectSupplierSelected ||
      !selectAddSupplierJobItem.parentJob
    )
      return;
    dispatch(
      addSupplierJobItem({
        ...jobItemValue,
        supplierId: selectSupplierSelected.id,
        groupItemId: selectAddSupplierJobItem.parentJob.id,
      })
    );
  };

  const closeAddSupplierGroupJobItem = () => {
    dispatch(
      setAddSupplierGroupJobOpen({
        open: false,
        parentJob: null,
      })
    );
  };
  const handleCreateSupplierGroupJobItem = async (
    parentJob?: IGroupJobItem | null
  ) => {
    const groupJobItemValue = await groupJobForm
      .validateFields()
      .catch((err) => console.log(err));
    if (!groupJobItemValue || !selectSupplierSelected) return;
    dispatch(
      addSupplierGroupJobItem({
        name: groupJobItemValue.name,
        supplierId: selectSupplierSelected.id,
        parentId: parentJob ? parentJob.id : undefined,
        type: parentJob ? GroupItemType.Child : GroupItemType.Parent,
      })
    );
  };

  const closeEditJobItem = () => {
    dispatch(
      editSupplierJobItem({
        jobEditing: null,
        parentJob: null,
      })
    );
  };
  const handleSaveJobItem = async () => {
    const jobItemValue = await jobItemForm
      .validateFields()
      .catch((err) => console.log(err));
    if (
      !jobItemValue ||
      !selectSupplierSelected ||
      !selectSupplierJobEditing.jobEditing ||
      !selectSupplierJobEditing.parentJob
    )
      return;
    dispatch(
      saveSupplierJobItem({
        ...selectSupplierJobEditing.jobEditing,
        ...jobItemValue,
        supplierId: selectSupplierSelected.id,
        groupItemId: selectSupplierJobEditing.parentJob.id,
      })
    );
  };

  const closeEditGroupJobItem = () => {
    dispatch(
      editSupplierGroupJobItem({
        groupJobEditing: null,
        parentJob: null,
      })
    );
  };
  const handleSaveGroupJobItem = async () => {
    const groupJobItemValue = await groupJobForm
      .validateFields()
      .catch((err) => console.log(err));
    if (
      !groupJobItemValue ||
      !selectSupplierSelected ||
      !selectSupplierGroupJobEditing.groupJobEditing
    )
      return;
    dispatch(
      saveSupplierGroupJobItem({
        ...selectSupplierGroupJobEditing.groupJobEditing,
        name: groupJobItemValue.name,
        groupItemDTOs: undefined,
        supplierId: selectSupplierSelected.id,
      })
    );
  };

  // const handleCreateSupplierJob = async () => {};

  // const closeEditSupplierJob = () => {
  //   dispatch(
  //     editSupplierJob({
  //       jobEditing: null,
  //       parentJob: null,
  //     })
  //   );
  // };
  // const handleSaveSupplierJob = async () => {};

  const closeDiscountJob = () => {
    dispatch(setDiscountJob(null));
  };
  const handleSubmitDiscountJob = async () => {
    const jobDiscountFormValue = await jobDiscountForm
      .validateFields()
      .catch((err) => {
        console.log(err);
      });
    const discountFormValue = await discountForm
      .validateFields()
      .catch((err) => {
        console.log(err);
      });
    if (selectDiscountJobOpen) {
      let payload: ICreateDiscount = {
        soLuongChietKhau: 0,
        discountPercent: 0,
        discountValue: 0,
        valueFrom: 0,
        valueTo: 0,
        type: jobDiscountFormValue.type,
      };
      switch (jobDiscountFormValue.type) {
        case JobDiscountType.ChietKhauNumberGroup:
          payload = {
            ...payload,
            valueFrom: jobDiscountFormValue.valueFrom,
            valueTo: jobDiscountFormValue.valueFrom,
          };
          switch (discountFormValue.type) {
            case DiscountType.BY_PERCENT:
              payload = {
                ...payload,
                discountPercent: discountFormValue.amount,
                soLuongChietKhau: 0,
              };
              break;
            case DiscountType.BY_VALUE:
              payload = {
                ...payload,
                soLuongChietKhau: discountFormValue.amount,
                discountPercent: 0,
              };
              break;

            default:
              break;
          }
          break;

        case JobDiscountType.ChietKhauNumberPeople:
          payload = {
            ...payload,
            valueFrom: jobDiscountFormValue.valueFrom,
            valueTo: jobDiscountFormValue.valueTo,
          };
          switch (discountFormValue.type) {
            case DiscountType.BY_PERCENT:
              payload = {
                ...payload,
                discountPercent: discountFormValue.amount,
                soLuongChietKhau: 0,
              };
              break;
            case DiscountType.BY_VALUE:
              payload = {
                ...payload,
                soLuongChietKhau: discountFormValue.amount,
                discountPercent: 0,
              };
              break;

            default:
              break;
          }
          break;

        case JobDiscountType.ChietKhauBill:
          payload = {
            ...payload,
            valueFrom: jobDiscountFormValue.valueFrom,
            valueTo: jobDiscountFormValue.valueTo,
          };
          switch (discountFormValue.type) {
            case DiscountType.BY_PERCENT:
              payload = {
                ...payload,
                discountPercent: discountFormValue.amount,
                discountValue: 0,
              };
              break;
            case DiscountType.BY_VALUE:
              payload = {
                ...payload,
                discountValue: discountFormValue.amount,
                discountPercent: 0,
              };
              break;

            default:
              break;
          }
          break;

        default:
          break;
      }

      if (selectJobItemDiscountValue) {
        dispatch(
          saveJobItemDiscount({
            ...selectJobItemDiscountValue,
            ...payload,
          })
        );
        console.log({
          ...selectJobItemDiscountValue,
          ...payload,
        });
      } else {
        dispatch(
          addJobItemDiscount({
            jobItemId: selectDiscountJobOpen.id,
            body: payload,
          })
        );
      }
    }
  };

  return (
    <div className={styles.agentsContainer}>
      <div className={styles.container}>
        <SupplierList />
        <SupplierJobList />
      </div>
      {
        <Modal
          open={!!selectDiscountJobOpen}
          title={`Chiết khấu của ${selectDiscountJobOpen?.name}`}
          onCancel={closeDiscountJob}
          afterClose={() => {
            jobDiscountForm.resetFields();
            discountForm.resetFields();
          }}
          destroyOnClose
          okText="Lưu"
          cancelText="Hủy"
          onOk={handleSubmitDiscountJob}
        >
          <JobDiscountForm
            forms={{ jobDiscountForm, discountForm }}
            discountValue={selectJobItemDiscountValue}
          />
        </Modal>
      }
      {selectAddSupplierOpen && (
        <Modal
          open={selectAddSupplierOpen}
          title="Thêm nhà cung cấp"
          onCancel={closeAddSupplier}
          afterClose={() => supplierForm.resetFields()}
          destroyOnClose
          okText="Lưu"
          cancelText="Hủy"
          onOk={handleCreateSupplier}
        >
          <SupplierForm form={supplierForm} />
        </Modal>
      )}
      {selectSupplierEditing !== null && (
        <Modal
          open={!!selectSupplierEditing}
          title="Thông tin nhà cung cấp"
          okText="Lưu"
          cancelText="Hủy"
          afterClose={() => supplierForm.resetFields()}
          destroyOnClose
          onCancel={closeEditSupplier}
          onOk={handleSaveSupplier}
        >
          <SupplierForm
            form={supplierForm}
            editingSupplier={selectSupplierEditing}
          />
        </Modal>
      )}

      {!!selectAddSupplierJobItem && (
        <Modal
          open={selectAddSupplierJobItem.open}
          title={`Thêm công việc cho ${selectAddSupplierJobItem.parentJob?.name}`}
          okText="Lưu"
          cancelText="Hủy"
          afterClose={() => {
            jobItemForm.resetFields();
          }}
          destroyOnClose
          onCancel={closeAddSupplierJobItem}
          onOk={handleCreateSupplierJobItem}
        >
          <JobItemForm form={jobItemForm} />
        </Modal>
      )}

      {!!selectAddSupplierGroupJobItem &&
        (!selectAddSupplierGroupJobItem.parentJob ? (
          <Modal
            open={selectAddSupplierGroupJobItem.open}
            title="Thêm điểm đến"
            onCancel={closeAddSupplierGroupJobItem}
            destroyOnClose
            afterClose={() => {
              groupJobForm.resetFields();
            }}
            okText="Lưu"
            cancelText="Hủy"
            onOk={() => handleCreateSupplierGroupJobItem()}
          >
            <GroupJobForm form={groupJobForm} />
          </Modal>
        ) : (
          <Modal
            open={selectAddSupplierGroupJobItem.open}
            title={`Thêm Nhóm công việc cho ${selectAddSupplierGroupJobItem.parentJob?.name}`}
            onCancel={closeAddSupplierGroupJobItem}
            afterClose={() => groupJobForm.resetFields()}
            destroyOnClose
            okText="Lưu"
            cancelText="Hủy"
            onOk={() =>
              handleCreateSupplierGroupJobItem(
                selectAddSupplierGroupJobItem.parentJob
              )
            }
          >
            <GroupJobForm form={groupJobForm} />
          </Modal>
        ))}

      {!!selectSupplierJobEditing && (
        <Modal
          open={!!selectSupplierJobEditing.jobEditing}
          title={`Sửa công việc cho ${selectSupplierJobEditing.parentJob?.name}`}
          okText="Lưu"
          cancelText="Hủy"
          afterClose={() => {
            jobItemForm.resetFields();
          }}
          destroyOnClose
          onCancel={closeEditJobItem}
          onOk={handleSaveJobItem}
        >
          <JobItemForm
            form={jobItemForm}
            editingJobItem={selectSupplierJobEditing.jobEditing || undefined}
          />
        </Modal>
      )}

      {!!selectSupplierGroupJobEditing &&
        (!selectSupplierGroupJobEditing.parentJob ? (
          <Modal
            open={!!selectSupplierGroupJobEditing.groupJobEditing}
            title="Sửa điểm đến"
            onCancel={closeEditGroupJobItem}
            afterClose={() => groupJobForm.resetFields()}
            destroyOnClose
            okText="Lưu"
            cancelText="Hủy"
            onOk={handleSaveGroupJobItem}
          >
            <GroupJobForm
              form={groupJobForm}
              editingGroupJob={
                selectSupplierGroupJobEditing.groupJobEditing || undefined
              }
            />
          </Modal>
        ) : (
          <Modal
            open={!!selectSupplierGroupJobEditing.groupJobEditing}
            title={`Sửa Nhóm công việc cho ${selectSupplierGroupJobEditing.parentJob?.name}`}
            onCancel={closeEditGroupJobItem}
            afterClose={() => groupJobForm.resetFields()}
            destroyOnClose
            okText="Lưu"
            cancelText="Hủy"
            onOk={handleSaveGroupJobItem}
          >
            <GroupJobForm
              form={groupJobForm}
              editingGroupJob={
                selectSupplierGroupJobEditing.groupJobEditing || undefined
              }
            />
          </Modal>
        ))}
    </div>
  );
};
